import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AccountId: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  AchievementId: any;
  StoryId: any;
  StoryBlockId: any;
  StoryBlockLabel: any;
  BenefactorAchievementId: any;
  BenefactorId: any;
  CloudPaymentsSavedCardId: any;
  CoinsAccumulationId: any;
  SkillId: any;
  /** The `TimeSpan` scalar represents an ISO-8601 compliant duration type. */
  TimeSpan: any;
  FoundationId: any;
  FoundationEventId: any;
  FoundationEventAttendanceId: any;
  FoundationEventApplicationId: any;
  WardId: any;
  NeedId: any;
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: any;
  CategoryId: any;
  DonationSubscriptionId: any;
  SpecialProjectId: any;
  OrderId: any;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
  VoucherId: any;
  BroadcastNotificationId: any;
  NotificationId: any;
  BenefactorStatusId: any;
  DonationId: any;
  CityId: any;
  ClientVersionId: any;
  LimitedLiabilityCompanyReportId: any;
  ProjectExpenseId: any;
  RegularNeedPeriodId: any;
  SkillCategoryId: any;
  LimitedLiabilityCompanyId: any;
};


/** Счёт для накопления пожертвований */
export type Account = {
  /** Количество денег на счёте */
  amount: Scalars['Int'];
  /** Дата и время закрытия счёта */
  closedAt?: Maybe<Scalars['DateTime']>;
  /** Пожертвования */
  donations?: Maybe<DonationsConnection>;
  id: Scalars['AccountId'];
  /** Счета, с которых можно перевести средства на данный счёт */
  transferSources: Array<Account>;
  /** Дата и время последнего обновления счёта */
  updatedAt: Scalars['DateTime'];
};


/** Счёт для накопления пожертвований */
export type AccountDonationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<DonationSortInput>>;
};


/** Разрешения, относящиеся к действиям со счетами */
export type AccountsPermissions = {
  __typename?: 'AccountsPermissions';
  /** Текущий пользователь может менять состояние счетов */
  changeStates: Scalars['Boolean'];
  /** Текущий пользователь может создавать или изменять данные счетов-депозитов */
  manageDepositAccounts: Scalars['Boolean'];
  /** Текущий пользователь может управлять документами счетов */
  manageDocuments: Scalars['Boolean'];
  /** Текущий пользователь может переводить средства между счетами */
  transferFunds: Scalars['Boolean'];
  /** Текущий пользователь может видеть список счетов-депозитов жертвователя */
  viewDepositAccounts: Scalars['Boolean'];
};

/** Счёт, возможно имеющий ограничение на количество средств */
export type AccountWithOptionalRequiredAmount = {
  /** Требуемое количество средств */
  requiredAmount?: Maybe<Scalars['Int']>;
};

/** Счёт, имеющий ограничение на количество средств */
export type AccountWithRequiredAmount = {
  /** Требуемое количество средств */
  requiredAmount: Scalars['Int'];
};

/** Достижение */
export type Achievement = {
  benefactors?: Maybe<BenefactorAchievementsConnection>;
  /** Количество монет, которое требуется для получения достижения */
  coins: Scalars['Int'];
  /** Информация о создании достижения */
  created: AuditMarker;
  /** Описание достижения */
  description: Scalars['String'];
  id: Scalars['AchievementId'];
  /** Изображение достижения в ленте */
  image: FileInformation;
  /** Достижение выделяется? */
  isHighlighted: Scalars['Boolean'];
  /** Информация о публикации достижения */
  published?: Maybe<AuditMarker>;
  /** История, содержащая описание достижения и/или награду. */
  story: Story;
  /** Название достижения */
  title: Scalars['String'];
  /** Изображение достижения в списке топ-N достижений */
  topImage?: Maybe<FileInformation>;
  /** Позиция достижения в топе */
  topPosition?: Maybe<Scalars['Int']>;
  /** Информация о последнем обновлении достижения */
  updated: AuditMarker;
};


/** Достижение */
export type AchievementBenefactorsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A connection to a list of items. */
export type AchievementsConnection = {
  __typename?: 'AchievementsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AchievementsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Achievement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AchievementsEdge = {
  __typename?: 'AchievementsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Achievement>;
};

/** Разрешения, относящиеся к действиям с достижениями. */
export type AchievementsPermissions = {
  __typename?: 'AchievementsPermissions';
  /** Текущий пользователь может назначать монеты жертвователям. */
  assignCoins: Scalars['Boolean'];
  /** Текущий пользователь может управлять достижениями. */
  manage: Scalars['Boolean'];
  /** Текущий пользователь может просматривать достижения. */
  view: Scalars['Boolean'];
};

/** Достижение с ограниченным количеством наград */
export type AchievementWithLimitedNumberOfPrizes = {
  benefactors?: Maybe<BenefactorAchievementsConnection>;
  /** Количество монет, которое требуется для получения достижения */
  coins: Scalars['Int'];
  /** Информация о создании достижения */
  created: AuditMarker;
  /** Описание достижения */
  description: Scalars['String'];
  id: Scalars['AchievementId'];
  /** Изображение достижения в ленте */
  image: FileInformation;
  /** Достижение выделяется? */
  isHighlighted: Scalars['Boolean'];
  /** Количество выданных призов */
  numberOfGrantedPrizes: Scalars['Int'];
  /** Информация о публикации достижения */
  published?: Maybe<AuditMarker>;
  /** История, содержащая описание достижения и/или награду. */
  story: Story;
  /** Название достижения */
  title: Scalars['String'];
  /** Изображение достижения в списке топ-N достижений */
  topImage?: Maybe<FileInformation>;
  /** Позиция достижения в топе */
  topPosition?: Maybe<Scalars['Int']>;
  /** Общее количество призов */
  totalNumberOfPrizes: Scalars['Int'];
  /** Информация о последнем обновлении достижения */
  updated: AuditMarker;
};


/** Достижение с ограниченным количеством наград */
export type AchievementWithLimitedNumberOfPrizesBenefactorsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Достижение с материальными наградами */
export type AchievementWithMaterialPrizes = Achievement & AchievementWithLimitedNumberOfPrizes & {
  __typename?: 'AchievementWithMaterialPrizes';
  /** Жертвователи, получившие достижение */
  benefactors?: Maybe<BenefactorAchievementsConnection>;
  /** Количество монет, которое требуется для получения достижения */
  coins: Scalars['Int'];
  /** Информация о создании достижения */
  created: AuditMarker;
  /** Описание достижения */
  description: Scalars['String'];
  id: Scalars['AchievementId'];
  /** Изображение достижения в ленте */
  image: FileInformation;
  /** Достижение выделяется? */
  isHighlighted: Scalars['Boolean'];
  /** Количество выданных призов */
  numberOfGrantedPrizes: Scalars['Int'];
  /** Информация о публикации достижения */
  published?: Maybe<AuditMarker>;
  /** История, содержащая описание достижения и/или награду. */
  story: Story;
  /** Название достижения */
  title: Scalars['String'];
  /** Изображение достижения в списке топ-N достижений */
  topImage?: Maybe<FileInformation>;
  /** Позиция достижения в топе */
  topPosition?: Maybe<Scalars['Int']>;
  /** Общее количество призов */
  totalNumberOfPrizes: Scalars['Int'];
  /** Информация о последнем обновлении достижения */
  updated: AuditMarker;
};


/** Достижение с материальными наградами */
export type AchievementWithMaterialPrizesBenefactorsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Достижение с наградой в виде промо-кода */
export type AchievementWithPromoCodes = Achievement & AchievementWithLimitedNumberOfPrizes & {
  __typename?: 'AchievementWithPromoCodes';
  /** Жертвователи, получившие достижение */
  benefactors?: Maybe<BenefactorAchievementsConnection>;
  /** Количество монет, которое требуется для получения достижения */
  coins: Scalars['Int'];
  /** Информация о создании достижения */
  created: AuditMarker;
  /** Описание достижения */
  description: Scalars['String'];
  id: Scalars['AchievementId'];
  /** Изображение достижения в ленте */
  image: FileInformation;
  /** Достижение выделяется? */
  isHighlighted: Scalars['Boolean'];
  /** Количество выданных призов */
  numberOfGrantedPrizes: Scalars['Int'];
  /** Информация о публикации достижения */
  published?: Maybe<AuditMarker>;
  /** История, содержащая описание достижения и/или награду. */
  story: Story;
  /** Название достижения */
  title: Scalars['String'];
  /** Изображение достижения в списке топ-N достижений */
  topImage?: Maybe<FileInformation>;
  /** Позиция достижения в топе */
  topPosition?: Maybe<Scalars['Int']>;
  /** Общее количество призов */
  totalNumberOfPrizes: Scalars['Int'];
  /** Информация о последнем обновлении достижения */
  updated: AuditMarker;
  /** Ссылка для применения промо-кода */
  url?: Maybe<Scalars['String']>;
};


/** Достижение с наградой в виде промо-кода */
export type AchievementWithPromoCodesBenefactorsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Достижение с неограниченным количеством наград */
export type AchievementWithUnlimitedNumberOfPrizes = Achievement & {
  __typename?: 'AchievementWithUnlimitedNumberOfPrizes';
  /** Жертвователи, получившие достижение */
  benefactors?: Maybe<BenefactorAchievementsConnection>;
  /** Количество монет, которое требуется для получения достижения */
  coins: Scalars['Int'];
  /** Информация о создании достижения */
  created: AuditMarker;
  /** Описание достижения */
  description: Scalars['String'];
  id: Scalars['AchievementId'];
  /** Изображение достижения в ленте */
  image: FileInformation;
  /** Достижение выделяется? */
  isHighlighted: Scalars['Boolean'];
  /** Информация о публикации достижения */
  published?: Maybe<AuditMarker>;
  /** История, содержащая описание достижения и/или награду. */
  story: Story;
  /** Название достижения */
  title: Scalars['String'];
  /** Изображение достижения в списке топ-N достижений */
  topImage?: Maybe<FileInformation>;
  /** Позиция достижения в топе */
  topPosition?: Maybe<Scalars['Int']>;
  /** Информация о последнем обновлении достижения */
  updated: AuditMarker;
};


/** Достижение с неограниченным количеством наград */
export type AchievementWithUnlimitedNumberOfPrizesBenefactorsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Данные для создания достижения с физическими призами */
export type AddAchievementWithMaterialPrizesInput = {
  coins: Scalars['Int'];
  description: Scalars['String'];
  imageId: Scalars['String'];
  isHighlighted: Scalars['Boolean'];
  storyId: Scalars['StoryId'];
  title: Scalars['String'];
  topImageId?: Maybe<Scalars['String']>;
  topPosition?: Maybe<Scalars['Int']>;
  /** Общее количество призов */
  totalNumberOfPrizes: Scalars['Int'];
};

export type AddAchievementWithMaterialPrizesPayload = {
  __typename?: 'AddAchievementWithMaterialPrizesPayload';
  achievement?: Maybe<Achievement>;
};

/** Данные для создания достижения с промо-кодами в качестве призов */
export type AddAchievementWithPromoCodesInput = {
  coins: Scalars['Int'];
  description: Scalars['String'];
  imageId: Scalars['String'];
  isHighlighted: Scalars['Boolean'];
  storyId: Scalars['StoryId'];
  title: Scalars['String'];
  topImageId?: Maybe<Scalars['String']>;
  topPosition?: Maybe<Scalars['Int']>;
};

export type AddAchievementWithPromoCodesPayload = {
  __typename?: 'AddAchievementWithPromoCodesPayload';
  achievement?: Maybe<Achievement>;
};

/** Данные для создания достижения с неограниченным количеством наград */
export type AddAchievementWithUnlimitedNumberOfPrizesInput = {
  coins: Scalars['Int'];
  description: Scalars['String'];
  imageId: Scalars['String'];
  isHighlighted: Scalars['Boolean'];
  storyId: Scalars['StoryId'];
  title: Scalars['String'];
  topImageId?: Maybe<Scalars['String']>;
  topPosition?: Maybe<Scalars['Int']>;
};

export type AddAchievementWithUnlimitedNumberOfPrizesPayload = {
  __typename?: 'AddAchievementWithUnlimitedNumberOfPrizesPayload';
  achievement?: Maybe<Achievement>;
};

export type AddBenefactorStatusInput = {
  /** Количество сердец, по достижению которого выдаётся статус */
  coins: Scalars['Int'];
  /** Описание статуса */
  description: Scalars['String'];
  /** Идентификатор изображения статуса в ленте */
  imageId: Scalars['String'];
  /** Идентификатор истории, содержащей описание статуса */
  storyId: Scalars['StoryId'];
  /** Название статуса */
  title: Scalars['String'];
};

export type AddBenefactorStatusPayload = {
  __typename?: 'AddBenefactorStatusPayload';
  status?: Maybe<BenefactorStatus>;
};

/** Параметры добавления широковещательного уведомления */
export type AddBroadcastNotificationInput = {
  /** Текст кнопки */
  buttonText?: Maybe<Scalars['String']>;
  /** Адрес перехода при нажатии на кнопку */
  buttonUrl?: Maybe<Scalars['String']>;
  /** Файл со списком получателей уведомления */
  fileWithListOfRecipients?: Maybe<Scalars['String']>;
  /** Список идентификаторов изображений, прикреплённых к уведомлению */
  images?: Maybe<Array<Scalars['String']>>;
  /** Получатели уведомления */
  recipients?: Maybe<BroadcastNotificationRecipients>;
  /** Текст уведомления */
  text: Scalars['String'];
  /** Заголовок уведомления */
  title: Scalars['String'];
};

export type AddBroadcastNotificationPayload = {
  __typename?: 'AddBroadcastNotificationPayload';
  broadcastNotification?: Maybe<BroadcastNotification>;
};

export type AddCategoryError = CategoryAlreadyExistsError;

/** Параметры добавления новой категории */
export type AddCategoryInput = {
  /** Изображение */
  imageId?: Maybe<Scalars['String']>;
  /** Название */
  name: Scalars['String'];
};

export type AddCategoryPayload = {
  __typename?: 'AddCategoryPayload';
  category?: Maybe<Category>;
  errors?: Maybe<Array<AddCategoryError>>;
};

export type AddCityError = CityAlreadyExistsError;

/** Параметры добавления нового города */
export type AddCityInput = {
  /** Показывать город в списке городов по-умолчанию */
  default: Scalars['Boolean'];
  /** Название города */
  name: Scalars['String'];
  /** Порядок показа города в списке городов по-умолчанию */
  order: Scalars['Int'];
};

export type AddCityPayload = {
  __typename?: 'AddCityPayload';
  city?: Maybe<City>;
  errors?: Maybe<Array<AddCityError>>;
};

export type AddClientVersionError = ClientVersionAlreadyExistsError;

/** Параметры добавления информации о новой версии клиентского приложения */
export type AddClientVersionInput = {
  /** Идентификатор приложения */
  client: ClientApplication;
  /** MAJOR-часть версии */
  major: Scalars['Int'];
  /** Признак обязательности обновления */
  mandatory: Scalars['Boolean'];
  /** MINOR-часть версии */
  minor: Scalars['Int'];
  /** PATCH-часть версии */
  patch: Scalars['Int'];
};

export type AddClientVersionPayload = {
  __typename?: 'AddClientVersionPayload';
  errors?: Maybe<Array<AddClientVersionError>>;
  version?: Maybe<ClientVersion>;
};

/** Параметры добавления события фонда-партнёра */
export type AddFoundationEventInput = {
  address: Scalars['String'];
  agreementFileId?: Maybe<Scalars['String']>;
  agreementUrl?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  description: Scalars['String'];
  /** Идентификатор фонда-партнёра */
  foundationId: Scalars['FoundationId'];
  images: Array<Scalars['String']>;
  meetingInstructions: Scalars['String'];
  passportRequired: Scalars['Boolean'];
  phoneNumber: Scalars['String'];
  requirements: Array<FoundationEventParticipationRequirementInput>;
  start: Scalars['DateTime'];
  title: Scalars['String'];
};

export type AddFoundationEventPayload = {
  __typename?: 'AddFoundationEventPayload';
  foundationEvent?: Maybe<FoundationEvent>;
};

/** Параметры добавления фонда-партнёра */
export type AddFoundationInput = {
  address: Scalars['String'];
  description: Scalars['String'];
  logoId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type AddFoundationPayload = {
  __typename?: 'AddFoundationPayload';
  foundation?: Maybe<Foundation>;
};

/** Параметры создания потребности "Продуктовая корзина" */
export type AddGrocerySetNeedInput = {
  wardId: Scalars['WardId'];
};

export type AddGrocerySetNeedPayload = {
  __typename?: 'AddGrocerySetNeedPayload';
  need?: Maybe<Need>;
};

/** Параметры добавления разовой потребности */
export type AddOneTimeNeedInput = {
  /** Описание потребности */
  description: Scalars['String'];
  /** Номер иконки в списке иконок */
  icon: Scalars['Int'];
  /** Требуемое количество средств */
  requiredAmount: Scalars['Int'];
  /** Дата, к которой требуется собрать средства */
  targetDate: Scalars['Date'];
  /** Заголовок потребности */
  title: Scalars['String'];
  /** Ссылка на страницу с информацией о продукте/услуге */
  url?: Maybe<Scalars['String']>;
  wardId: Scalars['WardId'];
};

export type AddOneTimeNeedPayload = {
  __typename?: 'AddOneTimeNeedPayload';
  need?: Maybe<Need>;
};

/** Параметры для создания счёта с расходами проекта за период */
export type AddProjectExpenseInput = {
  /** Дата отчётного периода, используются только месяц и год */
  referenceDate: Scalars['Date'];
  /** Требуемая (потраченная) сумма */
  requiredAmount: Scalars['Int'];
  /** Краткое описание расходов */
  title: Scalars['String'];
};

export type AddProjectExpensePayload = {
  __typename?: 'AddProjectExpensePayload';
  projectExpense?: Maybe<ProjectExpense>;
};

/** Адрес */
export type Address = {
  __typename?: 'Address';
  /** Район в регионе */
  area?: Maybe<IdentifiableAddressElement>;
  /** Корпус/строение */
  block?: Maybe<AddressElement>;
  /** Город */
  city?: Maybe<IdentifiableAddressElement>;
  /** Текст для отображения */
  displayText: Scalars['String'];
  /** Корпус/строение */
  flat?: Maybe<AddressElement>;
  /** Полный адрес для последующего поиска в адресных системах, например "Тверская обл, пгт Пено" */
  fullAddress: Scalars['String'];
  /** Дом */
  house?: Maybe<AddressElement>;
  /** Широта */
  latitude?: Maybe<Scalars['Float']>;
  /** Долгота */
  longitude?: Maybe<Scalars['Float']>;
  /** Регион (область, край) */
  region?: Maybe<IdentifiableAddressElement>;
  /** Населённый пункт */
  settlement?: Maybe<IdentifiableAddressElement>;
  /** Улица */
  street?: Maybe<IdentifiableAddressElement>;
};

/** Элемент адреса */
export type AddressElement = {
  __typename?: 'AddressElement';
  /** Тип элемента, полный (город, район и т.п.) */
  fullType: Scalars['String'];
  /** Тип элемента, сокращённый (г, р-н и т.п.) */
  type: Scalars['String'];
  /** Значение элемента (название района, города и т.п) */
  value: Scalars['String'];
  /** Значение с полным типом (город Москва, Калужский район и т.п.) */
  valueWithFullType: Scalars['String'];
  /** Значение с сокращённым типом (г Москва, Калужский р-н и т.п.) */
  valueWithType: Scalars['String'];
};

/** Элемент адреса */
export type AddressElementFilterInput = {
  and?: Maybe<Array<AddressElementFilterInput>>;
  /** Тип элемента, полный (город, район и т.п.) */
  fullType?: Maybe<StringOperationFilterInput>;
  or?: Maybe<Array<AddressElementFilterInput>>;
  /** Тип элемента, сокращённый (г, р-н и т.п.) */
  type?: Maybe<StringOperationFilterInput>;
  /** Значение элемента (название района, города и т.п) */
  value?: Maybe<StringOperationFilterInput>;
  /** Значение с полным типом (город Москва, Калужский район и т.п.) */
  valueWithFullType?: Maybe<StringOperationFilterInput>;
  /** Значение с сокращённым типом (г Москва, Калужский р-н и т.п.) */
  valueWithType?: Maybe<StringOperationFilterInput>;
};

/** Элемент адреса */
export type AddressElementSortInput = {
  /** Тип элемента, полный (город, район и т.п.) */
  fullType?: Maybe<SortEnumType>;
  /** Тип элемента, сокращённый (г, р-н и т.п.) */
  type?: Maybe<SortEnumType>;
  /** Значение элемента (название района, города и т.п) */
  value?: Maybe<SortEnumType>;
  /** Значение с полным типом (город Москва, Калужский район и т.п.) */
  valueWithFullType?: Maybe<SortEnumType>;
  /** Значение с сокращённым типом (г Москва, Калужский р-н и т.п.) */
  valueWithType?: Maybe<SortEnumType>;
};

/** Адрес */
export type AddressFilterInput = {
  and?: Maybe<Array<AddressFilterInput>>;
  /** Район в регионе */
  area?: Maybe<IdentifiableAddressElementFilterInput>;
  /** Корпус/строение */
  block?: Maybe<AddressElementFilterInput>;
  /** Город */
  city?: Maybe<IdentifiableAddressElementFilterInput>;
  /** Текст для отображения */
  displayText?: Maybe<StringOperationFilterInput>;
  /** Корпус/строение */
  flat?: Maybe<AddressElementFilterInput>;
  /** Полный адрес для последующего поиска в адресных системах, например "Тверская обл, пгт Пено" */
  fullAddress?: Maybe<StringOperationFilterInput>;
  /** Дом */
  house?: Maybe<AddressElementFilterInput>;
  /** Широта */
  latitude?: Maybe<FloatOperationFilterInput>;
  /** Долгота */
  longitude?: Maybe<FloatOperationFilterInput>;
  or?: Maybe<Array<AddressFilterInput>>;
  /** Регион (область, край) */
  region?: Maybe<IdentifiableAddressElementFilterInput>;
  /** Населённый пункт */
  settlement?: Maybe<IdentifiableAddressElementFilterInput>;
  /** Улица */
  street?: Maybe<IdentifiableAddressElementFilterInput>;
};

/** Адрес */
export type AddressSortInput = {
  /** Район в регионе */
  area?: Maybe<IdentifiableAddressElementSortInput>;
  /** Корпус/строение */
  block?: Maybe<AddressElementSortInput>;
  /** Город */
  city?: Maybe<IdentifiableAddressElementSortInput>;
  /** Текст для отображения */
  displayText?: Maybe<SortEnumType>;
  /** Корпус/строение */
  flat?: Maybe<AddressElementSortInput>;
  /** Полный адрес для последующего поиска в адресных системах, например "Тверская обл, пгт Пено" */
  fullAddress?: Maybe<SortEnumType>;
  /** Дом */
  house?: Maybe<AddressElementSortInput>;
  /** Широта */
  latitude?: Maybe<SortEnumType>;
  /** Долгота */
  longitude?: Maybe<SortEnumType>;
  /** Регион (область, край) */
  region?: Maybe<IdentifiableAddressElementSortInput>;
  /** Населённый пункт */
  settlement?: Maybe<IdentifiableAddressElementSortInput>;
  /** Улица */
  street?: Maybe<IdentifiableAddressElementSortInput>;
};

/** Подсказка вводимого адреса */
export type AddressSuggestion = {
  __typename?: 'AddressSuggestion';
  /** Текст для отображения */
  displayText: Scalars['String'];
  /** Полный текст адреса */
  fullAddress: Scalars['String'];
  /** У адреса определился город */
  hasCity: Scalars['Boolean'];
  /** У адреса определился город/населённый пункт */
  hasCityOrSettlement: Scalars['Boolean'];
  /** Для адреса доступны координаты */
  hasCoordinates: Scalars['Boolean'];
  /** У адреса определился конкретный дом */
  hasHouse: Scalars['Boolean'];
  /** У адреса определился регион */
  hasRegion: Scalars['Boolean'];
  /** У адреса определился населённый пункт */
  hasSettlement: Scalars['Boolean'];
  /** Полное название региона */
  region?: Maybe<Scalars['String']>;
};

/** Настройки подсказок адресов */
export enum AddressSuggestionMode {
  /** Возвращать подсказки для городов и населённых пунктов */
  CitiesAndSettlements = 'CITIES_AND_SETTLEMENTS',
  /** Возвращать подсказки только для городов, без отдельно стоящих населённых пунктов */
  OnlyCities = 'ONLY_CITIES',
  /** Возвращать подсказки для всех адресов, вплоть до домов/строений */
  Regular = 'REGULAR'
}

export type AddStoryBlockError = StoryBlockWithSpecifiedLabelAlreadyExistsError;

/** Параметры добавления блока историй. */
export type AddStoryBlockInput = {
  /** Метка блока историй. */
  label: Scalars['StoryBlockLabel'];
  /** Заголовок блока историй. */
  title?: Maybe<Scalars['String']>;
};

export type AddStoryBlockPayload = {
  __typename?: 'AddStoryBlockPayload';
  errors?: Maybe<Array<AddStoryBlockError>>;
  storyBlock?: Maybe<StoryBlock>;
};

/** Параметры добавления новой истории к блоку историй. */
export type AddStoryInput = {
  /** Идентификатор блока историй. */
  storyBlockId: Scalars['StoryBlockId'];
  /** Заголовок истории. */
  title: Scalars['String'];
};

export type AddStoryPayload = {
  __typename?: 'AddStoryPayload';
  story?: Maybe<Story>;
};

/** Параметры добавления списка просмотренных историй. */
export type AddViewedStoriesInput = {
  /** Идентификаторы просмотренных историй. */
  viewedStories: Array<Scalars['StoryId']>;
};

export type AddViewedStoriesPayload = {
  __typename?: 'AddViewedStoriesPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type AddWardError = CategoryNotFoundError | CityNotFoundError;

/** Параметры добавления подопечного */
export type AddWardInput = {
  address: Scalars['String'];
  categories: Array<Scalars['CategoryId']>;
  city: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  dateOfBirth: Scalars['Date'];
  disabilityGroup?: Maybe<Scalars['Int']>;
  entranceNumber?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  flatFloor?: Maybe<Scalars['String']>;
  gender: Gender;
  lastName: Scalars['String'];
  maritalStatus?: Maybe<MaritalStatus>;
  middleName?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['Date']>;
  passportIssuer?: Maybe<Scalars['String']>;
  passportIssuerCode?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportSeries?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  photoId: Scalars['String'];
  placeOfBirth?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  publicCoordinates: Scalars['String'];
  rank?: Maybe<Scalars['String']>;
  story: Scalars['String'];
};

export type AddWardPayload = {
  __typename?: 'AddWardPayload';
  errors?: Maybe<Array<AddWardError>>;
  ward?: Maybe<Ward>;
};

/** Данные для добавления подопечных к списку подопечных счёта-депозита */
export type AddWardsToTheBenefactorDepositAccountForSpecificWardsInput = {
  id: Scalars['AccountId'];
  /** Список идентификаторов подопечных для добавления */
  wardIds: Array<Scalars['WardId']>;
};

export type AddWardsToTheBenefactorDepositAccountForSpecificWardsPayload = {
  __typename?: 'AddWardsToTheBenefactorDepositAccountForSpecificWardsPayload';
  benefactorDepositAccountForSpecificWards?: Maybe<BenefactorDepositAccountForSpecificWards>;
};

/** A connection to a list of items. */
export type AdministratorsConnection = {
  __typename?: 'AdministratorsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AdministratorsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AdministratorsEdge = {
  __typename?: 'AdministratorsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

/** Параметры для отбора администраторов */
export type AdministratorsFilterInput = {
  /** Учётная запись заблокирована */
  disabled?: Maybe<Scalars['Boolean']>;
  /** Имя */
  displayName?: Maybe<Scalars['String']>;
  /** Адрес электронной почты */
  email?: Maybe<Scalars['String']>;
  /** Адрес электронной почты подтверждён */
  emailConfirmed?: Maybe<Scalars['Boolean']>;
  /** Тип административных пользователей для поиска */
  kind?: Maybe<AdministratorsKind>;
};

/** Тип администраторов для фильтрации */
export enum AdministratorsKind {
  /** Представители фондов */
  FoundationRepresentatives = 'FOUNDATION_REPRESENTATIVES',
  /** Модераторы */
  Moderators = 'MODERATORS',
  /** Системные администраторы */
  SystemAdministrators = 'SYSTEM_ADMINISTRATORS'
}

/** Параметры упорядочивания учётных записей администраторов */
export type AdministratorsSortInput = {
  createdOn?: Maybe<SortEnumType>;
  disabled?: Maybe<SortEnumType>;
  displayName?: Maybe<SortEnumType>;
};

export type AllowSubscriptionTransferInput = {
  subscriptionId: Scalars['DonationSubscriptionId'];
};

export type AllowSubscriptionTransferPayload = {
  __typename?: 'AllowSubscriptionTransferPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** Различные расчётные значения, предназначенные для визуализации статистики платформы */
export type Amounts = {
  __typename?: 'Amounts';
  /** Сумма финансовых показателей активных подопечных */
  activeWardsCountsAndAmounts: WardCountsAndAmounts;
  /** Сумма средств на счетах-депозитах жертвователей */
  amountOfBenefactorDeposits: Scalars['Int'];
  /** Сумма всех действующих подписок проекта */
  amountOfProjectActiveSubscriptions: Scalars['Int'];
  /** Сумма всех действующих подписок на проект, созданных до начала текущего месяца */
  amountOfProjectActiveSubscriptionsCreatedBeforeCurrentMonth: Scalars['Int'];
  /** Сумма всех действующих подписок на проект, созданных в текущем месяце */
  amountOfProjectActiveSubscriptionsCreatedInCurrentMonth: Scalars['Int'];
  /** Сумма всех переводов на счёт проекта собранных в текущем месяце */
  amountOfProjectDonationsCollectedInCurrentMonth: Scalars['Int'];
  /** Сумма всех переводов на счёт проекта, не являющихся подписками */
  amountOfProjectNonSubscriptionDonations: Scalars['Int'];
  /** Сумма всех переводов на счёт проекта, не являющихся подписками, собранных в текущем месяце */
  amountOfProjectNonSubscriptionDonationsCollectedInCurrentMonth: Scalars['Int'];
  /** Возвращает количество средств от подписки на проект, собранных в текущем месяце */
  amountOfProjectSubscriptionDonationsCollectedInCurrentMonth: Scalars['Int'];
  /** Сумма всех подписок на проект, отменённых в текущем месяце */
  amountOfProjectSubscriptionsCancelledInCurrentMonth: Scalars['Int'];
  /** Сумма всех действующих подписок на регулярные потребности всех подопечных */
  amountOfWardsActiveSubscriptions: Scalars['Int'];
  /** Сумма всех действующих подписок на регулярные потребности всех подопечных, созданных до начала текущего месяца */
  amountOfWardsActiveSubscriptionsCreatedBeforeCurrentMonth: Scalars['Int'];
  /** Сумма всех действующих подписок на регулярные потребности всех подопечных, созданных в текущем месяце */
  amountOfWardsActiveSubscriptionsCreatedInCurrentMonth: Scalars['Int'];
  /** Сумма всех переводов, не являющихся подписками, на регулярные потребности всех подопечных */
  amountOfWardsNonSubscriptionDonations: Scalars['Int'];
  /** Сумма всех переводов, не являющихся подписками, на регулярные потребности всех подопечных, собранных в текущем месяце */
  amountOfWardsNonSubscriptionDonationsCollectedInCurrentMonth: Scalars['Int'];
  /** Сумма средств на счётах-кошельках подопечных */
  amountOfWardsRegularNeedsAccounts: Scalars['Int'];
  /** Сумма средств от подписки на регулярные потребности всех подопечных, собранных в текущем месяце */
  amountOfWardsSubscriptionDonationsCollectedInCurrentMonth: Scalars['Int'];
  /** Сумма всех подписок на регулярные потребности всех подопечных, отменённых в текущем месяце */
  amountOfWardsSubscriptionsCancelledInCurrentMonth: Scalars['Int'];
  /** Дефицит средств для закрытия регулярных потребностей в текущем месяце */
  wardsRegularNeedsAmountDeficit: Scalars['Int'];
};

export type AnonymizeBenefactorPayload = {
  __typename?: 'AnonymizeBenefactorPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** A connection to a list of items. */
export type ApplicationsConnection = {
  __typename?: 'ApplicationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ApplicationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<FoundationEventApplication>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ApplicationsEdge = {
  __typename?: 'ApplicationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FoundationEventApplication;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type ApplyToFoundationEventError = BenefactorPersonalDataMissingError | FoundationEventRegistrationClosedError;

/** Параметры подачи заявки на участие в событии фонда-партнёра */
export type ApplyToFoundationEventInput = {
  /** Идентификатор события */
  id: Scalars['FoundationEventId'];
  /** Список навыков, которые текущий жертвователь готов предложить */
  skillIds: Array<Scalars['SkillId']>;
};

export type ApplyToFoundationEventPayload = {
  __typename?: 'ApplyToFoundationEventPayload';
  application?: Maybe<FoundationEventApplication>;
  errors?: Maybe<Array<ApplyToFoundationEventError>>;
};

export type ApproveFoundationEventInput = {
  /** Идентификатор события */
  foundationEventId: Scalars['FoundationEventId'];
};

export type ApproveFoundationEventPayload = {
  __typename?: 'ApproveFoundationEventPayload';
  foundationEvent?: Maybe<FoundationEvent>;
};

export type ArchiveNeedInput = {
  /** Идентификатор потребности */
  needId: Scalars['NeedId'];
};

export type ArchiveNeedPayload = {
  __typename?: 'ArchiveNeedPayload';
  need?: Maybe<Need>;
};

/** Параметры назначения монет жертвователю */
export type AssignCoinsInput = {
  /** Количество монет, которые нужно назначить */
  amount: Scalars['Int'];
  /** Список идентификаторов жертвователей */
  benefactorIds: Array<Scalars['BenefactorId']>;
  /** Описание причины назначения монет */
  description: Scalars['String'];
};

export type AssignCoinsPayload = {
  __typename?: 'AssignCoinsPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** A connection to a list of items. */
export type AttendancesConnection = {
  __typename?: 'AttendancesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AttendancesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<FoundationEventAttendance>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AttendancesEdge = {
  __typename?: 'AttendancesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FoundationEventAttendance;
};

/** Информация о пользователе и дате и времени совершения какой-либо операции */
export type AuditMarker = {
  __typename?: 'AuditMarker';
  /** Дата и время совершения операции */
  timestamp: Scalars['DateTime'];
  /** Пользователь, совершивший операцию */
  user?: Maybe<User>;
};

/** Информация о пользователе и дате и времени совершения какой-либо операции */
export type AuditMarkerFilterInput = {
  and?: Maybe<Array<AuditMarkerFilterInput>>;
  exists?: Maybe<Scalars['Boolean']>;
  or?: Maybe<Array<AuditMarkerFilterInput>>;
  /** Дата и время совершения операции */
  timestamp?: Maybe<DateTimeOperationFilterInput>;
};

/** Информация о пользователе и дате и времени совершения какой-либо операции */
export type AuditMarkerSortInput = {
  /** Дата и время совершения операции */
  timestamp?: Maybe<SortEnumType>;
};

/** Информация о банковском переводе средств */
export type BankTransferPaymentDetails = {
  __typename?: 'BankTransferPaymentDetails';
  /** Название контрагента */
  counterpartyName: Scalars['String'];
  /** Номер платёжного документа */
  documentNumber: Scalars['String'];
  /** Назначение платежа */
  purpose: Scalars['String'];
  /** Дата перевода */
  transferDate: Scalars['Date'];
};

/** Намерение осуществить оплату банковским переводом */
export type BankTransferPaymentIntent = {
  __typename?: 'BankTransferPaymentIntent';
  /** Название контрагента */
  counterpartyName: Scalars['String'];
  /** Номер платёжного документа */
  documentNumber: Scalars['String'];
  /** Назначение платежа */
  purpose: Scalars['String'];
  /** Дата перевода */
  transferDate: Scalars['Date'];
};

/** Намерение совершить оплату банковским переводом */
export type BankTransferPaymentIntentInput = {
  /** Название контрагента */
  counterpartyName: Scalars['String'];
  /** Номер платёжного документа */
  documentNumber: Scalars['String'];
  /** Назначение платежа */
  purpose: Scalars['String'];
  /** Дата перевода */
  transferDate: Scalars['Date'];
};

/** Информация о жертвователе */
export type Benefactor = {
  __typename?: 'Benefactor';
  /** Полученные достижения */
  achievements?: Maybe<BenefactorAchievementsConnection>;
  /** Количество достижений */
  achievementsCount: Scalars['Int'];
  /** Количество средств жертвователя в резервном фонде подопечных */
  amountOfDonationsInWardsReserveAccount: Scalars['Int'];
  /** Города, новости/события в которых могут представлять интерес для жертвователя */
  citiesOfInterest: Array<Scalars['String']>;
  /** Сохранённые платёжные карты. */
  cloudPaymentsSavedCards: Array<CloudPaymentsSavedCard>;
  /** Количество полученных монет */
  coins: Scalars['Int'];
  /** Количество монет, о которых сообщалось пользователю */
  coinsAccountedByClient: Scalars['Int'];
  /** История накопления монет */
  coinsAccumulationHistory?: Maybe<CoinsAccumulationHistoryConnection>;
  /** Дата и время регистрации жертвователя */
  createdAt: Scalars['DateTime'];
  /** Платёжная карта по-умолчанию для совершения платежей и списания подписки. */
  defaultCloudPaymentsSavedCard?: Maybe<CloudPaymentsSavedCard>;
  /** Счёта-депозиты жертвователя */
  depositAccounts: Array<Account>;
  /** Имя или псевдоним жертвователя */
  displayName: Scalars['String'];
  /** Адрес электронной почты */
  email?: Maybe<Scalars['String']>;
  /** Количество неуспешных платежей */
  failedPaymentsCount: Scalars['Int'];
  /** Заявки на участие в событиях фондов-партнёров */
  foundationEventApplications?: Maybe<FoundationEventApplicationsConnection>;
  /** Участие в событиях фондов-партнёров */
  foundationEventAttendance?: Maybe<FoundationEventAttendanceConnection>;
  /** Друзья жертвователя, зарегистрировавшиеся с указанием его кода регистрации */
  friends?: Maybe<FriendsConnection>;
  /** Не отображать профиль */
  hideProfile: Scalars['Boolean'];
  id: Scalars['BenefactorId'];
  /** Находит отменённые подписки и предлагает список подопечных с размерами подписки */
  intentsToRecoverCancelledSubscriptions: Array<SubscriptionIntentUnion>;
  /** Дата и время получения последнего достижения */
  latestAchievementReceivedAt?: Maybe<Scalars['DateTime']>;
  /** Уведомления (AKA pushes) */
  notifications?: Maybe<NotificationsConnection>;
  /** Заказы жертвователя */
  orders?: Maybe<OrdersConnection>;
  /** Общая сумма успешных платежей */
  paymentsAmount: Scalars['Int'];
  /** Персональные данные жертвователя */
  personalData?: Maybe<BenefactorPersonalData>;
  /** Дата и время первой установки персональных данных (становления Помощником) */
  personalDataRegisteredAt?: Maybe<Scalars['DateTime']>;
  /** Номер телефона */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Фотография или аватар жертвователя */
  photo?: Maybe<FileInformation>;
  /** Рейтинг пользователя по итогам участия в событиях */
  rating?: Maybe<Scalars['Float']>;
  /** Жертвователь, чей код был использован во время регистрации */
  referrer?: Maybe<Benefactor>;
  /** Навыки */
  skillsInformation?: Maybe<BenefactorSkillsInformation>;
  /** Текущий статус жертвователя */
  status?: Maybe<BenefactorStatus>;
  /** Возвращает все доступные статусы жертвователя */
  statuses?: Maybe<BenefactorStatusesConnection>;
  /** Подписки */
  subscriptions?: Maybe<SubscriptionsConnection>;
  /** Переводы */
  transfers?: Maybe<TransfersConnection>;
  /** Количество непрочитанных сообщений */
  unreadNotificationsCount?: Maybe<Scalars['Int']>;
  /** Дата и время изменения данных жертвователя */
  updatedAt: Scalars['DateTime'];
  /** Учётная запись, связанная с жертвователем, если она существует */
  user?: Maybe<User>;
  /** Волонтёрский опыт */
  volunteerInformation?: Maybe<BenefactorVolunteerInformation>;
  /** Количество подопечных, на которых есть активная подписка */
  wardsCount: Scalars['Int'];
};


/** Информация о жертвователе */
export type BenefactorAchievementsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Информация о жертвователе */
export type BenefactorCoinsAccumulationHistoryArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Информация о жертвователе */
export type BenefactorFoundationEventApplicationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<FoundationEventApplicationSortInput>>;
  where?: Maybe<FoundationEventApplicationFilterInput>;
};


/** Информация о жертвователе */
export type BenefactorFoundationEventAttendanceArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<FoundationEventAttendanceSortInput>>;
  where?: Maybe<FoundationEventAttendanceFilterInput>;
};


/** Информация о жертвователе */
export type BenefactorFriendsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Информация о жертвователе */
export type BenefactorNotificationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Информация о жертвователе */
export type BenefactorOrdersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<OrderSortInput>>;
  where?: Maybe<OrderFilterInput>;
};


/** Информация о жертвователе */
export type BenefactorStatusesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Информация о жертвователе */
export type BenefactorSubscriptionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  includeCancelled?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
};


/** Информация о жертвователе */
export type BenefactorTransfersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Счёт жертвователя */
export type BenefactorAccount = {
  /** Количество денег на счёте */
  amount: Scalars['Int'];
  /** Жертвователь */
  benefactor: Benefactor;
  /** Дата и время закрытия счёта */
  closedAt?: Maybe<Scalars['DateTime']>;
  /** Пожертвования */
  donations?: Maybe<DonationsConnection>;
  id: Scalars['AccountId'];
  /** Счета, с которых можно перевести средства на данный счёт */
  transferSources: Array<Account>;
  /** Дата и время последнего обновления счёта */
  updatedAt: Scalars['DateTime'];
};


/** Счёт жертвователя */
export type BenefactorAccountDonationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<DonationSortInput>>;
};

/** Полученное достижение */
export type BenefactorAchievement = {
  /** Достижение */
  achievement: Achievement;
  /** Идентификатор жертвователя */
  benefactor: Benefactor;
  id: Scalars['BenefactorAchievementId'];
  /** Дата и время получения достижения */
  receivedAt: Scalars['DateTime'];
  /** Дата и время показа достижения жертвователю */
  shownToBenefactorAt?: Maybe<Scalars['DateTime']>;
};


/** A connection to a list of items. */
export type BenefactorAchievementsConnection = {
  __typename?: 'BenefactorAchievementsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<BenefactorAchievementsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<BenefactorAchievement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BenefactorAchievementsEdge = {
  __typename?: 'BenefactorAchievementsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BenefactorAchievement>;
};

/** Достижение с материальным призом */
export type BenefactorAchievementWithMaterialPrize = BenefactorAchievement & {
  __typename?: 'BenefactorAchievementWithMaterialPrize';
  /** Достижение */
  achievement: Achievement;
  /** Идентификатор жертвователя */
  benefactor: Benefactor;
  id: Scalars['BenefactorAchievementId'];
  /** Порядковый номер полученного приза */
  number: Scalars['Int'];
  /** Дата и время получения достижения */
  receivedAt: Scalars['DateTime'];
  /** Дата и время показа достижения жертвователю */
  shownToBenefactorAt?: Maybe<Scalars['DateTime']>;
};

/** Полученное достижение без приза */
export type BenefactorAchievementWithoutPrize = BenefactorAchievement & {
  __typename?: 'BenefactorAchievementWithoutPrize';
  /** Достижение */
  achievement: Achievement;
  /** Идентификатор жертвователя */
  benefactor: Benefactor;
  id: Scalars['BenefactorAchievementId'];
  /** Дата и время получения достижения */
  receivedAt: Scalars['DateTime'];
  /** Дата и время показа достижения жертвователю */
  shownToBenefactorAt?: Maybe<Scalars['DateTime']>;
};

/** Достижение с промо-кодом */
export type BenefactorAchievementWithPromoCode = BenefactorAchievement & {
  __typename?: 'BenefactorAchievementWithPromoCode';
  /** Достижение */
  achievement: Achievement;
  /** Идентификатор жертвователя */
  benefactor: Benefactor;
  id: Scalars['BenefactorAchievementId'];
  /** Порядковый номер полученного приза */
  number: Scalars['Int'];
  /** Промо-код достижения */
  promoCode?: Maybe<Scalars['String']>;
  /** Дата и время получения достижения */
  receivedAt: Scalars['DateTime'];
  /** Дата и время показа достижения жертвователю */
  shownToBenefactorAt?: Maybe<Scalars['DateTime']>;
};

/** Полученное достижение, содержащее видео */
export type BenefactorAchievementWithVideo = BenefactorAchievement & {
  __typename?: 'BenefactorAchievementWithVideo';
  /** Достижение */
  achievement: Achievement;
  /** Идентификатор жертвователя */
  benefactor: Benefactor;
  id: Scalars['BenefactorAchievementId'];
  /** Дата и время получения достижения */
  receivedAt: Scalars['DateTime'];
  /** Дата и время показа достижения жертвователю */
  shownToBenefactorAt?: Maybe<Scalars['DateTime']>;
  /** Ссылка на видео */
  videoUrl: Scalars['String'];
};

/** Жертвователь и количество денег на счетах-депозитах. */
export type BenefactorAndDepositAccountsAmount = {
  __typename?: 'BenefactorAndDepositAccountsAmount';
  /** Количество денег на счетах-депозитах */
  amount: Scalars['Int'];
  /** Жертвователь */
  benefactor: Benefactor;
};

/** Событие изменения баланса монет жертвователя */
export type BenefactorCoinsBalanceChanged = {
  __typename?: 'BenefactorCoinsBalanceChanged';
  /** Жертвователь */
  benefactor: Benefactor;
  /** Количество добавленных монет */
  coinsAccumulated: Scalars['Int'];
};

/** Намерение зачислить средства на счёт-депозит жертвователя */
export type BenefactorDepositAccountDonationIntent = {
  __typename?: 'BenefactorDepositAccountDonationIntent';
  /** Идентификатор счёта-депозита жертвователя */
  account: Account;
  /** Размер вносимого депозита */
  amount: Scalars['Int'];
  /** Дата внесения средств */
  transferDate: Scalars['Date'];
};

/** Намерение пополнить счёт-депозит жертвователя */
export type BenefactorDepositAccountDonationIntentInput = {
  /** Идентификатор счёта-депозита жертвователя */
  accountId: Scalars['AccountId'];
  /** Размер депозита */
  amount: Scalars['Int'];
  /** Дата перевода */
  transferDate: Scalars['Date'];
};

/** Счёт-депозит жертвователя, средства с которого могут расходоваться на потребности всех подопечных */
export type BenefactorDepositAccountForAllWards = Account & BenefactorAccount & {
  __typename?: 'BenefactorDepositAccountForAllWards';
  /** Количество денег на счёте */
  amount: Scalars['Int'];
  /** Жертвователь */
  benefactor: Benefactor;
  /** Дата и время закрытия счёта */
  closedAt?: Maybe<Scalars['DateTime']>;
  /** Пожертвования */
  donations?: Maybe<DonationsConnection>;
  id: Scalars['AccountId'];
  /** Счета, с которых можно перевести средства на данный счёт */
  transferSources: Array<Account>;
  /** Дата и время последнего обновления счёта */
  updatedAt: Scalars['DateTime'];
};


/** Счёт-депозит жертвователя, средства с которого могут расходоваться на потребности всех подопечных */
export type BenefactorDepositAccountForAllWardsDonationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<DonationSortInput>>;
};

/** Счёт-депозит жертвователя, средства с которого могут расходоваться только на нужды проекта */
export type BenefactorDepositAccountForProjectNeeds = Account & BenefactorAccount & {
  __typename?: 'BenefactorDepositAccountForProjectNeeds';
  /** Количество денег на счёте */
  amount: Scalars['Int'];
  /** Жертвователь */
  benefactor: Benefactor;
  /** Дата и время закрытия счёта */
  closedAt?: Maybe<Scalars['DateTime']>;
  /** Пожертвования */
  donations?: Maybe<DonationsConnection>;
  id: Scalars['AccountId'];
  /** Счета, с которых можно перевести средства на данный счёт */
  transferSources: Array<Account>;
  /** Дата и время последнего обновления счёта */
  updatedAt: Scalars['DateTime'];
};


/** Счёт-депозит жертвователя, средства с которого могут расходоваться только на нужды проекта */
export type BenefactorDepositAccountForProjectNeedsDonationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<DonationSortInput>>;
};

/** Счёт-депозит жертвователя, средства с которого могут расходоваться только на потребности конкретных подопечных */
export type BenefactorDepositAccountForSpecificWards = Account & BenefactorAccount & {
  __typename?: 'BenefactorDepositAccountForSpecificWards';
  /** Количество денег на счёте */
  amount: Scalars['Int'];
  /** Жертвователь */
  benefactor: Benefactor;
  /** Дата и время закрытия счёта */
  closedAt?: Maybe<Scalars['DateTime']>;
  /** Пожертвования */
  donations?: Maybe<DonationsConnection>;
  id: Scalars['AccountId'];
  /** Заголовок счёта, для показа в списках */
  title: Scalars['String'];
  /** Счета, с которых можно перевести средства на данный счёт */
  transferSources: Array<Account>;
  /** Дата и время последнего обновления счёта */
  updatedAt: Scalars['DateTime'];
  /** Подопечные, на потребности которых могут расходоваться средства данного счёта */
  wards: Array<Ward>;
};


/** Счёт-депозит жертвователя, средства с которого могут расходоваться только на потребности конкретных подопечных */
export type BenefactorDepositAccountForSpecificWardsDonationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<DonationSortInput>>;
};

/** Счёт-депозит жертвователя, средства с которого могут расходоваться на любые цели */
export type BenefactorDepositAccountWithoutConditions = Account & BenefactorAccount & {
  __typename?: 'BenefactorDepositAccountWithoutConditions';
  /** Количество денег на счёте */
  amount: Scalars['Int'];
  /** Жертвователь */
  benefactor: Benefactor;
  /** Дата и время закрытия счёта */
  closedAt?: Maybe<Scalars['DateTime']>;
  /** Пожертвования */
  donations?: Maybe<DonationsConnection>;
  id: Scalars['AccountId'];
  /** Счета, с которых можно перевести средства на данный счёт */
  transferSources: Array<Account>;
  /** Дата и время последнего обновления счёта */
  updatedAt: Scalars['DateTime'];
};


/** Счёт-депозит жертвователя, средства с которого могут расходоваться на любые цели */
export type BenefactorDepositAccountWithoutConditionsDonationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<DonationSortInput>>;
};

/** A connection to a list of items. */
export type BenefactorDonationsConnection = {
  __typename?: 'BenefactorDonationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<BenefactorDonationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Donation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BenefactorDonationsEdge = {
  __typename?: 'BenefactorDonationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Donation;
};

/** Параметры для отбора жертвователей */
export type BenefactorFilter = {
  and?: Maybe<Array<BenefactorFilter>>;
  /** Дата и время "удаления" (анонимизации) пользователя */
  anonymizedAt?: Maybe<DateTimeOperationFilterInput>;
  /** Имя или псевдоним */
  displayName?: Maybe<StringOperationFilterInput>;
  /** Адрес электронной почты */
  email?: Maybe<StringOperationFilterInput>;
  or?: Maybe<Array<BenefactorFilter>>;
  /** Дата и время первой установки персональных данных (становления Помощником) */
  personalDataRegisteredAt?: Maybe<DateTimeOperationFilterInput>;
  /** Номер телефона */
  phoneNumber?: Maybe<StringOperationFilterInput>;
};

/** Друг жертвователя */
export type BenefactorFriend = {
  __typename?: 'BenefactorFriend';
  /** Друг жертвователя */
  friend: Benefactor;
};


/** Учётная запись жертвователя */
export type BenefactorIdentity = User & {
  __typename?: 'BenefactorIdentity';
  /** Жертвователь */
  benefactor: Benefactor;
  /** Дата регистрации */
  createdOn: Scalars['DateTime'];
  /** Признак блокировки учётной записи */
  disabled: Scalars['Boolean'];
  /** Имя для показа */
  displayName?: Maybe<Scalars['String']>;
  /** Адрес электронной почты */
  email?: Maybe<Scalars['String']>;
  /** Адрес электронной почты подтверждён администратором */
  emailConfirmed: Scalars['Boolean'];
  /** Идентификатор */
  id: Scalars['ID'];
  /** Номер телефона */
  phoneNumber?: Maybe<Scalars['String']>;
};

/** Разрешения, относящиеся к действиям с конкретным жертвователем */
export type BenefactorPermissions = {
  __typename?: 'BenefactorPermissions';
  /** Текущий пользователь может изменить информацию жертвователя */
  edit: Scalars['Boolean'];
  /** Идентификатор жертвователя */
  id: Scalars['BenefactorId'];
  /** Текущий пользователь может просматривать контактную информацию жертвователя */
  viewContactInformation: Scalars['Boolean'];
  /** Текущий пользователь может просматривать персональные данные жертвователя */
  viewPaymentInformation: Scalars['Boolean'];
  /** Текущий пользователь может просматривать персональные данные жертвователя */
  viewPersonalData: Scalars['Boolean'];
  /** Текущий пользователь может видеть рейтинг жертвователя (относится к участию в событиях) */
  viewRating: Scalars['Boolean'];
  /** Текущий пользователь может просматривать информацию, связанную с волонтёрской деятельностью жертвователя */
  viewVolunteerInformation: Scalars['Boolean'];
};

/** Персональные данные жертвователя */
export type BenefactorPersonalData = {
  __typename?: 'BenefactorPersonalData';
  /** Рассказ жертвователя о себе */
  about?: Maybe<Scalars['String']>;
  /** Дата рождения */
  birthday: Scalars['Date'];
  /** Имя */
  name: Nomen;
  /** Учётная запись VK */
  vkUsername?: Maybe<Scalars['String']>;
};

/** Персональные данные жертвователя */
export type BenefactorPersonalDataFilterInput = {
  /** Рассказ жертвователя о себе */
  about?: Maybe<StringOperationFilterInput>;
  and?: Maybe<Array<BenefactorPersonalDataFilterInput>>;
  /** Дата рождения */
  birthday?: Maybe<DateOperationFilterInput>;
  exists?: Maybe<Scalars['Boolean']>;
  /** Имя */
  name?: Maybe<NomenFilterInput>;
  or?: Maybe<Array<BenefactorPersonalDataFilterInput>>;
  /** Учётная запись VK */
  vkUsername?: Maybe<StringOperationFilterInput>;
};

export type BenefactorPersonalDataMissingError = Error & {
  __typename?: 'BenefactorPersonalDataMissingError';
  message: Scalars['String'];
};

/** A connection to a list of items. */
export type BenefactorsCoinsAccumulationRatingConnection = {
  __typename?: 'BenefactorsCoinsAccumulationRatingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<BenefactorsCoinsAccumulationRatingEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<BenefactorsCoinsAccumulationRatingEntry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BenefactorsCoinsAccumulationRatingEdge = {
  __typename?: 'BenefactorsCoinsAccumulationRatingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BenefactorsCoinsAccumulationRatingEntry;
};

/** Информация о балансе монет жертвователя. */
export type BenefactorsCoinsAccumulationRatingEntry = {
  __typename?: 'BenefactorsCoinsAccumulationRatingEntry';
  benefactor: Benefactor;
  /** Количество набранных монет */
  coins: Scalars['Int'];
};

/** A connection to a list of items. */
export type BenefactorsConnection = {
  __typename?: 'BenefactorsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<BenefactorsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Benefactor>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BenefactorsEdge = {
  __typename?: 'BenefactorsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Benefactor;
};

/** Информация о предлагаемых навыках помощника */
export type BenefactorSkillsInformation = {
  __typename?: 'BenefactorSkillsInformation';
  /** Описание опыта */
  experience?: Maybe<Scalars['String']>;
  /** Ссылка на портфолио */
  portfolio?: Maybe<Scalars['String']>;
  /** Навыки */
  skills: Array<Skill>;
};

/** Параметры для упорядочивания списка жертвователей */
export type BenefactorSort = {
  /** Имя или псевдоним */
  displayName?: Maybe<SortEnumType>;
  /** Номер телефона */
  phoneNumber?: Maybe<SortEnumType>;
};

/** Разрешения, относящиеся к действиям с жертвователями */
export type BenefactorsPermissions = {
  __typename?: 'BenefactorsPermissions';
  /** Текущий пользователь может регистрировать новых жертвователей */
  create: Scalars['Boolean'];
  /** Текущий пользователь может просматривать список всех жертвователей */
  list: Scalars['Boolean'];
  /** Текущий пользователь может управлять данными жертвователей */
  manage: Scalars['Boolean'];
};

/** Статус жертвователя */
export type BenefactorStatus = {
  __typename?: 'BenefactorStatus';
  /** Количество монет, по достижению которого выдаётся статус */
  coins: Scalars['Int'];
  /** Информация о создании статуса */
  created: AuditMarker;
  /** Описание статуса */
  description: Scalars['String'];
  id: Scalars['BenefactorStatusId'];
  /** Изображение статуса в ленте */
  image: FileInformation;
  /** История, содержащая описание статуса */
  story: Story;
  /** Название статуса */
  title: Scalars['String'];
  /** Информация о последнем обновлении статуса */
  updated: AuditMarker;
};

/** A connection to a list of items. */
export type BenefactorStatusesConnection = {
  __typename?: 'BenefactorStatusesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<BenefactorStatusesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<BenefactorStatus>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BenefactorStatusesEdge = {
  __typename?: 'BenefactorStatusesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BenefactorStatus;
};


/** A connection to a list of items. */
export type BenefactorsWithDepositAccountsConnection = {
  __typename?: 'BenefactorsWithDepositAccountsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<BenefactorsWithDepositAccountsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<BenefactorAndDepositAccountsAmount>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BenefactorsWithDepositAccountsEdge = {
  __typename?: 'BenefactorsWithDepositAccountsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BenefactorAndDepositAccountsAmount;
};

/** Информация о волонтёрском опыте и навыках */
export type BenefactorVolunteerInformation = {
  __typename?: 'BenefactorVolunteerInformation';
  /** Документы, имеющие отношение к волонтёрству */
  documents: Array<Document>;
  /** Описание волонтёрского опыта */
  experience?: Maybe<Scalars['String']>;
};

export type BooleanOperationFilterInput = {
  eq?: Maybe<Scalars['Boolean']>;
  neq?: Maybe<Scalars['Boolean']>;
};

/** Широковещательное уведомление */
export type BroadcastNotification = {
  __typename?: 'BroadcastNotification';
  /** Текст кнопки */
  buttonText?: Maybe<Scalars['String']>;
  /** Адрес перехода по нажатию кнопки */
  buttonUrl?: Maybe<Scalars['String']>;
  /** Информация о создании объекта */
  created: AuditMarker;
  /** Сообщение об ошибке, если такая возникла во время рассылки */
  errorMessage?: Maybe<Scalars['String']>;
  /** Файл со списком получателей уведомления */
  fileWithListOfRecipients?: Maybe<FileInformation>;
  id: Scalars['BroadcastNotificationId'];
  /** Изображения уведомления */
  images: Array<FileInformation>;
  /** Количество созданных уведомлений */
  populatedNotificationsCount: Scalars['Int'];
  /** Получатели уведомления */
  recipients?: Maybe<BroadcastNotificationRecipients>;
  /** Статус уведомления */
  status: BroadcastNotificationStatus;
  /** Текст уведомления */
  text: Scalars['String'];
  /** Заголовок уведомления */
  title: Scalars['String'];
};


/** Перечисление возможных получателей широковещательных уведомлений */
export enum BroadcastNotificationRecipients {
  /** Жертвователи, хоть раз перечислившие деньги (на проект или на подопечных) */
  Donated = 'DONATED',
  /** Жертвователи, хоть раз перечислившие деньги на проект */
  DonatedToProject = 'DONATED_TO_PROJECT',
  /** Жертвователи, хоть раз перечислившие деньги на подопечных */
  DonatedToWards = 'DONATED_TO_WARDS',
  /** Все жертвователи */
  Everyone = 'EVERYONE',
  /** Жертвователи, ни разу не перечислявшие денег */
  NeverDonated = 'NEVER_DONATED',
  /** Жертвователи без действующих подписок, но имеющие отменённые подписки на проект */
  WithoutActiveButWithCancelledProjectSubscriptions = 'WITHOUT_ACTIVE_BUT_WITH_CANCELLED_PROJECT_SUBSCRIPTIONS',
  /** Жертвователи без действующих подписок, но имеющие отменённые подписки (на проект или на подопечных) */
  WithoutActiveButWithCancelledSubscriptions = 'WITHOUT_ACTIVE_BUT_WITH_CANCELLED_SUBSCRIPTIONS',
  /** Жертвователи без действующих подписок, но имеющие отменённые подписки на подопечных */
  WithoutActiveButWithCancelledWardSubscriptions = 'WITHOUT_ACTIVE_BUT_WITH_CANCELLED_WARD_SUBSCRIPTIONS',
  /** Жертвователи без действующих подписок */
  WithoutActiveSubscriptions = 'WITHOUT_ACTIVE_SUBSCRIPTIONS',
  /** Жертвователи, ни разу не оформлявшие подписку */
  WithoutSubscriptions = 'WITHOUT_SUBSCRIPTIONS',
  /** Жертвователи, у которых есть активные подписки и на подопечных и на проект */
  WithActiveProjectAndWardsSubscriptions = 'WITH_ACTIVE_PROJECT_AND_WARDS_SUBSCRIPTIONS',
  /** Жертвователи, у которых есть активные подписки на подопечных, но нет активных подписок на проект */
  WithActiveProjectButWithoutActiveWardsSubscriptions = 'WITH_ACTIVE_PROJECT_BUT_WITHOUT_ACTIVE_WARDS_SUBSCRIPTIONS',
  /** Жертвователи с действующими подписками на проект */
  WithActiveProjectSubscriptions = 'WITH_ACTIVE_PROJECT_SUBSCRIPTIONS',
  /** Жертвователи с действующими подписками (на проект или на подопечных) */
  WithActiveSubscriptions = 'WITH_ACTIVE_SUBSCRIPTIONS',
  /** Жертвователи, у которых есть активные подписки на проект, но нет активных подписок на подопечных */
  WithActiveWardsButWithoutActiveProjectSubscriptions = 'WITH_ACTIVE_WARDS_BUT_WITHOUT_ACTIVE_PROJECT_SUBSCRIPTIONS',
  /** Жертвователи с действующими подписками на подопечных */
  WithActiveWardSubscriptions = 'WITH_ACTIVE_WARD_SUBSCRIPTIONS'
}

/** A connection to a list of items. */
export type BroadcastNotificationsConnection = {
  __typename?: 'BroadcastNotificationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<BroadcastNotificationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<BroadcastNotification>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BroadcastNotificationsEdge = {
  __typename?: 'BroadcastNotificationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BroadcastNotification;
};

/** Широковещательное уведомление */
export type BroadcastNotificationSortInput = {
  /** Информация о создании объекта */
  created?: Maybe<AuditMarkerSortInput>;
  /** Статус уведомления */
  status?: Maybe<SortEnumType>;
};

/** Разрешения, относящиеся к действиям с широковещательными уведомлениями */
export type BroadcastNotificationsPermissions = {
  __typename?: 'BroadcastNotificationsPermissions';
  /** Текущий пользователь может создавать широковещательные уведомления */
  create: Scalars['Boolean'];
  /** Текущий пользователь может просматривать широковещательные уведомления */
  view: Scalars['Boolean'];
};

/** Статус широковещательного уведомления */
export enum BroadcastNotificationStatus {
  /** Уведомление создано */
  Created = 'CREATED',
  /** Возникла ошибка при отправке */
  Error = 'ERROR',
  /** Уведомления для конкретных получателей созданы */
  Populated = 'POPULATED',
  /** Происходит создание уведомлений для конкретных получателей */
  Populating = 'POPULATING'
}

export type CancelFoundationEventInput = {
  /** Идентификатор события */
  foundationEventId: Scalars['FoundationEventId'];
};

export type CancelFoundationEventPayload = {
  __typename?: 'CancelFoundationEventPayload';
  foundationEvent?: Maybe<FoundationEvent>;
};

/** Параметры отмены спецпроекта */
export type CancelSpecialProjectInput = {
  /** Идентификатор спецпроекта */
  id: Scalars['SpecialProjectId'];
  /** Причина отмены спецпроекта */
  reason: Scalars['String'];
};

export type CancelSpecialProjectPayload = {
  __typename?: 'CancelSpecialProjectPayload';
  specialProject?: Maybe<SpecialProject>;
};

/** Параметры запроса на отмену подписки */
export type CancelSubscriptionInput = {
  /** Идентификатор подписки, которую требуется отменить */
  subscriptionId: Scalars['DonationSubscriptionId'];
};

export type CancelSubscriptionPayload = {
  __typename?: 'CancelSubscriptionPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** Разрешения, относящиеся к разнообразным каталогам данных */
export type CatalogsPermissions = {
  __typename?: 'CatalogsPermissions';
  /** Текущий пользователь может управлять каталогом городов. */
  manageCities: Scalars['Boolean'];
};

/** Разрешения, относящиеся к работе с категориями */
export type CategoriesPermissions = {
  __typename?: 'CategoriesPermissions';
  /** Текущий пользователь может управлять категориями. */
  manage: Scalars['Boolean'];
};

/** Категория подопечного, спецпроекта и т.п. */
export type Category = {
  __typename?: 'Category';
  /** Дата и время создания категории. */
  createdAt: Scalars['DateTime'];
  id: Scalars['CategoryId'];
  /** Изображение */
  image?: Maybe<FileInformation>;
  /** Название */
  name: Scalars['String'];
  /** Порядок показа в списке */
  order: Scalars['Int'];
  /** Дата и время последнего обновления категории. */
  updatedAt: Scalars['DateTime'];
};

/** Ошибка, сообщающая, что категория уже существует */
export type CategoryAlreadyExistsError = Error & {
  __typename?: 'CategoryAlreadyExistsError';
  message: Scalars['String'];
};


export type CategoryIdFilterInput = {
  and?: Maybe<Array<CategoryIdFilterInput>>;
  eq: Scalars['CategoryId'];
  neq: Scalars['CategoryId'];
  or?: Maybe<Array<CategoryIdFilterInput>>;
};

export type CategoryNotFoundError = Error & {
  __typename?: 'CategoryNotFoundError';
  message: Scalars['String'];
};

/** Параметры изменения видимости профиля жертвователя */
export type ChangeBenefactorProfileVisibilityInput = {
  /** Скрывать профиль жертвователя */
  hideProfile: Scalars['Boolean'];
};

export type ChangeBenefactorProfileVisibilityPayload = {
  __typename?: 'ChangeBenefactorProfileVisibilityPayload';
  benefactor?: Maybe<Benefactor>;
};

export type ChangePhoneNumberByAdministratorError = PhoneNumberAlreadyRegisteredError | UserPersistenceError;

/** Параметры изменения номера телефона жертвователя */
export type ChangePhoneNumberByAdministratorInput = {
  /** Номер телефона */
  phoneNumber: Scalars['String'];
  /** Идентификатор пользователя */
  userId: Scalars['ID'];
};

export type ChangePhoneNumberByAdministratorPayload = {
  __typename?: 'ChangePhoneNumberByAdministratorPayload';
  errors?: Maybe<Array<ChangePhoneNumberByAdministratorError>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ChangePhoneNumberCodeError = ForbiddenForUserError | PhoneNumberAlreadyRegisteredError | UserCommunicationError;

/** Параметры инициирования смены номера телефона */
export type ChangePhoneNumberCodeInput = {
  /** Новый номер телефона */
  newPhoneNumber: Scalars['String'];
};

export type ChangePhoneNumberCodePayload = {
  __typename?: 'ChangePhoneNumberCodePayload';
  errors?: Maybe<Array<ChangePhoneNumberCodeError>>;
  resendAt?: Maybe<Scalars['DateTime']>;
};

export type ChangePhoneNumberError = ForbiddenForUserError | PhoneNumberAlreadyRegisteredError | UserCommunicationError;

/** Параметры изменения номера телефона пользователя */
export type ChangePhoneNumberInput = {
  /** Код подтверждения */
  code: Scalars['String'];
  /** Новый номер телефона */
  newPhoneNumber: Scalars['String'];
};

export type ChangePhoneNumberPayload = {
  __typename?: 'ChangePhoneNumberPayload';
  errors?: Maybe<Array<ChangePhoneNumberError>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Параметры изменения порядка следования историй в блоке. */
export type ChangeStoriesOrderInput = {
  blockId: Scalars['StoryBlockId'];
  storiesIds: Array<Scalars['StoryId']>;
};

export type ChangeStoriesOrderPayload = {
  __typename?: 'ChangeStoriesOrderPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type ChangeUserPasswordError = ForbiddenForUserError | UserPersistenceError;

/** Параметры изменения пароля пользователя */
export type ChangeUserPasswordInput = {
  /** Идентификатор пользователя */
  id: Scalars['ID'];
  /** Новый пароль пользователя */
  newPassword: Scalars['String'];
};

export type ChangeUserPasswordPayload = {
  __typename?: 'ChangeUserPasswordPayload';
  errors?: Maybe<Array<ChangeUserPasswordError>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CheckinToFoundationEventError = InvalidFoundationEventAttendanceCodeError;

export type CheckinToFoundationEventInput = {
  /** Регистрационный код */
  code: Scalars['Int'];
  /** Идентификатор события */
  id: Scalars['FoundationEventId'];
};

export type CheckinToFoundationEventPayload = {
  __typename?: 'CheckinToFoundationEventPayload';
  attendance?: Maybe<FoundationEventAttendance>;
  errors?: Maybe<Array<CheckinToFoundationEventError>>;
};

/** A connection to a list of items. */
export type CitiesConnection = {
  __typename?: 'CitiesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CitiesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<City>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CitiesEdge = {
  __typename?: 'CitiesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: City;
};

/** Город */
export type City = {
  __typename?: 'City';
  /** Показывать город в списке городов по-умолчанию */
  default: Scalars['Boolean'];
  id: Scalars['CityId'];
  /** Название города */
  name: Scalars['String'];
  /** Порядок показа города в списке городов по-умолчанию */
  order: Scalars['Int'];
};

export type CityAlreadyExistsError = Error & {
  __typename?: 'CityAlreadyExistsError';
  message: Scalars['String'];
  name: Scalars['String'];
};

export type CityByIpResult = {
  __typename?: 'CityByIpResult';
  city?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
};


export type CityIsAssignedError = Error & {
  __typename?: 'CityIsAssignedError';
  message: Scalars['String'];
  name: Scalars['String'];
};

export type CityNotFoundError = Error & {
  __typename?: 'CityNotFoundError';
  cityName: Scalars['String'];
  message: Scalars['String'];
};

/** Город */
export type CitySortInput = {
  /** Показывать город в списке городов по-умолчанию */
  default?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  /** Название города */
  name?: Maybe<SortEnumType>;
  /** Порядок показа города в списке городов по-умолчанию */
  order?: Maybe<SortEnumType>;
};

/** Клиентские приложения */
export enum ClientApplication {
  /** Мобильное приложение Android */
  Android = 'ANDROID',
  /** Мобильное приложение iOS */
  Ios = 'IOS'
}

/** Информация о версии клиента */
export type ClientVersion = {
  __typename?: 'ClientVersion';
  /** Идентификатор клиента */
  client: ClientApplication;
  id: Scalars['ClientVersionId'];
  /** MAJOR-часть версии клиента */
  major: Scalars['Int'];
  /** Признак обязательности обновления */
  mandatory: Scalars['Boolean'];
  /** MINOR-часть версии клиента */
  minor: Scalars['Int'];
  /** PATCH-часть версии клиента */
  patch: Scalars['Int'];
};

export type ClientVersionAlreadyExistsError = Error & {
  __typename?: 'ClientVersionAlreadyExistsError';
  client: ClientApplication;
  major: Scalars['Int'];
  message: Scalars['String'];
  minor: Scalars['Int'];
  patch: Scalars['Int'];
};


/** A connection to a list of items. */
export type ClientVersionsConnection = {
  __typename?: 'ClientVersionsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ClientVersionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ClientVersion>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ClientVersionsEdge = {
  __typename?: 'ClientVersionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ClientVersion;
};

/** Разрешения, относящиеся к действиям с версиями клиентских приложений */
export type ClientVersionsPermissions = {
  __typename?: 'ClientVersionsPermissions';
  /** Текущий пользователь может управлять версиями клиентских приложений */
  manage: Scalars['Boolean'];
};

export type CloseFoundationEventError = FoundationEventReportDoesNotExistsError;

export type CloseFoundationEventInput = {
  /** Идентификатор события */
  foundationEventId: Scalars['FoundationEventId'];
};

export type CloseFoundationEventPayload = {
  __typename?: 'CloseFoundationEventPayload';
  errors?: Maybe<Array<CloseFoundationEventError>>;
  foundationEvent?: Maybe<FoundationEvent>;
};

export type CloseFoundationEventRegistrationInput = {
  /** Идентификатор события */
  foundationEventId: Scalars['FoundationEventId'];
};

export type CloseFoundationEventRegistrationPayload = {
  __typename?: 'CloseFoundationEventRegistrationPayload';
  foundationEvent?: Maybe<FoundationEvent>;
};

export type CloseSpecialProjectInput = {
  /** Идентификатор спецпроекта */
  id: Scalars['SpecialProjectId'];
};

export type CloseSpecialProjectPayload = {
  __typename?: 'CloseSpecialProjectPayload';
  specialProject?: Maybe<SpecialProject>;
};

/** Требование пройти 3DS-верификацию для подтверждения оплаты картой в системе CloudPayments */
export type CloudPayments3DSecurePaymentChallenge = {
  __typename?: 'CloudPayments3DSecurePaymentChallenge';
  /** Адрес страницы подтверждения */
  acsUrl: Scalars['String'];
  /** Данные требования */
  paReq: Scalars['String'];
};

/** Ответ на запрос дополнительной проверки подлинности держателя карты Visa или MasterCard */
export type CloudPayments3DSecurePaymentChallengeResponseInput = {
  /** Код ответа */
  paRes: Scalars['String'];
};

/** Информация о неуспешном платеже банковской картой в системе CloudPayments */
export type CloudPaymentsCardFailedPaymentDetails = CloudPaymentsCardPaymentDetails & CloudPaymentsPaymentDetails & {
  __typename?: 'CloudPaymentsCardFailedPaymentDetails';
  /** Тип платёжной карты (Visa, Mastercard, etc) */
  cardType: Scalars['String'];
  /** Сообщение для владельца карты */
  cardholderMessage: Scalars['String'];
  /** Маскированный номер платёжной карты */
  maskedCardNumber: Scalars['String'];
  /** Код отказа транзакции */
  reasonCode: Scalars['Int'];
  /** Идентификатор транзакции CloudPayments */
  transactionId: Scalars['Long'];
};

/** Информация о платеже банковской картой, проведённом через CloudPayments */
export type CloudPaymentsCardPaymentDetails = {
  /** Тип платёжной карты (Visa, Mastercard, etc) */
  cardType: Scalars['String'];
  /** Маскированный номер платёжной карты */
  maskedCardNumber: Scalars['String'];
  /** Идентификатор транзакции CloudPayments */
  transactionId: Scalars['Long'];
};

/** Информация об успешном платеже банковской картой в системе CloudPayments */
export type CloudPaymentsCardSuccessfulPaymentDetails = CloudPaymentsCardPaymentDetails & CloudPaymentsPaymentDetails & {
  __typename?: 'CloudPaymentsCardSuccessfulPaymentDetails';
  /** Код авторизации транзакции */
  authorizationCode: Scalars['String'];
  /** Дата и время авторизации транзакции */
  authorizationDate: Scalars['DateTime'];
  /** Тип платёжной карты (Visa, Mastercard, etc) */
  cardType: Scalars['String'];
  /** Дата и время подтверждения транзакции */
  confirmDate: Scalars['DateTime'];
  /** Маскированный номер платёжной карты */
  maskedCardNumber: Scalars['String'];
  /** Идентификатор транзакции CloudPayments */
  transactionId: Scalars['Long'];
};

/** Намерение совершить оплату новой (не привязанной) платёжной картой */
export type CloudPaymentsNewCardPaymentIntent = {
  __typename?: 'CloudPaymentsNewCardPaymentIntent';
  /** Имя держателя карты, латиницей */
  cardholderName: Scalars['String'];
  /** Криптограмма, созданная API CloudPayments */
  cryptogram: Scalars['String'];
  /** Сохранить карту для будущих платежей? */
  saveCard: Scalars['Boolean'];
  /** Сделать картой по умолчанию? */
  setAsDefaultCard: Scalars['Boolean'];
};

/** Намерение оплатить заказ новой картой через систему CloudPayments */
export type CloudPaymentsNewCardPaymentIntentInput = {
  /** Имя держателя карты, латиницей */
  cardholderName: Scalars['String'];
  /** Криптограмма, созданная API CloudPayments */
  cryptogram: Scalars['String'];
  /** Сохранить карту для будущих платежей? */
  saveCard: Scalars['Boolean'];
  /** Сделать картой по-умолчанию? */
  setAsDefaultCard: Scalars['Boolean'];
};

/** Способ оплаты "Новая банковская карта РФ или МИР" */
export type CloudPaymentsNewCardPaymentMethod = PaymentMethod & {
  __typename?: 'CloudPaymentsNewCardPaymentMethod';
  /** Указанная карта обязана быть сохранена. */
  cardMustBeSaved: Scalars['Boolean'];
  /** Название способа оплаты */
  title: Scalars['String'];
};

/** Информация о платеже, произведённом через CloudPayments */
export type CloudPaymentsPaymentDetails = {
  /** Идентификатор транзакции CloudPayments */
  transactionId: Scalars['Long'];
};

/** Информация о сохранённой в системе CloudPayments банковской карте */
export type CloudPaymentsSavedCard = {
  __typename?: 'CloudPaymentsSavedCard';
  /** Жертвователь */
  benefactorId: Scalars['BenefactorId'];
  /** Срок действия карты */
  cardExpirationDate: Scalars['String'];
  /** Тип карты (VISA/Mastercard/MIR/etc) */
  cardType: Scalars['String'];
  /** Имя владельца карты */
  cardholderName: Scalars['String'];
  /** Причина последней неуспешной попытки списания средств */
  failureMessage?: Maybe<Scalars['String']>;
  /** Количество неуспешных попыток списания средств */
  failuresCount: Scalars['Int'];
  id: Scalars['CloudPaymentsSavedCardId'];
  /** Безопасный для показа номер карты */
  maskedCardNumber: Scalars['String'];
  /** Платёжный токен; используется для платежей вместо данных карты */
  token: Scalars['String'];
};


/** Намерение совершить оплату сохранённой платёжной картой */
export type CloudPaymentsSavedCardPaymentIntent = {
  __typename?: 'CloudPaymentsSavedCardPaymentIntent';
  /** Сохранённая карта, использующаяся для оплаты */
  savedCard?: Maybe<CloudPaymentsSavedCard>;
  /** Сделать картой по-умолчанию? */
  setAsDefaultCard: Scalars['Boolean'];
};

/** Намерение оплатить заказ сохранённой картой */
export type CloudPaymentsSavedCardPaymentIntentInput = {
  /** Идентификатор сохранённой карты */
  savedCardId: Scalars['CloudPaymentsSavedCardId'];
  /** Сделать картой по-умолчанию? */
  setAsDefaultCard: Scalars['Boolean'];
};

/** Способ оплаты "Сохранённая банковская карта РФ или МИР" */
export type CloudPaymentsSavedCardPaymentMethod = PaymentMethod & {
  __typename?: 'CloudPaymentsSavedCardPaymentMethod';
  /** Банковская карта по-умолчанию */
  defaultCard?: Maybe<CloudPaymentsSavedCard>;
  /** Сохранённые банковские карты */
  savedCards: Array<CloudPaymentsSavedCard>;
  /** Название способа оплаты */
  title: Scalars['String'];
};

/** Информация о неуспешном платеже через СБП в системе CloudPayments */
export type CloudPaymentsSbpFailedPaymentDetails = CloudPaymentsPaymentDetails & CloudPaymentsSbpPaymentDetails & {
  __typename?: 'CloudPaymentsSbpFailedPaymentDetails';
  message: Scalars['String'];
  /** Идентификатор QR-кода от провайдера */
  providerQrId: Scalars['String'];
  /** Идентификатор транзакции CloudPayments */
  transactionId: Scalars['Long'];
};

/** Информация для проведения оплаты через СБП в системе CloudPayments */
export type CloudPaymentsSbpPaymentChallenge = {
  __typename?: 'CloudPaymentsSbpPaymentChallenge';
  /** Ссылка на страницу оплаты */
  link: Scalars['String'];
};

/** Информация о платеже через СБП, проведённом через CloudPayments */
export type CloudPaymentsSbpPaymentDetails = {
  /** Идентификатор QR-кода от провайдера */
  providerQrId: Scalars['String'];
  /** Идентификатор транзакции CloudPayments */
  transactionId: Scalars['Long'];
};

/** Намерение совершить оплату через СБП */
export type CloudPaymentsSbpPaymentIntent = {
  __typename?: 'CloudPaymentsSbpPaymentIntent';
  anyValue?: Maybe<Scalars['Boolean']>;
};

/** Намерение оплатить заказ через СПБ */
export type CloudPaymentsSbpPaymentIntentInput = {
  /** Любое значение, игнорируется */
  anyValue: Scalars['Boolean'];
};

/** Способ оплаты "Оплата через СБП" */
export type CloudPaymentsSbpPaymentMethod = PaymentMethod & {
  __typename?: 'CloudPaymentsSbpPaymentMethod';
  /** Название способа оплаты */
  title: Scalars['String'];
};

/** Информация об успешном платеже через СБП в системе CloudPayments */
export type CloudPaymentsSbpSuccessfulPaymentDetails = CloudPaymentsPaymentDetails & CloudPaymentsSbpPaymentDetails & {
  __typename?: 'CloudPaymentsSbpSuccessfulPaymentDetails';
  /** Идентификатор QR-кода от провайдера */
  providerQrId: Scalars['String'];
  /** Идентификатор транзакции CloudPayments */
  transactionId: Scalars['Long'];
};

/** Информация о начислении монет жертвователю */
export type CoinsAccumulation = {
  __typename?: 'CoinsAccumulation';
  /** Количество начисленных монет */
  amount: Scalars['Int'];
  /** Дата и время начисления */
  createdAt: Scalars['DateTime'];
  /** Описание начисления */
  description: Scalars['String'];
  id: Scalars['CoinsAccumulationId'];
  /** Причина начисления сердец */
  reason: CoinsAccumulationReason;
};

/** A connection to a list of items. */
export type CoinsAccumulationHistoryConnection = {
  __typename?: 'CoinsAccumulationHistoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CoinsAccumulationHistoryEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<CoinsAccumulation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CoinsAccumulationHistoryEdge = {
  __typename?: 'CoinsAccumulationHistoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CoinsAccumulation;
};


/** Причина начисления сердец жертвователю */
export enum CoinsAccumulationReason {
  /** Комиссия за пожертвование */
  Commission = 'COMMISSION',
  /** Первый платёж */
  FirstTimeDonation = 'FIRST_TIME_DONATION',
  /** Участие в деле */
  FoundationEventParticipation = 'FOUNDATION_EVENT_PARTICIPATION',
  /** Пожертвование друга */
  FriendDonation = 'FRIEND_DONATION',
  /** Регистрация нового друга */
  FriendRegistered = 'FRIEND_REGISTERED',
  /** Ручное назначение монет администратором */
  ManualAssignment = 'MANUAL_ASSIGNMENT',
  /** Разовый платёж */
  OneTimeDonation = 'ONE_TIME_DONATION',
  /** Новая подписка на проект */
  ProjectSubscription = 'PROJECT_SUBSCRIPTION',
  /** Регистрация */
  Registration = 'REGISTRATION',
  /** Ежемесячное списание подписки */
  SubscriptionCharge = 'SUBSCRIPTION_CHARGE',
  /** Обновление условий подписки */
  SubscriptionUpgrade = 'SUBSCRIPTION_UPGRADE',
  /** Заполнение анкеты помощника */
  VolunteerInformationProvided = 'VOLUNTEER_INFORMATION_PROVIDED',
  /** Новая подписка на подопечного */
  WardSubscription = 'WARD_SUBSCRIPTION'
}

/** Информация о предлагаемом уровне комиссии */
export type CommissionPercentageSuggestion = {
  __typename?: 'CommissionPercentageSuggestion';
  /** Размер комиссии */
  commissionPercentage: Scalars['Int'];
  /** Описание */
  description: Scalars['String'];
  /** Заголовок */
  title: Scalars['String'];
};

export type ConfirmEmailError = UserNotFoundError | UserPersistenceError;

/** Параметры подтверждения адреса электронной почты пользователя */
export type ConfirmEmailInput = {
  /** Код подтверждения */
  code: Scalars['String'];
  /** Идентификатор пользователя */
  id: Scalars['ID'];
};

export type ConfirmEmailPayload = {
  __typename?: 'ConfirmEmailPayload';
  errors?: Maybe<Array<ConfirmEmailError>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ConvertFoundationEventApplicationToAttendanceInput = {
  /** Идентификатор заявки на участие в событии */
  applicationId: Scalars['FoundationEventApplicationId'];
};

export type ConvertFoundationEventApplicationToAttendancePayload = {
  __typename?: 'ConvertFoundationEventApplicationToAttendancePayload';
  attendance?: Maybe<FoundationEventAttendance>;
};

export type CreateBenefactorDepositAccountForAllWardsError = DepositAccountAlreadyExistsError;

/** Данные для создания счёта-депозита жертвователя, средства с которого могут расходоваться на потребности всех подопечных */
export type CreateBenefactorDepositAccountForAllWardsInput = {
  benefactorId: Scalars['BenefactorId'];
};

export type CreateBenefactorDepositAccountForAllWardsPayload = {
  __typename?: 'CreateBenefactorDepositAccountForAllWardsPayload';
  benefactor?: Maybe<Benefactor>;
  errors?: Maybe<Array<CreateBenefactorDepositAccountForAllWardsError>>;
};

export type CreateBenefactorDepositAccountForProjectNeedsError = DepositAccountAlreadyExistsError;

/** Данные для создания счёта-депозита жертвователя, средства с которого могут расходоваться только на нужды проекта */
export type CreateBenefactorDepositAccountForProjectNeedsInput = {
  benefactorId: Scalars['BenefactorId'];
};

export type CreateBenefactorDepositAccountForProjectNeedsPayload = {
  __typename?: 'CreateBenefactorDepositAccountForProjectNeedsPayload';
  benefactor?: Maybe<Benefactor>;
  errors?: Maybe<Array<CreateBenefactorDepositAccountForProjectNeedsError>>;
};

/**
 * Данные для создания счёта-депозита жертвователя, средства с которого могут
 * расходоваться только на потребности указанных подопечных
 */
export type CreateBenefactorDepositAccountForSpecificWardsInput = {
  benefactorId: Scalars['BenefactorId'];
  /** Заголовок счёта, для показа в списках */
  title: Scalars['String'];
};

export type CreateBenefactorDepositAccountForSpecificWardsPayload = {
  __typename?: 'CreateBenefactorDepositAccountForSpecificWardsPayload';
  benefactor?: Maybe<Benefactor>;
};

export type CreateBenefactorDepositAccountWithoutConditionsError = DepositAccountAlreadyExistsError;

/** Данные для создания счёта-депозита жертвователя, средства с которого могут расходоваться на любые цели */
export type CreateBenefactorDepositAccountWithoutConditionsInput = {
  benefactorId: Scalars['BenefactorId'];
};

export type CreateBenefactorDepositAccountWithoutConditionsPayload = {
  __typename?: 'CreateBenefactorDepositAccountWithoutConditionsPayload';
  benefactor?: Maybe<Benefactor>;
  errors?: Maybe<Array<CreateBenefactorDepositAccountWithoutConditionsError>>;
};

export type CreateBenefactorError = EmailAlreadyRegisteredError | PhoneNumberAlreadyRegisteredError | UserPersistenceError;

/** Параметры добавления нового жертвователя */
export type CreateBenefactorInput = {
  /** Имя */
  displayName: Scalars['String'];
  /** Адрес электронной почты */
  email?: Maybe<Scalars['String']>;
  /** Скрывать профиль */
  hideProfile: Scalars['Boolean'];
  /** Номер телефона */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Идентификатор файла-фотографии жертвователя */
  photoId?: Maybe<Scalars['String']>;
};

export type CreateBenefactorPayload = {
  __typename?: 'CreateBenefactorPayload';
  benefactor?: Maybe<Benefactor>;
  errors?: Maybe<Array<CreateBenefactorError>>;
};

export type CreateFoundationRepresentativeError = EmailAlreadyRegisteredError | UserCommunicationError | UserPersistenceError;

/** Параметры создания учётной записи представителя фонда */
export type CreateFoundationRepresentativeInput = {
  displayName: Scalars['String'];
  email: Scalars['String'];
  /** Идентификатор фонда */
  foundationId: Scalars['FoundationId'];
};

export type CreateFoundationRepresentativePayload = {
  __typename?: 'CreateFoundationRepresentativePayload';
  errors?: Maybe<Array<CreateFoundationRepresentativeError>>;
  user?: Maybe<User>;
};

/** Параметры для создания отчёта ООО */
export type CreateLimitedLiabilityCompanyReportInput = {
  referenceDate: Scalars['Date'];
  title: Scalars['String'];
};

export type CreateLimitedLiabilityCompanyReportPayload = {
  __typename?: 'CreateLimitedLiabilityCompanyReportPayload';
  report?: Maybe<LimitedLiabilityCompanyReport>;
};

export type CreateModeratorError = EmailAlreadyRegisteredError | UserCommunicationError | UserPersistenceError;

/** Параметры создания учётной записи модератора */
export type CreateModeratorInput = {
  displayName: Scalars['String'];
  email: Scalars['String'];
};

export type CreateModeratorPayload = {
  __typename?: 'CreateModeratorPayload';
  errors?: Maybe<Array<CreateModeratorError>>;
  user?: Maybe<User>;
};

export type CreateReferrerLinkPayload = {
  __typename?: 'CreateReferrerLinkPayload';
  link?: Maybe<Scalars['String']>;
};

export type CreateSpecialProjectError = CategoryNotFoundError | CityNotFoundError;

/** Параметры создания нового спецпроекта */
export type CreateSpecialProjectInput = {
  /** Адрес */
  address?: Maybe<Scalars['String']>;
  /** Маркетинговая кампания, к которой принадлежит спецпроект */
  campaign?: Maybe<Scalars['String']>;
  /** Категории */
  categories: Array<Scalars['CategoryId']>;
  /** Город */
  city?: Maybe<Scalars['String']>;
  /** Описание */
  description: Scalars['String'];
  /** Дата, к которой требуется собрать средства */
  end?: Maybe<Scalars['DateTime']>;
  /** Изображение для ленты/поиска */
  feedImage: Scalars['String'];
  /** Изображения */
  images: Array<Scalars['String']>;
  /** Логотип фонда-партнёра для наложения поверх остальных изображений */
  logo?: Maybe<Scalars['String']>;
  /** Необходимая сумма */
  requiredAmount?: Maybe<Scalars['Int']>;
  /** Дата начала сбора средств */
  start: Scalars['DateTime'];
  /** Название */
  title: Scalars['String'];
};

export type CreateSpecialProjectPayload = {
  __typename?: 'CreateSpecialProjectPayload';
  errors?: Maybe<Array<CreateSpecialProjectError>>;
  specialProject?: Maybe<SpecialProject>;
};

export type CreateSystemAdministratorError = EmailAlreadyRegisteredError | UserCommunicationError | UserPersistenceError;

/** Параметры создания учётной записи системного администратора */
export type CreateSystemAdministratorInput = {
  displayName: Scalars['String'];
  email: Scalars['String'];
};

export type CreateSystemAdministratorPayload = {
  __typename?: 'CreateSystemAdministratorPayload';
  errors?: Maybe<Array<CreateSystemAdministratorError>>;
  user?: Maybe<User>;
};


export type DateOperationFilterInput = {
  eq?: Maybe<Scalars['Date']>;
  gt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  in?: Maybe<Array<Maybe<Scalars['Date']>>>;
  lt?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  neq?: Maybe<Scalars['Date']>;
  ngt?: Maybe<Scalars['Date']>;
  ngte?: Maybe<Scalars['Date']>;
  nin?: Maybe<Array<Maybe<Scalars['Date']>>>;
  nlt?: Maybe<Scalars['Date']>;
  nlte?: Maybe<Scalars['Date']>;
};


export type DateTimeOperationFilterInput = {
  eq?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  ngt?: Maybe<Scalars['DateTime']>;
  ngte?: Maybe<Scalars['DateTime']>;
  nin?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  nlt?: Maybe<Scalars['DateTime']>;
  nlte?: Maybe<Scalars['DateTime']>;
};

export type DeactivateWardInput = {
  /** Причина деактивации */
  reason: WardDeactivationReason;
  /** Идентификатор подопечного */
  wardId: Scalars['WardId'];
};

export type DeactivateWardPayload = {
  __typename?: 'DeactivateWardPayload';
  ward?: Maybe<Ward>;
};

/** Параметры отклонения заявки на участие в событии фонда-партнёра */
export type DeclineFoundationEventApplicationInput = {
  applicationId: Scalars['FoundationEventApplicationId'];
};

export type DeclineFoundationEventApplicationPayload = {
  __typename?: 'DeclineFoundationEventApplicationPayload';
  application?: Maybe<FoundationEventApplication>;
};

export type DeleteAchievementInput = {
  /** Идентификатор достижения */
  id: Scalars['AchievementId'];
};

export type DeleteAchievementPayload = {
  __typename?: 'DeleteAchievementPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteBenefactorStatusInput = {
  /** Идентификатор статуса жертвователя */
  id: Scalars['BenefactorStatusId'];
};

export type DeleteBenefactorStatusPayload = {
  __typename?: 'DeleteBenefactorStatusPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteCategoryInput = {
  /** Идентификатор категории */
  id: Scalars['CategoryId'];
};

export type DeleteCategoryPayload = {
  __typename?: 'DeleteCategoryPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteCityError = CityIsAssignedError;

/** Параметры удаления города. */
export type DeleteCityInput = {
  /** Идентификатор города. */
  id: Scalars['CityId'];
};

export type DeleteCityPayload = {
  __typename?: 'DeleteCityPayload';
  errors?: Maybe<Array<DeleteCityError>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteStoryBlockError = StoryBlockWithStoriesCanNotBeDeletedError;

/** Параметры удаления блока историй. */
export type DeleteStoryBlockInput = {
  /** Идентификатор блока историй. */
  id: Scalars['StoryBlockId'];
};

export type DeleteStoryBlockPayload = {
  __typename?: 'DeleteStoryBlockPayload';
  errors?: Maybe<Array<DeleteStoryBlockError>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Параметры удаления истории. */
export type DeleteStoryInput = {
  /** Идентификатор истории. */
  id: Scalars['StoryId'];
};

export type DeleteStoryPayload = {
  __typename?: 'DeleteStoryPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteUserError = ForbiddenForUserError | UserCanNotBeDeletedError | UserPersistenceError;

/** Параметры удаления учётной записи пользователя */
export type DeleteUserInput = {
  /** Идентификатор пользователя */
  id: Scalars['ID'];
};

export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  errors?: Maybe<Array<DeleteUserError>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DepositAccountAlreadyExistsError = Error & {
  __typename?: 'DepositAccountAlreadyExistsError';
  message: Scalars['String'];
};

/** Параметры размещения депозита жертвователя */
export type DepositToBenefactorAccountInput = {
  /** Идентификатор счёта-депозита жертвователя */
  accountId: Scalars['AccountId'];
  /** Размер депозита */
  amount: Scalars['Int'];
  /** Название контрагента */
  counterpartyName: Scalars['String'];
  /** Номер платёжного документа */
  documentNumber: Scalars['String'];
  /** Назначение платежа */
  purpose: Scalars['String'];
  /** Дата перевода */
  transferDate: Scalars['Date'];
};

export type DepositToBenefactorAccountPayload = {
  __typename?: 'DepositToBenefactorAccountPayload';
  order?: Maybe<Order>;
};

export type DisableSubscriptionTransferToWardInput = {
  /** Идентификатор подопечного */
  wardId: Scalars['WardId'];
};

export type DisableSubscriptionTransferToWardPayload = {
  __typename?: 'DisableSubscriptionTransferToWardPayload';
  ward?: Maybe<Ward>;
};

/** Отчётный документ или именованный файл */
export type Document = {
  __typename?: 'Document';
  /** Информация о файле */
  file: FileInformation;
  /** Заголовок/название файла */
  title?: Maybe<Scalars['String']>;
};

/** Параметры добавления или изменения документы */
export type DocumentInput = {
  /** Идентификатор файла */
  id: Scalars['String'];
  /** Заголовок или название файла */
  title?: Maybe<Scalars['String']>;
};

/** Пожертвование */
export type Donation = {
  __typename?: 'Donation';
  /** Счёт зачисления средств */
  account: Account;
  /** Размер пожертвования */
  amount: Scalars['Int'];
  /** Жертвователь */
  benefactor: Benefactor;
  /** Дата пожертвования */
  createdAt: Scalars['DateTime'];
  id: Scalars['DonationId'];
  /** Заказ, на основе которого создано данное пожертвование */
  order: Order;
  /** Подписка */
  subscription?: Maybe<DonationSubscription>;
};


/** Назначение пожертвования или подписки */
export type DonationIntent = BenefactorDepositAccountDonationIntent | LegacyOneTimeDonationIntent | LegacySubscriptionChargeIntent | LegacySubscriptionIntent | ProjectDonationIntent | ProjectSubscriptionIntent | SpecialProjectDonationIntent | SubscriptionChargeIntent | WardOneTimeNeedDonationIntent | WardRegularNeedsDonationIntent | WardsReserveDonationIntent | WardsReserveSubscriptionIntent | WardsSubscriptionIntent;

export type DonationIntentInput = {
  /** Намерение пополнить счёт-депозит жертвователя */
  depositToBenefactorAccount?: Maybe<BenefactorDepositAccountDonationIntentInput>;
  /** Намерение совершить пожертвование на развитие проекта */
  projectDonation?: Maybe<ProjectDonationIntentInput>;
  /** Намерение подписаться на развитие проекта */
  projectSubscription?: Maybe<ProjectSubscriptionIntentInput>;
  /** Намерение совершить пожертвование на спецпроект */
  specialProjectDonation?: Maybe<SpecialProjectDonationIntentInput>;
  /** Намерение совершить пожертвование на разовую потребность подопечного */
  wardOneTimeNeedDonation?: Maybe<WardOneTimeNeedDonationIntentInput>;
  /** Намерение совершить пожертвование на регулярные потребности подопечного */
  wardRegularNeedsDonation?: Maybe<WardRegularNeedsDonationIntentInput>;
  /** Намерение совершить пожертвование в резерв подопечных */
  wardsReserveDonation?: Maybe<WardsReserveDonationIntentInput>;
  /** Намерение совершать регулярные пожертвования в резервный фонд подопечных */
  wardsReserveSubscription?: Maybe<WardsReserveSubscriptionIntentInput>;
  /** Намерение подписаться на регулярные потребности указанных подопечных */
  wardsSubscription?: Maybe<WardsSubscriptionIntentInput>;
};

/** A connection to a list of items. */
export type DonationsConnection = {
  __typename?: 'DonationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<DonationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Donation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type DonationsEdge = {
  __typename?: 'DonationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Donation>;
};

/** Пожертвование */
export type DonationSortInput = {
  /** Размер пожертвования */
  amount?: Maybe<SortEnumType>;
  /** Дата пожертвования */
  createdAt?: Maybe<SortEnumType>;
};

/** Подписка - регулярные пожертвования */
export type DonationSubscription = {
  /** Сумма подписки */
  amount: Scalars['Int'];
  /** Жертвователь */
  benefactor: Benefactor;
  /** Дата отмены подписки */
  cancelledAt?: Maybe<Scalars['DateTime']>;
  /** Дата создания подписки */
  createdAt: Scalars['DateTime'];
  id: Scalars['DonationSubscriptionId'];
  /** Заказ, на основе которого создана подписка */
  order: Order;
};

/** Причина отмены подписки */
export enum DonationSubscriptionCancellationReason {
  /** Жертвователь удалил (анонимизировал) свой профиль */
  BenefactorAnonymized = 'BENEFACTOR_ANONYMIZED',
  /** Подписка отменена жертвователем */
  CancelledByBenefactor = 'CANCELLED_BY_BENEFACTOR',
  /** Подписка отменена, так как у пользователя нет привязанной карты */
  CardNotBound = 'CARD_NOT_BOUND',
  /** Подписка отменена для переоформления в связи с изменением размера комиссии */
  CommissionChanged = 'COMMISSION_CHANGED',
  /** Подписка отменена в связи с невозможностью списать средства с карты */
  SubscriptionChargeFailed = 'SUBSCRIPTION_CHARGE_FAILED',
  /** Подписка отменена по техническим причинам */
  Technical = 'TECHNICAL',
  /** Причина не известна */
  Unknown = 'UNKNOWN',
  /** Подписка отменена так как подопечный деактивирован */
  WardDeactivated = 'WARD_DEACTIVATED',
  /** Подписка отменена в связи со смертью подопечного */
  WardPassedAway = 'WARD_PASSED_AWAY'
}


export type EmailAlreadyRegisteredError = Error & {
  __typename?: 'EmailAlreadyRegisteredError';
  email: Scalars['String'];
  message: Scalars['String'];
};

export type EnableOrDisableUserError = ForbiddenForUserError | UserPersistenceError;

/** Параметры блокировки или отмены блокировки учётной записи пользователя */
export type EnableOrDisableUserInput = {
  /** Признак блокировки учётной записи */
  disabled: Scalars['Boolean'];
  /** Идентификатор пользователя */
  id: Scalars['ID'];
};

export type EnableOrDisableUserPayload = {
  __typename?: 'EnableOrDisableUserPayload';
  errors?: Maybe<Array<EnableOrDisableUserError>>;
  user?: Maybe<User>;
};

export type EnableSubscriptionTransferToWardInput = {
  /** Идентификатор подопечного */
  wardId: Scalars['WardId'];
};

export type EnableSubscriptionTransferToWardPayload = {
  __typename?: 'EnableSubscriptionTransferToWardPayload';
  ward?: Maybe<Ward>;
};

export type Error = {
  message: Scalars['String'];
};

/** A connection to a list of items. */
export type EventsConnection = {
  __typename?: 'EventsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<EventsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<FoundationEvent>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type EventsEdge = {
  __typename?: 'EventsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FoundationEvent;
};

/** Файл, хранящийся в хранилище файлов */
export type File = {
  /** Идентификатор файла */
  id: Scalars['String'];
  /** Ссылка для отображения или скачивания файла */
  url: Scalars['String'];
};


/** Файл, хранящийся в хранилище файлов */
export type FileUrlArgs = {
  asAttachment?: Scalars['Boolean'];
  variant?: Maybe<Scalars['String']>;
};

/** Файл, хранящийся в хранилище файлов */
export type FileInformation = File & {
  __typename?: 'FileInformation';
  /** Идентификатор файла */
  id: Scalars['String'];
  /** Ссылка для отображения или скачивания файла */
  url: Scalars['String'];
};


/** Файл, хранящийся в хранилище файлов */
export type FileInformationUrlArgs = {
  asAttachment?: Scalars['Boolean'];
  variant?: Maybe<Scalars['String']>;
};

/** Данные для загрузки файла в хранилище */
export type FileUploadInformation = File & {
  __typename?: 'FileUploadInformation';
  /** Имя файла (как в запросе) */
  fileName: Scalars['String'];
  /** Идентификатор файла */
  id: Scalars['String'];
  /** Адрес, на который необходимо загрузить содержимое файла (должен использоваться HTTP-метод PUT) */
  uploadUrl: Scalars['String'];
  /** Ссылка для отображения или скачивания файла */
  url: Scalars['String'];
};


/** Данные для загрузки файла в хранилище */
export type FileUploadInformationUrlArgs = {
  asAttachment?: Scalars['Boolean'];
  variant?: Maybe<Scalars['String']>;
};

/** Запрос на предоставление информации для загрузки файла в хранилище файлов */
export type FileUploadRequestInput = {
  /** Тип содержимого загружаемого файла */
  contentType: Scalars['String'];
  /** Имя загружаемого файла */
  fileName: Scalars['String'];
};

export type FloatOperationFilterInput = {
  eq?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  neq?: Maybe<Scalars['Float']>;
  ngt?: Maybe<Scalars['Float']>;
  ngte?: Maybe<Scalars['Float']>;
  nin?: Maybe<Array<Maybe<Scalars['Float']>>>;
  nlt?: Maybe<Scalars['Float']>;
  nlte?: Maybe<Scalars['Float']>;
};

export type ForbiddenForUserError = Error & {
  __typename?: 'ForbiddenForUserError';
  message: Scalars['String'];
};

export type ForbidSubscriptionTransferInput = {
  subscriptionId: Scalars['DonationSubscriptionId'];
};

export type ForbidSubscriptionTransferPayload = {
  __typename?: 'ForbidSubscriptionTransferPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type ForgotPasswordError = ForbiddenForUserError | UserCommunicationError;

/** Параметры запроса на сброс пароля */
export type ForgotPasswordInput = {
  /** Адрес электронной почты пользователя */
  email: Scalars['String'];
};

export type ForgotPasswordPayload = {
  __typename?: 'ForgotPasswordPayload';
  errors?: Maybe<Array<ForgotPasswordError>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Фонд-партнёр проекта Помощь */
export type Foundation = {
  __typename?: 'Foundation';
  /** Адрес фонда */
  address: Address;
  /** Аудит добавления */
  created: AuditMarker;
  /** Описание фонда */
  description: Scalars['String'];
  /** События фонда-партнёра */
  events?: Maybe<EventsConnection>;
  /** Счётчики событий */
  eventsCounters: FoundationEventsCounters;
  id: Scalars['FoundationId'];
  /** Логотип фонда-партнёра */
  logo?: Maybe<FileInformation>;
  /** Название фонда */
  name: Scalars['String'];
  /** Представители фонда */
  representatives?: Maybe<RepresentativesConnection>;
  /** Аудит изменения */
  updated: AuditMarker;
};


/** Фонд-партнёр проекта Помощь */
export type FoundationEventsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<FoundationEventSortInput>>;
  where?: Maybe<FoundationEventFilterInput>;
};


/** Фонд-партнёр проекта Помощь */
export type FoundationRepresentativesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<AdministratorsSortInput>>;
};

/** Событие фонда-партнёра */
export type FoundationEvent = {
  __typename?: 'FoundationEvent';
  /** Заявки на участие в событии */
  applications?: Maybe<ApplicationsConnection>;
  /** Аудит запроса на утверждение события */
  approvalRequested?: Maybe<AuditMarker>;
  /** Аудит публикации события */
  approved?: Maybe<AuditMarker>;
  /** Участники события */
  attendances?: Maybe<AttendancesConnection>;
  /** Заявка текущего жертвователя на участие в событии */
  benefactorApplication?: Maybe<FoundationEventApplication>;
  /** Участие текущего жертвователя в событии */
  benefactorAttendance?: Maybe<FoundationEventAttendance>;
  /** Аудит отмены события */
  cancelled?: Maybe<AuditMarker>;
  /** URL для отметки участия в событии */
  checkinUrl?: Maybe<Scalars['String']>;
  /** Дата и время отправки оповещения о закрытии события */
  closeNotificationSentAt?: Maybe<Scalars['DateTime']>;
  /** Аудит закрытия события */
  closed?: Maybe<AuditMarker>;
  /** Счётчики жертвователей, подавших заявку на участие в событии */
  counters: FoundationEventApplicantsCounters;
  /** Аудит добавления */
  created: AuditMarker;
  /** Информация о событии */
  details: FoundationEventDetails;
  /** Фонд-партнёр, создавший данное событие */
  foundation?: Maybe<Foundation>;
  id: Scalars['FoundationEventId'];
  /** Владелец/ответственный события */
  owner: AuditMarker;
  /** Аудит закрытия регистрации на событие */
  registrationClosed?: Maybe<AuditMarker>;
  /** Отчёт о проведении события */
  report?: Maybe<FoundationEventReport>;
  /** Аудит изменения */
  updated: AuditMarker;
};


/** Событие фонда-партнёра */
export type FoundationEventApplicationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<FoundationEventApplicationSortInput>>;
  where?: Maybe<FoundationEventApplicationFilterInput>;
};


/** Событие фонда-партнёра */
export type FoundationEventAttendancesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  benefactorSearch?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<FoundationEventAttendanceSortInput>>;
  where?: Maybe<FoundationEventAttendanceFilterInput>;
};

/** Счётчики жертвователей, подавших заявку на участие в событии */
export type FoundationEventApplicantsCounters = {
  __typename?: 'FoundationEventApplicantsCounters';
  /** Количество жертвователей, подавший заявки на участие */
  applied: Scalars['Int'];
  /** Количество жертвователей, реально участвовавших в событии */
  attended: Scalars['Int'];
  /** Количество жертвователей, которым отказано в участии */
  declined: Scalars['Int'];
  /** Количество жертвователей, заявки которых ещё не обработаны фондом */
  notProcessed: Scalars['Int'];
  /** Количество жертвователей, самостоятельно отказавшихся от участия */
  revoked: Scalars['Int'];
  /** Количество жертвователей, отобранных для участия */
  selected: Scalars['Int'];
};

/** Запрос на участие в событии */
export type FoundationEventApplication = {
  __typename?: 'FoundationEventApplication';
  /** Информация о фактическом участии в событии жертвователя, подавшего заявку */
  attendance?: Maybe<FoundationEventAttendance>;
  /** Помощник */
  benefactor: Benefactor;
  /** Рейтинг жертвователя */
  benefactorRating: Scalars['Float'];
  /** Аудит создания */
  created: AuditMarker;
  /** Событие фонда-партнёра */
  event: FoundationEvent;
  /** Событие данной заявки завершено */
  eventIsClosed: Scalars['Boolean'];
  id: Scalars['FoundationEventApplicationId'];
  /** Предложенные навыки */
  skill: Array<Skill>;
  /** Статус заявки */
  status: FoundationEventApplicationStatus;
  /** Аудит изменения */
  updated: AuditMarker;
};

/** Запрос на участие в событии */
export type FoundationEventApplicationFilterInput = {
  and?: Maybe<Array<FoundationEventApplicationFilterInput>>;
  /** Рейтинг жертвователя */
  benefactorRating?: Maybe<FloatOperationFilterInput>;
  or?: Maybe<Array<FoundationEventApplicationFilterInput>>;
  /** Статус заявки */
  status?: Maybe<FoundationEventApplicationStatusOperationFilterInput>;
};


/** A connection to a list of items. */
export type FoundationEventApplicationsConnection = {
  __typename?: 'FoundationEventApplicationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<FoundationEventApplicationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<FoundationEventApplication>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type FoundationEventApplicationsEdge = {
  __typename?: 'FoundationEventApplicationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FoundationEventApplication;
};

/** Запрос на участие в событии */
export type FoundationEventApplicationSortInput = {
  /** Рейтинг жертвователя */
  benefactorRating?: Maybe<SortEnumType>;
  /** Аудит создания */
  created?: Maybe<AuditMarkerSortInput>;
  /** Статус заявки */
  status?: Maybe<SortEnumType>;
  /** Аудит изменения */
  updated?: Maybe<AuditMarkerSortInput>;
};

/** Статус заявки на участие в событии */
export enum FoundationEventApplicationStatus {
  /** Заявка ещё не рассмотрена */
  Applied = 'APPLIED',
  /** Заявка отклонена */
  Declined = 'DECLINED',
  /** Заявка отозвана */
  Revoked = 'REVOKED',
  /** Жертвователь выбран как запасной участник */
  SelectedAsBackup = 'SELECTED_AS_BACKUP',
  /** Жертвователь выбран как основной участник */
  SelectedAsPrimary = 'SELECTED_AS_PRIMARY'
}

export type FoundationEventApplicationStatusOperationFilterInput = {
  eq?: Maybe<FoundationEventApplicationStatus>;
  in?: Maybe<Array<FoundationEventApplicationStatus>>;
  neq?: Maybe<FoundationEventApplicationStatus>;
  nin?: Maybe<Array<FoundationEventApplicationStatus>>;
};

/** Информация об участии в событии */
export type FoundationEventAttendance = {
  __typename?: 'FoundationEventAttendance';
  /** Комментарий о событии от участника */
  attendeeComment?: Maybe<Scalars['String']>;
  /** Оценка события от участника */
  attendeeRating?: Maybe<Scalars['Int']>;
  /** Помощник */
  benefactor: Benefactor;
  /** Дата и время отметки об участии */
  checkedInAt: Scalars['DateTime'];
  /** Комментарий об участнике от организатора события */
  foundationComment?: Maybe<Scalars['String']>;
  /** Событие, в котором жертвователь принимал участие */
  foundationEvent: FoundationEvent;
  /** Оценка участника от организатора события */
  foundationRating?: Maybe<Scalars['Int']>;
  id: Scalars['FoundationEventAttendanceId'];
};

/** A connection to a list of items. */
export type FoundationEventAttendanceConnection = {
  __typename?: 'FoundationEventAttendanceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<FoundationEventAttendanceEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<FoundationEventAttendance>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type FoundationEventAttendanceEdge = {
  __typename?: 'FoundationEventAttendanceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FoundationEventAttendance;
};

/** Информация об участии в событии */
export type FoundationEventAttendanceFilterInput = {
  and?: Maybe<Array<FoundationEventAttendanceFilterInput>>;
  /** Комментарий о событии от участника */
  attendeeComment?: Maybe<StringOperationFilterInput>;
  /** Оценка события от участника */
  attendeeRating?: Maybe<IntOperationFilterInput>;
  /** Дата и время отметки об участии */
  checkedInAt?: Maybe<DateTimeOperationFilterInput>;
  /** Комментарий об участнике от организатора мероприятия */
  foundationComment?: Maybe<StringOperationFilterInput>;
  /** Оценка участника от организатора мероприятия */
  foundationRating?: Maybe<IntOperationFilterInput>;
  or?: Maybe<Array<FoundationEventAttendanceFilterInput>>;
  /** Дата, время и пользователь, последним изменивший объект */
  updated?: Maybe<AuditMarkerFilterInput>;
};


/** Информация об участии в событии */
export type FoundationEventAttendanceSortInput = {
  /** Оценка события от участника */
  attendeeRating?: Maybe<SortEnumType>;
  /** Дата и время отметки об участии */
  checkedInAt?: Maybe<SortEnumType>;
  /** Оценка участника от организатора мероприятия */
  foundationRating?: Maybe<SortEnumType>;
  /** Дата, время и пользователь, последним изменивший объект */
  updated?: Maybe<AuditMarkerSortInput>;
};

/** Информация о событии */
export type FoundationEventDetails = {
  __typename?: 'FoundationEventDetails';
  /** Адрес проведения события */
  address: Address;
  /** Файл договора */
  agreementFile?: Maybe<FileInformation>;
  /** Ссылка на договор */
  agreementUrl?: Maybe<Scalars['String']>;
  /** Город проведения события */
  city: Scalars['String'];
  /** Описание события */
  description: Scalars['String'];
  /** Изображения события */
  images: Array<FileInformation>;
  /** Место сбора/инструкции участникам */
  meetingInstructions?: Maybe<Scalars['String']>;
  /** Признак необходимости наличия паспорта (для заключения договора) */
  passportRequired: Scalars['Boolean'];
  /** Номер телефона организатора */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Список требуемых участников */
  requirements: Array<FoundationEventParticipationRequirement>;
  /** Дата и время начала события относительно города проведения события */
  start: Scalars['DateTime'];
  /** Временная зона проведения события, в часах */
  timezoneOffset: Scalars['TimeSpan'];
  /** Название события */
  title: Scalars['String'];
};

/** Информация о событии */
export type FoundationEventDetailsFilterInput = {
  /** Адрес проведения события */
  address?: Maybe<AddressFilterInput>;
  and?: Maybe<Array<FoundationEventDetailsFilterInput>>;
  /** Описание события */
  description?: Maybe<StringOperationFilterInput>;
  or?: Maybe<Array<FoundationEventDetailsFilterInput>>;
  /** Дата и время начала события относительно города проведения события */
  start?: Maybe<DateTimeOperationFilterInput>;
  /** Название события */
  title?: Maybe<StringOperationFilterInput>;
};

/** Информация о событии */
export type FoundationEventDetailsSortInput = {
  /** Адрес проведения события */
  address?: Maybe<AddressSortInput>;
  /** Дата и время начала события относительно города проведения события */
  start?: Maybe<SortEnumType>;
  /** Название события */
  title?: Maybe<SortEnumType>;
};

/** Событие фонда-партнёра */
export type FoundationEventFilterInput = {
  and?: Maybe<Array<FoundationEventFilterInput>>;
  /** Аудит запроса на утверждение события */
  approvalRequested?: Maybe<AuditMarkerFilterInput>;
  /** Аудит отмены события */
  cancelled?: Maybe<AuditMarkerFilterInput>;
  /** Аудит закрытия события */
  closed?: Maybe<AuditMarkerFilterInput>;
  /** Аудит добавления */
  created?: Maybe<AuditMarkerFilterInput>;
  /** Информация о событии */
  details?: Maybe<FoundationEventDetailsFilterInput>;
  or?: Maybe<Array<FoundationEventFilterInput>>;
};


/** Требования к участнику события */
export type FoundationEventParticipationRequirement = {
  __typename?: 'FoundationEventParticipationRequirement';
  /** Автоматически выбирать заявки для участия в мероприятии */
  autoSelect: Scalars['Boolean'];
  /** Комментарий для участника */
  description?: Maybe<Scalars['String']>;
  /** Требуемое количество участников */
  quantity: Scalars['Int'];
  /** Требуемый навык */
  skill: Skill;
};

/** Требования к участнику события */
export type FoundationEventParticipationRequirementInput = {
  /** Автоматически выбирать заявки данного навыка для участия в событии */
  autoSelect: Scalars['Boolean'];
  /** Комментарий для участника */
  description?: Maybe<Scalars['String']>;
  /** Требуемое количество участников (0 - без ограничений) */
  quantity: Scalars['Int'];
  /** Идентификатор навыка */
  skillId: Scalars['SkillId'];
};

/** Разрешения, относящиеся к конкретному событию фонда-партнёра */
export type FoundationEventPermissions = {
  __typename?: 'FoundationEventPermissions';
  /** Текущий пользователь может отметить участие зарегистрированного участника в событии */
  checkin: Scalars['Boolean'];
  /** Текущий пользователь может закрыть событие */
  close: Scalars['Boolean'];
  /** Текущий пользователь может редактировать событие */
  edit: Scalars['Boolean'];
  /** Текущий пользователь может редактировать дату и список требований одобренного события */
  editApproved: Scalars['Boolean'];
  /** Текущий пользователь может редактировать отчёт о событии */
  editReport: Scalars['Boolean'];
  /** Идентификатор события */
  id: Scalars['FoundationEventId'];
  /** Текущий пользователь может открывать и закрывать регистрацию на событие */
  openOrCloseRegistration: Scalars['Boolean'];
  /** Текущий пользователь может оценивать зарегистрированных участников события */
  rateAttendees: Scalars['Boolean'];
  /** Текущий пользователь может запросить одобрение события */
  requestApproval: Scalars['Boolean'];
  /** Текущий пользователь может выбирать заявки для участия в событии */
  selectApplications: Scalars['Boolean'];
  /** Текущий пользователь может назначить себя ответственным за событие */
  takeOwnership: Scalars['Boolean'];
  /** Текущий пользователь может просматривать информацию о событии */
  view: Scalars['Boolean'];
  /** Текущий пользователь может просматривать зарегистрированных участников события */
  viewAttendees: Scalars['Boolean'];
};

export type FoundationEventRegistrationClosedError = Error & {
  __typename?: 'FoundationEventRegistrationClosedError';
  message: Scalars['String'];
};

/** Отчёт о проведении события */
export type FoundationEventReport = {
  __typename?: 'FoundationEventReport';
  /** Прикреплённые изображения */
  images: Array<Document>;
  /** Текст отчёта о событии */
  text: Scalars['String'];
};

export type FoundationEventReportDoesNotExistsError = Error & {
  __typename?: 'FoundationEventReportDoesNotExistsError';
  message: Scalars['String'];
};

/** A connection to a list of items. */
export type FoundationEventsConnection = {
  __typename?: 'FoundationEventsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<FoundationEventsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<FoundationEvent>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** Аккумулированные счётчики событий фондов-партнёров */
export type FoundationEventsCounters = {
  __typename?: 'FoundationEventsCounters';
  /** Количество активных событий */
  active: Scalars['Int'];
  /** Количество отменённых событий */
  cancelled: Scalars['Int'];
  /** Количество завершённых событий */
  closed: Scalars['Int'];
  /** Общее количество событий */
  total: Scalars['Int'];
};

/** Аккумулированные счётчики событий фондов-партнёров */
export type FoundationEventsCountersFilterInput = {
  /** Количество активных событий */
  active?: Maybe<IntOperationFilterInput>;
  and?: Maybe<Array<FoundationEventsCountersFilterInput>>;
  /** Количество отменённых событий */
  cancelled?: Maybe<IntOperationFilterInput>;
  /** Количество завершённых событий */
  closed?: Maybe<IntOperationFilterInput>;
  or?: Maybe<Array<FoundationEventsCountersFilterInput>>;
  /** Общее количество событий */
  total?: Maybe<IntOperationFilterInput>;
};

/** Аккумулированные счётчики событий фондов-партнёров */
export type FoundationEventsCountersSortInput = {
  /** Количество активных событий */
  active?: Maybe<SortEnumType>;
  /** Количество отменённых событий */
  cancelled?: Maybe<SortEnumType>;
  /** Количество завершённых событий */
  closed?: Maybe<SortEnumType>;
  /** Общее количество событий */
  total?: Maybe<SortEnumType>;
};

/** An edge in a connection. */
export type FoundationEventsEdge = {
  __typename?: 'FoundationEventsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FoundationEvent;
};

/** Событие фонда-партнёра */
export type FoundationEventSortInput = {
  /** Аудит запроса на утверждение события */
  approvalRequested?: Maybe<AuditMarkerSortInput>;
  /** Аудит публикации события */
  approved?: Maybe<AuditMarkerSortInput>;
  /** Аудит закрытия события */
  closed?: Maybe<AuditMarkerSortInput>;
  /** Информация о событии */
  details?: Maybe<FoundationEventDetailsSortInput>;
};

/** A connection to a list of items. */
export type FoundationEventsPendingApprovalConnection = {
  __typename?: 'FoundationEventsPendingApprovalConnection';
  /** A list of edges. */
  edges?: Maybe<Array<FoundationEventsPendingApprovalEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<FoundationEvent>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type FoundationEventsPendingApprovalEdge = {
  __typename?: 'FoundationEventsPendingApprovalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FoundationEvent;
};

/** Параметры отбора фондов-партнёров */
export type FoundationFilterInput = {
  and?: Maybe<Array<FoundationFilterInput>>;
  /** Аудит добавления */
  created?: Maybe<AuditMarkerFilterInput>;
  /** Счётчики событий */
  eventsCounters?: Maybe<FoundationEventsCountersFilterInput>;
  /** Название фонда */
  name?: Maybe<StringOperationFilterInput>;
  or?: Maybe<Array<FoundationFilterInput>>;
};


/** Разрешения, относящиеся к действиям с конкретным фондом-партнёром */
export type FoundationPermissions = {
  __typename?: 'FoundationPermissions';
  /** Текущий пользователь может добавить представителя фонда-партнёра */
  addRepresentative: Scalars['Boolean'];
  /** Текущий пользователь может создавать события от лица фонда-партнёра */
  createEvents: Scalars['Boolean'];
  /** Текущий пользователя может редактировать информацию фонда-партнёра */
  edit: Scalars['Boolean'];
  /** Идентификатор фонда-партнёра */
  id: Scalars['FoundationId'];
  /** Текущий пользователь может видеть список представителей фонда-партнёра */
  viewRepresentatives: Scalars['Boolean'];
};

export type FoundationPersistenceError = Error & {
  __typename?: 'FoundationPersistenceError';
  message: Scalars['String'];
};

/** Представитель фонда-партнёра */
export type FoundationRepresentative = User & {
  __typename?: 'FoundationRepresentative';
  /** Дата регистрации */
  createdOn: Scalars['DateTime'];
  /** Признак блокировки учётной записи */
  disabled: Scalars['Boolean'];
  /** Имя для показа */
  displayName?: Maybe<Scalars['String']>;
  /** Адрес электронной почты */
  email?: Maybe<Scalars['String']>;
  /** Адрес электронной почты подтверждён администратором */
  emailConfirmed: Scalars['Boolean'];
  /** Фонд-партнёр, от лица которого выступает представитель */
  foundation: Foundation;
  /** Идентификатор */
  id: Scalars['ID'];
  /** Номер телефона */
  phoneNumber?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type FoundationsConnection = {
  __typename?: 'FoundationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<FoundationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Foundation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type FoundationsEdge = {
  __typename?: 'FoundationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Foundation;
};

/** Фонд-партнёр проекта Помощь */
export type FoundationSortInput = {
  /** Адрес фонда */
  address?: Maybe<AddressSortInput>;
  /** Аудит добавления */
  created?: Maybe<AuditMarkerSortInput>;
  /** Описание фонда */
  description?: Maybe<SortEnumType>;
  /** Счётчики событий */
  eventsCounters?: Maybe<FoundationEventsCountersSortInput>;
  /** Идентификатор изображения-логотипа фонда */
  logoId?: Maybe<SortEnumType>;
  /** Название фонда */
  name?: Maybe<SortEnumType>;
  /** Аудит изменения */
  updated?: Maybe<AuditMarkerSortInput>;
};

/** Разрешения, относящиеся к действиям с фондами-партнёрами */
export type FoundationsPermissions = {
  __typename?: 'FoundationsPermissions';
  /** Текущий пользователь может одобрять события фондов-партнёров */
  approveEvents: Scalars['Boolean'];
  /** Текущий пользователь может добавлять новые фонды-партнёры */
  create: Scalars['Boolean'];
  /** Текущий пользователь может редактировать существующие фонды-партнёры */
  edit: Scalars['Boolean'];
};

/** A connection to a list of items. */
export type FriendsConnection = {
  __typename?: 'FriendsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<FriendsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<BenefactorFriend>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type FriendsEdge = {
  __typename?: 'FriendsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BenefactorFriend;
};

export type FundsTransferProhibitedError = Error & {
  __typename?: 'FundsTransferProhibitedError';
  message: Scalars['String'];
};

/** Пол подопечного */
export enum Gender {
  /** Женский */
  Female = 'FEMALE',
  /** Мужской */
  Male = 'MALE'
}

export type GenderOperationFilterInput = {
  eq?: Maybe<Gender>;
  in?: Maybe<Array<Gender>>;
  neq?: Maybe<Gender>;
  nin?: Maybe<Array<Gender>>;
};

/** Информация о неизвестной ошибке платежа */
export type GenericFailedPaymentDetails = {
  __typename?: 'GenericFailedPaymentDetails';
  /** Сообщение об ошибке */
  failureMessage: Scalars['String'];
};

/** Потребность: Продуктовая корзина */
export type GrocerySetNeed = Need & RegularNeed & {
  __typename?: 'GrocerySetNeed';
  /** Информации об архивировании потребности */
  archived?: Maybe<AuditMarker>;
  /** Информация о добавлении потребности */
  created: AuditMarker;
  id: Scalars['NeedId'];
  /** Периоды потребности */
  periods?: Maybe<RegularNeedPeriodsConnection>;
  /** Требуемое количество средств */
  requiredAmount: Scalars['Int'];
  /** Информация о последнем обновлении потребности */
  updated: AuditMarker;
  /** Подопечный */
  ward: Ward;
};


/** Потребность: Продуктовая корзина */
export type GrocerySetNeedPeriodsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<RegularNeedPeriodSortInput>>;
};

/** Группа подопечных */
export type GroupedWardsSearchResultItem = SearchResultItem & {
  __typename?: 'GroupedWardsSearchResultItem';
  /** Категории */
  categories: Array<Category>;
  /** Город */
  city?: Maybe<Scalars['String']>;
  /** Собранная сумма */
  collectedAmount: Scalars['Int'];
  /** Требуется ли помощь? */
  isHelpNeeded: Scalars['Boolean'];
  /** Способ группировки подопечных */
  method: SearchResultsWardsGroupingMethod;
  /** Требуемая сумма */
  requiredAmount?: Maybe<Scalars['Int']>;
  /** Подопечные */
  wards: Array<WardSearchResultItem>;
};

/** Определяет наличие прикрепляемых документов */
export type HaveDocuments = {
  /** Прикреплённые файлы-документы */
  documents: Array<Document>;
};

/** Элемент адреса с идентификатором в ФИАС */
export type IdentifiableAddressElement = {
  __typename?: 'IdentifiableAddressElement';
  /** Тип элемента, полный (город, район и т.п.) */
  fullType: Scalars['String'];
  /** Идентификатор ФИАС элемента адреса */
  id: Scalars['String'];
  /** Тип элемента, сокращённый (г, р-н и т.п.) */
  type: Scalars['String'];
  /** Значение элемента (название района, города и т.п) */
  value: Scalars['String'];
  /** Значение с полным типом (город Москва, Калужский район и т.п.) */
  valueWithFullType: Scalars['String'];
  /** Значение с сокращённым типом (г Москва, Калужский р-н и т.п.) */
  valueWithType: Scalars['String'];
};

/** Элемент адреса с идентификатором в ФИАС */
export type IdentifiableAddressElementFilterInput = {
  and?: Maybe<Array<IdentifiableAddressElementFilterInput>>;
  /** Тип элемента, полный (город, район и т.п.) */
  fullType?: Maybe<StringOperationFilterInput>;
  /** Идентификатор ФИАС элемента адреса */
  id?: Maybe<StringOperationFilterInput>;
  or?: Maybe<Array<IdentifiableAddressElementFilterInput>>;
  /** Тип элемента, сокращённый (г, р-н и т.п.) */
  type?: Maybe<StringOperationFilterInput>;
  /** Значение элемента (название района, города и т.п) */
  value?: Maybe<StringOperationFilterInput>;
  /** Значение с полным типом (город Москва, Калужский район и т.п.) */
  valueWithFullType?: Maybe<StringOperationFilterInput>;
  /** Значение с сокращённым типом (г Москва, Калужский р-н и т.п.) */
  valueWithType?: Maybe<StringOperationFilterInput>;
};

/** Элемент адреса с идентификатором в ФИАС */
export type IdentifiableAddressElementSortInput = {
  /** Тип элемента, полный (город, район и т.п.) */
  fullType?: Maybe<SortEnumType>;
  /** Идентификатор ФИАС элемента адреса */
  id?: Maybe<SortEnumType>;
  /** Тип элемента, сокращённый (г, р-н и т.п.) */
  type?: Maybe<SortEnumType>;
  /** Значение элемента (название района, города и т.п) */
  value?: Maybe<SortEnumType>;
  /** Значение с полным типом (город Москва, Калужский район и т.п.) */
  valueWithFullType?: Maybe<SortEnumType>;
  /** Значение с сокращённым типом (г Москва, Калужский р-н и т.п.) */
  valueWithType?: Maybe<SortEnumType>;
};

export type IncompatibleAchievementTypeError = Error & {
  __typename?: 'IncompatibleAchievementTypeError';
  message: Scalars['String'];
};

export type IntOperationFilterInput = {
  eq?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  neq?: Maybe<Scalars['Int']>;
  ngt?: Maybe<Scalars['Int']>;
  ngte?: Maybe<Scalars['Int']>;
  nin?: Maybe<Array<Maybe<Scalars['Int']>>>;
  nlt?: Maybe<Scalars['Int']>;
  nlte?: Maybe<Scalars['Int']>;
};

export type InvalidFoundationEventAttendanceCodeError = Error & {
  __typename?: 'InvalidFoundationEventAttendanceCodeError';
  message: Scalars['String'];
};

export type KeepAliveInput = {
  uniqueClientSequence: Scalars['String'];
};

export type KeepAlivePayload = {
  __typename?: 'KeepAlivePayload';
  activeClientsCount?: Maybe<Scalars['Int']>;
};

export type KeyValuePairOfStringAndString = {
  __typename?: 'KeyValuePairOfStringAndString';
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Устаревшее намерение совершить разовое пожертвование */
export type LegacyOneTimeDonationIntent = {
  __typename?: 'LegacyOneTimeDonationIntent';
  /** Размер пожертвования */
  amount: Scalars['Int'];
};

/** Устаревшее намерение оплатить ежемесячное списание подписки */
export type LegacySubscriptionChargeIntent = {
  __typename?: 'LegacySubscriptionChargeIntent';
  /** Размер пожертвования */
  amount: Scalars['Int'];
};

/** Устаревшее намерения совершить подписку */
export type LegacySubscriptionIntent = {
  __typename?: 'LegacySubscriptionIntent';
  /** Размер пожертвования */
  amount: Scalars['Int'];
};

/**
 * Информация о балансе ООО - коммерческой компании Фонда "Помощь", направленной на продажу мерча и
 * передаче прибыли в Фонд.
 */
export type LimitedLiabilityCompany = {
  __typename?: 'LimitedLiabilityCompany';
  /** Баланс ООО */
  balance: Scalars['Int'];
  /** Опубликованные отчёты о деятельности ООО */
  publishedReports?: Maybe<PublishedReportsConnection>;
  /** Отчёты о деятельности ООО */
  reports?: Maybe<ReportsConnection>;
  /** Аудит обновления объекта */
  updated: AuditMarker;
};


/**
 * Информация о балансе ООО - коммерческой компании Фонда "Помощь", направленной на продажу мерча и
 * передаче прибыли в Фонд.
 */
export type LimitedLiabilityCompanyPublishedReportsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<LimitedLiabilityCompanyReportSortInput>>;
};


/**
 * Информация о балансе ООО - коммерческой компании Фонда "Помощь", направленной на продажу мерча и
 * передаче прибыли в Фонд.
 */
export type LimitedLiabilityCompanyReportsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<LimitedLiabilityCompanyReportSortInput>>;
};


/** Разрешения, относящиеся к действиям с информацией об ООО */
export type LimitedLiabilityCompanyPermissions = {
  __typename?: 'LimitedLiabilityCompanyPermissions';
  /** Текущий пользователь может управлять информацией об ООО */
  manage: Scalars['Boolean'];
};

/** Отчёт о деятельности ООО */
export type LimitedLiabilityCompanyReport = HaveDocuments & {
  __typename?: 'LimitedLiabilityCompanyReport';
  /** Аудит создания отчёта */
  created: AuditMarker;
  /** Прикреплённые файлы-документы */
  documents: Array<Document>;
  id: Scalars['LimitedLiabilityCompanyReportId'];
  /** Аудит публикации отчёта */
  published?: Maybe<AuditMarker>;
  /** Отчётный период - используются только месяц и года */
  referenceDate: Scalars['Date'];
  /** Краткое описание отчёта */
  title: Scalars['String'];
  /** Аудит изменения отчёта */
  updated: AuditMarker;
};


/** Отчёт о деятельности ООО */
export type LimitedLiabilityCompanyReportSortInput = {
  /** Аудит публикации отчёта */
  published?: Maybe<AuditMarkerSortInput>;
  /** Отчётный период - используются только месяц и года */
  referenceDate?: Maybe<SortEnumType>;
};

export type ListCategoryIdOperationFilterInput = {
  all?: Maybe<CategoryIdFilterInput>;
  any?: Maybe<Scalars['Boolean']>;
  none?: Maybe<CategoryIdFilterInput>;
  some?: Maybe<CategoryIdFilterInput>;
};


/** Семейный статус */
export enum MaritalStatus {
  /** Разведён/разведена */
  Divorced = 'DIVORCED',
  /** Женат/замужем */
  Married = 'MARRIED',
  /** Живёт отдельно от супруга */
  Separated = 'SEPARATED',
  /** Холост/не замужем */
  Single = 'SINGLE',
  /** Вдовец/вдова */
  Widowed = 'WIDOWED'
}

export type MarkAllNotificationsAsReadPayload = {
  __typename?: 'MarkAllNotificationsAsReadPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type MarkBenefactorAchievementAsShownInput = {
  /** Идентификатор достижения жертвователя */
  benefactorAchievementId: Scalars['BenefactorAchievementId'];
};

export type MarkBenefactorAchievementAsShownPayload = {
  __typename?: 'MarkBenefactorAchievementAsShownPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type MarkWardAsNotRequiringImmediateHelpInput = {
  id: Scalars['WardId'];
};

export type MarkWardAsNotRequiringImmediateHelpPayload = {
  __typename?: 'MarkWardAsNotRequiringImmediateHelpPayload';
  ward?: Maybe<Ward>;
};

export type MarkWardAsRequiringImmediateHelpInput = {
  id: Scalars['WardId'];
};

export type MarkWardAsRequiringImmediateHelpPayload = {
  __typename?: 'MarkWardAsRequiringImmediateHelpPayload';
  ward?: Maybe<Ward>;
};

/** Информация о текущем пользователе */
export type Me = {
  __typename?: 'Me';
  /** Информация о текущем жертвователе */
  benefactor?: Maybe<Benefactor>;
  /** Непросмотренные истории указанного блока историй. */
  blockStories: Array<Story>;
  /** Права текущего пользователя */
  permissions: Permissions;
  /** Информация о текущем пользователе */
  user: User;
};


/** Информация о текущем пользователе */
export type MeBlockStoriesArgs = {
  blockLabel: Scalars['StoryBlockLabel'];
};

/** Модератор системы */
export type Moderator = User & {
  __typename?: 'Moderator';
  /** Дата регистрации */
  createdOn: Scalars['DateTime'];
  /** Признак блокировки учётной записи */
  disabled: Scalars['Boolean'];
  /** Имя для показа */
  displayName?: Maybe<Scalars['String']>;
  /** Адрес электронной почты */
  email?: Maybe<Scalars['String']>;
  /** Адрес электронной почты подтверждён администратором */
  emailConfirmed: Scalars['Boolean'];
  /** Идентификатор */
  id: Scalars['ID'];
  /** Номер телефона */
  phoneNumber?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Добавляет новое достижение с физическими призами */
  addAchievementWithMaterialPrizes: AddAchievementWithMaterialPrizesPayload;
  /** Добавляет новое достижение с промо-кодами в качестве призов */
  addAchievementWithPromoCodes: AddAchievementWithPromoCodesPayload;
  /** Добавляет новое достижение с неограниченным количеством наград */
  addAchievementWithUnlimitedNumberOfPrizes: AddAchievementWithUnlimitedNumberOfPrizesPayload;
  /** Добавляет описание статус жертвователя */
  addBenefactorStatus: AddBenefactorStatusPayload;
  /** Добавить широковещательное уведомление */
  addBroadcastNotification: AddBroadcastNotificationPayload;
  /** Добавляет новую категорию в список категорий */
  addCategory: AddCategoryPayload;
  /** Добавляет новый город в каталог городов. */
  addCity: AddCityPayload;
  /** Добавляет информацию о версии клиентского приложения */
  addClientVersion: AddClientVersionPayload;
  /** Добавляет фонд-партнёр в систему */
  addFoundation: AddFoundationPayload;
  /** Добавляет новое событие для указанного фонда-партнёра */
  addFoundationEvent: AddFoundationEventPayload;
  /** Добавить продуктовую корзину */
  addGrocerySetNeed: AddGrocerySetNeedPayload;
  /** Добавить разовую потребность */
  addOneTimeNeed: AddOneTimeNeedPayload;
  /** Добавляет новый расход проекта за указанный период */
  addProjectExpense: AddProjectExpensePayload;
  /** Добавляет новую историю к блоку историй. */
  addStory: AddStoryPayload;
  /** Создаёт новый блок историй. */
  addStoryBlock: AddStoryBlockPayload;
  /** Добавляет идентификаторы просмотренных историй к списку идентификаторов просмотренных пользователем историй. */
  addViewedStories: AddViewedStoriesPayload;
  /** Добавление нового подопечного */
  addWard: AddWardPayload;
  /** Добавляет подопечного к списку подопечных счёта-депозита */
  addWardsToTheBenefactorDepositAccountForSpecificWards: AddWardsToTheBenefactorDepositAccountForSpecificWardsPayload;
  /** Разрешить перенос подписки на другого подопечного */
  allowSubscriptionTransfer: AllowSubscriptionTransferPayload;
  /** Удалить персональные данные текущего пользователя. ВНИМАНИЕ: ЭТО НЕОБРАТИМАЯ ОПЕРАЦИЯ! */
  anonymizeBenefactor: AnonymizeBenefactorPayload;
  /** Подать заявку на участие в событии фонда-партнёра */
  applyToFoundationEvent: ApplyToFoundationEventPayload;
  /** Утвердить событие фонда-партнёра */
  approveFoundationEvent: ApproveFoundationEventPayload;
  /** Убрать потребность в архив */
  archiveNeed: ArchiveNeedPayload;
  /** Назначает указанное количество монет указанным жертвователям */
  assignCoins: AssignCoinsPayload;
  /** Отменить событие фонда-партнёра */
  cancelFoundationEvent: CancelFoundationEventPayload;
  /** Отменяет спецпроект */
  cancelSpecialProject: CancelSpecialProjectPayload;
  /** Отменить подписку с указанным идентификатором */
  cancelSubscription: CancelSubscriptionPayload;
  /** Изменяет видимость профиля жертвователя */
  changeBenefactorProfileVisibility: ChangeBenefactorProfileVisibilityPayload;
  /** Изменение номера телефона при наличии кода подтверждения */
  changePhoneNumber: ChangePhoneNumberPayload;
  /** Изменить номер телефона пользователя администратором платформы */
  changePhoneNumberByAdministrator: ChangePhoneNumberByAdministratorPayload;
  /** Инициирование смены номера телефона - получение кода подтверждения */
  changePhoneNumberCode: ChangePhoneNumberCodePayload;
  /** Изменяет порядок следования историй в блоке. */
  changeStoriesOrder: ChangeStoriesOrderPayload;
  /** Изменение пароля учётной записи пользователя */
  changeUserPassword: ChangeUserPasswordPayload;
  /** Отметить участие в событии */
  checkinToFoundationEvent: CheckinToFoundationEventPayload;
  /** Закрыть событие фонда-партнёра */
  closeFoundationEvent: CloseFoundationEventPayload;
  /** Закрыть регистрацию на событие фонда-партнёра */
  closeFoundationEventRegistration: CloseFoundationEventRegistrationPayload;
  /** Закрывает спецпроект */
  closeSpecialProject: CloseSpecialProjectPayload;
  /** Подтверждение адреса электронной почты учётной записи */
  confirmEmail: ConfirmEmailPayload;
  /** Отметить участие в событии */
  convertFoundationEventApplicationToAttendance: ConvertFoundationEventApplicationToAttendancePayload;
  /** Регистрация нового жертвователя администратором платформы */
  createBenefactor: CreateBenefactorPayload;
  /** Создаёт счёт-депозит для указанного жертвователя, средства с которого могут расходоваться на потребности всех подопечных */
  createBenefactorDepositAccountForAllWards: CreateBenefactorDepositAccountForAllWardsPayload;
  /** Создаёт счёт-депозит для указанного жертвователя, средства с которого могут расходоваться только на нужды проекта */
  createBenefactorDepositAccountForProjectNeeds: CreateBenefactorDepositAccountForProjectNeedsPayload;
  /**
   * Создаёт счёт-депозит для указанного жертвователя, средства с которого могут
   * расходоваться на потребности конкретных подопечных
   */
  createBenefactorDepositAccountForSpecificWards: CreateBenefactorDepositAccountForSpecificWardsPayload;
  /** Создаёт счёт-депозит для указанного жертвователя, средства с которого могут расходоваться на любые цели */
  createBenefactorDepositAccountWithoutConditions: CreateBenefactorDepositAccountWithoutConditionsPayload;
  /** Создать учётную запись представителя фонда */
  createFoundationRepresentative: CreateFoundationRepresentativePayload;
  /** Создание нового отчёта ООО */
  createLimitedLiabilityCompanyReport: CreateLimitedLiabilityCompanyReportPayload;
  /** Создать учётную запись модератора */
  createModerator: CreateModeratorPayload;
  /** Создаёт и возвращает ссылку для регистрации с использованием кода пользователя */
  createReferrerLink: CreateReferrerLinkPayload;
  /** Создаёт новый спецпроект. */
  createSpecialProject: CreateSpecialProjectPayload;
  /** Создать учётную запись администратора */
  createSystemAdministrator: CreateSystemAdministratorPayload;
  /** Запускает процедуру деактивации подопечного */
  deactivateWard: DeactivateWardPayload;
  /** Отклонить заявку на участие в событии фонда-партнёра */
  declineFoundationEventApplication: DeclineFoundationEventApplicationPayload;
  /** Удаляет достижение */
  deleteAchievement: DeleteAchievementPayload;
  /** Удаляет описание статуса жертвователя */
  deleteBenefactorStatus: DeleteBenefactorStatusPayload;
  /** Удаляет категорию */
  deleteCategory: DeleteCategoryPayload;
  /** Удаляет город. */
  deleteCity: DeleteCityPayload;
  /** Удаляет историю. */
  deleteStory: DeleteStoryPayload;
  /** Удаляет блок историй. */
  deleteStoryBlock: DeleteStoryBlockPayload;
  /** Удаление учётной записи пользователя */
  deleteUser: DeleteUserPayload;
  /**
   * Размещает депозит на счёте-депозите жертвователя
   * @deprecated Используйте мутацию placeOrder
   */
  depositToBenefactorAccount: DepositToBenefactorAccountPayload;
  /** Запретить перенос подписок на указанного подопечного */
  disableSubscriptionTransferToWard: DisableSubscriptionTransferToWardPayload;
  /** Блокировка или отмена блокировки учётной записи пользователя */
  enableOrDisableUser: EnableOrDisableUserPayload;
  /** Разрешить перенос подписок на указанного подопечного */
  enableSubscriptionTransferToWard: EnableSubscriptionTransferToWardPayload;
  /** Запретить перенос подписки на другого подопечного */
  forbidSubscriptionTransfer: ForbidSubscriptionTransferPayload;
  /** Запрос на сброс пароля */
  forgotPassword: ForgotPasswordPayload;
  /** Отмечает клиента как активного */
  keepAlive: KeepAlivePayload;
  /** Пометить все оповещения как прочтённые */
  markAllNotificationsAsRead: MarkAllNotificationsAsReadPayload;
  /** Отмечает достижение, как показанное жертвователю */
  markBenefactorAchievementAsShown: MarkBenefactorAchievementAsShownPayload;
  /** Отметить подопечного как не требующего немедленной помощи */
  markWardAsNotRequiringImmediateHelp: MarkWardAsNotRequiringImmediateHelpPayload;
  /** Отметить подопечного как требующего немедленной помощи */
  markWardAsRequiringImmediateHelp: MarkWardAsRequiringImmediateHelpPayload;
  /** Открыть регистрацию на событие фонда-партнёра */
  openFoundationEventRegistration: OpenFoundationEventRegistrationPayload;
  /** Получение кода подтверждения для входа по номеру телефона */
  phoneLoginCode: PhoneLoginCodePayload;
  /** Размещает новый заказ */
  placeOrder: PlaceOrderPayload;
  /** Подготовка к загрузке файлов в хранилище */
  prepareFilesUpload: PrepareFilesUploadPayload;
  /** Отмечает достижение как опубликованное */
  publishAchievement: PublishAchievementPayload;
  /** Публикация отчёта ООО */
  publishLimitedLiabilityCompanyReport: PublishLimitedLiabilityCompanyReportPayload;
  /** Публикует спецпроект */
  publishSpecialProject: PublishSpecialProjectPayload;
  /** Публикует историю. */
  publishStory: PublishStoryPayload;
  /** Сделать подопечного доступным для просмотра жертвователями */
  publishWard: PublishWardPayload;
  /** Регистрирует мобильное устройство текущего пользователя */
  registerDeviceToken: RegisterDeviceTokenPayload;
  /** Удаляет сохранённую платёжную карту. */
  removeSavedCloudPaymentsCard: RemoveSavedCloudPaymentsCardPayload;
  /** Удаляет подопечного из списка подопечных счёта-депозита */
  removeWardFromTheBenefactorDepositAccountForSpecificWards: RemoveWardFromTheBenefactorDepositAccountForSpecificWardsPayload;
  /** Запросить утверждение события фонда-партнёра */
  requestFoundationEventApproval: RequestFoundationEventApprovalPayload;
  /** Изменение пароля учётной записи */
  resetPassword: ResetPasswordPayload;
  /** Сохраняет результат дополнительной верификации платежа */
  respondToPaymentChallenge: RespondToPaymentChallengePayload;
  /** Запускает процедуру перевода подопечного в статус умершего */
  restInPeace: RestInPeacePayload;
  /** Отозвать заявку на участие в событии фонда-партнёра */
  revokeFoundationEventApplication: RevokeFoundationEventApplicationPayload;
  /** Запускает формирование отчёта с указанным идентификатором. */
  runReport: RunReportPayload;
  /** Отобрать заявку для участия в событии фонда-партнёра */
  selectFoundationEventApplication: SelectFoundationEventApplicationPayload;
  /** Отправляет отчёт о выполнении разовой потребности */
  sendOneTimeNeedReport: SendOneTimeNeedReportPayload;
  /** Отправляет отчёт о расходе средств проектом */
  sendProjectExpenseReport: SendProjectExpenseReportPayload;
  /** Отправляет отчёт о выполнении периода регулярной потребности */
  sendRegularNeedPeriodReport: SendRegularNeedPeriodReportPayload;
  /** Отправить отчёт о спецпроекте */
  sendSpecialProjectReport: SendSpecialProjectReportPayload;
  /** Помечает сохранённую платёжную карту как карту по умолчанию. */
  setDefaultCloudPaymentsCard: SetDefaultCloudPaymentsCardPayload;
  /** Назначить текущего пользователя ответственным за событие */
  takeFoundationEventOwnership: TakeFoundationEventOwnershipPayload;
  /** Переводит средства между счетами */
  transferFundsToAccount: TransferFundsToAccountPayload;
  /** Отмечает достижение как неопубликованное */
  unpublishAchievement: UnpublishAchievementPayload;
  /** Снимает спецпроект с публикации */
  unpublishSpecialProject: UnpublishSpecialProjectPayload;
  /** Отменяет публикацию истории. */
  unpublishStory: UnpublishStoryPayload;
  /** Сделать подопечного недоступным для просмотра жертвователями */
  unpublishWard: UnpublishWardPayload;
  /** Удаляет регистрацию мобильного устройства текущего пользователя */
  unregisterDeviceToken: UnregisterDeviceTokenPayload;
  /** Обновляет достижение с физическими призами */
  updateAchievementWithMaterialPrizes: UpdateAchievementWithMaterialPrizesPayload;
  /** Обновляет достижение с промо-кодами в качестве призов */
  updateAchievementWithPromoCodes: UpdateAchievementWithPromoCodesPayload;
  /** Обновляет достижение с неограниченным количеством наград */
  updateAchievementWithUnlimitedNumberOfPrizes: UpdateAchievementWithUnlimitedNumberOfPrizesPayload;
  /** Регистрация или изменение информации о жертвователе */
  updateBenefactor: UpdateBenefactorPayload;
  /** Изменить базовую информацию жертвователя администратором платформы */
  updateBenefactorByAdministrator: UpdateBenefactorByAdministratorPayload;
  /** Изменяет список городов, за которыми следит жертвователь */
  updateBenefactorCitiesOfInterest: UpdateBenefactorCitiesOfInterestPayload;
  /** Изменяет данные счёта-депозита жертвователя, средства с которого могут расходоваться только на нужды указанных подопечных */
  updateBenefactorDepositAccountForSpecificWards: UpdateBenefactorDepositAccountForSpecificWardsPayload;
  /** Изменяет адрес электронной почты жертвователя */
  updateBenefactorEmail: UpdateBenefactorEmailPayload;
  /** Изменить адрес электронной почты жертвователя администратором платформы */
  updateBenefactorEmailByAdministrator: UpdateBenefactorEmailByAdministratorPayload;
  /** Изменяет персональные данные жертвователя */
  updateBenefactorPersonalData: UpdateBenefactorPersonalDataPayload;
  /** Изменяет персональные данные жертвователя администратором платформы */
  updateBenefactorPersonalDataByAdministrator: UpdateBenefactorPersonalDataByAdministratorPayload;
  /** Изменяет список навыков жертвователя */
  updateBenefactorSkillsInformation: UpdateBenefactorSkillsInformationPayload;
  /** Изменяет список навыков жертвователя администратором платформы */
  updateBenefactorSkillsInformationByAdministrator: UpdateBenefactorSkillsInformationByAdministratorPayload;
  /** Изменяет описание статуса жертвователя */
  updateBenefactorStatus: UpdateBenefactorStatusPayload;
  /** Изменяет информацию о волонтёрском опыте жертвователя */
  updateBenefactorVolunteerInformation: UpdateBenefactorVolunteerInformationPayload;
  /** Изменяет информацию о волонтёрском опыте жертвователя администратором платформы */
  updateBenefactorVolunteerInformationByAdministrator: UpdateBenefactorVolunteerInformationByAdministratorPayload;
  /** Изменяет порядок категорий */
  updateCategoriesOrder: UpdateCategoriesOrderPayload;
  /** Обновляет данные категории */
  updateCategory: UpdateCategoryPayload;
  /** Изменяет название города. */
  updateCity: UpdateCityPayload;
  /** Обновляет количество монет, о которых сообщалось пользователю */
  updateCoinsAccountedByClient: UpdateCoinsAccountedByClientPayload;
  /** Изменяет информацию о фонде-партнёре */
  updateFoundation: UpdateFoundationPayload;
  /** Изменяет данные события фонда-партнёра */
  updateFoundationEvent: UpdateFoundationEventPayload;
  /** Изменить оценку мероприятия или участия помощника в мероприятии */
  updateFoundationEventAttendanceRating: UpdateFoundationEventAttendanceRatingPayload;
  /** Изменить отчёт о проведённом событии фонда-партнёра */
  updateFoundationEventReport: UpdateFoundationEventReportPayload;
  /** Изменение данных учётной записи представителя фонда-партнёра */
  updateFoundationRepresentative: UpdateFoundationRepresentativePayload;
  /** Обновление баланса ООО */
  updateLimitedLiabilityCompanyBalance: UpdateLimitedLiabilityCompanyBalancePayload;
  /** Изменение отчёта ООО */
  updateLimitedLiabilityCompanyReport: UpdateLimitedLiabilityCompanyReportPayload;
  /** Обновить список документов счёта */
  updateLimitedLiabilityCompanyReportDocuments: UpdateLimitedLiabilityCompanyReportDocumentsPayload;
  /** Изменение данных учётной записи модератора */
  updateModerator: UpdateModeratorPayload;
  /** Обновить разовую потребность */
  updateOneTimeNeed: UpdateOneTimeNeedPayload;
  /** Изменяет документы отчёта разовой потребности */
  updateOneTimeNeedDocuments: UpdateOneTimeNeedDocumentsPayload;
  /** Изменяет документы расхода проекта */
  updateProjectExpenseDocuments: UpdateProjectExpenseDocumentsPayload;
  /** Изменяет информацию о финансовых потребностях проекта */
  updateProjectRequirements: UpdateProjectRequirementsPayload;
  /** Изменяет документы отчёта периода регулярной потребности */
  updateRegularNeedPeriodDocuments: UpdateRegularNeedPeriodDocumentsPayload;
  /** Изменяет данные спецпроекта. */
  updateSpecialProject: UpdateSpecialProjectPayload;
  /** Изменяет документы спецпроекта. */
  updateSpecialProjectDocuments: UpdateSpecialProjectDocumentsPayload;
  /** Изменяет порядок отображения опубликованных незавершённых спецпроектов */
  updateSpecialProjectsVisibilityOrder: UpdateSpecialProjectsVisibilityOrderPayload;
  /** Обновляет историю */
  updateStory: UpdateStoryPayload;
  /** Изменяет данные блока историй. */
  updateStoryBlock: UpdateStoryBlockPayload;
  /** Обновляет экраны истории */
  updateStoryScreens: UpdateStoryScreensPayload;
  /** Изменение данных учётной записи системного администратора */
  updateSystemAdministrator: UpdateSystemAdministratorPayload;
  /** Изменение информации о подопечном */
  updateWard: UpdateWardPayload;
  /** Обновить список документов подопечного */
  updateWardDocuments: UpdateWardDocumentsPayload;
  /** Изменить информацию об условиях списания комиссии подписки */
  upgradeSubscriptionCommission: UpgradeSubscriptionCommissionPayload;
  /** Загружает промо-коды в достижение с промо-кодами */
  uploadPromoCodes: UploadPromoCodesPayload;
};


export type MutationAddAchievementWithMaterialPrizesArgs = {
  input: AddAchievementWithMaterialPrizesInput;
};


export type MutationAddAchievementWithPromoCodesArgs = {
  input: AddAchievementWithPromoCodesInput;
};


export type MutationAddAchievementWithUnlimitedNumberOfPrizesArgs = {
  input: AddAchievementWithUnlimitedNumberOfPrizesInput;
};


export type MutationAddBenefactorStatusArgs = {
  input: AddBenefactorStatusInput;
};


export type MutationAddBroadcastNotificationArgs = {
  input: AddBroadcastNotificationInput;
};


export type MutationAddCategoryArgs = {
  input: AddCategoryInput;
};


export type MutationAddCityArgs = {
  input: AddCityInput;
};


export type MutationAddClientVersionArgs = {
  input: AddClientVersionInput;
};


export type MutationAddFoundationArgs = {
  input: AddFoundationInput;
};


export type MutationAddFoundationEventArgs = {
  input: AddFoundationEventInput;
};


export type MutationAddGrocerySetNeedArgs = {
  input: AddGrocerySetNeedInput;
};


export type MutationAddOneTimeNeedArgs = {
  input: AddOneTimeNeedInput;
};


export type MutationAddProjectExpenseArgs = {
  input: AddProjectExpenseInput;
};


export type MutationAddStoryArgs = {
  input: AddStoryInput;
};


export type MutationAddStoryBlockArgs = {
  input: AddStoryBlockInput;
};


export type MutationAddViewedStoriesArgs = {
  input: AddViewedStoriesInput;
};


export type MutationAddWardArgs = {
  input: AddWardInput;
};


export type MutationAddWardsToTheBenefactorDepositAccountForSpecificWardsArgs = {
  input: AddWardsToTheBenefactorDepositAccountForSpecificWardsInput;
};


export type MutationAllowSubscriptionTransferArgs = {
  input: AllowSubscriptionTransferInput;
};


export type MutationApplyToFoundationEventArgs = {
  input: ApplyToFoundationEventInput;
};


export type MutationApproveFoundationEventArgs = {
  input: ApproveFoundationEventInput;
};


export type MutationArchiveNeedArgs = {
  input: ArchiveNeedInput;
};


export type MutationAssignCoinsArgs = {
  input: AssignCoinsInput;
};


export type MutationCancelFoundationEventArgs = {
  input: CancelFoundationEventInput;
};


export type MutationCancelSpecialProjectArgs = {
  input: CancelSpecialProjectInput;
};


export type MutationCancelSubscriptionArgs = {
  input: CancelSubscriptionInput;
};


export type MutationChangeBenefactorProfileVisibilityArgs = {
  input: ChangeBenefactorProfileVisibilityInput;
};


export type MutationChangePhoneNumberArgs = {
  input: ChangePhoneNumberInput;
};


export type MutationChangePhoneNumberByAdministratorArgs = {
  input: ChangePhoneNumberByAdministratorInput;
};


export type MutationChangePhoneNumberCodeArgs = {
  input: ChangePhoneNumberCodeInput;
};


export type MutationChangeStoriesOrderArgs = {
  input: ChangeStoriesOrderInput;
};


export type MutationChangeUserPasswordArgs = {
  input: ChangeUserPasswordInput;
};


export type MutationCheckinToFoundationEventArgs = {
  input: CheckinToFoundationEventInput;
};


export type MutationCloseFoundationEventArgs = {
  input: CloseFoundationEventInput;
};


export type MutationCloseFoundationEventRegistrationArgs = {
  input: CloseFoundationEventRegistrationInput;
};


export type MutationCloseSpecialProjectArgs = {
  input: CloseSpecialProjectInput;
};


export type MutationConfirmEmailArgs = {
  input: ConfirmEmailInput;
};


export type MutationConvertFoundationEventApplicationToAttendanceArgs = {
  input: ConvertFoundationEventApplicationToAttendanceInput;
};


export type MutationCreateBenefactorArgs = {
  input: CreateBenefactorInput;
};


export type MutationCreateBenefactorDepositAccountForAllWardsArgs = {
  input: CreateBenefactorDepositAccountForAllWardsInput;
};


export type MutationCreateBenefactorDepositAccountForProjectNeedsArgs = {
  input: CreateBenefactorDepositAccountForProjectNeedsInput;
};


export type MutationCreateBenefactorDepositAccountForSpecificWardsArgs = {
  input: CreateBenefactorDepositAccountForSpecificWardsInput;
};


export type MutationCreateBenefactorDepositAccountWithoutConditionsArgs = {
  input: CreateBenefactorDepositAccountWithoutConditionsInput;
};


export type MutationCreateFoundationRepresentativeArgs = {
  input: CreateFoundationRepresentativeInput;
};


export type MutationCreateLimitedLiabilityCompanyReportArgs = {
  input: CreateLimitedLiabilityCompanyReportInput;
};


export type MutationCreateModeratorArgs = {
  input: CreateModeratorInput;
};


export type MutationCreateSpecialProjectArgs = {
  input: CreateSpecialProjectInput;
};


export type MutationCreateSystemAdministratorArgs = {
  input: CreateSystemAdministratorInput;
};


export type MutationDeactivateWardArgs = {
  input: DeactivateWardInput;
};


export type MutationDeclineFoundationEventApplicationArgs = {
  input: DeclineFoundationEventApplicationInput;
};


export type MutationDeleteAchievementArgs = {
  input: DeleteAchievementInput;
};


export type MutationDeleteBenefactorStatusArgs = {
  input: DeleteBenefactorStatusInput;
};


export type MutationDeleteCategoryArgs = {
  input: DeleteCategoryInput;
};


export type MutationDeleteCityArgs = {
  input: DeleteCityInput;
};


export type MutationDeleteStoryArgs = {
  input: DeleteStoryInput;
};


export type MutationDeleteStoryBlockArgs = {
  input: DeleteStoryBlockInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationDepositToBenefactorAccountArgs = {
  input: DepositToBenefactorAccountInput;
};


export type MutationDisableSubscriptionTransferToWardArgs = {
  input: DisableSubscriptionTransferToWardInput;
};


export type MutationEnableOrDisableUserArgs = {
  input: EnableOrDisableUserInput;
};


export type MutationEnableSubscriptionTransferToWardArgs = {
  input: EnableSubscriptionTransferToWardInput;
};


export type MutationForbidSubscriptionTransferArgs = {
  input: ForbidSubscriptionTransferInput;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationKeepAliveArgs = {
  input: KeepAliveInput;
};


export type MutationMarkBenefactorAchievementAsShownArgs = {
  input: MarkBenefactorAchievementAsShownInput;
};


export type MutationMarkWardAsNotRequiringImmediateHelpArgs = {
  input: MarkWardAsNotRequiringImmediateHelpInput;
};


export type MutationMarkWardAsRequiringImmediateHelpArgs = {
  input: MarkWardAsRequiringImmediateHelpInput;
};


export type MutationOpenFoundationEventRegistrationArgs = {
  input: OpenFoundationEventRegistrationInput;
};


export type MutationPhoneLoginCodeArgs = {
  input: PhoneLoginCodeInput;
};


export type MutationPlaceOrderArgs = {
  input: PlaceOrderInput;
};


export type MutationPrepareFilesUploadArgs = {
  input: PrepareFilesUploadInput;
};


export type MutationPublishAchievementArgs = {
  input: PublishAchievementInput;
};


export type MutationPublishLimitedLiabilityCompanyReportArgs = {
  input: PublishLimitedLiabilityCompanyReportInput;
};


export type MutationPublishSpecialProjectArgs = {
  input: PublishSpecialProjectInput;
};


export type MutationPublishStoryArgs = {
  input: PublishStoryInput;
};


export type MutationPublishWardArgs = {
  input: PublishWardInput;
};


export type MutationRegisterDeviceTokenArgs = {
  input: RegisterDeviceTokenInput;
};


export type MutationRemoveSavedCloudPaymentsCardArgs = {
  input: RemoveSavedCloudPaymentsCardInput;
};


export type MutationRemoveWardFromTheBenefactorDepositAccountForSpecificWardsArgs = {
  input: RemoveWardFromTheBenefactorDepositAccountForSpecificWardsInput;
};


export type MutationRequestFoundationEventApprovalArgs = {
  input: RequestFoundationEventApprovalInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationRespondToPaymentChallengeArgs = {
  input: RespondToPaymentChallengeInput;
};


export type MutationRestInPeaceArgs = {
  input: RestInPeaceInput;
};


export type MutationRevokeFoundationEventApplicationArgs = {
  input: RevokeFoundationEventApplicationInput;
};


export type MutationRunReportArgs = {
  input: RunReportInput;
};


export type MutationSelectFoundationEventApplicationArgs = {
  input: SelectFoundationEventApplicationInput;
};


export type MutationSendOneTimeNeedReportArgs = {
  input: SendOneTimeNeedReportInput;
};


export type MutationSendProjectExpenseReportArgs = {
  input: SendProjectExpenseReportInput;
};


export type MutationSendRegularNeedPeriodReportArgs = {
  input: SendRegularNeedPeriodReportInput;
};


export type MutationSendSpecialProjectReportArgs = {
  input: SendSpecialProjectReportInput;
};


export type MutationSetDefaultCloudPaymentsCardArgs = {
  input: SetDefaultCloudPaymentsCardInput;
};


export type MutationTakeFoundationEventOwnershipArgs = {
  input: TakeFoundationEventOwnershipInput;
};


export type MutationTransferFundsToAccountArgs = {
  input: TransferFundsToAccountInput;
};


export type MutationUnpublishAchievementArgs = {
  input: UnpublishAchievementInput;
};


export type MutationUnpublishSpecialProjectArgs = {
  input: UnpublishSpecialProjectInput;
};


export type MutationUnpublishStoryArgs = {
  input: UnpublishStoryInput;
};


export type MutationUnpublishWardArgs = {
  input: UnpublishWardInput;
};


export type MutationUpdateAchievementWithMaterialPrizesArgs = {
  input: UpdateAchievementWithMaterialPrizesInput;
};


export type MutationUpdateAchievementWithPromoCodesArgs = {
  input: UpdateAchievementWithPromoCodesInput;
};


export type MutationUpdateAchievementWithUnlimitedNumberOfPrizesArgs = {
  input: UpdateAchievementWithUnlimitedNumberOfPrizesInput;
};


export type MutationUpdateBenefactorArgs = {
  input: UpdateBenefactorInput;
};


export type MutationUpdateBenefactorByAdministratorArgs = {
  input: UpdateBenefactorByAdministratorInput;
};


export type MutationUpdateBenefactorCitiesOfInterestArgs = {
  input: UpdateBenefactorCitiesOfInterestInput;
};


export type MutationUpdateBenefactorDepositAccountForSpecificWardsArgs = {
  input: UpdateBenefactorDepositAccountForSpecificWardsInput;
};


export type MutationUpdateBenefactorEmailArgs = {
  input: UpdateBenefactorEmailInput;
};


export type MutationUpdateBenefactorEmailByAdministratorArgs = {
  input: UpdateBenefactorEmailByAdministratorInput;
};


export type MutationUpdateBenefactorPersonalDataArgs = {
  input: UpdateBenefactorPersonalDataInput;
};


export type MutationUpdateBenefactorPersonalDataByAdministratorArgs = {
  input: UpdateBenefactorPersonalDataByAdministratorInput;
};


export type MutationUpdateBenefactorSkillsInformationArgs = {
  input: UpdateBenefactorSkillsInformationInput;
};


export type MutationUpdateBenefactorSkillsInformationByAdministratorArgs = {
  input: UpdateBenefactorSkillsInformationByAdministratorInput;
};


export type MutationUpdateBenefactorStatusArgs = {
  input: UpdateBenefactorStatusInput;
};


export type MutationUpdateBenefactorVolunteerInformationArgs = {
  input: UpdateBenefactorVolunteerInformationInput;
};


export type MutationUpdateBenefactorVolunteerInformationByAdministratorArgs = {
  input: UpdateBenefactorVolunteerInformationByAdministratorInput;
};


export type MutationUpdateCategoriesOrderArgs = {
  input: UpdateCategoriesOrderInput;
};


export type MutationUpdateCategoryArgs = {
  input: UpdateCategoryInput;
};


export type MutationUpdateCityArgs = {
  input: UpdateCityInput;
};


export type MutationUpdateCoinsAccountedByClientArgs = {
  input: UpdateCoinsAccountedByClientInput;
};


export type MutationUpdateFoundationArgs = {
  input: UpdateFoundationInput;
};


export type MutationUpdateFoundationEventArgs = {
  input: UpdateFoundationEventInput;
};


export type MutationUpdateFoundationEventAttendanceRatingArgs = {
  input: UpdateFoundationEventAttendanceRatingInput;
};


export type MutationUpdateFoundationEventReportArgs = {
  input: UpdateFoundationEventReportInput;
};


export type MutationUpdateFoundationRepresentativeArgs = {
  input: UpdateFoundationRepresentativeInput;
};


export type MutationUpdateLimitedLiabilityCompanyBalanceArgs = {
  input: UpdateLimitedLiabilityCompanyBalanceInput;
};


export type MutationUpdateLimitedLiabilityCompanyReportArgs = {
  input: UpdateLimitedLiabilityCompanyReportInput;
};


export type MutationUpdateLimitedLiabilityCompanyReportDocumentsArgs = {
  input: UpdateLimitedLiabilityCompanyReportDocumentsInput;
};


export type MutationUpdateModeratorArgs = {
  input: UpdateModeratorInput;
};


export type MutationUpdateOneTimeNeedArgs = {
  input: UpdateOneTimeNeedInput;
};


export type MutationUpdateOneTimeNeedDocumentsArgs = {
  input: UpdateOneTimeNeedDocumentsInput;
};


export type MutationUpdateProjectExpenseDocumentsArgs = {
  input: UpdateProjectExpenseDocumentsInput;
};


export type MutationUpdateProjectRequirementsArgs = {
  input: UpdateProjectRequirementsInput;
};


export type MutationUpdateRegularNeedPeriodDocumentsArgs = {
  input: UpdateRegularNeedPeriodDocumentsInput;
};


export type MutationUpdateSpecialProjectArgs = {
  input: UpdateSpecialProjectInput;
};


export type MutationUpdateSpecialProjectDocumentsArgs = {
  input: UpdateSpecialProjectDocumentsInput;
};


export type MutationUpdateSpecialProjectsVisibilityOrderArgs = {
  input: UpdateSpecialProjectsVisibilityOrderInput;
};


export type MutationUpdateStoryArgs = {
  input: UpdateStoryInput;
};


export type MutationUpdateStoryBlockArgs = {
  input: UpdateStoryBlockInput;
};


export type MutationUpdateStoryScreensArgs = {
  input: UpdateStoryScreensInput;
};


export type MutationUpdateSystemAdministratorArgs = {
  input: UpdateSystemAdministratorInput;
};


export type MutationUpdateWardArgs = {
  input: UpdateWardInput;
};


export type MutationUpdateWardDocumentsArgs = {
  input: UpdateWardDocumentsInput;
};


export type MutationUpgradeSubscriptionCommissionArgs = {
  input: UpgradeSubscriptionCommissionInput;
};


export type MutationUploadPromoCodesArgs = {
  input: UploadPromoCodesInput;
};

/** Потребность */
export type Need = {
  /** Информации об архивировании потребности */
  archived?: Maybe<AuditMarker>;
  /** Информация о добавлении потребности */
  created: AuditMarker;
  id: Scalars['NeedId'];
  /** Требуемое количество средств */
  requiredAmount: Scalars['Int'];
  /** Информация о последнем обновлении потребности */
  updated: AuditMarker;
  /** Подопечный */
  ward: Ward;
};


/** Информация для создания нового заказа */
export type NewOrderInformation = {
  __typename?: 'NewOrderInformation';
  /** Доступные способы оплаты */
  availablePaymentMethods: Array<PaymentMethod>;
  /** Предлагаемые размеры комиссии */
  commissionPercentageSuggestions: Array<CommissionPercentageSuggestion>;
};

/** Имя человека */
export type Nomen = {
  __typename?: 'Nomen';
  /** Имя для отображения (Имя Фамилия) */
  displayName: Scalars['String'];
  /** Имя */
  firstName: Scalars['String'];
  /** Полное имя (Имя Отчество Фамилия) */
  fullName: Scalars['String'];
  /** Фамилия */
  lastName: Scalars['String'];
  /** Отчество */
  middleName?: Maybe<Scalars['String']>;
};

/** Имя человека */
export type NomenFilterInput = {
  and?: Maybe<Array<NomenFilterInput>>;
  /** Имя для отображения (Имя Фамилия) */
  displayName?: Maybe<StringOperationFilterInput>;
  /** Имя */
  firstName?: Maybe<StringOperationFilterInput>;
  /** Полное имя (Имя Отчество Фамилия) */
  fullName?: Maybe<StringOperationFilterInput>;
  /** Фамилия */
  lastName?: Maybe<StringOperationFilterInput>;
  /** Отчество */
  middleName?: Maybe<StringOperationFilterInput>;
  or?: Maybe<Array<NomenFilterInput>>;
};

/** Имя человека */
export type NomenSortInput = {
  /** Имя для отображения (Имя Фамилия) */
  displayName?: Maybe<SortEnumType>;
  /** Имя */
  firstName?: Maybe<SortEnumType>;
  /** Полное имя (Имя Отчество Фамилия) */
  fullName?: Maybe<SortEnumType>;
  /** Фамилия */
  lastName?: Maybe<SortEnumType>;
  /** Отчество */
  middleName?: Maybe<SortEnumType>;
};

/** Уведомление */
export type Notification = {
  __typename?: 'Notification';
  /** Помимо PUSH-оповещения отправлять по СМС */
  alsoSendAsSms: Scalars['Boolean'];
  /** Широковещательное уведомление. */
  broadcastNotification?: Maybe<BroadcastNotification>;
  /** Дата и время создания оповещения */
  createdAt: Scalars['DateTime'];
  /** Дополнительные данные */
  data?: Maybe<Array<KeyValuePairOfStringAndString>>;
  id: Scalars['NotificationId'];
  /** Дата и время прочтения оповещения */
  readAt?: Maybe<Scalars['DateTime']>;
  /** Дата и время отправки оповещения */
  sentAt?: Maybe<Scalars['DateTime']>;
  /** Текст уведомления */
  text: Scalars['String'];
  /** Заголовок уведомления */
  title: Scalars['String'];
};


/** A connection to a list of items. */
export type NotificationsConnection = {
  __typename?: 'NotificationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<NotificationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Notification>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type NotificationsEdge = {
  __typename?: 'NotificationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Notification;
};

/** Разовая потребность (не повторяется) */
export type OneTimeNeed = Need & {
  __typename?: 'OneTimeNeed';
  /** Счёт потребности */
  account: Account;
  /** Информации об архивировании потребности */
  archived?: Maybe<AuditMarker>;
  /** Пожертвования, сделанные текущим жертвователем */
  benefactorDonations?: Maybe<BenefactorDonationsConnection>;
  /** Общая сумма пожертвований, сделанных текущим жертвователем */
  benefactorDonationsAmount?: Maybe<Scalars['Int']>;
  /** Информация о добавлении потребности */
  created: AuditMarker;
  /** Описание потребности */
  description: Scalars['String'];
  /** Прикреплённые документы */
  documents: Array<Document>;
  /** Номер иконки в списке доступных иконок */
  icon: Scalars['Int'];
  id: Scalars['NeedId'];
  /** Дата и время отправки отчёта о выполнении потребности */
  reportSent?: Maybe<AuditMarker>;
  /** Требуемое количество средств */
  requiredAmount: Scalars['Int'];
  /** Уже помогают */
  supporters?: Maybe<SupportersConnection>;
  /** Дата, к которой требуется собрать средства (включительно) */
  targetDate: Scalars['Date'];
  /** Заголовок потребности */
  title: Scalars['String'];
  /** Информация о последнем обновлении потребности */
  updated: AuditMarker;
  /** Ссылка на страницу, содержащую подробную информацию о товаре/продукте/услуге */
  url?: Maybe<Scalars['String']>;
  /** Подопечный */
  ward: Ward;
};


/** Разовая потребность (не повторяется) */
export type OneTimeNeedBenefactorDonationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Разовая потребность (не повторяется) */
export type OneTimeNeedSupportersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Счёт разовой потребности */
export type OneTimeNeedAccount = Account & AccountWithRequiredAmount & {
  __typename?: 'OneTimeNeedAccount';
  /** Количество денег на счёте */
  amount: Scalars['Int'];
  /** Дата и время закрытия счёта */
  closedAt?: Maybe<Scalars['DateTime']>;
  /** Пожертвования */
  donations?: Maybe<DonationsConnection>;
  id: Scalars['AccountId'];
  /** Потребность */
  need: Need;
  /** Требуемое количество средств */
  requiredAmount: Scalars['Int'];
  /** Счета, с которых можно перевести средства на данный счёт */
  transferSources: Array<Account>;
  /** Дата и время последнего обновления счёта */
  updatedAt: Scalars['DateTime'];
  /** Подопечный */
  ward: Ward;
};


/** Счёт разовой потребности */
export type OneTimeNeedAccountDonationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<DonationSortInput>>;
};

export type OpenFoundationEventRegistrationInput = {
  /** Идентификатор события */
  foundationEventId: Scalars['FoundationEventId'];
};

export type OpenFoundationEventRegistrationPayload = {
  __typename?: 'OpenFoundationEventRegistrationPayload';
  foundationEvent?: Maybe<FoundationEvent>;
};

/** Заказ */
export type Order = {
  __typename?: 'Order';
  /** Фактическое распределение суммы пожертвования */
  actualAmountSummary?: Maybe<OrderAmountSummary>;
  /** Сумма заказа */
  amount: Scalars['Int'];
  /** Жертвователь */
  benefactor: Benefactor;
  /** Информация о дате, времени и пользователе, создавшем заказ */
  created: AuditMarker;
  /** Назначение пожертвования */
  donationIntents: Array<DonationIntent>;
  /** Пожертвования */
  donations?: Maybe<DonationsConnection>;
  /** Дата и время, в которые заказ стал устаревшим */
  expiredAt?: Maybe<Scalars['DateTime']>;
  /** Сообщение для показа пользователю в случае ошибки */
  failureMessage?: Maybe<Scalars['String']>;
  id: Scalars['OrderId'];
  /** Распределение суммы пожертвования согласно намерению жертвователя */
  intentAmountSummary?: Maybe<OrderAmountSummary>;
  /** Заказ обработан с ошибкой? */
  isFailed: Scalars['Boolean'];
  /** Заказ обработан успешно? */
  isSucceeded: Scalars['Boolean'];
  /** Требование пройти верификацию платежа */
  paymentChallenge?: Maybe<PaymentChallenge>;
  /** Информация о платеже */
  paymentDetails?: Maybe<PaymentDetails>;
  /** Способ оплаты */
  paymentIntent?: Maybe<PaymentIntent>;
  /** Дата и время, по истечении которых заказ устареет и будет отменён */
  shouldExpireAt?: Maybe<Scalars['DateTime']>;
  /** Статус заказа */
  status: OrderStatus;
  /** Подписки */
  subscriptions?: Maybe<SubscriptionsConnection>;
  /** Дата и время последнего изменения данных заказа */
  updatedAt: Scalars['DateTime'];
};


/** Заказ */
export type OrderDonationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Заказ */
export type OrderSubscriptionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Распределение средств по категориям */
export type OrderAmountSummary = {
  __typename?: 'OrderAmountSummary';
  /** Размер комиссии */
  commission: Scalars['Int'];
  /** Разовое пожертвование на проект */
  oneTimeProjectDonation: Scalars['Int'];
  /** Разовое пожертвование на подопечных */
  oneTimeWardDonation: Scalars['Int'];
  /** Разовое пожертвование в резерв подопечных */
  oneTimeWardsReserveDonation: Scalars['Int'];
  /** Подписка на проект */
  projectSubscription: Scalars['Int'];
  /** Списание подписки на проект */
  projectSubscriptionCharge: Scalars['Int'];
  /** Пожертвование на спецпроект */
  specialProjectDonation: Scalars['Int'];
  /** Подписка на подопечных */
  wardSubscription: Scalars['Int'];
  /** Списание подписки на подопечных */
  wardSubscriptionCharge: Scalars['Int'];
  /** Регулярные пожертвования в резерв подопечных */
  wardsReserveSubscription: Scalars['Int'];
  /** Списание регулярного пожертвования в резерв подопечных */
  wardsReserveSubscriptionCharge: Scalars['Int'];
};

/** Распределение средств по категориям */
export type OrderAmountSummaryFilterInput = {
  and?: Maybe<Array<OrderAmountSummaryFilterInput>>;
  /** Размер комиссии */
  commission?: Maybe<IntOperationFilterInput>;
  /** Разовое пожертвование на проект */
  oneTimeProjectDonation?: Maybe<IntOperationFilterInput>;
  /** Разовое пожертвование на подопечных */
  oneTimeWardDonation?: Maybe<IntOperationFilterInput>;
  /** Разовое пожертвование в резерв подопечных */
  oneTimeWardsReserveDonation?: Maybe<IntOperationFilterInput>;
  or?: Maybe<Array<OrderAmountSummaryFilterInput>>;
  /** Подписка на проект */
  projectSubscription?: Maybe<IntOperationFilterInput>;
  /** Списание подписки на проект */
  projectSubscriptionCharge?: Maybe<IntOperationFilterInput>;
  /** Пожертвование на спецпроект */
  specialProjectDonation?: Maybe<IntOperationFilterInput>;
  /** Подписка на подопечных */
  wardSubscription?: Maybe<IntOperationFilterInput>;
  /** Списание подписки на подопечных */
  wardSubscriptionCharge?: Maybe<IntOperationFilterInput>;
  /** Регулярные пожертвования в резерв подопечных */
  wardsReserveSubscription?: Maybe<IntOperationFilterInput>;
  /** Списание регулярного пожертвования в резерв подопечных */
  wardsReserveSubscriptionCharge?: Maybe<IntOperationFilterInput>;
};

/** Заказ */
export type OrderFilterInput = {
  /** Фактическое распределение суммы пожертвования */
  actualAmountSummary?: Maybe<OrderAmountSummaryFilterInput>;
  and?: Maybe<Array<OrderFilterInput>>;
  /** Информация о дате, времени и пользователе, создавшем заказ */
  created?: Maybe<AuditMarkerFilterInput>;
  /** Дата и время, в которые заказ стал устаревшим */
  expiredAt?: Maybe<DateTimeOperationFilterInput>;
  /** Распределение суммы пожертвования согласно намерению жертвователя */
  intentAmountSummary?: Maybe<OrderAmountSummaryFilterInput>;
  or?: Maybe<Array<OrderFilterInput>>;
  /** Дата и время, по истечении которых заказ устареет и будет отменён */
  shouldExpireAt?: Maybe<DateTimeOperationFilterInput>;
  /** Статус заказа */
  status?: Maybe<OrderStatusOperationFilterInput>;
  /** Дата и время последнего изменения данных заказа */
  updatedAt?: Maybe<DateTimeOperationFilterInput>;
};


export type OrderPlacementError = Error & {
  __typename?: 'OrderPlacementError';
  message: Scalars['String'];
};

/** A connection to a list of items. */
export type OrdersConnection = {
  __typename?: 'OrdersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OrdersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Order>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type OrdersEdge = {
  __typename?: 'OrdersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Order;
};

/** Заказ */
export type OrderSortInput = {
  /** Информация о дате, времени и пользователе, создавшем заказ */
  created?: Maybe<AuditMarkerSortInput>;
  /** Дата и время, по истечении которых заказ устареет и будет отменён */
  shouldExpireAt?: Maybe<SortEnumType>;
  /** Статус заказа */
  status?: Maybe<SortEnumType>;
  /** Дата и время последнего изменения данных заказа */
  updatedAt?: Maybe<SortEnumType>;
};

/** Разрешения, относящиеся к действиям с заказами */
export type OrdersPermissions = {
  __typename?: 'OrdersPermissions';
  /** Текущий пользователь может создавать новые заказы */
  create: Scalars['Boolean'];
  /** Текущий пользователь может просматривать список счетов */
  view: Scalars['Boolean'];
};

export enum OrderStatus {
  /** Обработка заказа завершена */
  Completed = 'COMPLETED',
  /** Заказ создан */
  Created = 'CREATED',
  /** Заказ устарел */
  Expired = 'EXPIRED',
  /** Необходимо вмешательство пользователя для продолжения оплаты (например, ввести 3D Secure код) */
  PaymentChallenge = 'PAYMENT_CHALLENGE',
  /** Платёж не удался */
  PaymentFailed = 'PAYMENT_FAILED',
  /** Ожидается оплата заказа */
  PaymentInProgress = 'PAYMENT_IN_PROGRESS',
  /** Платёж успешно завершён */
  PaymentSucceeded = 'PAYMENT_SUCCEEDED'
}

export type OrderStatusOperationFilterInput = {
  eq?: Maybe<OrderStatus>;
  in?: Maybe<Array<OrderStatus>>;
  neq?: Maybe<OrderStatus>;
  nin?: Maybe<Array<OrderStatus>>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** Данные паспорта РФ */
export type Passport = {
  __typename?: 'Passport';
  /** Дата выдачи */
  dateOfIssue?: Maybe<Scalars['Date']>;
  /** Кем выдан паспорт */
  issuer?: Maybe<Scalars['String']>;
  /** Код подразделения, выдавшего паспорт */
  issuerCode?: Maybe<Scalars['String']>;
  /** Номер */
  number?: Maybe<Scalars['String']>;
  /** Место рождения */
  placeOfBirth?: Maybe<Scalars['String']>;
  /** Серия */
  series?: Maybe<Scalars['String']>;
};

/** Данные паспорта РФ */
export type PassportSortInput = {
  /** Дата выдачи */
  dateOfIssue?: Maybe<SortEnumType>;
  /** Кем выдан паспорт */
  issuer?: Maybe<SortEnumType>;
  /** Код подразделения, выдавшего паспорт */
  issuerCode?: Maybe<SortEnumType>;
  /** Номер */
  number?: Maybe<SortEnumType>;
  /** Место рождения */
  placeOfBirth?: Maybe<SortEnumType>;
  /** Серия */
  series?: Maybe<SortEnumType>;
};

/** Требование пройти верификацию платежа */
export type PaymentChallenge = CloudPayments3DSecurePaymentChallenge | CloudPaymentsSbpPaymentChallenge;

/** Ответ на требование пройти верификацию платежа */
export type PaymentChallengeResponseInput = {
  /** Ответ на требование пройти 3DS-верификацию */
  cloudPayments3dSecureChallengeResponse?: Maybe<CloudPayments3DSecurePaymentChallengeResponseInput>;
};

/** Данные платежа */
export type PaymentDetails = BankTransferPaymentDetails | CloudPaymentsCardFailedPaymentDetails | CloudPaymentsCardSuccessfulPaymentDetails | CloudPaymentsSbpFailedPaymentDetails | CloudPaymentsSbpSuccessfulPaymentDetails | GenericFailedPaymentDetails | VoucherFailedPaymentDetails | VoucherSuccessfulPaymentDetails;

/** Способ оплаты заказа */
export type PaymentIntent = BankTransferPaymentIntent | CloudPaymentsNewCardPaymentIntent | CloudPaymentsSavedCardPaymentIntent | CloudPaymentsSbpPaymentIntent | VoucherPaymentIntent;

export type PaymentIntentInput = {
  /** Намерение совершить оплату банковским переводом */
  bankTransfer?: Maybe<BankTransferPaymentIntentInput>;
  /** Намерение оплатить заказ новой картой */
  cloudPaymentsNewCard?: Maybe<CloudPaymentsNewCardPaymentIntentInput>;
  /** Намерение оплатить заказ сохранённой картой */
  cloudPaymentsSavedCard?: Maybe<CloudPaymentsSavedCardPaymentIntentInput>;
  /** Намерение оплатить заказ через СБП */
  cloudPaymentsSbp?: Maybe<CloudPaymentsSbpPaymentIntentInput>;
  /** Намерение совершить оплату ваучером */
  voucher?: Maybe<VoucherPaymentIntentInput>;
};

/** Способ оплаты */
export type PaymentMethod = {
  /** Название способа оплаты */
  title: Scalars['String'];
};

/** Разрешения текущего пользователя */
export type Permissions = {
  __typename?: 'Permissions';
  /** Разрешения, относящиеся к действиям со счетами */
  accounts: AccountsPermissions;
  /** Разрешения, относящиеся к действиям с достижениями. */
  achievements: AchievementsPermissions;
  /** Разрешения, относящиеся к действиям с конкретным жертвователем */
  benefactor: BenefactorPermissions;
  /** Разрешения, относящиеся к действиям с жертвователями */
  benefactors: BenefactorsPermissions;
  /** Разрешения, относящиеся к действиям с широковещательными уведомлениями */
  broadcastNotifications: BroadcastNotificationsPermissions;
  /** Разрешения, относящиеся к разнообразным каталогам данных */
  catalogs: CatalogsPermissions;
  /** Разрешения, относящиеся к работе с категориями */
  categories: CategoriesPermissions;
  /** Разрешения, относящиеся к действиям с версиями клиентских приложений */
  clientVersions: ClientVersionsPermissions;
  /** Разрешения, относящиеся к действиям с конкретным фондом-партнёром */
  foundation: FoundationPermissions;
  /** Разрешения, относящиеся к действиям с конкретным событием фонда-партнёра */
  foundationEvent: FoundationEventPermissions;
  /** Разрешения, относящиеся к действиям с фондами-партнёрами */
  foundations: FoundationsPermissions;
  /** Разрешения, относящиеся к действиям с информацией об ООО */
  limitedLiabilityCompany: LimitedLiabilityCompanyPermissions;
  /** Разрешения, относящиеся к действиям с заказами */
  orders: OrdersPermissions;
  /** Разрешения, относящиеся к действиям с проектом */
  project: ProjectPermissions;
  /** Разрешения, относящиеся к действиям с отчётами */
  reports: ReportsPermissions;
  /** Разрешения, относящиеся к действиям со спецпроектами */
  specialProjects: SpecialProjectsPermissions;
  /** Разрешения, относящиеся к действиям с историями */
  stories: StoriesPermissions;
  /** Разрешения, относящиеся к действиям с пользователями */
  users: UsersPermissions;
  /** Разрешения, относящиеся к действиям с ваучерами */
  vouchers: VouchersPermissions;
  /** Разрешения, относящиеся к действиям подопечными */
  wards: WardsPermissions;
};


/** Разрешения текущего пользователя */
export type PermissionsBenefactorArgs = {
  id: Scalars['BenefactorId'];
};


/** Разрешения текущего пользователя */
export type PermissionsFoundationArgs = {
  id: Scalars['FoundationId'];
};


/** Разрешения текущего пользователя */
export type PermissionsFoundationEventArgs = {
  id: Scalars['FoundationEventId'];
};

export type PhoneLoginCodeError = ForbiddenForUserError | RegistrationProhibitedError | UserCommunicationError | UserPersistenceError;

/** Параметры получения кода для входа по номеру телефона */
export type PhoneLoginCodeInput = {
  /** Номер телефона */
  phone: Scalars['String'];
};

export type PhoneLoginCodePayload = {
  __typename?: 'PhoneLoginCodePayload';
  errors?: Maybe<Array<PhoneLoginCodeError>>;
  resendAt?: Maybe<Scalars['DateTime']>;
};

export type PhoneNumberAlreadyRegisteredError = Error & {
  __typename?: 'PhoneNumberAlreadyRegisteredError';
  message: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type PlaceOrderError = OrderPlacementError;

/** Параметры размещения заказа */
export type PlaceOrderInput = {
  /** Идентификатор жертвователя, от лица которого создаётся заказ */
  benefactorId?: Maybe<Scalars['BenefactorId']>;
  /** Текущие дата и время жертвователя */
  currentDateTime: Scalars['DateTime'];
  /** Назначение пожертвования */
  donationIntents: Array<DonationIntentInput>;
  /** Способ оплаты */
  paymentIntent: PaymentIntentInput;
};

export type PlaceOrderPayload = {
  __typename?: 'PlaceOrderPayload';
  errors?: Maybe<Array<PlaceOrderError>>;
  order?: Maybe<Order>;
};

/** Параметры запроса на подготовку файлов к загрузке в хранилище */
export type PrepareFilesUploadInput = {
  /** Информация о загружаемых файлах */
  files: Array<FileUploadRequestInput>;
};

export type PrepareFilesUploadPayload = {
  __typename?: 'PrepareFilesUploadPayload';
  files?: Maybe<Array<FileUploadInformation>>;
};

/** Информация о проекте */
export type Project = {
  __typename?: 'Project';
  /** Пожертвования, сделанные текущим жертвователем */
  benefactorDonations?: Maybe<BenefactorDonationsConnection>;
  /** Общая сумма пожертвований, сделанных текущим жертвователем */
  benefactorDonationsAmount?: Maybe<Scalars['Int']>;
  /** Расходы проекта */
  expenses?: Maybe<ProjectExpensesConnection>;
  /** Основной счёт проекта */
  mainAccount: Account;
  /** Потребности проекта */
  requirements: ProjectRequirements;
  /** Подписки на развитие проекта */
  subscriptions?: Maybe<ProjectSubscriptionsConnection>;
  /** Список помогающих проекту */
  supporters?: Maybe<SupportersConnection>;
  /** Счёт резерва подопечных */
  wardsReserveAccount: Account;
};


/** Информация о проекте */
export type ProjectBenefactorDonationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Информация о проекте */
export type ProjectExpensesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<ProjectExpenseSortInput>>;
};


/** Информация о проекте */
export type ProjectSubscriptionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  includeCancelled?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
};


/** Информация о проекте */
export type ProjectSupportersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Намерение совершить пожертвование на развитие проекта */
export type ProjectDonationIntent = {
  __typename?: 'ProjectDonationIntent';
  /** Размер пожертвования */
  amount: Scalars['Int'];
};

/** Намерение совершить пожертвование на развитие проекта */
export type ProjectDonationIntentInput = {
  /** Размер пожертвования */
  amount: Scalars['Int'];
};

/** Подписка на развитие проекта */
export type ProjectDonationSubscription = DonationSubscription & {
  __typename?: 'ProjectDonationSubscription';
  /** Сумма подписки */
  amount: Scalars['Int'];
  /** Жертвователь */
  benefactor: Benefactor;
  /** Причина отмены подписки */
  cancellationReason?: Maybe<DonationSubscriptionCancellationReason>;
  /** Дата отмены подписки */
  cancelledAt?: Maybe<Scalars['DateTime']>;
  /** Дата создания подписки */
  createdAt: Scalars['DateTime'];
  id: Scalars['DonationSubscriptionId'];
  /** Заказ, на основе которого создана подписка */
  order: Order;
};

/** Расход проекта */
export type ProjectExpense = {
  __typename?: 'ProjectExpense';
  /** Счёт расхода */
  account: Account;
  /** Пожертвования, сделанные текущим жертвователем */
  benefactorDonations?: Maybe<BenefactorDonationsConnection>;
  /** Общая сумма пожертвований, сделанных текущим жертвователем */
  benefactorDonationsAmount?: Maybe<Scalars['Int']>;
  /** Дата и время создания расхода */
  createdAt: Scalars['DateTime'];
  /** Прикреплённые документы */
  documents: Array<Document>;
  id: Scalars['ProjectExpenseId'];
  /** Дата отчётного периода, используется только год и месяц */
  referenceDate: Scalars['Date'];
  /** Дата и время отправки отчёта о выполнении потребности */
  reportSentAt?: Maybe<Scalars['DateTime']>;
  /** Размер расхода */
  requiredAmount: Scalars['Int'];
  /** Чьи пожертвования оплатили расход проекта */
  supporters?: Maybe<SupportersConnection>;
  /** Название (содержание) расхода */
  title: Scalars['String'];
  /** Дата и время обновления расхода */
  updatedAt: Scalars['DateTime'];
};


/** Расход проекта */
export type ProjectExpenseBenefactorDonationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Расход проекта */
export type ProjectExpenseSupportersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Счёт расхода проекта */
export type ProjectExpenseAccount = Account & AccountWithRequiredAmount & {
  __typename?: 'ProjectExpenseAccount';
  /** Количество денег на счёте */
  amount: Scalars['Int'];
  /** Дата и время закрытия счёта */
  closedAt?: Maybe<Scalars['DateTime']>;
  /** Пожертвования */
  donations?: Maybe<DonationsConnection>;
  id: Scalars['AccountId'];
  /** Расход проекта */
  projectExpense: ProjectExpense;
  /** Требуемое количество средств */
  requiredAmount: Scalars['Int'];
  /** Счета, с которых можно перевести средства на данный счёт */
  transferSources: Array<Account>;
  /** Дата и время последнего обновления счёта */
  updatedAt: Scalars['DateTime'];
};


/** Счёт расхода проекта */
export type ProjectExpenseAccountDonationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<DonationSortInput>>;
};


/** A connection to a list of items. */
export type ProjectExpensesConnection = {
  __typename?: 'ProjectExpensesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ProjectExpensesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ProjectExpense>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ProjectExpensesEdge = {
  __typename?: 'ProjectExpensesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProjectExpense;
};

/** Параметры упорядочивания расходов проекта */
export type ProjectExpenseSortInput = {
  /** Дата отчётного периода, используется только год и месяц */
  referenceDate?: Maybe<SortEnumType>;
  /** Название (содержание) расхода */
  title?: Maybe<SortEnumType>;
};

/** Разрешения, относящиеся к действиям с проектом */
export type ProjectPermissions = {
  __typename?: 'ProjectPermissions';
  /** Текущий пользователь может управлять счетами-расходами проекта */
  manageExpenses: Scalars['Boolean'];
};

/** Финансовые потребности проекта */
export type ProjectRequirements = {
  __typename?: 'ProjectRequirements';
  /** Ожидаемая сумма расходов проекта за месяц */
  estimatedMonthlyExpensesAmount: Scalars['Int'];
  /** На сколько хватит средств проекта? */
  remainingDuration: Scalars['String'];
};

export type ProjectSubscriptionChargeItem = SubscriptionChargeItem & {
  __typename?: 'ProjectSubscriptionChargeItem';
  /** Размер подписки */
  amount: Scalars['Int'];
};

/** Намерение подписаться на развитие проекта */
export type ProjectSubscriptionIntent = {
  __typename?: 'ProjectSubscriptionIntent';
  /** Размер подписки */
  amount: Scalars['Int'];
};

/** Намерение подписаться на развитие проекта */
export type ProjectSubscriptionIntentInput = {
  /** Размер подписки */
  amount: Scalars['Int'];
};

/** A connection to a list of items. */
export type ProjectSubscriptionsConnection = {
  __typename?: 'ProjectSubscriptionsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ProjectSubscriptionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ProjectDonationSubscription>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ProjectSubscriptionsEdge = {
  __typename?: 'ProjectSubscriptionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProjectDonationSubscription;
};

export type PublishAchievementInput = {
  /** Идентификатор достижения */
  id: Scalars['AchievementId'];
};

export type PublishAchievementPayload = {
  __typename?: 'PublishAchievementPayload';
  achievement?: Maybe<Achievement>;
};

/** A connection to a list of items. */
export type PublishedReportsConnection = {
  __typename?: 'PublishedReportsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<PublishedReportsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<LimitedLiabilityCompanyReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PublishedReportsEdge = {
  __typename?: 'PublishedReportsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: LimitedLiabilityCompanyReport;
};

/** Параметры публикации отчёта ООО */
export type PublishLimitedLiabilityCompanyReportInput = {
  /** Идентификатор отчёта */
  id: Scalars['LimitedLiabilityCompanyReportId'];
};

export type PublishLimitedLiabilityCompanyReportPayload = {
  __typename?: 'PublishLimitedLiabilityCompanyReportPayload';
  report?: Maybe<LimitedLiabilityCompanyReport>;
};

export type PublishSpecialProjectInput = {
  /** Идентификатор спецпроекта */
  id: Scalars['SpecialProjectId'];
};

export type PublishSpecialProjectPayload = {
  __typename?: 'PublishSpecialProjectPayload';
  specialProject?: Maybe<SpecialProject>;
};

export type PublishStoryError = StoryWithoutScreensCanNotBePublishedError;

/** Параметры публикации истории. */
export type PublishStoryInput = {
  /** Идентификатор истории. */
  id: Scalars['StoryId'];
};

export type PublishStoryPayload = {
  __typename?: 'PublishStoryPayload';
  errors?: Maybe<Array<PublishStoryError>>;
  story?: Maybe<Story>;
};

export type PublishWardInput = {
  id: Scalars['WardId'];
};

export type PublishWardPayload = {
  __typename?: 'PublishWardPayload';
  ward?: Maybe<Ward>;
};

export type Query = {
  __typename?: 'Query';
  /** Счёт с указанным идентификатором */
  account?: Maybe<Account>;
  /** Возвращает достижение с указанным идентификатором */
  achievementById?: Maybe<Achievement>;
  /** Возвращает список достижений */
  achievements?: Maybe<AchievementsConnection>;
  /** Количество активных клиентов */
  activeClientsCount: Scalars['Int'];
  /** Активные события фондов-партнёров */
  activeFoundationEvents?: Maybe<FoundationEventsConnection>;
  /** Подсказки адресов для заданной строки */
  addressSuggestions: Array<AddressSuggestion>;
  /** Администраторы системы */
  administrators?: Maybe<AdministratorsConnection>;
  /** Различные расчётные значения, предназначенные для визуализации статистики платформы */
  amounts: Amounts;
  /** Утверждённые события фондов-партнёров */
  approvedFoundationEvents?: Maybe<FoundationEventsConnection>;
  /** Жертвователь с указанным идентификатором */
  benefactorById: Benefactor;
  /** Статус жертвователя с указанным идентификатором */
  benefactorStatusById?: Maybe<BenefactorStatus>;
  /** Статусы жертвователя */
  benefactorStatuses?: Maybe<BenefactorStatusesConnection>;
  /** Список идентификаторов подопечных, которым помогает текущий жертвователь */
  benefactorWardsIds: Array<Scalars['WardId']>;
  /** Жертвователи */
  benefactors?: Maybe<BenefactorsConnection>;
  /** Рейтинг накопления монет */
  benefactorsCoinsAccumulationRating?: Maybe<BenefactorsCoinsAccumulationRatingConnection>;
  /** Общее количество средств на всех счетах-депозитах жертвователей */
  benefactorsDepositAccountsAmount: Scalars['Int'];
  /** Жертвователи, у которых есть счета-депозиты */
  benefactorsWithDepositAccounts?: Maybe<BenefactorsWithDepositAccountsConnection>;
  /** Широковещательные уведомления */
  broadcastNotifications?: Maybe<BroadcastNotificationsConnection>;
  /** Категории */
  categories: Array<Category>;
  /** Категория с указанным идентификатором */
  categoryById?: Maybe<Category>;
  /** Список городов */
  cities?: Maybe<CitiesConnection>;
  /** Город с указанным идентификатором */
  cityById?: Maybe<City>;
  cityByIpAddress: CityByIpResult;
  /** Версии клиентов */
  clientVersions?: Maybe<ClientVersionsConnection>;
  /** Возвращает Public ID платёжной системы CloudPayments для формирования криптограмм */
  cloudPaymentsPublicId: Scalars['String'];
  /** Пожертвование с указанным идентификатором */
  donation?: Maybe<Donation>;
  /** Фонд-партнёр */
  foundationById?: Maybe<Foundation>;
  /** Событие с указанным идентификатором */
  foundationEventById?: Maybe<FoundationEvent>;
  /** Аккумулированные счётчики событий фондов-партнёров */
  foundationEventsCounters: FoundationEventsCounters;
  /** События фондов-партнёров требующие утверждения */
  foundationEventsPendingApproval?: Maybe<FoundationEventsPendingApprovalConnection>;
  /** Фонды-партнёры */
  foundations?: Maybe<FoundationsConnection>;
  /** Информация о самой последней доступной версии клиентского приложения */
  latestClientVersion?: Maybe<ClientVersion>;
  /** Возвращает данные об ООО */
  limitedLiabilityCompany: LimitedLiabilityCompany;
  /** Возвращает отчёт ООО с указанным идентификатором */
  limitedLiabilityCompanyReport?: Maybe<LimitedLiabilityCompanyReport>;
  /** Информация о текущем пользователе */
  me: Me;
  /** Возвращает потребность с указанным идентификатором */
  needById?: Maybe<Need>;
  /** Информация, необходимая для создания заказа */
  newOrderInformation: NewOrderInformation;
  /** Уведомление с указанным идентификатором. */
  notificationById?: Maybe<Notification>;
  /** Заказ с указанным идентификатором */
  orderById?: Maybe<Order>;
  /** Заказы */
  orders?: Maybe<OrdersConnection>;
  /** Проект */
  project: Project;
  /** Период регулярной потребности */
  regularNeedPeriodById?: Maybe<RegularNeedPeriod>;
  search?: Maybe<SearchConnection>;
  /** Категории навыков помощников */
  skillCategories: Array<SkillCategory>;
  /** Возвращает спецпроект с указанным идентификатором */
  specialProjectById?: Maybe<SpecialProject>;
  /** Возвращает спецпроекты */
  specialProjects?: Maybe<SpecialProjectsConnection>;
  /** Статистика платежей */
  statistics: Statistics;
  /** Блок историй с указанным идентификатором. */
  storyBlockById?: Maybe<StoryBlock>;
  /** Блок историй с указанной меткой. */
  storyBlockByLabel?: Maybe<StoryBlock>;
  /** Блоки историй. */
  storyBlocks: Array<StoryBlock>;
  /** История с указанным идентификатором. */
  storyById?: Maybe<Story>;
  /** Подписка на регулярные потребности с указанным идентификатором */
  subscriptionById?: Maybe<DonationSubscription>;
  /** Пользователь с указанным идентификатором */
  userById?: Maybe<User>;
  /** Возвращает ваучер с указанным кодом. */
  voucherByCode?: Maybe<Voucher>;
  /** Подопечный с указанным идентификатором */
  wardById?: Maybe<Ward>;
  /** Подопечные */
  wards?: Maybe<WardsConnection>;
  /** Список подопечных для указанных идентификаторов */
  wardsByIds: Array<Maybe<Ward>>;
  /** Подопечные и их финансовая информация */
  wardsFinances?: Maybe<WardsFinancesConnection>;
  /** Идентификаторы всех подопечных */
  wardsIds: Array<Scalars['WardId']>;
};


export type QueryAccountArgs = {
  id: Scalars['AccountId'];
};


export type QueryAchievementByIdArgs = {
  id: Scalars['AchievementId'];
};


export type QueryAchievementsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  includeUnpublished?: Scalars['Boolean'];
  last?: Maybe<Scalars['Int']>;
};


export type QueryActiveFoundationEventsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<FoundationEventSortInput>>;
  search?: Maybe<Scalars['String']>;
};


export type QueryAddressSuggestionsArgs = {
  count?: Maybe<Scalars['Int']>;
  mode?: AddressSuggestionMode;
  query: Scalars['String'];
};


export type QueryAdministratorsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<AdministratorsFilterInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<AdministratorsSortInput>>;
};


export type QueryApprovedFoundationEventsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<FoundationEventSortInput>>;
  search?: Maybe<Scalars['String']>;
};


export type QueryBenefactorByIdArgs = {
  id: Scalars['BenefactorId'];
};


export type QueryBenefactorStatusByIdArgs = {
  id: Scalars['BenefactorStatusId'];
};


export type QueryBenefactorStatusesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryBenefactorsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<BenefactorSort>>;
  where?: Maybe<BenefactorFilter>;
};


export type QueryBenefactorsCoinsAccumulationRatingArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryBenefactorsWithDepositAccountsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryBroadcastNotificationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<BroadcastNotificationSortInput>>;
};


export type QueryCategoryByIdArgs = {
  id: Scalars['CategoryId'];
};


export type QueryCitiesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<CitySortInput>>;
};


export type QueryCityByIdArgs = {
  id: Scalars['CityId'];
};


export type QueryClientVersionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDonationArgs = {
  id: Scalars['DonationId'];
};


export type QueryFoundationByIdArgs = {
  id: Scalars['FoundationId'];
};


export type QueryFoundationEventByIdArgs = {
  id: Scalars['FoundationEventId'];
};


export type QueryFoundationEventsPendingApprovalArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<FoundationEventSortInput>>;
  where?: Maybe<FoundationEventFilterInput>;
};


export type QueryFoundationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<FoundationSortInput>>;
  where?: Maybe<FoundationFilterInput>;
};


export type QueryLatestClientVersionArgs = {
  client: ClientApplication;
  major: Scalars['Int'];
  minor: Scalars['Int'];
  patch: Scalars['Int'];
};


export type QueryLimitedLiabilityCompanyReportArgs = {
  id: Scalars['LimitedLiabilityCompanyReportId'];
};


export type QueryNeedByIdArgs = {
  needId: Scalars['NeedId'];
};


export type QueryNewOrderInformationArgs = {
  benefactorId?: Maybe<Scalars['BenefactorId']>;
  donationIntents: Array<DonationIntentInput>;
};


export type QueryNotificationByIdArgs = {
  id: Scalars['NotificationId'];
};


export type QueryOrderByIdArgs = {
  id: Scalars['OrderId'];
};


export type QueryOrdersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<OrderSortInput>>;
  where?: Maybe<OrderFilterInput>;
};


export type QueryRegularNeedPeriodByIdArgs = {
  id: Scalars['RegularNeedPeriodId'];
};


export type QuerySearchArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Scalars['CategoryId']>>;
  city?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  mode?: SearchResultsSelectionMode;
  onlyRelated?: Scalars['Boolean'];
};


export type QuerySpecialProjectByIdArgs = {
  id: Scalars['SpecialProjectId'];
};


export type QuerySpecialProjectsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  includeClosedOrCancelled?: Scalars['Boolean'];
  includeUnpublished?: Scalars['Boolean'];
  last?: Maybe<Scalars['Int']>;
};


export type QueryStoryBlockByIdArgs = {
  id: Scalars['StoryBlockId'];
};


export type QueryStoryBlockByLabelArgs = {
  label: Scalars['StoryBlockLabel'];
};


export type QueryStoryByIdArgs = {
  id: Scalars['StoryId'];
};


export type QuerySubscriptionByIdArgs = {
  subscriptionId: Scalars['DonationSubscriptionId'];
};


export type QueryUserByIdArgs = {
  id: Scalars['ID'];
};


export type QueryVoucherByCodeArgs = {
  code: Scalars['String'];
};


export type QueryWardByIdArgs = {
  id: Scalars['WardId'];
};


export type QueryWardsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<WardSortInput>>;
  where?: Maybe<WardFilterInput>;
};


export type QueryWardsByIdsArgs = {
  ids: Array<Scalars['WardId']>;
};


export type QueryWardsFinancesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<WardFinancesSortInput>>;
  where?: Maybe<WardFinancesFilterInput>;
};


export type QueryWardsIdsArgs = {
  includeUnpublished?: Maybe<Scalars['Boolean']>;
};

/** Параметры регистрации токена мобильного устройства */
export type RegisterDeviceTokenInput = {
  /** Токен мобильного устройства */
  token: Scalars['String'];
};

export type RegisterDeviceTokenPayload = {
  __typename?: 'RegisterDeviceTokenPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type RegistrationProhibitedError = Error & {
  __typename?: 'RegistrationProhibitedError';
  message: Scalars['String'];
};

/** Регулярная (повторяющаяся каждый период) потребность */
export type RegularNeed = {
  /** Информации об архивировании потребности */
  archived?: Maybe<AuditMarker>;
  /** Информация о добавлении потребности */
  created: AuditMarker;
  id: Scalars['NeedId'];
  /** Периоды потребности */
  periods?: Maybe<RegularNeedPeriodsConnection>;
  /** Требуемое количество средств */
  requiredAmount: Scalars['Int'];
  /** Информация о последнем обновлении потребности */
  updated: AuditMarker;
  /** Подопечный */
  ward: Ward;
};


/** Регулярная (повторяющаяся каждый период) потребность */
export type RegularNeedPeriodsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<RegularNeedPeriodSortInput>>;
};

/** Период регулярной потребности */
export type RegularNeedPeriod = {
  __typename?: 'RegularNeedPeriod';
  /** Счёт периода */
  account?: Maybe<Account>;
  /** Пожертвования, сделанные текущим жертвователем */
  benefactorDonations?: Maybe<BenefactorDonationsConnection>;
  /** Общая сумма пожертвований, сделанных текущим жертвователем */
  benefactorDonationsAmount?: Maybe<Scalars['Int']>;
  /** Дата и время создания периода */
  createdAt: Scalars['DateTime'];
  /** Прикреплённые документы */
  documents: Array<Document>;
  /** Окончание периода */
  end: Scalars['DateTime'];
  id: Scalars['RegularNeedPeriodId'];
  /** Потребность */
  need: Need;
  /** Дата и время отправки отчёта о выполнении потребности */
  reportSentAt?: Maybe<Scalars['DateTime']>;
  /** Начало периода */
  start: Scalars['DateTime'];
  /** Чьи пожертвования оплатили период регулярной потребности */
  supporters?: Maybe<SupportersConnection>;
  /** Дата и время обновления периода */
  updatedAt: Scalars['DateTime'];
  /** Подопечный */
  ward: Ward;
};


/** Период регулярной потребности */
export type RegularNeedPeriodBenefactorDonationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Период регулярной потребности */
export type RegularNeedPeriodSupportersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Счёт периода регулярной потребности */
export type RegularNeedPeriodAccount = Account & AccountWithRequiredAmount & {
  __typename?: 'RegularNeedPeriodAccount';
  /** Количество денег на счёте */
  amount: Scalars['Int'];
  /** Дата и время закрытия счёта */
  closedAt?: Maybe<Scalars['DateTime']>;
  /** Пожертвования */
  donations?: Maybe<DonationsConnection>;
  id: Scalars['AccountId'];
  /** Потребность */
  need: Need;
  /** Период регулярной потребности */
  regularNeedPeriod: RegularNeedPeriod;
  /** Требуемое количество средств */
  requiredAmount: Scalars['Int'];
  /** Счета, с которых можно перевести средства на данный счёт */
  transferSources: Array<Account>;
  /** Дата и время последнего обновления счёта */
  updatedAt: Scalars['DateTime'];
  /** Подопечный */
  ward: Ward;
};


/** Счёт периода регулярной потребности */
export type RegularNeedPeriodAccountDonationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<DonationSortInput>>;
};


/** A connection to a list of items. */
export type RegularNeedPeriodsConnection = {
  __typename?: 'RegularNeedPeriodsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<RegularNeedPeriodsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<RegularNeedPeriod>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type RegularNeedPeriodsEdge = {
  __typename?: 'RegularNeedPeriodsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<RegularNeedPeriod>;
};

/** Период регулярной потребности */
export type RegularNeedPeriodSortInput = {
  /** Начало периода */
  start?: Maybe<SortEnumType>;
};

/** Счёт для сбора средств на регулярные потребности подопечных */
export type RegularNeedsAccount = Account & {
  __typename?: 'RegularNeedsAccount';
  /** Количество денег на счёте */
  amount: Scalars['Int'];
  /** Дата и время закрытия счёта */
  closedAt?: Maybe<Scalars['DateTime']>;
  /** Пожертвования */
  donations?: Maybe<DonationsConnection>;
  id: Scalars['AccountId'];
  /** Счета, с которых можно перевести средства на данный счёт */
  transferSources: Array<Account>;
  /** Дата и время последнего обновления счёта */
  updatedAt: Scalars['DateTime'];
  /** Подопечный */
  ward: Ward;
};


/** Счёт для сбора средств на регулярные потребности подопечных */
export type RegularNeedsAccountDonationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<DonationSortInput>>;
};

export type RemoveSavedCloudPaymentsCardInput = {
  /** Идентификатор сохранённой платёжной карты. */
  savedCardId: Scalars['CloudPaymentsSavedCardId'];
};

export type RemoveSavedCloudPaymentsCardPayload = {
  __typename?: 'RemoveSavedCloudPaymentsCardPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** Данные для удаления подопечного из списка подопечных счёта-депозита */
export type RemoveWardFromTheBenefactorDepositAccountForSpecificWardsInput = {
  id: Scalars['AccountId'];
  /** Идентификатор подопечного */
  wardId: Scalars['WardId'];
};

export type RemoveWardFromTheBenefactorDepositAccountForSpecificWardsPayload = {
  __typename?: 'RemoveWardFromTheBenefactorDepositAccountForSpecificWardsPayload';
  benefactorDepositAccountForSpecificWards?: Maybe<BenefactorDepositAccountForSpecificWards>;
};

/** A connection to a list of items. */
export type ReportsConnection = {
  __typename?: 'ReportsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ReportsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<LimitedLiabilityCompanyReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ReportsEdge = {
  __typename?: 'ReportsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: LimitedLiabilityCompanyReport;
};

/** Разрешения, относящиеся к действиям с отчётами */
export type ReportsPermissions = {
  __typename?: 'ReportsPermissions';
  /** Текущий пользователь может запускать создание отчётов вне расписания */
  runReport: Scalars['Boolean'];
};

/** A connection to a list of items. */
export type RepresentativesConnection = {
  __typename?: 'RepresentativesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<RepresentativesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type RepresentativesEdge = {
  __typename?: 'RepresentativesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

export type RequestFoundationEventApprovalInput = {
  /** Идентификатор события */
  foundationEventId: Scalars['FoundationEventId'];
};

export type RequestFoundationEventApprovalPayload = {
  __typename?: 'RequestFoundationEventApprovalPayload';
  foundationEvent?: Maybe<FoundationEvent>;
};

export type RequiredAmountIsLessThanCollectedAmountError = Error & {
  __typename?: 'RequiredAmountIsLessThanCollectedAmountError';
  accountId: Scalars['AccountId'];
  message: Scalars['String'];
};

export type ResetPasswordError = UserPersistenceError;

/** Параметры изменения пароля учётной записи */
export type ResetPasswordInput = {
  /** Код подтверждения операции */
  code: Scalars['String'];
  /** Подтверждение нового пароля */
  confirmPassword: Scalars['String'];
  /** Адрес электронной почты пользователя */
  email: Scalars['String'];
  /** Новый пароль */
  password: Scalars['String'];
};

export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  errors?: Maybe<Array<ResetPasswordError>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type RespondToPaymentChallengeInput = {
  orderId: Scalars['OrderId'];
  response: PaymentChallengeResponseInput;
};

export type RespondToPaymentChallengePayload = {
  __typename?: 'RespondToPaymentChallengePayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type RestInPeaceInput = {
  /** Дата смерти */
  dateOfDeath: Scalars['Date'];
  /** Идентификатор подопечного */
  wardId: Scalars['WardId'];
};

export type RestInPeacePayload = {
  __typename?: 'RestInPeacePayload';
  ward?: Maybe<Ward>;
};

export type RevokeFoundationEventApplicationInput = {
  /** Идентификатор заявки */
  applicationId: Scalars['FoundationEventApplicationId'];
};

export type RevokeFoundationEventApplicationPayload = {
  __typename?: 'RevokeFoundationEventApplicationPayload';
  application?: Maybe<FoundationEventApplication>;
};

/** Параметры ручного запуска отчёта */
export type RunReportInput = {
  /** Идентификатор отчёта */
  reportId: Scalars['String'];
};

export type RunReportPayload = {
  __typename?: 'RunReportPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** A connection to a list of items. */
export type SearchConnection = {
  __typename?: 'SearchConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SearchEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<SearchResultItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type SearchEdge = {
  __typename?: 'SearchEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<SearchResultItem>;
};

/** Элемент выполнения поискового запроса */
export type SearchResultItem = {
  /** Категории */
  categories: Array<Category>;
  /** Город */
  city?: Maybe<Scalars['String']>;
  /** Собранная сумма */
  collectedAmount: Scalars['Int'];
  /** Требуется ли помощь? */
  isHelpNeeded: Scalars['Boolean'];
  /** Требуемая сумма */
  requiredAmount?: Maybe<Scalars['Int']>;
};

/** Режим выбора результатов поиска */
export enum SearchResultsSelectionMode {
  /** Выводить все элементы */
  All = 'ALL',
  /** Выводить только тех подопечных и спецпроекты, которые ожидают помощи */
  OnlyAwaitingHelp = 'ONLY_AWAITING_HELP',
  /** Выводить только тех подопечных и спецпроекты, которые собрали необходимую сумму */
  OnlyCollectedRequiredAmount = 'ONLY_COLLECTED_REQUIRED_AMOUNT'
}

/** Способ группировки подопечных */
export enum SearchResultsWardsGroupingMethod {
  /** По городу */
  ByCity = 'BY_CITY',
  /** По количеству собранных средств */
  ByCollectedAmount = 'BY_COLLECTED_AMOUNT'
}

/** Параметры отбора заявки на участие в событии фонда-партнёра */
export type SelectFoundationEventApplicationInput = {
  applicationId: Scalars['FoundationEventApplicationId'];
  /** Выбрать как основного участника */
  primary: Scalars['Boolean'];
};

export type SelectFoundationEventApplicationPayload = {
  __typename?: 'SelectFoundationEventApplicationPayload';
  application?: Maybe<FoundationEventApplication>;
};

export type SendOneTimeNeedReportInput = {
  /** Идентификатор потребности */
  id: Scalars['NeedId'];
};

export type SendOneTimeNeedReportPayload = {
  __typename?: 'SendOneTimeNeedReportPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type SendProjectExpenseReportInput = {
  /** Идентификатор расхода */
  id: Scalars['ProjectExpenseId'];
};

export type SendProjectExpenseReportPayload = {
  __typename?: 'SendProjectExpenseReportPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type SendRegularNeedPeriodReportInput = {
  /** Идентификатор периода */
  id: Scalars['RegularNeedPeriodId'];
};

export type SendRegularNeedPeriodReportPayload = {
  __typename?: 'SendRegularNeedPeriodReportPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type SendSpecialProjectReportInput = {
  /** Идентификатор спецпроекта */
  id: Scalars['SpecialProjectId'];
};

export type SendSpecialProjectReportPayload = {
  __typename?: 'SendSpecialProjectReportPayload';
  specialProject?: Maybe<SpecialProject>;
};

export type SetDefaultCloudPaymentsCardInput = {
  /** Идентификатор сохранённой платёжной карты. */
  savedCardId: Scalars['CloudPaymentsSavedCardId'];
};

export type SetDefaultCloudPaymentsCardPayload = {
  __typename?: 'SetDefaultCloudPaymentsCardPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** Навык помощника */
export type Skill = {
  __typename?: 'Skill';
  /** Описание навыка */
  description: Scalars['String'];
  id: Scalars['SkillId'];
  /** Помощник должен приложить портфолио с работами, связанными с данным навыком */
  portfolioIsRequired: Scalars['Boolean'];
  /** Название навыка */
  title: Scalars['String'];
};

/** Категория навыков */
export type SkillCategory = {
  __typename?: 'SkillCategory';
  id: Scalars['SkillCategoryId'];
  /** Навыки данной категории */
  skills: Array<Skill>;
  /** Название категории */
  title: Scalars['String'];
};



export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Спецпроект */
export type SpecialProject = HaveDocuments & {
  __typename?: 'SpecialProject';
  /** Счёт спецпроекта */
  account: Account;
  /** Адрес проведения */
  address?: Maybe<Address>;
  /** Пожертвования, сделанные текущим жертвователем */
  benefactorDonations?: Maybe<BenefactorDonationsConnection>;
  /** Общая сумма пожертвований, сделанных текущим жертвователем */
  benefactorDonationsAmount?: Maybe<Scalars['Int']>;
  /** Маркетинговая кампания, к которой принадлежит спецпроект */
  campaign?: Maybe<Scalars['String']>;
  /** Информация об отмене */
  cancelled?: Maybe<AuditMarker>;
  /** Категории */
  categories: Array<Category>;
  /** Город проведения */
  city?: Maybe<Scalars['String']>;
  /** Информация о закрытии */
  closed?: Maybe<AuditMarker>;
  /** Информация о добавлении */
  created: AuditMarker;
  /** Описание */
  description: Scalars['String'];
  /** Отчётные документы */
  documents: Array<Document>;
  /** Дата и время, к которым необходимо собрать указанную сумму */
  end?: Maybe<Scalars['DateTime']>;
  /** Изображение для ленты/поиска */
  feedImage: FileInformation;
  id: Scalars['SpecialProjectId'];
  /** Изображения */
  images: Array<FileInformation>;
  /** Логотип фонда-партнёра для наложения поверх других изображений */
  logo?: Maybe<FileInformation>;
  /** Порядок показа */
  order?: Maybe<Scalars['Int']>;
  /** Информация о публикации */
  published?: Maybe<AuditMarker>;
  /** Информация об отправке отчёта */
  reportSent?: Maybe<AuditMarker>;
  /** Дата и время начала сбора средств */
  start: Scalars['DateTime'];
  /** Список помогающих спецпроекту */
  supporters?: Maybe<SupportersConnection>;
  /** Название */
  title: Scalars['String'];
  /** Информация об изменении */
  updated: AuditMarker;
};


/** Спецпроект */
export type SpecialProjectBenefactorDonationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Спецпроект */
export type SpecialProjectSupportersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Счёт спецпроекта */
export type SpecialProjectAccount = Account & AccountWithOptionalRequiredAmount & {
  __typename?: 'SpecialProjectAccount';
  /** Количество денег на счёте */
  amount: Scalars['Int'];
  /** Дата и время закрытия счёта */
  closedAt?: Maybe<Scalars['DateTime']>;
  /** Пожертвования */
  donations?: Maybe<DonationsConnection>;
  id: Scalars['AccountId'];
  /** Требуемое количество средств */
  requiredAmount?: Maybe<Scalars['Int']>;
  /** Спецпроект */
  specialProject: SpecialProject;
  /** Счета, с которых можно перевести средства на данный счёт */
  transferSources: Array<Account>;
  /** Дата и время последнего обновления счёта */
  updatedAt: Scalars['DateTime'];
};


/** Счёт спецпроекта */
export type SpecialProjectAccountDonationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<DonationSortInput>>;
};

/** Намерение совершить пожертвование на спецпроект */
export type SpecialProjectDonationIntent = {
  __typename?: 'SpecialProjectDonationIntent';
  /** Размер пожертвования */
  amount: Scalars['Int'];
  /** Размер комиссии */
  commissionPercentage: Scalars['Int'];
  /** Покрывает ли комиссию жертвователь? */
  isCommissionCoveredByBenefactor: Scalars['Boolean'];
  /** Спецпроект */
  specialProject: SpecialProject;
};

/** Намерение совершить пожертвование на спецпроект */
export type SpecialProjectDonationIntentInput = {
  /** Размер пожертвования */
  amount: Scalars['Int'];
  /** Процент комиссии */
  commissionPercentage: Scalars['Int'];
  /** Готов ли жертвователь компенсировать комиссию? */
  isCommissionCoveredByBenefactor: Scalars['Boolean'];
  /** Идентификатор спецпроекта */
  specialProjectId: Scalars['SpecialProjectId'];
};


/** A connection to a list of items. */
export type SpecialProjectsConnection = {
  __typename?: 'SpecialProjectsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SpecialProjectsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<SpecialProject>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** Результат поиска (спецпроект) */
export type SpecialProjectSearchResultItem = SearchResultItem & {
  __typename?: 'SpecialProjectSearchResultItem';
  /** Категории */
  categories: Array<Category>;
  /** Город */
  city?: Maybe<Scalars['String']>;
  /** Дата и время закрытия спецпроекта (если не активен) */
  closedAt?: Maybe<Scalars['DateTime']>;
  /** Собранная сумма */
  collectedAmount: Scalars['Int'];
  /** Требуется ли помощь? */
  isHelpNeeded: Scalars['Boolean'];
  /** Требуемая сумма */
  requiredAmount?: Maybe<Scalars['Int']>;
  /** Спецпроект */
  specialProject: SpecialProject;
};

/** An edge in a connection. */
export type SpecialProjectsEdge = {
  __typename?: 'SpecialProjectsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: SpecialProject;
};

/** Разрешения, относящиеся к действиям со спецпроектами */
export type SpecialProjectsPermissions = {
  __typename?: 'SpecialProjectsPermissions';
  /** Текущий пользователь может управлять спецпроектами */
  manage: Scalars['Boolean'];
};

/** Периоды подсчёта статистики */
export type Statistics = {
  __typename?: 'Statistics';
  lastMonth: StatisticsPeriod;
  lastWeek: StatisticsPeriod;
  thisMonth: StatisticsPeriod;
  thisWeek: StatisticsPeriod;
  today: StatisticsPeriod;
  yesterday: StatisticsPeriod;
};

/** Статистика за указанный период */
export type StatisticsPeriod = {
  __typename?: 'StatisticsPeriod';
  /** Количество успешных платёжных транзакций */
  completedTransactionsCount: Scalars['Long'];
  /** Количество отклонённых платёжных транзакций */
  declinedTransactionsCount: Scalars['Long'];
  /** Окончание интервала статистики (не включительно) */
  end: Scalars['Date'];
  /** Начало интервала статистики (включительно) */
  start: Scalars['Date'];
  /** Общее количество полученных средств */
  transactionsAmount: Scalars['Int'];
};

/** A connection to a list of items. */
export type StoriesConnection = {
  __typename?: 'StoriesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<StoriesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Story>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type StoriesEdge = {
  __typename?: 'StoriesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Story;
};

/** Разрешения, относящиеся к действиям с историями */
export type StoriesPermissions = {
  __typename?: 'StoriesPermissions';
  /** Текущий пользователь может управлять историями. */
  manage: Scalars['Boolean'];
  /** Текущий пользователь может просматривать (неопубликованные) истории */
  view: Scalars['Boolean'];
};

/** История. */
export type Story = {
  __typename?: 'Story';
  /** Информация о создании истории. */
  created: AuditMarker;
  id: Scalars['StoryId'];
  /** Изображение для ленты историй */
  image?: Maybe<FileInformation>;
  /** Порядок следования истории в блоке. */
  order: Scalars['Int'];
  /** Информация о публикации истории. */
  published?: Maybe<AuditMarker>;
  /** Экраны истории. */
  screens: Array<StoryScreen>;
  /** Блок истории */
  storyBlock: StoryBlock;
  /** Заголовок истории. */
  title: Scalars['String'];
  /** Информация о последнем обновлении истории. */
  updated: AuditMarker;
};

/** Блок историй. */
export type StoryBlock = {
  __typename?: 'StoryBlock';
  id: Scalars['StoryBlockId'];
  /** Метка блока. */
  label: Scalars['StoryBlockLabel'];
  /** Истории блока. */
  stories?: Maybe<StoriesConnection>;
  /** Заголовок блока. */
  title: Scalars['String'];
};


/** Блок историй. */
export type StoryBlockStoriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  includeNotPublished?: Scalars['Boolean'];
  last?: Maybe<Scalars['Int']>;
};



export type StoryBlockWithSpecifiedLabelAlreadyExistsError = Error & {
  __typename?: 'StoryBlockWithSpecifiedLabelAlreadyExistsError';
  message: Scalars['String'];
};

export type StoryBlockWithStoriesCanNotBeDeletedError = Error & {
  __typename?: 'StoryBlockWithStoriesCanNotBeDeletedError';
  message: Scalars['String'];
};


/** Экран истории. */
export type StoryScreen = {
  __typename?: 'StoryScreen';
  /** Заголовок кнопки. */
  buttonTitle?: Maybe<Scalars['String']>;
  /** Адрес для перехода при нажатии на кнопку. */
  buttonUrl?: Maybe<Scalars['String']>;
  /** Фоновое изображение экрана. */
  image?: Maybe<FileInformation>;
  /** Основной текст. */
  text?: Maybe<Scalars['String']>;
  /** Тема экрана. */
  theme: StoryScreenTheme;
  /** Заголовок. */
  title?: Maybe<Scalars['String']>;
};

/** Параметры обновления экрана истории. */
export type StoryScreenInput = {
  /** Заголовок кнопки. */
  buttonTitle?: Maybe<Scalars['String']>;
  /** Адрес для перехода при нажатии на кнопку. */
  buttonUrl?: Maybe<Scalars['String']>;
  /** Идентификатор изображения. */
  imageId?: Maybe<Scalars['String']>;
  /** Основной текст. */
  text?: Maybe<Scalars['String']>;
  /** Тема экрана. */
  theme: StoryScreenTheme;
  /** Заголовок. */
  title?: Maybe<Scalars['String']>;
};

/** Тема экрана истории. */
export enum StoryScreenTheme {
  /** Тёмная тема. */
  Dark = 'DARK',
  /** Светлая тема. */
  Light = 'LIGHT'
}

export type StoryWithoutScreensCanNotBePublishedError = Error & {
  __typename?: 'StoryWithoutScreensCanNotBePublishedError';
  message: Scalars['String'];
};

export type StringOperationFilterInput = {
  and?: Maybe<Array<StringOperationFilterInput>>;
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  eq?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ncontains?: Maybe<Scalars['String']>;
  nendsWith?: Maybe<Scalars['String']>;
  neq?: Maybe<Scalars['String']>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  nstartsWith?: Maybe<Scalars['String']>;
  or?: Maybe<Array<StringOperationFilterInput>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Сообщает об изменении количества активных клиентов. */
  onActiveClientsCountChanged: Scalars['Int'];
  onBenefactorCoinsBalanceChanged: BenefactorCoinsBalanceChanged;
  /** Оповещает о каждом успешно завершённом заказе. */
  onOrderProcessed: Order;
  /** Сообщает, что изменился статус заказа с указанным идентификатором. */
  onOrderStatusChanged: Order;
};


export type SubscriptionOnBenefactorCoinsBalanceChangedArgs = {
  benefactorId: Scalars['BenefactorId'];
};


export type SubscriptionOnOrderStatusChangedArgs = {
  orderId: Scalars['OrderId'];
};

export type SubscriptionChargeIntent = {
  __typename?: 'SubscriptionChargeIntent';
  items: Array<SubscriptionChargeItem>;
};

export type SubscriptionChargeItem = {
  /** Размер подписки */
  amount: Scalars['Int'];
};

export type SubscriptionIntentUnion = ProjectSubscriptionIntent | WardsReserveSubscriptionIntent | WardsSubscriptionIntent;

/** A connection to a list of items. */
export type SubscriptionsConnection = {
  __typename?: 'SubscriptionsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SubscriptionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<DonationSubscription>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type SubscriptionsEdge = {
  __typename?: 'SubscriptionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<DonationSubscription>;
};

/** "Кто поддерживает" */
export type Supporter = {
  __typename?: 'Supporter';
  /** Потраченная сумма */
  amountSpent: Scalars['Int'];
  /** Жертвователь */
  benefactor: Benefactor;
};

/** A connection to a list of items. */
export type SupportersConnection = {
  __typename?: 'SupportersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SupportersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Supporter>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type SupportersEdge = {
  __typename?: 'SupportersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Supporter;
};

/** Системный счёт - основной счёт проекта или резерв подопечных */
export type SystemAccount = Account & {
  __typename?: 'SystemAccount';
  /** Количество денег на счёте */
  amount: Scalars['Int'];
  /** Дата и время закрытия счёта */
  closedAt?: Maybe<Scalars['DateTime']>;
  /** Пожертвования */
  donations?: Maybe<DonationsConnection>;
  id: Scalars['AccountId'];
  /** Назначение счёта */
  kind: SystemAccountKind;
  /** Счета, с которых можно перевести средства на данный счёт */
  transferSources: Array<Account>;
  /** Дата и время последнего обновления счёта */
  updatedAt: Scalars['DateTime'];
};


/** Системный счёт - основной счёт проекта или резерв подопечных */
export type SystemAccountDonationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Array<DonationSortInput>>;
};

/** Назначение системного счёта */
export enum SystemAccountKind {
  /** Основной счёт проекта */
  Main = 'MAIN',
  /** Резерв подопечных */
  WardsReserve = 'WARDS_RESERVE'
}

/** Администратор системы */
export type SystemAdministrator = User & {
  __typename?: 'SystemAdministrator';
  /** Дата регистрации */
  createdOn: Scalars['DateTime'];
  /** Признак блокировки учётной записи */
  disabled: Scalars['Boolean'];
  /** Имя для показа */
  displayName?: Maybe<Scalars['String']>;
  /** Адрес электронной почты */
  email?: Maybe<Scalars['String']>;
  /** Адрес электронной почты подтверждён администратором */
  emailConfirmed: Scalars['Boolean'];
  /** Идентификатор */
  id: Scalars['ID'];
  /** Номер телефона */
  phoneNumber?: Maybe<Scalars['String']>;
};

export type TakeFoundationEventOwnershipInput = {
  /** Идентификатор события */
  foundationEventId: Scalars['FoundationEventId'];
};

export type TakeFoundationEventOwnershipPayload = {
  __typename?: 'TakeFoundationEventOwnershipPayload';
  foundationEvent?: Maybe<FoundationEvent>;
};


export type TransferFundsToAccountError = FundsTransferProhibitedError;

/** Параметры перевода средств на счёт */
export type TransferFundsToAccountInput = {
  /** Идентификатор счёта-получателя средств */
  accountId: Scalars['AccountId'];
  /** Максимальное количество средств, которое будет переведено */
  maximumAmount: Scalars['Int'];
  /** Идентификатор счёта-источника средств */
  sourceAccountId: Scalars['AccountId'];
};

export type TransferFundsToAccountPayload = {
  __typename?: 'TransferFundsToAccountPayload';
  errors?: Maybe<Array<TransferFundsToAccountError>>;
  result?: Maybe<TransferFundsToAccountResult>;
};

/** Результат выполнения операции перевода средств на счёт */
export type TransferFundsToAccountResult = {
  __typename?: 'TransferFundsToAccountResult';
  /** Счёт, с которого списывались средства */
  sourceAccount: Account;
  /** Счёт, на который начислялись средства */
  targetAccount: Account;
};

/** A connection to a list of items. */
export type TransfersConnection = {
  __typename?: 'TransfersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<TransfersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Donation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type TransfersEdge = {
  __typename?: 'TransfersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Donation;
};

export type UnpublishAchievementInput = {
  /** Идентификатор достижения */
  id: Scalars['AchievementId'];
};

export type UnpublishAchievementPayload = {
  __typename?: 'UnpublishAchievementPayload';
  achievement?: Maybe<Achievement>;
};

export type UnpublishSpecialProjectInput = {
  /** Идентификатор спецпроекта */
  id: Scalars['SpecialProjectId'];
};

export type UnpublishSpecialProjectPayload = {
  __typename?: 'UnpublishSpecialProjectPayload';
  specialProject?: Maybe<SpecialProject>;
};

/** Параметры отмены публикации истории. */
export type UnpublishStoryInput = {
  /** Идентификатор истории. */
  id: Scalars['StoryId'];
};

export type UnpublishStoryPayload = {
  __typename?: 'UnpublishStoryPayload';
  story?: Maybe<Story>;
};

export type UnpublishWardInput = {
  id: Scalars['WardId'];
};

export type UnpublishWardPayload = {
  __typename?: 'UnpublishWardPayload';
  ward?: Maybe<Ward>;
};

export type UnregisterDeviceTokenPayload = {
  __typename?: 'UnregisterDeviceTokenPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** Данные для изменения достижения с физическими призами */
export type UpdateAchievementWithMaterialPrizesInput = {
  description: Scalars['String'];
  id: Scalars['AchievementId'];
  imageId: Scalars['String'];
  isHighlighted: Scalars['Boolean'];
  /** Количество выданных призов */
  numberOfGrantedPrizes: Scalars['Int'];
  storyId: Scalars['StoryId'];
  title: Scalars['String'];
  topImageId?: Maybe<Scalars['String']>;
  topPosition?: Maybe<Scalars['Int']>;
  /** Общее количество призов */
  totalNumberOfPrizes: Scalars['Int'];
};

export type UpdateAchievementWithMaterialPrizesPayload = {
  __typename?: 'UpdateAchievementWithMaterialPrizesPayload';
  achievement?: Maybe<Achievement>;
};

/** Данные для изменения достижения с промо-кодами в качестве призов */
export type UpdateAchievementWithPromoCodesInput = {
  description: Scalars['String'];
  id: Scalars['AchievementId'];
  imageId: Scalars['String'];
  isHighlighted: Scalars['Boolean'];
  storyId: Scalars['StoryId'];
  title: Scalars['String'];
  topImageId?: Maybe<Scalars['String']>;
  topPosition?: Maybe<Scalars['Int']>;
  /** Ссылка для применения промо-кода */
  url?: Maybe<Scalars['String']>;
};

export type UpdateAchievementWithPromoCodesPayload = {
  __typename?: 'UpdateAchievementWithPromoCodesPayload';
  achievement?: Maybe<Achievement>;
};

/** Данные для изменения данных достижения с неограниченным количеством наград */
export type UpdateAchievementWithUnlimitedNumberOfPrizesInput = {
  description: Scalars['String'];
  id: Scalars['AchievementId'];
  imageId: Scalars['String'];
  isHighlighted: Scalars['Boolean'];
  storyId: Scalars['StoryId'];
  title: Scalars['String'];
  topImageId?: Maybe<Scalars['String']>;
  topPosition?: Maybe<Scalars['Int']>;
};

export type UpdateAchievementWithUnlimitedNumberOfPrizesPayload = {
  __typename?: 'UpdateAchievementWithUnlimitedNumberOfPrizesPayload';
  achievement?: Maybe<Achievement>;
};

export type UpdateBenefactorByAdministratorError = UserPersistenceError;

/** Параметры изменения информации жертвователя администратором платформы */
export type UpdateBenefactorByAdministratorInput = {
  benefactorId: Scalars['BenefactorId'];
  /** Имя */
  displayName: Scalars['String'];
  /** Идентификатор файла-фотографии жертвователя */
  photoId?: Maybe<Scalars['String']>;
};

export type UpdateBenefactorByAdministratorPayload = {
  __typename?: 'UpdateBenefactorByAdministratorPayload';
  benefactor?: Maybe<Benefactor>;
  errors?: Maybe<Array<UpdateBenefactorByAdministratorError>>;
};

/** Параметры обновления информации о городах, представляющих интерес для жертвователя */
export type UpdateBenefactorCitiesOfInterestInput = {
  /** Список городов */
  cities: Array<Scalars['String']>;
};

export type UpdateBenefactorCitiesOfInterestPayload = {
  __typename?: 'UpdateBenefactorCitiesOfInterestPayload';
  benefactor?: Maybe<Benefactor>;
};

/** Данные для изменения счёта-депозита жертвователя, средства с которого могут расходоваться на любые цели */
export type UpdateBenefactorDepositAccountForSpecificWardsInput = {
  id: Scalars['AccountId'];
  /** Заголовок счёта, для показа в списках */
  title: Scalars['String'];
};

export type UpdateBenefactorDepositAccountForSpecificWardsPayload = {
  __typename?: 'UpdateBenefactorDepositAccountForSpecificWardsPayload';
  benefactorDepositAccountForSpecificWards?: Maybe<BenefactorDepositAccountForSpecificWards>;
};

/** Параметры изменения адреса электронной почты жертвователя администратором платформы */
export type UpdateBenefactorEmailByAdministratorInput = {
  benefactorId: Scalars['BenefactorId'];
  /** Адрес электронной почты */
  email?: Maybe<Scalars['String']>;
};

export type UpdateBenefactorEmailByAdministratorPayload = {
  __typename?: 'UpdateBenefactorEmailByAdministratorPayload';
  benefactor?: Maybe<Benefactor>;
};

export type UpdateBenefactorEmailError = UserPersistenceError;

/** Параметры изменения адреса электронной почты жертвователя */
export type UpdateBenefactorEmailInput = {
  /** Адрес электронной почты */
  email: Scalars['String'];
};

export type UpdateBenefactorEmailPayload = {
  __typename?: 'UpdateBenefactorEmailPayload';
  benefactor?: Maybe<Benefactor>;
  errors?: Maybe<Array<UpdateBenefactorEmailError>>;
};

/** Параметры изменения информации жертвователя */
export type UpdateBenefactorInput = {
  /** Имя */
  displayName: Scalars['String'];
  /** Идентификатор файла-фотографии жертвователя */
  photoId?: Maybe<Scalars['String']>;
  /** Код друга, пригласившего зарегистрироваться */
  referrerCode?: Maybe<Scalars['String']>;
};

export type UpdateBenefactorPayload = {
  __typename?: 'UpdateBenefactorPayload';
  benefactor?: Maybe<Benefactor>;
};

/** Параметры изменения персональных данных жертвователя */
export type UpdateBenefactorPersonalDataByAdministratorInput = {
  /** Информация о себе */
  about?: Maybe<Scalars['String']>;
  benefactorId: Scalars['BenefactorId'];
  /** Дата рождения */
  birthday: Scalars['Date'];
  /** Имя */
  firstName: Scalars['String'];
  /** Фамилия */
  lastName: Scalars['String'];
  /** Отчество */
  middleName?: Maybe<Scalars['String']>;
  /** Идентификатор пользователя в VK */
  vkUsername?: Maybe<Scalars['String']>;
};

export type UpdateBenefactorPersonalDataByAdministratorPayload = {
  __typename?: 'UpdateBenefactorPersonalDataByAdministratorPayload';
  benefactor?: Maybe<Benefactor>;
};

/** Параметры изменения персональных данных жертвователя */
export type UpdateBenefactorPersonalDataInput = {
  /** Информация о себе */
  about?: Maybe<Scalars['String']>;
  /** Дата рождения */
  birthday: Scalars['Date'];
  /** Имя */
  firstName: Scalars['String'];
  /** Фамилия */
  lastName: Scalars['String'];
  /** Отчество */
  middleName?: Maybe<Scalars['String']>;
  /** Идентификатор пользователя в VK */
  vkUsername?: Maybe<Scalars['String']>;
};

export type UpdateBenefactorPersonalDataPayload = {
  __typename?: 'UpdateBenefactorPersonalDataPayload';
  benefactor?: Maybe<Benefactor>;
};

/** Параметры изменения списка навыков жертвователя */
export type UpdateBenefactorSkillsInformationByAdministratorInput = {
  benefactorId: Scalars['BenefactorId'];
  /** Описание опыта */
  experience?: Maybe<Scalars['String']>;
  /** Ссылка на портфолио */
  portfolio?: Maybe<Scalars['String']>;
  /** Навыки */
  skills: Array<Scalars['SkillId']>;
};

export type UpdateBenefactorSkillsInformationByAdministratorPayload = {
  __typename?: 'UpdateBenefactorSkillsInformationByAdministratorPayload';
  benefactor?: Maybe<Benefactor>;
};

/** Параметры изменения информации о навыках жертвователя */
export type UpdateBenefactorSkillsInformationInput = {
  /** Описание опыта */
  experience?: Maybe<Scalars['String']>;
  /** Ссылка на портфолио */
  portfolio?: Maybe<Scalars['String']>;
  /** Навыки */
  skills: Array<Scalars['SkillId']>;
};

export type UpdateBenefactorSkillsInformationPayload = {
  __typename?: 'UpdateBenefactorSkillsInformationPayload';
  benefactor?: Maybe<Benefactor>;
};

export type UpdateBenefactorStatusInput = {
  /** Описание статуса */
  description: Scalars['String'];
  /** Идентификатор статуса */
  id: Scalars['BenefactorStatusId'];
  /** Идентификатор изображения статуса в ленте */
  imageId: Scalars['String'];
  /** Идентификатор истории, содержащей описание статуса */
  storyId: Scalars['StoryId'];
  /** Название статуса */
  title: Scalars['String'];
};

export type UpdateBenefactorStatusPayload = {
  __typename?: 'UpdateBenefactorStatusPayload';
  status?: Maybe<BenefactorStatus>;
};

/** Параметры изменения информации о волонтёрском опыте жертвователя */
export type UpdateBenefactorVolunteerInformationByAdministratorInput = {
  benefactorId: Scalars['BenefactorId'];
  /** Документы, имеющие отношение к волонтёрству */
  documents: Array<DocumentInput>;
  /** Волонтёрский опыт */
  experience?: Maybe<Scalars['String']>;
};

export type UpdateBenefactorVolunteerInformationByAdministratorPayload = {
  __typename?: 'UpdateBenefactorVolunteerInformationByAdministratorPayload';
  benefactor?: Maybe<Benefactor>;
};

/** Параметры изменения информации о волонтёрском опыте жертвователя */
export type UpdateBenefactorVolunteerInformationInput = {
  /** Документы, имеющие отношение к волонтёрству */
  documents: Array<DocumentInput>;
  /** Волонтёрский опыт */
  experience?: Maybe<Scalars['String']>;
};

export type UpdateBenefactorVolunteerInformationPayload = {
  __typename?: 'UpdateBenefactorVolunteerInformationPayload';
  benefactor?: Maybe<Benefactor>;
};

export type UpdateCategoriesOrderInput = {
  /** Идентификаторы категорий в требуемом порядке */
  ids: Array<Scalars['CategoryId']>;
};

export type UpdateCategoriesOrderPayload = {
  __typename?: 'UpdateCategoriesOrderPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateCategoryError = CategoryAlreadyExistsError;

/** Параметры обновления категории */
export type UpdateCategoryInput = {
  /** Идентификатор категории */
  id: Scalars['CategoryId'];
  /** Изображение */
  imageId?: Maybe<Scalars['String']>;
  /** Название */
  name: Scalars['String'];
};

export type UpdateCategoryPayload = {
  __typename?: 'UpdateCategoryPayload';
  category?: Maybe<Category>;
  errors?: Maybe<Array<UpdateCategoryError>>;
};

export type UpdateCityError = CityAlreadyExistsError;

/** Параметры изменения данных города */
export type UpdateCityInput = {
  /** Показывать город в списке городов по-умолчанию */
  default: Scalars['Boolean'];
  /** Идентификатор города */
  id: Scalars['CityId'];
  /** Название города */
  name: Scalars['String'];
  /** Порядок показа города в списке городов по-умолчанию */
  order: Scalars['Int'];
};

export type UpdateCityPayload = {
  __typename?: 'UpdateCityPayload';
  city?: Maybe<City>;
  errors?: Maybe<Array<UpdateCityError>>;
};

export type UpdateCoinsAccountedByClientInput = {
  /** Количество монет, о которых сообщалось пользователю */
  coins: Scalars['Int'];
};

export type UpdateCoinsAccountedByClientPayload = {
  __typename?: 'UpdateCoinsAccountedByClientPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateFoundationError = FoundationPersistenceError;

export type UpdateFoundationEventAttendanceRatingInput = {
  /** Идентификатор участия */
  attendanceId: Scalars['FoundationEventAttendanceId'];
  /** Комментарий */
  comment?: Maybe<Scalars['String']>;
  /** Оценка по пятибалльной шкале */
  rating: Scalars['Int'];
};

export type UpdateFoundationEventAttendanceRatingPayload = {
  __typename?: 'UpdateFoundationEventAttendanceRatingPayload';
  attendance?: Maybe<FoundationEventAttendance>;
};

/** Параметры изменения данных события фонда-партнёра */
export type UpdateFoundationEventInput = {
  address: Scalars['String'];
  agreementFileId?: Maybe<Scalars['String']>;
  agreementUrl?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  description: Scalars['String'];
  /** Идентификатор события */
  id: Scalars['FoundationEventId'];
  images: Array<Scalars['String']>;
  meetingInstructions: Scalars['String'];
  passportRequired: Scalars['Boolean'];
  phoneNumber: Scalars['String'];
  requirements: Array<FoundationEventParticipationRequirementInput>;
  start: Scalars['DateTime'];
  title: Scalars['String'];
};

export type UpdateFoundationEventPayload = {
  __typename?: 'UpdateFoundationEventPayload';
  foundationEvent?: Maybe<FoundationEvent>;
};

/** Параметры редактирования отчёта о событии фонда-партнёра */
export type UpdateFoundationEventReportInput = {
  /** Идентификатор события */
  id: Scalars['FoundationEventId'];
  /** Изображения, прикреплённые к отчёту */
  images: Array<DocumentInput>;
  /** Текст отчёта */
  text: Scalars['String'];
};

export type UpdateFoundationEventReportPayload = {
  __typename?: 'UpdateFoundationEventReportPayload';
  foundationEvent?: Maybe<FoundationEvent>;
};

/** Параметры изменения информации фонда-партнёра */
export type UpdateFoundationInput = {
  address: Scalars['String'];
  description: Scalars['String'];
  /** Идентификатор фонда-партнёра */
  id: Scalars['FoundationId'];
  logoId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type UpdateFoundationPayload = {
  __typename?: 'UpdateFoundationPayload';
  errors?: Maybe<Array<UpdateFoundationError>>;
  foundation?: Maybe<Foundation>;
};

export type UpdateFoundationRepresentativeError = UserPersistenceError;

/** Параметры изменения данных учётной записи представителя фонда */
export type UpdateFoundationRepresentativeInput = {
  displayName: Scalars['String'];
  id: Scalars['ID'];
};

export type UpdateFoundationRepresentativePayload = {
  __typename?: 'UpdateFoundationRepresentativePayload';
  errors?: Maybe<Array<UpdateFoundationRepresentativeError>>;
  user?: Maybe<User>;
};

/** Параметры обновления баланса ООО */
export type UpdateLimitedLiabilityCompanyBalanceInput = {
  /** Новое значение баланса */
  balance: Scalars['Int'];
};

export type UpdateLimitedLiabilityCompanyBalancePayload = {
  __typename?: 'UpdateLimitedLiabilityCompanyBalancePayload';
  company?: Maybe<LimitedLiabilityCompany>;
};

/** Параметры обновления документов отчёта ООО */
export type UpdateLimitedLiabilityCompanyReportDocumentsInput = {
  /** Документы отчёта */
  documents: Array<DocumentInput>;
  /** Идентификатор отчёта */
  id: Scalars['LimitedLiabilityCompanyReportId'];
};

export type UpdateLimitedLiabilityCompanyReportDocumentsPayload = {
  __typename?: 'UpdateLimitedLiabilityCompanyReportDocumentsPayload';
  report?: Maybe<LimitedLiabilityCompanyReport>;
};

/** Параметры для обновления отчёта ООО */
export type UpdateLimitedLiabilityCompanyReportInput = {
  /** Идентификатор отчёта */
  id: Scalars['LimitedLiabilityCompanyReportId'];
  referenceDate: Scalars['Date'];
  title: Scalars['String'];
};

export type UpdateLimitedLiabilityCompanyReportPayload = {
  __typename?: 'UpdateLimitedLiabilityCompanyReportPayload';
  report?: Maybe<LimitedLiabilityCompanyReport>;
};

export type UpdateModeratorError = UserPersistenceError;

/** Параметры изменения данных учётной записи модератора */
export type UpdateModeratorInput = {
  displayName: Scalars['String'];
  id: Scalars['ID'];
};

export type UpdateModeratorPayload = {
  __typename?: 'UpdateModeratorPayload';
  errors?: Maybe<Array<UpdateModeratorError>>;
  user?: Maybe<User>;
};

/** Параметры обновления списка документов разовой потребности */
export type UpdateOneTimeNeedDocumentsInput = {
  /** Документы */
  documents: Array<DocumentInput>;
  needId: Scalars['NeedId'];
};

export type UpdateOneTimeNeedDocumentsPayload = {
  __typename?: 'UpdateOneTimeNeedDocumentsPayload';
  oneTimeNeed?: Maybe<OneTimeNeed>;
};

/** Параметры изменения разовой потребности */
export type UpdateOneTimeNeedInput = {
  /** Описание потребности */
  description: Scalars['String'];
  /** Номер иконки в списке иконок */
  icon: Scalars['Int'];
  needId: Scalars['NeedId'];
  /** Заголовок потребности */
  title: Scalars['String'];
  /** Ссылка на страницу с информацией о продукте/услуге */
  url?: Maybe<Scalars['String']>;
};

export type UpdateOneTimeNeedPayload = {
  __typename?: 'UpdateOneTimeNeedPayload';
  need?: Maybe<Need>;
};

/** Параметры изменения документов расхода проекта */
export type UpdateProjectExpenseDocumentsInput = {
  /** Документы */
  documents: Array<DocumentInput>;
  /** Идентификатор расхода */
  id: Scalars['ProjectExpenseId'];
};

export type UpdateProjectExpenseDocumentsPayload = {
  __typename?: 'UpdateProjectExpenseDocumentsPayload';
  projectExpense?: Maybe<ProjectExpense>;
};

/** Параметры изменения финансовых потребностей проекта */
export type UpdateProjectRequirementsInput = {
  /** Ожидаемая сумма расходов проекта за месяц */
  estimatedMonthlyExpensesAmount: Scalars['Int'];
  /** На сколько хватит средств проекта? */
  remainingDuration: Scalars['String'];
};

export type UpdateProjectRequirementsPayload = {
  __typename?: 'UpdateProjectRequirementsPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** Параметры обновления списка документов периода регулярной потребности */
export type UpdateRegularNeedPeriodDocumentsInput = {
  /** Документы */
  documents: Array<DocumentInput>;
  /** Идентификатор периода регулярной потребности */
  periodId: Scalars['RegularNeedPeriodId'];
};

export type UpdateRegularNeedPeriodDocumentsPayload = {
  __typename?: 'UpdateRegularNeedPeriodDocumentsPayload';
  regularNeedPeriod?: Maybe<RegularNeedPeriod>;
};

/** Параметры обновления документов спецпроекта */
export type UpdateSpecialProjectDocumentsInput = {
  /** Документы */
  documents: Array<DocumentInput>;
  /** Идентификатор спецпроекта. */
  id: Scalars['SpecialProjectId'];
};

export type UpdateSpecialProjectDocumentsPayload = {
  __typename?: 'UpdateSpecialProjectDocumentsPayload';
  specialProject?: Maybe<SpecialProject>;
};

export type UpdateSpecialProjectError = CategoryNotFoundError | CityNotFoundError | RequiredAmountIsLessThanCollectedAmountError;

/** Параметры изменения данных спецпроекта */
export type UpdateSpecialProjectInput = {
  /** Адрес */
  address?: Maybe<Scalars['String']>;
  /** Маркетинговая кампания, к которой принадлежит спецпроект */
  campaign?: Maybe<Scalars['String']>;
  /** Категории */
  categories: Array<Scalars['CategoryId']>;
  /** Город */
  city?: Maybe<Scalars['String']>;
  /** Описание */
  description: Scalars['String'];
  /** Дата, к которой требуется собрать средства */
  end?: Maybe<Scalars['DateTime']>;
  /** Изображение для ленты/поиска */
  feedImage: Scalars['String'];
  /** Идентификатор спецпроекта. */
  id: Scalars['SpecialProjectId'];
  /** Изображения */
  images: Array<Scalars['String']>;
  /** Логотип фонда-партнёра для наложения поверх остальных изображений */
  logo?: Maybe<Scalars['String']>;
  /** Необходимая сумма */
  requiredAmount?: Maybe<Scalars['Int']>;
  /** Дата начала сбора средств */
  start: Scalars['DateTime'];
  /** Название */
  title: Scalars['String'];
};

export type UpdateSpecialProjectPayload = {
  __typename?: 'UpdateSpecialProjectPayload';
  errors?: Maybe<Array<UpdateSpecialProjectError>>;
  specialProject?: Maybe<SpecialProject>;
};

export type UpdateSpecialProjectsVisibilityOrderInput = {
  /** Идентификаторы спецпроектов в нужном порядке */
  ids: Array<Scalars['SpecialProjectId']>;
};

export type UpdateSpecialProjectsVisibilityOrderPayload = {
  __typename?: 'UpdateSpecialProjectsVisibilityOrderPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** Параметры изменения данных блока историй. */
export type UpdateStoryBlockInput = {
  /** Идентификатор блока историй. */
  id: Scalars['StoryBlockId'];
  /** Заголовок блока историй. */
  title: Scalars['String'];
};

export type UpdateStoryBlockPayload = {
  __typename?: 'UpdateStoryBlockPayload';
  storyBlock?: Maybe<StoryBlock>;
};

/** Параметры обновления истории. */
export type UpdateStoryInput = {
  /** Идентификатор истории. */
  id: Scalars['StoryId'];
  /** Изображение для ленты историй */
  imageId?: Maybe<Scalars['String']>;
  /** Заголовок истории. */
  title: Scalars['String'];
};

export type UpdateStoryPayload = {
  __typename?: 'UpdateStoryPayload';
  story?: Maybe<Story>;
};

/** Параметры обновления экранов истории */
export type UpdateStoryScreensInput = {
  /** Идентификатор истории */
  id: Scalars['StoryId'];
  /** Экраны истории */
  screens: Array<StoryScreenInput>;
};

export type UpdateStoryScreensPayload = {
  __typename?: 'UpdateStoryScreensPayload';
  story?: Maybe<Story>;
};

export type UpdateSystemAdministratorError = UserPersistenceError;

/** Параметры изменения данных учётной записи системного администратора */
export type UpdateSystemAdministratorInput = {
  displayName: Scalars['String'];
  id: Scalars['ID'];
};

export type UpdateSystemAdministratorPayload = {
  __typename?: 'UpdateSystemAdministratorPayload';
  errors?: Maybe<Array<UpdateSystemAdministratorError>>;
  user?: Maybe<User>;
};

/** Параметры изменения списка документов подопечного */
export type UpdateWardDocumentsInput = {
  /** Документы */
  documents: Array<DocumentInput>;
  /** Идентификатор подопечного */
  id: Scalars['WardId'];
};

export type UpdateWardDocumentsPayload = {
  __typename?: 'UpdateWardDocumentsPayload';
  ward?: Maybe<Ward>;
};

/** Параметры изменения данных подопечного */
export type UpdateWardInput = {
  address: Scalars['String'];
  categories: Array<Scalars['CategoryId']>;
  city: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  dateOfBirth: Scalars['Date'];
  disabilityGroup?: Maybe<Scalars['Int']>;
  entranceNumber?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  flatFloor?: Maybe<Scalars['String']>;
  gender: Gender;
  /** Идентификатор подопечного */
  id: Scalars['WardId'];
  lastName: Scalars['String'];
  maritalStatus?: Maybe<MaritalStatus>;
  middleName?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['Date']>;
  passportIssuer?: Maybe<Scalars['String']>;
  passportIssuerCode?: Maybe<Scalars['String']>;
  passportNumber?: Maybe<Scalars['String']>;
  passportSeries?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  photoId: Scalars['String'];
  placeOfBirth?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  publicCoordinates: Scalars['String'];
  rank?: Maybe<Scalars['String']>;
  story: Scalars['String'];
};

export type UpdateWardPayload = {
  __typename?: 'UpdateWardPayload';
  ward?: Maybe<Ward>;
};

/** Параметры изменения информации об условиях списания комиссии подписки */
export type UpgradeSubscriptionCommissionInput = {
  /** Процент комиссии */
  commissionPercentage: Scalars['Int'];
  /** Готов ли жертвователь компенсировать комиссию? */
  isCommissionCoveredByBenefactor: Scalars['Boolean'];
  /** Идентификатор подписки */
  subscriptionId: Scalars['DonationSubscriptionId'];
};

export type UpgradeSubscriptionCommissionPayload = {
  __typename?: 'UpgradeSubscriptionCommissionPayload';
  subscription?: Maybe<WardDonationSubscription>;
};

export type UploadPromoCodesError = IncompatibleAchievementTypeError;

/** Параметры загрузки промо-кодов в достижение с промо-кодами */
export type UploadPromoCodesInput = {
  /** Идентификатор достижения */
  achievementId: Scalars['AchievementId'];
  /** Идентификатор файла с промо-кодами */
  fileId: Scalars['String'];
};

export type UploadPromoCodesPayload = {
  __typename?: 'UploadPromoCodesPayload';
  achievement?: Maybe<Achievement>;
  errors?: Maybe<Array<UploadPromoCodesError>>;
};

/** Учётная запись пользователя системы */
export type User = {
  /** Дата регистрации */
  createdOn: Scalars['DateTime'];
  /** Признак блокировки учётной записи */
  disabled: Scalars['Boolean'];
  /** Имя для показа */
  displayName?: Maybe<Scalars['String']>;
  /** Адрес электронной почты */
  email?: Maybe<Scalars['String']>;
  /** Адрес электронной почты подтверждён администратором */
  emailConfirmed: Scalars['Boolean'];
  /** Идентификатор */
  id: Scalars['ID'];
  /** Номер телефона */
  phoneNumber?: Maybe<Scalars['String']>;
};

export type UserCanNotBeDeletedError = Error & {
  __typename?: 'UserCanNotBeDeletedError';
  message: Scalars['String'];
};

export type UserCommunicationError = Error & {
  __typename?: 'UserCommunicationError';
  message: Scalars['String'];
  reason: Scalars['String'];
};

export type UserNotFoundError = Error & {
  __typename?: 'UserNotFoundError';
  message: Scalars['String'];
  userId: Scalars['ID'];
};

export type UserPersistenceError = Error & {
  __typename?: 'UserPersistenceError';
  message: Scalars['String'];
};

/** Разрешения, относящиеся к действиям с пользователями */
export type UsersPermissions = {
  __typename?: 'UsersPermissions';
  /** Текущий пользователь может устанавливать номера телефона и адреса электронной почты */
  changeEmailAndPhoneNumber: Scalars['Boolean'];
  /** Текущий пользователь может устанавливать пароли учётных записей */
  changePassword: Scalars['Boolean'];
  /** Текущий пользователь может создавать учётные записи представителей фондов-партнёров */
  createAndEditFoundationRepresentative: Scalars['Boolean'];
  /** Текущий пользователь может создавать учётные записи модераторов */
  createAndEditModerator: Scalars['Boolean'];
  /** Текущий пользователь может создавать учётные записи системных администраторов */
  createAndEditSystemAdministrator: Scalars['Boolean'];
  /** Текущий пользователь может удалять учётные записи */
  delete: Scalars['Boolean'];
  /** Текущий пользователь может блокировать и разблокировать учётные записи */
  enableOrDisable: Scalars['Boolean'];
  /** Текущий пользователь может получать список учётных записей */
  list: Scalars['Boolean'];
  /** Текущий пользователь может просматривать информацию учётных записей (например, поля createdBy и updatedBy) */
  view: Scalars['Boolean'];
};

/** Ваучер */
export type Voucher = {
  __typename?: 'Voucher';
  /** Маркетинговая кампания, к которой принадлежит ваучер */
  campaign?: Maybe<Scalars['String']>;
  /** Код ваучера */
  code: Scalars['String'];
  /** Информация о создании ваучера */
  created: AuditMarker;
  id: Scalars['VoucherId'];
  /** Максимальное разрешённое количество применений */
  maximumUsageCount: Scalars['Int'];
  /** Максимальное разрешённое количество использования ваучера одним жертвователем */
  maximumUsageCountPerBenefactor: Scalars['Int'];
  /** Номинальная сумма ваучера */
  nominalAmount: Scalars['Int'];
  /** Дата и время окончания действия ваучера */
  notValidAfter?: Maybe<Scalars['DateTime']>;
  /** Дата и время начала действия ваучера */
  notValidBefore?: Maybe<Scalars['DateTime']>;
  /** Оставшееся количество использований ваучера */
  remainingUsageCount: Scalars['Int'];
  /** Название ваучера */
  title: Scalars['String'];
};

/** Информация об неудачном использовании ваучера */
export type VoucherFailedPaymentDetails = {
  __typename?: 'VoucherFailedPaymentDetails';
  /** Причина неудачной оплаты */
  reason: VoucherPaymentFailureReason;
  /** Ваучер */
  voucher?: Maybe<Voucher>;
};


/** Причина неудачной оплаты заказа ваучером */
export enum VoucherPaymentFailureReason {
  /** Номинал ваучера превышает требуемую сумму */
  ExcessAmount = 'EXCESS_AMOUNT',
  /** Номинал ваучера меньше требуемой суммы */
  InsufficientAmount = 'INSUFFICIENT_AMOUNT',
  /** Ваучер с указанным идентификатором не найден */
  NotFound = 'NOT_FOUND',
  /** Ваучер с указанным идентификатором недействителен */
  NotValid = 'NOT_VALID',
  /** Слишком много использований ваучера */
  UsageCountExceeded = 'USAGE_COUNT_EXCEEDED',
  /** Слишком много использований ваучера для данного жертвователя */
  UsageCountPerBenefactorExceeded = 'USAGE_COUNT_PER_BENEFACTOR_EXCEEDED'
}

/** Намерение использовать ваучер в качестве средства оплаты */
export type VoucherPaymentIntent = {
  __typename?: 'VoucherPaymentIntent';
  /** Ваучер, использующийся для оплаты */
  voucher?: Maybe<Voucher>;
};

/** Параметры намерения оплатить заказ ваучером */
export type VoucherPaymentIntentInput = {
  /** Идентификатор ваучера */
  voucherId: Scalars['VoucherId'];
};

/** Оплата ваучером */
export type VoucherPaymentMethod = PaymentMethod & {
  __typename?: 'VoucherPaymentMethod';
  /** Название способа оплаты */
  title: Scalars['String'];
};

/** Разрешения, относящиеся к действиям с ваучерами */
export type VouchersPermissions = {
  __typename?: 'VouchersPermissions';
  /** Текущий пользователь может управлять ваучерами */
  manage: Scalars['Boolean'];
  /** Текущий пользователь может просматривать подробную информацию о ваучере */
  viewDetails: Scalars['Boolean'];
};

/** Информация об успешном использовании ваучера */
export type VoucherSuccessfulPaymentDetails = {
  __typename?: 'VoucherSuccessfulPaymentDetails';
  /** Ваучер */
  voucher: Voucher;
};

/** Подопечный */
export type Ward = {
  __typename?: 'Ward';
  /** Список счетов-депозитов, средства которых можно расходовать на потребности данного подопечного */
  benefactorDepositAccounts: Array<BenefactorDepositAccountForSpecificWards>;
  /** Пожертвования, сделанные текущим жертвователем */
  benefactorDonations?: Maybe<BenefactorDonationsConnection>;
  /** Общая сумма пожертвований, сделанных текущим жертвователем */
  benefactorDonationsAmount?: Maybe<Scalars['Int']>;
  /** Агрегированные суммы и счётчики */
  countsAndAmounts: WardCountsAndAmounts;
  /** Аудит добавления */
  created: AuditMarker;
  /** Информация о деактивации подопечного */
  deactivationInformation?: Maybe<WardDeactivationInformation>;
  id: Scalars['WardId'];
  /** Подопечному требуется немедленная помощь */
  immediateHelpRequired: Scalars['Boolean'];
  /** Список потребностей */
  needs: Array<Need>;
  /** Частная информация о подопечном */
  privateInformation?: Maybe<WardPrivateInformation>;
  /** Общедоступная информация о подопечном */
  publicInformation: WardPublicInformation;
  /** Подопечный доступен для просмотра жертвователями */
  published: Scalars['Boolean'];
  /** Информация о регулярных потребностях */
  regularNeeds: WardRegularNeeds;
  /** Счёт для сбора средств на регулярные потребности */
  regularNeedsAccount: RegularNeedsAccount;
  /** Разрешено ли переносить подписки на данного подопечного? */
  subscriptionTransferEnabled: Scalars['Boolean'];
  /** Подписки */
  subscriptions?: Maybe<WardSubscriptionsConnection>;
  /** Список помогающих подопечному */
  supporters?: Maybe<SupportersConnection>;
  /** Аудит изменения */
  updated: AuditMarker;
};


/** Подопечный */
export type WardBenefactorDonationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Подопечный */
export type WardSubscriptionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  includeCancelled?: Scalars['Boolean'];
  last?: Maybe<Scalars['Int']>;
};


/** Подопечный */
export type WardSupportersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Агрегированные суммы и счётчики */
export type WardCountsAndAmounts = {
  __typename?: 'WardCountsAndAmounts';
  /** Количество действующих потребностей */
  activeNeedsCount: Scalars['Int'];
  /** Количество средств, собранных для всех разовых потребностей */
  activeOneTimeNeedsAmount: Scalars['Int'];
  /** Количество действующих разовых потребностей */
  activeOneTimeNeedsCount: Scalars['Int'];
  /** Сумма средств, требуемая для удовлетворения действующих разовых потребностей */
  activeOneTimeNeedsRequiredAmount: Scalars['Int'];
  /** Количество действующих регулярных потребностей */
  activeRegularNeedsCount: Scalars['Int'];
  /** Сумма средств, требуемая для удовлетворения действующих регулярных потребностей */
  activeRegularNeedsRequiredAmount: Scalars['Int'];
  /** Общее количество средств от действующих подписок */
  activeSubscriptionsAmount: Scalars['Int'];
  /** Общее количество действующих подписок */
  activeSubscriptionsCount: Scalars['Int'];
  /** Ожидаемая сумма на счёте-кошельке */
  estimatedWalletAmount: Scalars['Int'];
  /** Максимальное количество средств, допустимое для хранения на счёте регулярных потребностей */
  maximumAllowedRegularNeedsAmount: Scalars['Int'];
  /** Количество средств, допустимых для разового пожертвования на регулярные потребности */
  maximumAllowedRegularNeedsDonationAmount: Scalars['Int'];
  /** Нехватка средств подписки (ActiveRegularNeedsAmount - ActiveSubscriptionsAmount) */
  subscriptionsDeficit: Scalars['Int'];
  /** Общее количество потребностей, действующих и завершённых */
  totalNeedsCount: Scalars['Int'];
  /** Общее количество разовых потребностей, действующих и завершённых */
  totalOneTimeNeedsCount: Scalars['Int'];
  /** Общее количество регулярных потребностей, действующих и завершённых */
  totalRegularNeedsCount: Scalars['Int'];
  /** Общее количество средств от всех подписок, действующих и отменённых */
  totalSubscriptionsAmount: Scalars['Int'];
  /** Общее количество подписок, действующих и отменённых */
  totalSubscriptionsCount: Scalars['Int'];
  /** Количество средств не от подписок на счёте-кошельке */
  walletNonSubscriptionDonationsAmount: Scalars['Int'];
  /** Количество средств от подписок на счёте-кошельке */
  walletSubscriptionDonationsAmount: Scalars['Int'];
};

/** Агрегированные суммы и счётчики */
export type WardCountsAndAmountsFilterInput = {
  /** Количество действующих потребностей */
  activeNeedsCount?: Maybe<IntOperationFilterInput>;
  /** Количество средств, собранных для всех разовых потребностей */
  activeOneTimeNeedsAmount?: Maybe<IntOperationFilterInput>;
  /** Количество действующих разовых потребностей */
  activeOneTimeNeedsCount?: Maybe<IntOperationFilterInput>;
  /** Сумма средств, требуемая для удовлетворения действующих разовых потребностей */
  activeOneTimeNeedsRequiredAmount?: Maybe<IntOperationFilterInput>;
  /** Количество действующих регулярных потребностей */
  activeRegularNeedsCount?: Maybe<IntOperationFilterInput>;
  /** Сумма средств, требуемая для удовлетворения действующих регулярных потребностей */
  activeRegularNeedsRequiredAmount?: Maybe<IntOperationFilterInput>;
  /** Общее количество средств от действующих подписок */
  activeSubscriptionsAmount?: Maybe<IntOperationFilterInput>;
  /** Общее количество действующих подписок */
  activeSubscriptionsCount?: Maybe<IntOperationFilterInput>;
  and?: Maybe<Array<WardCountsAndAmountsFilterInput>>;
  /** Ожидаемая сумма на счёте-кошельке */
  estimatedWalletAmount?: Maybe<IntOperationFilterInput>;
  /** Максимальное количество средств, допустимое для хранения на счёте регулярных потребностей */
  maximumAllowedRegularNeedsAmount?: Maybe<IntOperationFilterInput>;
  /** Количество средств, допустимых для разового пожертвования на регулярные потребности */
  maximumAllowedRegularNeedsDonationAmount?: Maybe<IntOperationFilterInput>;
  or?: Maybe<Array<WardCountsAndAmountsFilterInput>>;
  /** Нехватка средств подписки (ActiveRegularNeedsAmount - ActiveSubscriptionsAmount) */
  subscriptionsDeficit?: Maybe<IntOperationFilterInput>;
  /** Общее количество потребностей, действующих и завершённых */
  totalNeedsCount?: Maybe<IntOperationFilterInput>;
  /** Общее количество разовых потребностей, действующих и завершённых */
  totalOneTimeNeedsCount?: Maybe<IntOperationFilterInput>;
  /** Общее количество регулярных потребностей, действующих и завершённых */
  totalRegularNeedsCount?: Maybe<IntOperationFilterInput>;
  /** Общее количество средств от всех подписок, действующих и отменённых */
  totalSubscriptionsAmount?: Maybe<IntOperationFilterInput>;
  /** Общее количество подписок, действующих и отменённых */
  totalSubscriptionsCount?: Maybe<IntOperationFilterInput>;
  /** Количество средств не от подписок на счёте-кошельке */
  walletNonSubscriptionDonationsAmount?: Maybe<IntOperationFilterInput>;
  /** Количество средств от подписок на счёте-кошельке */
  walletSubscriptionDonationsAmount?: Maybe<IntOperationFilterInput>;
};

/** Агрегированные суммы и счётчики */
export type WardCountsAndAmountsSortInput = {
  /** Количество действующих потребностей */
  activeNeedsCount?: Maybe<SortEnumType>;
  /** Количество средств, собранных для всех разовых потребностей */
  activeOneTimeNeedsAmount?: Maybe<SortEnumType>;
  /** Количество действующих разовых потребностей */
  activeOneTimeNeedsCount?: Maybe<SortEnumType>;
  /** Сумма средств, требуемая для удовлетворения действующих разовых потребностей */
  activeOneTimeNeedsRequiredAmount?: Maybe<SortEnumType>;
  /** Количество действующих регулярных потребностей */
  activeRegularNeedsCount?: Maybe<SortEnumType>;
  /** Сумма средств, требуемая для удовлетворения действующих регулярных потребностей */
  activeRegularNeedsRequiredAmount?: Maybe<SortEnumType>;
  /** Общее количество средств от действующих подписок */
  activeSubscriptionsAmount?: Maybe<SortEnumType>;
  /** Общее количество действующих подписок */
  activeSubscriptionsCount?: Maybe<SortEnumType>;
  /** Ожидаемая сумма на счёте-кошельке */
  estimatedWalletAmount?: Maybe<SortEnumType>;
  /** Максимальное количество средств, допустимое для хранения на счёте регулярных потребностей */
  maximumAllowedRegularNeedsAmount?: Maybe<SortEnumType>;
  /** Количество средств, допустимых для разового пожертвования на регулярные потребности */
  maximumAllowedRegularNeedsDonationAmount?: Maybe<SortEnumType>;
  /** Нехватка средств подписки (ActiveRegularNeedsAmount - ActiveSubscriptionsAmount) */
  subscriptionsDeficit?: Maybe<SortEnumType>;
  /** Общее количество потребностей, действующих и завершённых */
  totalNeedsCount?: Maybe<SortEnumType>;
  /** Общее количество разовых потребностей, действующих и завершённых */
  totalOneTimeNeedsCount?: Maybe<SortEnumType>;
  /** Общее количество регулярных потребностей, действующих и завершённых */
  totalRegularNeedsCount?: Maybe<SortEnumType>;
  /** Общее количество средств от всех подписок, действующих и отменённых */
  totalSubscriptionsAmount?: Maybe<SortEnumType>;
  /** Общее количество подписок, действующих и отменённых */
  totalSubscriptionsCount?: Maybe<SortEnumType>;
  /** Количество средств не от подписок на счёте-кошельке */
  walletNonSubscriptionDonationsAmount?: Maybe<SortEnumType>;
  /** Количество средств от подписок на счёте-кошельке */
  walletSubscriptionDonationsAmount?: Maybe<SortEnumType>;
};

/** Информация о деактивации подопечного */
export type WardDeactivationInformation = {
  __typename?: 'WardDeactivationInformation';
  /** Дата деактивации */
  date: Scalars['DateTime'];
  /** Причина деактивации */
  reason: WardDeactivationReason;
};

/** Информация о деактивации подопечного */
export type WardDeactivationInformationSortInput = {
  /** Дата деактивации */
  date?: Maybe<SortEnumType>;
  /** Причина деактивации */
  reason?: Maybe<SortEnumType>;
};

/** Причина деактивации подопечного */
export enum WardDeactivationReason {
  /** Смерть */
  Death = 'DEATH',
  /** Отключение */
  Discontinuation = 'DISCONTINUATION',
  /** Отказ от получения помощи */
  Refusal = 'REFUSAL'
}

/** Подписка на потребности подопечного */
export type WardDonationSubscription = DonationSubscription & {
  __typename?: 'WardDonationSubscription';
  /** Сумма подписки */
  amount: Scalars['Int'];
  /** Жертвователь */
  benefactor: Benefactor;
  /** Причина отмены подписки */
  cancellationReason?: Maybe<DonationSubscriptionCancellationReason>;
  /** Дата отмены подписки */
  cancelledAt?: Maybe<Scalars['DateTime']>;
  /** Размер комиссии */
  commissionAmount?: Maybe<Scalars['Int']>;
  /** Процент комиссии */
  commissionPercentage?: Maybe<Scalars['Int']>;
  /** Дата создания подписки */
  createdAt: Scalars['DateTime'];
  id: Scalars['DonationSubscriptionId'];
  /** Покрывается ли комиссия жертвователем? */
  isCommissionCoveredByBenefactor?: Maybe<Scalars['Boolean']>;
  /** Была ли подписка перенесена? */
  isTransferred: Scalars['Boolean'];
  /** Заказ, на основе которого создана подписка */
  order: Order;
  /** Данные переноса подписки на другого подопечного */
  transfer?: Maybe<WardSubscriptionTransfer>;
  /** Подопечный */
  ward: Ward;
};

/** Подопечный */
export type WardFilterInput = {
  and?: Maybe<Array<WardFilterInput>>;
  /** Аудит добавления */
  created?: Maybe<AuditMarkerFilterInput>;
  /** Подопечному требуется немедленная помощь */
  immediateHelpRequired?: Maybe<BooleanOperationFilterInput>;
  or?: Maybe<Array<WardFilterInput>>;
  /** Частная информация о подопечном */
  privateInformation?: Maybe<WardPrivateInformationFilterInput>;
  /** Общедоступная информация о подопечном */
  publicInformation?: Maybe<WardPublicInformationFilterInput>;
  /** Подопечный доступен для просмотра жертвователями */
  published?: Maybe<BooleanOperationFilterInput>;
  /** Аудит изменения */
  updated?: Maybe<AuditMarkerFilterInput>;
};

/** Данные подопечного, использующиеся для финансовых операций */
export type WardFinances = {
  __typename?: 'WardFinances';
  /** Город подопечного */
  city: Scalars['String'];
  /** Агрегированные суммы и счётчики */
  countsAndAmounts: WardCountsAndAmounts;
  /** Дата и время деактивации подопечного */
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  ward: Ward;
};

/** Данные подопечного, использующиеся для финансовых операций */
export type WardFinancesFilterInput = {
  and?: Maybe<Array<WardFinancesFilterInput>>;
  /** Город подопечного */
  city?: Maybe<StringOperationFilterInput>;
  /** Агрегированные суммы и счётчики */
  countsAndAmounts?: Maybe<WardCountsAndAmountsFilterInput>;
  /** Дата и время деактивации подопечного */
  deactivatedAt?: Maybe<DateTimeOperationFilterInput>;
  or?: Maybe<Array<WardFinancesFilterInput>>;
};

/** Данные подопечного, использующиеся для финансовых операций */
export type WardFinancesSortInput = {
  /** Город подопечного */
  city?: Maybe<SortEnumType>;
  /** Агрегированные суммы и счётчики */
  countsAndAmounts?: Maybe<WardCountsAndAmountsSortInput>;
  /** Дата и время деактивации подопечного */
  deactivatedAt?: Maybe<SortEnumType>;
};


/** Намерение совершить пожертвование на разовую потребность подопечного */
export type WardOneTimeNeedDonationIntent = {
  __typename?: 'WardOneTimeNeedDonationIntent';
  /** Размер пожертвования */
  amount: Scalars['Int'];
  /** Размер комиссии */
  commissionPercentage: Scalars['Int'];
  /** Покрывает ли комиссию жертвователь? */
  isCommissionCoveredByBenefactor: Scalars['Boolean'];
  /** Потребность */
  need: Need;
};

/** Намерение совершить пожертвование на разовую потребность подопечного */
export type WardOneTimeNeedDonationIntentInput = {
  /** Размер пожертвования */
  amount: Scalars['Int'];
  /** Процент комиссии */
  commissionPercentage: Scalars['Int'];
  /** Готов ли жертвователь компенсировать комиссию? */
  isCommissionCoveredByBenefactor: Scalars['Boolean'];
  /** Идентификатор потребности */
  needId: Scalars['NeedId'];
};

/** Частная информация о подопечном */
export type WardPrivateInformation = {
  __typename?: 'WardPrivateInformation';
  /** Адрес */
  address: Address;
  /** Частные заметки о подопечном */
  comments?: Maybe<Scalars['String']>;
  /** Группа инвалидности */
  disabilityGroup?: Maybe<Scalars['Int']>;
  /** Файлы-документы подопечного */
  documents: Array<Document>;
  /** Подъезд */
  entranceNumber?: Maybe<Scalars['String']>;
  /** Этаж */
  flatFloor?: Maybe<Scalars['String']>;
  /** Семейный статус */
  maritalStatus?: Maybe<MaritalStatus>;
  /** Паспортные данные */
  passport: Passport;
  /** Номер телефона */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Профессия */
  profession?: Maybe<Scalars['String']>;
  /** Звание или учёная степень */
  rank?: Maybe<Scalars['String']>;
};

/** Частная информация о подопечном */
export type WardPrivateInformationFilterInput = {
  /** Адрес */
  address?: Maybe<AddressFilterInput>;
  and?: Maybe<Array<WardPrivateInformationFilterInput>>;
  or?: Maybe<Array<WardPrivateInformationFilterInput>>;
};

/** Частная информация о подопечном */
export type WardPrivateInformationSortInput = {
  /** Адрес */
  address?: Maybe<AddressSortInput>;
  /** Частные заметки о подопечном */
  comments?: Maybe<SortEnumType>;
  /** Группа инвалидности */
  disabilityGroup?: Maybe<SortEnumType>;
  /** Подъезд */
  entranceNumber?: Maybe<SortEnumType>;
  /** Этаж */
  flatFloor?: Maybe<SortEnumType>;
  /** Семейный статус */
  maritalStatus?: Maybe<SortEnumType>;
  /** Паспортные данные */
  passport?: Maybe<PassportSortInput>;
  /** Номер телефона */
  phoneNumber?: Maybe<SortEnumType>;
  /** Профессия */
  profession?: Maybe<SortEnumType>;
  /** Звание или учёная степень */
  rank?: Maybe<SortEnumType>;
};

/** Общедоступная информация о подопечном */
export type WardPublicInformation = {
  __typename?: 'WardPublicInformation';
  /** Категории */
  categories: Array<Category>;
  /** Населённый пункт проживания */
  city: Scalars['String'];
  /** Дата рождения */
  dateOfBirth: Scalars['Date'];
  /** Дата смерти */
  dateOfDeath?: Maybe<Scalars['Date']>;
  /** Пол */
  gender: Gender;
  /** Имя */
  name: Nomen;
  /** Фотография подопечного */
  photo: FileInformation;
  /** Широта места проживания подопечного */
  publicLatitude: Scalars['Float'];
  /** Долгота места проживания подопечного */
  publicLongitude: Scalars['Float'];
  /** История подопечного */
  story: Scalars['String'];
};

/** Общедоступная информация о подопечном */
export type WardPublicInformationFilterInput = {
  and?: Maybe<Array<WardPublicInformationFilterInput>>;
  /** Категории */
  categories?: Maybe<ListCategoryIdOperationFilterInput>;
  /** Населённый пункт проживания */
  city?: Maybe<StringOperationFilterInput>;
  /** Дата рождения */
  dateOfBirth?: Maybe<DateOperationFilterInput>;
  /** Дата смерти */
  dateOfDeath?: Maybe<DateOperationFilterInput>;
  /** Пол */
  gender?: Maybe<GenderOperationFilterInput>;
  /** Имя */
  name?: Maybe<NomenFilterInput>;
  or?: Maybe<Array<WardPublicInformationFilterInput>>;
  /** История подопечного */
  story?: Maybe<StringOperationFilterInput>;
};

/** Общедоступная информация о подопечном */
export type WardPublicInformationSortInput = {
  /** Населённый пункт проживания */
  city?: Maybe<SortEnumType>;
  /** Дата рождения */
  dateOfBirth?: Maybe<SortEnumType>;
  /** Дата смерти */
  dateOfDeath?: Maybe<SortEnumType>;
  /** Пол */
  gender?: Maybe<SortEnumType>;
  /** Имя */
  name?: Maybe<NomenSortInput>;
  /** Широта места проживания подопечного */
  publicLatitude?: Maybe<SortEnumType>;
  /** Долгота места проживания подопечного */
  publicLongitude?: Maybe<SortEnumType>;
  /** История подопечного */
  story?: Maybe<SortEnumType>;
};

/** Информация о регулярных потребностях подопечного */
export type WardRegularNeeds = {
  __typename?: 'WardRegularNeeds';
  /** Пожертвования, сделанные текущим жертвователем */
  benefactorDonations?: Maybe<BenefactorDonationsConnection>;
  /** Общая сумма пожертвований, сделанных текущим жертвователем */
  benefactorDonationsAmount?: Maybe<Scalars['Int']>;
  /** Количество средств от подписок, созданных до текущего месяца */
  existingSubscriptionsAmount: Scalars['Int'];
  /** Количество средств от подписок, созданных в текущем месяце */
  newSubscriptionsAmount: Scalars['Int'];
  /** Общая сумма всех разовых пожертвований на регулярные потребности */
  oneTimeDonationsAmount: Scalars['Int'];
  /** Кто помогает */
  supporters?: Maybe<SupportersConnection>;
};


/** Информация о регулярных потребностях подопечного */
export type WardRegularNeedsBenefactorDonationsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Информация о регулярных потребностях подопечного */
export type WardRegularNeedsSupportersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  onlyCurrentMonth?: Maybe<Scalars['Boolean']>;
};

/** Намерение совершить пожертвование на регулярные потребности подопечного */
export type WardRegularNeedsDonationIntent = {
  __typename?: 'WardRegularNeedsDonationIntent';
  /** Размер пожертвования */
  amount: Scalars['Int'];
  /** Размер комиссии */
  commissionPercentage: Scalars['Int'];
  /** Покрывает ли комиссию жертвователь? */
  isCommissionCoveredByBenefactor: Scalars['Boolean'];
  /** Подопечный */
  ward: Ward;
};

/** Намерение совершить пожертвование на регулярные потребности подопечного */
export type WardRegularNeedsDonationIntentInput = {
  /** Размер пожертвования */
  amount: Scalars['Int'];
  /** Процент комиссии */
  commissionPercentage: Scalars['Int'];
  /** Готов ли жертвователь компенсировать комиссию? */
  isCommissionCoveredByBenefactor: Scalars['Boolean'];
  /** Идентификатор подопечного */
  wardId: Scalars['WardId'];
};

/** A connection to a list of items. */
export type WardsConnection = {
  __typename?: 'WardsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<WardsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Ward>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** Результат поиска (подопечный) */
export type WardSearchResultItem = SearchResultItem & {
  __typename?: 'WardSearchResultItem';
  /** Категории */
  categories: Array<Category>;
  /** Город */
  city?: Maybe<Scalars['String']>;
  /** Собранная сумма */
  collectedAmount: Scalars['Int'];
  /** Требуется ли помощь? */
  isHelpNeeded: Scalars['Boolean'];
  /** Требуемая сумма */
  requiredAmount?: Maybe<Scalars['Int']>;
  /** Подопечный */
  ward: Ward;
};

/** An edge in a connection. */
export type WardsEdge = {
  __typename?: 'WardsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Ward;
};

/** A connection to a list of items. */
export type WardsFinancesConnection = {
  __typename?: 'WardsFinancesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<WardsFinancesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<WardFinances>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type WardsFinancesEdge = {
  __typename?: 'WardsFinancesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: WardFinances;
};

/** Подопечный */
export type WardSortInput = {
  /** Аудит добавления */
  created?: Maybe<AuditMarkerSortInput>;
  /** Информация о деактивации подопечного */
  deactivationInformation?: Maybe<WardDeactivationInformationSortInput>;
  id?: Maybe<SortEnumType>;
  /** Подопечному требуется немедленная помощь */
  immediateHelpRequired?: Maybe<SortEnumType>;
  /** Частная информация о подопечном */
  privateInformation?: Maybe<WardPrivateInformationSortInput>;
  /** Общедоступная информация о подопечном */
  publicInformation?: Maybe<WardPublicInformationSortInput>;
  /** Подопечный доступен для просмотра жертвователями */
  published?: Maybe<SortEnumType>;
  /** Аудит изменения */
  updated?: Maybe<AuditMarkerSortInput>;
};

/** Разрешения, относящиеся к действиям подопечными */
export type WardsPermissions = {
  __typename?: 'WardsPermissions';
  /** Текущий пользователь может добавлять в систему подопечных */
  create: Scalars['Boolean'];
  /** Текущий пользователь может изменять информацию о подопечных */
  edit: Scalars['Boolean'];
  /** Текущий пользователь может управлять потребностями подопечного */
  manageNeeds: Scalars['Boolean'];
  /** Текущий пользователь может просматривать закрытую информацию подопечных */
  viewPrivateInformation: Scalars['Boolean'];
};

/** Намерение совершить пожертвование в резерв подопечных */
export type WardsReserveDonationIntent = {
  __typename?: 'WardsReserveDonationIntent';
  /** Размер пожертвования */
  amount: Scalars['Int'];
  /** Размер комиссии */
  commissionPercentage: Scalars['Int'];
  /** Покрывает ли комиссию жертвователь? */
  isCommissionCoveredByBenefactor: Scalars['Boolean'];
};

/** Намерение совершить пожертвование в резерв подопечных */
export type WardsReserveDonationIntentInput = {
  /** Размер пожертвования */
  amount: Scalars['Int'];
  /** Процент комиссии */
  commissionPercentage: Scalars['Int'];
  /** Готов ли жертвователь компенсировать комиссию? */
  isCommissionCoveredByBenefactor: Scalars['Boolean'];
};

/** Подписка на резервный фонд подопечных */
export type WardsReserveDonationSubscription = DonationSubscription & {
  __typename?: 'WardsReserveDonationSubscription';
  /** Сумма подписки */
  amount: Scalars['Int'];
  /** Жертвователь */
  benefactor: Benefactor;
  /** Причина отмены подписки */
  cancellationReason?: Maybe<DonationSubscriptionCancellationReason>;
  /** Дата отмены подписки */
  cancelledAt?: Maybe<Scalars['DateTime']>;
  /** Размер комиссии */
  commissionAmount: Scalars['Int'];
  /** Процент комиссии */
  commissionPercentage: Scalars['Int'];
  /** Дата создания подписки */
  createdAt: Scalars['DateTime'];
  id: Scalars['DonationSubscriptionId'];
  /** Покрывается ли комиссия жертвователем? */
  isCommissionCoveredByBenefactor: Scalars['Boolean'];
  /** Заказ, на основе которого создана подписка */
  order: Order;
};

export type WardsReserveSubscriptionChargeItem = SubscriptionChargeItem & {
  __typename?: 'WardsReserveSubscriptionChargeItem';
  /** Размер подписки */
  amount: Scalars['Int'];
  /** Размер комиссии */
  commissionAmount: Scalars['Int'];
};

/** Намерение совершать регулярные пожертвования в резервный фонд подопечных */
export type WardsReserveSubscriptionIntent = {
  __typename?: 'WardsReserveSubscriptionIntent';
  /** Размер подписки */
  amount: Scalars['Int'];
  /** Размер комиссии */
  commissionPercentage: Scalars['Int'];
  /** Покрывает ли комиссию жертвователь? */
  isCommissionCoveredByBenefactor: Scalars['Boolean'];
};

/** Намерение совершать регулярные пожертвования в резервный фонд подопечных */
export type WardsReserveSubscriptionIntentInput = {
  /** Размер подписки */
  amount: Scalars['Int'];
  /** Процент комиссии */
  commissionPercentage: Scalars['Int'];
  /** Готов ли жертвователь компенсировать комиссию? */
  isCommissionCoveredByBenefactor: Scalars['Boolean'];
};

/** Намерение подписаться на регулярные потребности указанных подопечных */
export type WardsSubscriptionIntent = {
  __typename?: 'WardsSubscriptionIntent';
  /** Размер комиссии */
  commissionPercentage: Scalars['Int'];
  /** Покрывает ли комиссию жертвователь? */
  isCommissionCoveredByBenefactor: Scalars['Boolean'];
  /** Список подопечных и размеров подписки */
  wardsSubscriptionsAmounts: Array<WardSubscriptionAmount>;
};

/** Намерение подписаться на регулярные потребности указанных подопечных */
export type WardsSubscriptionIntentInput = {
  /** Процент комиссии */
  commissionPercentage: Scalars['Int'];
  /** Готов ли жертвователь компенсировать комиссию? */
  isCommissionCoveredByBenefactor: Scalars['Boolean'];
  /** Список подопечных и размеров подписки */
  wardsSubscriptionsAmounts: Array<WardSubscriptionAmountInput>;
};

/** Подопечный и сумма подписки */
export type WardSubscriptionAmount = {
  __typename?: 'WardSubscriptionAmount';
  /** Сумма подписки */
  amount: Scalars['Int'];
  /** Подопечный */
  ward: Ward;
};

/** Параметры подписки на подопечного */
export type WardSubscriptionAmountInput = {
  /** Размер пожертвования или подписки. */
  amount: Scalars['Int'];
  /** Идентификатор подопечного. */
  wardId: Scalars['WardId'];
};

export type WardSubscriptionChargeItem = SubscriptionChargeItem & {
  __typename?: 'WardSubscriptionChargeItem';
  /** Размер подписки */
  amount: Scalars['Int'];
  /** Размер комиссии */
  commissionAmount: Scalars['Int'];
  /** Подопечный */
  ward: Ward;
};

/** A connection to a list of items. */
export type WardSubscriptionsConnection = {
  __typename?: 'WardSubscriptionsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<WardSubscriptionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<WardDonationSubscription>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type WardSubscriptionsEdge = {
  __typename?: 'WardSubscriptionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: WardDonationSubscription;
};

/** Перенос подписки от умершего подопечного */
export type WardSubscriptionTransfer = {
  __typename?: 'WardSubscriptionTransfer';
  /** Дата отправки следующего оповещения */
  nextNotificationAt: Scalars['DateTime'];
  /** Дата отправки последнего оповещения */
  notificationSentAt: Scalars['DateTime'];
  /** Количество отправленных оповещений */
  sentNotificationsCount: Scalars['Int'];
  /** Дата начала переноса подписки */
  transferInitiatedAt: Scalars['DateTime'];
};

export type AddressSuggestionsQueryVariables = Exact<{
  mode: AddressSuggestionMode;
  count?: Maybe<Scalars['Int']>;
  query: Scalars['String'];
}>;


export type AddressSuggestionsQuery = (
  { __typename?: 'Query' }
  & { addressSuggestions: Array<(
    { __typename?: 'AddressSuggestion' }
    & Pick<AddressSuggestion, 'displayText' | 'fullAddress' | 'hasCoordinates' | 'hasCity' | 'hasHouse' | 'hasRegion'>
  )> }
);

export type SkillCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type SkillCategoriesQuery = (
  { __typename?: 'Query' }
  & { skillCategories: Array<(
    { __typename?: 'SkillCategory' }
    & Pick<SkillCategory, 'id' | 'title'>
    & { skills: Array<(
      { __typename?: 'Skill' }
      & Pick<Skill, 'id' | 'title' | 'description' | 'portfolioIsRequired'>
    )> }
  )> }
);

export type PrepareFilesUploadMutationVariables = Exact<{
  input: PrepareFilesUploadInput;
}>;


export type PrepareFilesUploadMutation = (
  { __typename?: 'Mutation' }
  & { prepareFilesUpload: (
    { __typename?: 'PrepareFilesUploadPayload' }
    & { files?: Maybe<Array<(
      { __typename?: 'FileUploadInformation' }
      & Pick<FileUploadInformation, 'fileName' | 'id' | 'uploadUrl' | 'url'>
    )>> }
  ) }
);

export type SendOneTimeNeedReportMutationVariables = Exact<{
  input: SendOneTimeNeedReportInput;
}>;


export type SendOneTimeNeedReportMutation = (
  { __typename?: 'Mutation' }
  & { sendOneTimeNeedReport: (
    { __typename?: 'SendOneTimeNeedReportPayload' }
    & Pick<SendOneTimeNeedReportPayload, 'success'>
  ) }
);

export type SendProjectExpenseReportMutationVariables = Exact<{
  input: SendProjectExpenseReportInput;
}>;


export type SendProjectExpenseReportMutation = (
  { __typename?: 'Mutation' }
  & { sendProjectExpenseReport: (
    { __typename?: 'SendProjectExpenseReportPayload' }
    & Pick<SendProjectExpenseReportPayload, 'success'>
  ) }
);

export type SendRegularNeedPeriodReportMutationVariables = Exact<{
  input: SendRegularNeedPeriodReportInput;
}>;


export type SendRegularNeedPeriodReportMutation = (
  { __typename?: 'Mutation' }
  & { sendRegularNeedPeriodReport: (
    { __typename?: 'SendRegularNeedPeriodReportPayload' }
    & Pick<SendRegularNeedPeriodReportPayload, 'success'>
  ) }
);

export type TransferFundsToAccountMutationVariables = Exact<{
  input: TransferFundsToAccountInput;
}>;


export type TransferFundsToAccountMutation = (
  { __typename?: 'Mutation' }
  & { transferFundsToAccount: (
    { __typename?: 'TransferFundsToAccountPayload' }
    & { result?: Maybe<(
      { __typename?: 'TransferFundsToAccountResult' }
      & { sourceAccount: (
        { __typename?: 'BenefactorDepositAccountForSpecificWards' }
        & Pick<BenefactorDepositAccountForSpecificWards, 'id'>
      ) | (
        { __typename?: 'RegularNeedsAccount' }
        & Pick<RegularNeedsAccount, 'id'>
      ) | (
        { __typename?: 'BenefactorDepositAccountForAllWards' }
        & Pick<BenefactorDepositAccountForAllWards, 'id'>
      ) | (
        { __typename?: 'BenefactorDepositAccountForProjectNeeds' }
        & Pick<BenefactorDepositAccountForProjectNeeds, 'id'>
      ) | (
        { __typename?: 'BenefactorDepositAccountWithoutConditions' }
        & Pick<BenefactorDepositAccountWithoutConditions, 'id'>
      ) | (
        { __typename?: 'OneTimeNeedAccount' }
        & Pick<OneTimeNeedAccount, 'id'>
      ) | (
        { __typename?: 'ProjectExpenseAccount' }
        & Pick<ProjectExpenseAccount, 'id'>
      ) | (
        { __typename?: 'RegularNeedPeriodAccount' }
        & Pick<RegularNeedPeriodAccount, 'id'>
      ) | (
        { __typename?: 'SpecialProjectAccount' }
        & Pick<SpecialProjectAccount, 'id'>
      ) | (
        { __typename?: 'SystemAccount' }
        & Pick<SystemAccount, 'id'>
      ), targetAccount: (
        { __typename?: 'BenefactorDepositAccountForSpecificWards' }
        & Pick<BenefactorDepositAccountForSpecificWards, 'id'>
      ) | (
        { __typename?: 'RegularNeedsAccount' }
        & Pick<RegularNeedsAccount, 'id'>
      ) | (
        { __typename?: 'BenefactorDepositAccountForAllWards' }
        & Pick<BenefactorDepositAccountForAllWards, 'id'>
      ) | (
        { __typename?: 'BenefactorDepositAccountForProjectNeeds' }
        & Pick<BenefactorDepositAccountForProjectNeeds, 'id'>
      ) | (
        { __typename?: 'BenefactorDepositAccountWithoutConditions' }
        & Pick<BenefactorDepositAccountWithoutConditions, 'id'>
      ) | (
        { __typename?: 'OneTimeNeedAccount' }
        & Pick<OneTimeNeedAccount, 'id'>
      ) | (
        { __typename?: 'ProjectExpenseAccount' }
        & Pick<ProjectExpenseAccount, 'id'>
      ) | (
        { __typename?: 'RegularNeedPeriodAccount' }
        & Pick<RegularNeedPeriodAccount, 'id'>
      ) | (
        { __typename?: 'SpecialProjectAccount' }
        & Pick<SpecialProjectAccount, 'id'>
      ) | (
        { __typename?: 'SystemAccount' }
        & Pick<SystemAccount, 'id'>
      ) }
    )>, errors?: Maybe<Array<(
      { __typename?: 'FundsTransferProhibitedError' }
      & Pick<FundsTransferProhibitedError, 'message'>
    )>> }
  ) }
);

type Account_BenefactorDepositAccountForSpecificWards_Fragment = (
  { __typename?: 'BenefactorDepositAccountForSpecificWards' }
  & Pick<BenefactorDepositAccountForSpecificWards, 'title' | 'id' | 'updatedAt' | 'amount' | 'closedAt'>
  & { benefactor: (
    { __typename?: 'Benefactor' }
    & AccountBenefactorInformationFragment
  ) }
);

type Account_RegularNeedsAccount_Fragment = (
  { __typename: 'RegularNeedsAccount' }
  & Pick<RegularNeedsAccount, 'amount' | 'id' | 'updatedAt' | 'closedAt'>
  & { ward: (
    { __typename?: 'Ward' }
    & AccountWardInformationFragment
  ) }
);

type Account_BenefactorDepositAccountForAllWards_Fragment = (
  { __typename?: 'BenefactorDepositAccountForAllWards' }
  & Pick<BenefactorDepositAccountForAllWards, 'id' | 'updatedAt' | 'amount' | 'closedAt'>
  & { benefactor: (
    { __typename?: 'Benefactor' }
    & AccountBenefactorInformationFragment
  ) }
);

type Account_BenefactorDepositAccountForProjectNeeds_Fragment = (
  { __typename?: 'BenefactorDepositAccountForProjectNeeds' }
  & Pick<BenefactorDepositAccountForProjectNeeds, 'id' | 'updatedAt' | 'amount' | 'closedAt'>
  & { benefactor: (
    { __typename?: 'Benefactor' }
    & AccountBenefactorInformationFragment
  ) }
);

type Account_BenefactorDepositAccountWithoutConditions_Fragment = (
  { __typename?: 'BenefactorDepositAccountWithoutConditions' }
  & Pick<BenefactorDepositAccountWithoutConditions, 'id' | 'updatedAt' | 'amount' | 'closedAt'>
  & { benefactor: (
    { __typename?: 'Benefactor' }
    & AccountBenefactorInformationFragment
  ) }
);

type Account_OneTimeNeedAccount_Fragment = (
  { __typename?: 'OneTimeNeedAccount' }
  & Pick<OneTimeNeedAccount, 'id' | 'updatedAt' | 'amount' | 'closedAt'>
);

type Account_ProjectExpenseAccount_Fragment = (
  { __typename?: 'ProjectExpenseAccount' }
  & Pick<ProjectExpenseAccount, 'id' | 'amount' | 'updatedAt' | 'closedAt'>
  & { projectExpense: (
    { __typename?: 'ProjectExpense' }
    & Pick<ProjectExpense, 'id'>
  ) }
);

type Account_RegularNeedPeriodAccount_Fragment = (
  { __typename?: 'RegularNeedPeriodAccount' }
  & Pick<RegularNeedPeriodAccount, 'id' | 'updatedAt' | 'amount' | 'closedAt'>
  & { transferSources: Array<(
    { __typename?: 'BenefactorDepositAccountForSpecificWards' }
    & Pick<BenefactorDepositAccountForSpecificWards, 'id'>
  ) | (
    { __typename?: 'RegularNeedsAccount' }
    & Pick<RegularNeedsAccount, 'id'>
  ) | (
    { __typename?: 'BenefactorDepositAccountForAllWards' }
    & Pick<BenefactorDepositAccountForAllWards, 'id'>
  ) | (
    { __typename?: 'BenefactorDepositAccountForProjectNeeds' }
    & Pick<BenefactorDepositAccountForProjectNeeds, 'id'>
  ) | (
    { __typename?: 'BenefactorDepositAccountWithoutConditions' }
    & Pick<BenefactorDepositAccountWithoutConditions, 'id'>
  ) | (
    { __typename?: 'OneTimeNeedAccount' }
    & Pick<OneTimeNeedAccount, 'id'>
  ) | (
    { __typename?: 'ProjectExpenseAccount' }
    & Pick<ProjectExpenseAccount, 'id'>
  ) | (
    { __typename?: 'RegularNeedPeriodAccount' }
    & Pick<RegularNeedPeriodAccount, 'id'>
  ) | (
    { __typename?: 'SpecialProjectAccount' }
    & Pick<SpecialProjectAccount, 'id'>
  ) | (
    { __typename?: 'SystemAccount' }
    & Pick<SystemAccount, 'id'>
  )> }
);

type Account_SpecialProjectAccount_Fragment = (
  { __typename?: 'SpecialProjectAccount' }
  & Pick<SpecialProjectAccount, 'id' | 'updatedAt' | 'amount' | 'closedAt'>
  & SpecialProjectAccountPropsFragment
);

type Account_SystemAccount_Fragment = (
  { __typename?: 'SystemAccount' }
  & Pick<SystemAccount, 'updatedAt' | 'kind' | 'id' | 'amount' | 'closedAt'>
  & { transferSources: Array<(
    { __typename?: 'BenefactorDepositAccountForSpecificWards' }
    & Pick<BenefactorDepositAccountForSpecificWards, 'id'>
  ) | (
    { __typename?: 'RegularNeedsAccount' }
    & Pick<RegularNeedsAccount, 'id'>
  ) | (
    { __typename?: 'BenefactorDepositAccountForAllWards' }
    & Pick<BenefactorDepositAccountForAllWards, 'id'>
  ) | (
    { __typename?: 'BenefactorDepositAccountForProjectNeeds' }
    & Pick<BenefactorDepositAccountForProjectNeeds, 'id'>
  ) | (
    { __typename?: 'BenefactorDepositAccountWithoutConditions' }
    & Pick<BenefactorDepositAccountWithoutConditions, 'id'>
  ) | (
    { __typename?: 'OneTimeNeedAccount' }
    & Pick<OneTimeNeedAccount, 'id'>
  ) | (
    { __typename?: 'ProjectExpenseAccount' }
    & Pick<ProjectExpenseAccount, 'id'>
  ) | (
    { __typename?: 'RegularNeedPeriodAccount' }
    & Pick<RegularNeedPeriodAccount, 'id'>
  ) | (
    { __typename?: 'SpecialProjectAccount' }
    & Pick<SpecialProjectAccount, 'id'>
  ) | (
    { __typename?: 'SystemAccount' }
    & Pick<SystemAccount, 'id'>
  )> }
);

export type AccountFragment = Account_BenefactorDepositAccountForSpecificWards_Fragment | Account_RegularNeedsAccount_Fragment | Account_BenefactorDepositAccountForAllWards_Fragment | Account_BenefactorDepositAccountForProjectNeeds_Fragment | Account_BenefactorDepositAccountWithoutConditions_Fragment | Account_OneTimeNeedAccount_Fragment | Account_ProjectExpenseAccount_Fragment | Account_RegularNeedPeriodAccount_Fragment | Account_SpecialProjectAccount_Fragment | Account_SystemAccount_Fragment;

export type AccountBenefactorInformationFragment = (
  { __typename?: 'Benefactor' }
  & Pick<Benefactor, 'id' | 'displayName'>
);

export type AccountQueryVariables = Exact<{
  id: Scalars['AccountId'];
}>;


export type AccountQuery = (
  { __typename?: 'Query' }
  & { account?: Maybe<(
    { __typename?: 'BenefactorDepositAccountForSpecificWards' }
    & { transferSources: Array<(
      { __typename: 'BenefactorDepositAccountForSpecificWards' }
      & Pick<BenefactorDepositAccountForSpecificWards, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename?: 'RegularNeedsAccount' }
      & Pick<RegularNeedsAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename: 'BenefactorDepositAccountForAllWards' }
      & Pick<BenefactorDepositAccountForAllWards, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename: 'BenefactorDepositAccountForProjectNeeds' }
      & Pick<BenefactorDepositAccountForProjectNeeds, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename: 'BenefactorDepositAccountWithoutConditions' }
      & Pick<BenefactorDepositAccountWithoutConditions, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename?: 'OneTimeNeedAccount' }
      & Pick<OneTimeNeedAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'ProjectExpenseAccount' }
      & Pick<ProjectExpenseAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'RegularNeedPeriodAccount' }
      & Pick<RegularNeedPeriodAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'SpecialProjectAccount' }
      & Pick<SpecialProjectAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename: 'SystemAccount' }
      & Pick<SystemAccount, 'kind' | 'id' | 'amount' | 'updatedAt'>
    )> }
    & Account_BenefactorDepositAccountForSpecificWards_Fragment
  ) | (
    { __typename?: 'RegularNeedsAccount' }
    & { transferSources: Array<(
      { __typename: 'BenefactorDepositAccountForSpecificWards' }
      & Pick<BenefactorDepositAccountForSpecificWards, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename?: 'RegularNeedsAccount' }
      & Pick<RegularNeedsAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename: 'BenefactorDepositAccountForAllWards' }
      & Pick<BenefactorDepositAccountForAllWards, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename: 'BenefactorDepositAccountForProjectNeeds' }
      & Pick<BenefactorDepositAccountForProjectNeeds, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename: 'BenefactorDepositAccountWithoutConditions' }
      & Pick<BenefactorDepositAccountWithoutConditions, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename?: 'OneTimeNeedAccount' }
      & Pick<OneTimeNeedAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'ProjectExpenseAccount' }
      & Pick<ProjectExpenseAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'RegularNeedPeriodAccount' }
      & Pick<RegularNeedPeriodAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'SpecialProjectAccount' }
      & Pick<SpecialProjectAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename: 'SystemAccount' }
      & Pick<SystemAccount, 'kind' | 'id' | 'amount' | 'updatedAt'>
    )> }
    & Account_RegularNeedsAccount_Fragment
  ) | (
    { __typename?: 'BenefactorDepositAccountForAllWards' }
    & { transferSources: Array<(
      { __typename: 'BenefactorDepositAccountForSpecificWards' }
      & Pick<BenefactorDepositAccountForSpecificWards, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename?: 'RegularNeedsAccount' }
      & Pick<RegularNeedsAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename: 'BenefactorDepositAccountForAllWards' }
      & Pick<BenefactorDepositAccountForAllWards, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename: 'BenefactorDepositAccountForProjectNeeds' }
      & Pick<BenefactorDepositAccountForProjectNeeds, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename: 'BenefactorDepositAccountWithoutConditions' }
      & Pick<BenefactorDepositAccountWithoutConditions, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename?: 'OneTimeNeedAccount' }
      & Pick<OneTimeNeedAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'ProjectExpenseAccount' }
      & Pick<ProjectExpenseAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'RegularNeedPeriodAccount' }
      & Pick<RegularNeedPeriodAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'SpecialProjectAccount' }
      & Pick<SpecialProjectAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename: 'SystemAccount' }
      & Pick<SystemAccount, 'kind' | 'id' | 'amount' | 'updatedAt'>
    )> }
    & Account_BenefactorDepositAccountForAllWards_Fragment
  ) | (
    { __typename?: 'BenefactorDepositAccountForProjectNeeds' }
    & { transferSources: Array<(
      { __typename: 'BenefactorDepositAccountForSpecificWards' }
      & Pick<BenefactorDepositAccountForSpecificWards, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename?: 'RegularNeedsAccount' }
      & Pick<RegularNeedsAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename: 'BenefactorDepositAccountForAllWards' }
      & Pick<BenefactorDepositAccountForAllWards, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename: 'BenefactorDepositAccountForProjectNeeds' }
      & Pick<BenefactorDepositAccountForProjectNeeds, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename: 'BenefactorDepositAccountWithoutConditions' }
      & Pick<BenefactorDepositAccountWithoutConditions, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename?: 'OneTimeNeedAccount' }
      & Pick<OneTimeNeedAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'ProjectExpenseAccount' }
      & Pick<ProjectExpenseAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'RegularNeedPeriodAccount' }
      & Pick<RegularNeedPeriodAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'SpecialProjectAccount' }
      & Pick<SpecialProjectAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename: 'SystemAccount' }
      & Pick<SystemAccount, 'kind' | 'id' | 'amount' | 'updatedAt'>
    )> }
    & Account_BenefactorDepositAccountForProjectNeeds_Fragment
  ) | (
    { __typename?: 'BenefactorDepositAccountWithoutConditions' }
    & { transferSources: Array<(
      { __typename: 'BenefactorDepositAccountForSpecificWards' }
      & Pick<BenefactorDepositAccountForSpecificWards, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename?: 'RegularNeedsAccount' }
      & Pick<RegularNeedsAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename: 'BenefactorDepositAccountForAllWards' }
      & Pick<BenefactorDepositAccountForAllWards, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename: 'BenefactorDepositAccountForProjectNeeds' }
      & Pick<BenefactorDepositAccountForProjectNeeds, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename: 'BenefactorDepositAccountWithoutConditions' }
      & Pick<BenefactorDepositAccountWithoutConditions, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename?: 'OneTimeNeedAccount' }
      & Pick<OneTimeNeedAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'ProjectExpenseAccount' }
      & Pick<ProjectExpenseAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'RegularNeedPeriodAccount' }
      & Pick<RegularNeedPeriodAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'SpecialProjectAccount' }
      & Pick<SpecialProjectAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename: 'SystemAccount' }
      & Pick<SystemAccount, 'kind' | 'id' | 'amount' | 'updatedAt'>
    )> }
    & Account_BenefactorDepositAccountWithoutConditions_Fragment
  ) | (
    { __typename?: 'OneTimeNeedAccount' }
    & { transferSources: Array<(
      { __typename: 'BenefactorDepositAccountForSpecificWards' }
      & Pick<BenefactorDepositAccountForSpecificWards, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename?: 'RegularNeedsAccount' }
      & Pick<RegularNeedsAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename: 'BenefactorDepositAccountForAllWards' }
      & Pick<BenefactorDepositAccountForAllWards, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename: 'BenefactorDepositAccountForProjectNeeds' }
      & Pick<BenefactorDepositAccountForProjectNeeds, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename: 'BenefactorDepositAccountWithoutConditions' }
      & Pick<BenefactorDepositAccountWithoutConditions, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename?: 'OneTimeNeedAccount' }
      & Pick<OneTimeNeedAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'ProjectExpenseAccount' }
      & Pick<ProjectExpenseAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'RegularNeedPeriodAccount' }
      & Pick<RegularNeedPeriodAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'SpecialProjectAccount' }
      & Pick<SpecialProjectAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename: 'SystemAccount' }
      & Pick<SystemAccount, 'kind' | 'id' | 'amount' | 'updatedAt'>
    )> }
    & Account_OneTimeNeedAccount_Fragment
  ) | (
    { __typename?: 'ProjectExpenseAccount' }
    & { transferSources: Array<(
      { __typename: 'BenefactorDepositAccountForSpecificWards' }
      & Pick<BenefactorDepositAccountForSpecificWards, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename?: 'RegularNeedsAccount' }
      & Pick<RegularNeedsAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename: 'BenefactorDepositAccountForAllWards' }
      & Pick<BenefactorDepositAccountForAllWards, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename: 'BenefactorDepositAccountForProjectNeeds' }
      & Pick<BenefactorDepositAccountForProjectNeeds, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename: 'BenefactorDepositAccountWithoutConditions' }
      & Pick<BenefactorDepositAccountWithoutConditions, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename?: 'OneTimeNeedAccount' }
      & Pick<OneTimeNeedAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'ProjectExpenseAccount' }
      & Pick<ProjectExpenseAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'RegularNeedPeriodAccount' }
      & Pick<RegularNeedPeriodAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'SpecialProjectAccount' }
      & Pick<SpecialProjectAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename: 'SystemAccount' }
      & Pick<SystemAccount, 'kind' | 'id' | 'amount' | 'updatedAt'>
    )> }
    & Account_ProjectExpenseAccount_Fragment
  ) | (
    { __typename?: 'RegularNeedPeriodAccount' }
    & { transferSources: Array<(
      { __typename: 'BenefactorDepositAccountForSpecificWards' }
      & Pick<BenefactorDepositAccountForSpecificWards, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename?: 'RegularNeedsAccount' }
      & Pick<RegularNeedsAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename: 'BenefactorDepositAccountForAllWards' }
      & Pick<BenefactorDepositAccountForAllWards, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename: 'BenefactorDepositAccountForProjectNeeds' }
      & Pick<BenefactorDepositAccountForProjectNeeds, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename: 'BenefactorDepositAccountWithoutConditions' }
      & Pick<BenefactorDepositAccountWithoutConditions, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename?: 'OneTimeNeedAccount' }
      & Pick<OneTimeNeedAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'ProjectExpenseAccount' }
      & Pick<ProjectExpenseAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'RegularNeedPeriodAccount' }
      & Pick<RegularNeedPeriodAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'SpecialProjectAccount' }
      & Pick<SpecialProjectAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename: 'SystemAccount' }
      & Pick<SystemAccount, 'kind' | 'id' | 'amount' | 'updatedAt'>
    )> }
    & Account_RegularNeedPeriodAccount_Fragment
  ) | (
    { __typename?: 'SpecialProjectAccount' }
    & { transferSources: Array<(
      { __typename: 'BenefactorDepositAccountForSpecificWards' }
      & Pick<BenefactorDepositAccountForSpecificWards, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename?: 'RegularNeedsAccount' }
      & Pick<RegularNeedsAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename: 'BenefactorDepositAccountForAllWards' }
      & Pick<BenefactorDepositAccountForAllWards, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename: 'BenefactorDepositAccountForProjectNeeds' }
      & Pick<BenefactorDepositAccountForProjectNeeds, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename: 'BenefactorDepositAccountWithoutConditions' }
      & Pick<BenefactorDepositAccountWithoutConditions, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename?: 'OneTimeNeedAccount' }
      & Pick<OneTimeNeedAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'ProjectExpenseAccount' }
      & Pick<ProjectExpenseAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'RegularNeedPeriodAccount' }
      & Pick<RegularNeedPeriodAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'SpecialProjectAccount' }
      & Pick<SpecialProjectAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename: 'SystemAccount' }
      & Pick<SystemAccount, 'kind' | 'id' | 'amount' | 'updatedAt'>
    )> }
    & Account_SpecialProjectAccount_Fragment
  ) | (
    { __typename?: 'SystemAccount' }
    & { transferSources: Array<(
      { __typename: 'BenefactorDepositAccountForSpecificWards' }
      & Pick<BenefactorDepositAccountForSpecificWards, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename?: 'RegularNeedsAccount' }
      & Pick<RegularNeedsAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename: 'BenefactorDepositAccountForAllWards' }
      & Pick<BenefactorDepositAccountForAllWards, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename: 'BenefactorDepositAccountForProjectNeeds' }
      & Pick<BenefactorDepositAccountForProjectNeeds, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename: 'BenefactorDepositAccountWithoutConditions' }
      & Pick<BenefactorDepositAccountWithoutConditions, 'id' | 'amount' | 'updatedAt'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & AccountBenefactorInformationFragment
      ) }
    ) | (
      { __typename?: 'OneTimeNeedAccount' }
      & Pick<OneTimeNeedAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'ProjectExpenseAccount' }
      & Pick<ProjectExpenseAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'RegularNeedPeriodAccount' }
      & Pick<RegularNeedPeriodAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename?: 'SpecialProjectAccount' }
      & Pick<SpecialProjectAccount, 'id' | 'amount' | 'updatedAt'>
    ) | (
      { __typename: 'SystemAccount' }
      & Pick<SystemAccount, 'kind' | 'id' | 'amount' | 'updatedAt'>
    )> }
    & Account_SystemAccount_Fragment
  )> }
);

export type AccountDonationsQueryVariables = Exact<{
  id: Scalars['AccountId'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type AccountDonationsQuery = (
  { __typename?: 'Query' }
  & { account?: Maybe<(
    { __typename?: 'BenefactorDepositAccountForSpecificWards' }
    & { donations?: Maybe<(
      { __typename?: 'DonationsConnection' }
      & Pick<DonationsConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Donation' }
        & Pick<Donation, 'id' | 'amount' | 'createdAt'>
        & { benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
          & { photo?: Maybe<(
            { __typename?: 'FileInformation' }
            & Pick<FileInformation, 'url'>
          )> }
        ) }
      )>>> }
    )> }
  ) | (
    { __typename?: 'RegularNeedsAccount' }
    & { donations?: Maybe<(
      { __typename?: 'DonationsConnection' }
      & Pick<DonationsConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Donation' }
        & Pick<Donation, 'id' | 'amount' | 'createdAt'>
        & { benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
          & { photo?: Maybe<(
            { __typename?: 'FileInformation' }
            & Pick<FileInformation, 'url'>
          )> }
        ) }
      )>>> }
    )> }
  ) | (
    { __typename?: 'BenefactorDepositAccountForAllWards' }
    & { donations?: Maybe<(
      { __typename?: 'DonationsConnection' }
      & Pick<DonationsConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Donation' }
        & Pick<Donation, 'id' | 'amount' | 'createdAt'>
        & { benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
          & { photo?: Maybe<(
            { __typename?: 'FileInformation' }
            & Pick<FileInformation, 'url'>
          )> }
        ) }
      )>>> }
    )> }
  ) | (
    { __typename?: 'BenefactorDepositAccountForProjectNeeds' }
    & { donations?: Maybe<(
      { __typename?: 'DonationsConnection' }
      & Pick<DonationsConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Donation' }
        & Pick<Donation, 'id' | 'amount' | 'createdAt'>
        & { benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
          & { photo?: Maybe<(
            { __typename?: 'FileInformation' }
            & Pick<FileInformation, 'url'>
          )> }
        ) }
      )>>> }
    )> }
  ) | (
    { __typename?: 'BenefactorDepositAccountWithoutConditions' }
    & { donations?: Maybe<(
      { __typename?: 'DonationsConnection' }
      & Pick<DonationsConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Donation' }
        & Pick<Donation, 'id' | 'amount' | 'createdAt'>
        & { benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
          & { photo?: Maybe<(
            { __typename?: 'FileInformation' }
            & Pick<FileInformation, 'url'>
          )> }
        ) }
      )>>> }
    )> }
  ) | (
    { __typename?: 'OneTimeNeedAccount' }
    & { donations?: Maybe<(
      { __typename?: 'DonationsConnection' }
      & Pick<DonationsConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Donation' }
        & Pick<Donation, 'id' | 'amount' | 'createdAt'>
        & { benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
          & { photo?: Maybe<(
            { __typename?: 'FileInformation' }
            & Pick<FileInformation, 'url'>
          )> }
        ) }
      )>>> }
    )> }
  ) | (
    { __typename?: 'ProjectExpenseAccount' }
    & { donations?: Maybe<(
      { __typename?: 'DonationsConnection' }
      & Pick<DonationsConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Donation' }
        & Pick<Donation, 'id' | 'amount' | 'createdAt'>
        & { benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
          & { photo?: Maybe<(
            { __typename?: 'FileInformation' }
            & Pick<FileInformation, 'url'>
          )> }
        ) }
      )>>> }
    )> }
  ) | (
    { __typename?: 'RegularNeedPeriodAccount' }
    & { donations?: Maybe<(
      { __typename?: 'DonationsConnection' }
      & Pick<DonationsConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Donation' }
        & Pick<Donation, 'id' | 'amount' | 'createdAt'>
        & { benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
          & { photo?: Maybe<(
            { __typename?: 'FileInformation' }
            & Pick<FileInformation, 'url'>
          )> }
        ) }
      )>>> }
    )> }
  ) | (
    { __typename?: 'SpecialProjectAccount' }
    & { donations?: Maybe<(
      { __typename?: 'DonationsConnection' }
      & Pick<DonationsConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Donation' }
        & Pick<Donation, 'id' | 'amount' | 'createdAt'>
        & { benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
          & { photo?: Maybe<(
            { __typename?: 'FileInformation' }
            & Pick<FileInformation, 'url'>
          )> }
        ) }
      )>>> }
    )> }
  ) | (
    { __typename?: 'SystemAccount' }
    & { donations?: Maybe<(
      { __typename?: 'DonationsConnection' }
      & Pick<DonationsConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Donation' }
        & Pick<Donation, 'id' | 'amount' | 'createdAt'>
        & { benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
          & { photo?: Maybe<(
            { __typename?: 'FileInformation' }
            & Pick<FileInformation, 'url'>
          )> }
        ) }
      )>>> }
    )> }
  )> }
);

export type AccountWardInformationFragment = (
  { __typename?: 'Ward' }
  & Pick<Ward, 'id'>
  & { publicInformation: (
    { __typename?: 'WardPublicInformation' }
    & { name: (
      { __typename?: 'Nomen' }
      & Pick<Nomen, 'fullName'>
    ) }
  ) }
);

export type SpecialProjectAccountPropsFragment = (
  { __typename?: 'SpecialProjectAccount' }
  & Pick<SpecialProjectAccount, 'id' | 'amount' | 'requiredAmount'>
  & { specialProject: (
    { __typename?: 'SpecialProject' }
    & Pick<SpecialProject, 'id' | 'title' | 'start' | 'end'>
    & { account: (
      { __typename?: 'BenefactorDepositAccountForSpecificWards' }
      & Pick<BenefactorDepositAccountForSpecificWards, 'amount'>
    ) | (
      { __typename?: 'RegularNeedsAccount' }
      & Pick<RegularNeedsAccount, 'amount'>
    ) | (
      { __typename?: 'BenefactorDepositAccountForAllWards' }
      & Pick<BenefactorDepositAccountForAllWards, 'amount'>
    ) | (
      { __typename?: 'BenefactorDepositAccountForProjectNeeds' }
      & Pick<BenefactorDepositAccountForProjectNeeds, 'amount'>
    ) | (
      { __typename?: 'BenefactorDepositAccountWithoutConditions' }
      & Pick<BenefactorDepositAccountWithoutConditions, 'amount'>
    ) | (
      { __typename?: 'OneTimeNeedAccount' }
      & Pick<OneTimeNeedAccount, 'amount'>
    ) | (
      { __typename?: 'ProjectExpenseAccount' }
      & Pick<ProjectExpenseAccount, 'amount'>
    ) | (
      { __typename?: 'RegularNeedPeriodAccount' }
      & Pick<RegularNeedPeriodAccount, 'amount'>
    ) | (
      { __typename?: 'SpecialProjectAccount' }
      & Pick<SpecialProjectAccount, 'amount'>
    ) | (
      { __typename?: 'SystemAccount' }
      & Pick<SystemAccount, 'amount'>
    ) }
  ), transferSources: Array<(
    { __typename?: 'BenefactorDepositAccountForSpecificWards' }
    & Pick<BenefactorDepositAccountForSpecificWards, 'id' | 'amount' | 'updatedAt'>
  ) | (
    { __typename?: 'RegularNeedsAccount' }
    & Pick<RegularNeedsAccount, 'id' | 'amount' | 'updatedAt'>
  ) | (
    { __typename?: 'BenefactorDepositAccountForAllWards' }
    & Pick<BenefactorDepositAccountForAllWards, 'id' | 'amount' | 'updatedAt'>
  ) | (
    { __typename?: 'BenefactorDepositAccountForProjectNeeds' }
    & Pick<BenefactorDepositAccountForProjectNeeds, 'id' | 'amount' | 'updatedAt'>
  ) | (
    { __typename?: 'BenefactorDepositAccountWithoutConditions' }
    & Pick<BenefactorDepositAccountWithoutConditions, 'id' | 'amount' | 'updatedAt'>
  ) | (
    { __typename?: 'OneTimeNeedAccount' }
    & Pick<OneTimeNeedAccount, 'id' | 'amount' | 'updatedAt'>
  ) | (
    { __typename?: 'ProjectExpenseAccount' }
    & Pick<ProjectExpenseAccount, 'id' | 'amount' | 'updatedAt'>
  ) | (
    { __typename?: 'RegularNeedPeriodAccount' }
    & Pick<RegularNeedPeriodAccount, 'id' | 'amount' | 'updatedAt'>
  ) | (
    { __typename?: 'SpecialProjectAccount' }
    & Pick<SpecialProjectAccount, 'id' | 'amount' | 'updatedAt'>
  ) | (
    { __typename?: 'SystemAccount' }
    & Pick<SystemAccount, 'id' | 'amount' | 'updatedAt'>
  )> }
);

export type AddAchievementWithMaterialPrizesMutationVariables = Exact<{
  input: AddAchievementWithMaterialPrizesInput;
}>;


export type AddAchievementWithMaterialPrizesMutation = (
  { __typename?: 'Mutation' }
  & { addAchievementWithMaterialPrizes: (
    { __typename?: 'AddAchievementWithMaterialPrizesPayload' }
    & { achievement?: Maybe<(
      { __typename?: 'AchievementWithMaterialPrizes' }
      & Pick<AchievementWithMaterialPrizes, 'id' | 'title'>
      & { created: (
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      ) }
    ) | (
      { __typename?: 'AchievementWithPromoCodes' }
      & Pick<AchievementWithPromoCodes, 'id' | 'title'>
      & { created: (
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      ) }
    ) | (
      { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
      & Pick<AchievementWithUnlimitedNumberOfPrizes, 'id' | 'title'>
      & { created: (
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      ) }
    )> }
  ) }
);

export type AddAchievementWithPromoCodesMutationVariables = Exact<{
  input: AddAchievementWithPromoCodesInput;
}>;


export type AddAchievementWithPromoCodesMutation = (
  { __typename?: 'Mutation' }
  & { addAchievementWithPromoCodes: (
    { __typename?: 'AddAchievementWithPromoCodesPayload' }
    & { achievement?: Maybe<(
      { __typename?: 'AchievementWithMaterialPrizes' }
      & Pick<AchievementWithMaterialPrizes, 'id' | 'title'>
      & { created: (
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      ) }
    ) | (
      { __typename?: 'AchievementWithPromoCodes' }
      & Pick<AchievementWithPromoCodes, 'id' | 'title'>
      & { created: (
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      ) }
    ) | (
      { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
      & Pick<AchievementWithUnlimitedNumberOfPrizes, 'id' | 'title'>
      & { created: (
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      ) }
    )> }
  ) }
);

export type AddAchievementWithUnlimitedNumberOfPrizesMutationVariables = Exact<{
  input: AddAchievementWithUnlimitedNumberOfPrizesInput;
}>;


export type AddAchievementWithUnlimitedNumberOfPrizesMutation = (
  { __typename?: 'Mutation' }
  & { addAchievementWithUnlimitedNumberOfPrizes: (
    { __typename?: 'AddAchievementWithUnlimitedNumberOfPrizesPayload' }
    & { achievement?: Maybe<(
      { __typename?: 'AchievementWithMaterialPrizes' }
      & Pick<AchievementWithMaterialPrizes, 'id' | 'title'>
      & { created: (
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      ) }
    ) | (
      { __typename?: 'AchievementWithPromoCodes' }
      & Pick<AchievementWithPromoCodes, 'id' | 'title'>
      & { created: (
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      ) }
    ) | (
      { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
      & Pick<AchievementWithUnlimitedNumberOfPrizes, 'id' | 'title'>
      & { created: (
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      ) }
    )> }
  ) }
);

export type AddBenefactorStatusMutationVariables = Exact<{
  input: AddBenefactorStatusInput;
}>;


export type AddBenefactorStatusMutation = (
  { __typename?: 'Mutation' }
  & { addBenefactorStatus: (
    { __typename?: 'AddBenefactorStatusPayload' }
    & { status?: Maybe<(
      { __typename?: 'BenefactorStatus' }
      & Pick<BenefactorStatus, 'id' | 'title'>
    )> }
  ) }
);

export type UploadPromoCodesMutationVariables = Exact<{
  input: UploadPromoCodesInput;
}>;


export type UploadPromoCodesMutation = (
  { __typename?: 'Mutation' }
  & { uploadPromoCodes: (
    { __typename?: 'UploadPromoCodesPayload' }
    & { achievement?: Maybe<(
      { __typename?: 'AchievementWithMaterialPrizes' }
      & Pick<AchievementWithMaterialPrizes, 'id'>
    ) | (
      { __typename?: 'AchievementWithPromoCodes' }
      & Pick<AchievementWithPromoCodes, 'id'>
    ) | (
      { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
      & Pick<AchievementWithUnlimitedNumberOfPrizes, 'id'>
    )> }
  ) }
);

export type DeleteAchievementMutationVariables = Exact<{
  input: DeleteAchievementInput;
}>;


export type DeleteAchievementMutation = (
  { __typename?: 'Mutation' }
  & { deleteAchievement: (
    { __typename?: 'DeleteAchievementPayload' }
    & Pick<DeleteAchievementPayload, 'success'>
  ) }
);

export type UpdateAchievementWithMaterialPrizesMutationVariables = Exact<{
  input: UpdateAchievementWithMaterialPrizesInput;
}>;


export type UpdateAchievementWithMaterialPrizesMutation = (
  { __typename?: 'Mutation' }
  & { updateAchievementWithMaterialPrizes: (
    { __typename?: 'UpdateAchievementWithMaterialPrizesPayload' }
    & { achievement?: Maybe<(
      { __typename?: 'AchievementWithMaterialPrizes' }
      & Pick<AchievementWithMaterialPrizes, 'id' | 'title'>
      & { created: (
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      ) }
    ) | (
      { __typename?: 'AchievementWithPromoCodes' }
      & Pick<AchievementWithPromoCodes, 'id' | 'title'>
      & { created: (
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      ) }
    ) | (
      { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
      & Pick<AchievementWithUnlimitedNumberOfPrizes, 'id' | 'title'>
      & { created: (
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      ) }
    )> }
  ) }
);

export type UpdateAchievementWithPromoCodesMutationVariables = Exact<{
  input: UpdateAchievementWithPromoCodesInput;
}>;


export type UpdateAchievementWithPromoCodesMutation = (
  { __typename?: 'Mutation' }
  & { updateAchievementWithPromoCodes: (
    { __typename?: 'UpdateAchievementWithPromoCodesPayload' }
    & { achievement?: Maybe<(
      { __typename?: 'AchievementWithMaterialPrizes' }
      & Pick<AchievementWithMaterialPrizes, 'id' | 'title'>
      & { created: (
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      ) }
    ) | (
      { __typename?: 'AchievementWithPromoCodes' }
      & Pick<AchievementWithPromoCodes, 'id' | 'title'>
      & { created: (
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      ) }
    ) | (
      { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
      & Pick<AchievementWithUnlimitedNumberOfPrizes, 'id' | 'title'>
      & { created: (
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      ) }
    )> }
  ) }
);

export type UpdateAchievementWithUnlimitedNumberOfPrizesMutationVariables = Exact<{
  input: UpdateAchievementWithUnlimitedNumberOfPrizesInput;
}>;


export type UpdateAchievementWithUnlimitedNumberOfPrizesMutation = (
  { __typename?: 'Mutation' }
  & { updateAchievementWithUnlimitedNumberOfPrizes: (
    { __typename?: 'UpdateAchievementWithUnlimitedNumberOfPrizesPayload' }
    & { achievement?: Maybe<(
      { __typename?: 'AchievementWithMaterialPrizes' }
      & Pick<AchievementWithMaterialPrizes, 'id' | 'title'>
      & { created: (
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      ) }
    ) | (
      { __typename?: 'AchievementWithPromoCodes' }
      & Pick<AchievementWithPromoCodes, 'id' | 'title'>
      & { created: (
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      ) }
    ) | (
      { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
      & Pick<AchievementWithUnlimitedNumberOfPrizes, 'id' | 'title'>
      & { created: (
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      ) }
    )> }
  ) }
);

export type PublishAchievementMutationVariables = Exact<{
  input: PublishAchievementInput;
}>;


export type PublishAchievementMutation = (
  { __typename?: 'Mutation' }
  & { publishAchievement: (
    { __typename?: 'PublishAchievementPayload' }
    & { achievement?: Maybe<(
      { __typename?: 'AchievementWithMaterialPrizes' }
      & Pick<AchievementWithMaterialPrizes, 'id' | 'title'>
    ) | (
      { __typename?: 'AchievementWithPromoCodes' }
      & Pick<AchievementWithPromoCodes, 'id' | 'title'>
    ) | (
      { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
      & Pick<AchievementWithUnlimitedNumberOfPrizes, 'id' | 'title'>
    )> }
  ) }
);

export type UnPublishAchievementMutationVariables = Exact<{
  input: UnpublishAchievementInput;
}>;


export type UnPublishAchievementMutation = (
  { __typename?: 'Mutation' }
  & { unpublishAchievement: (
    { __typename?: 'UnpublishAchievementPayload' }
    & { achievement?: Maybe<(
      { __typename?: 'AchievementWithMaterialPrizes' }
      & Pick<AchievementWithMaterialPrizes, 'id' | 'title'>
    ) | (
      { __typename?: 'AchievementWithPromoCodes' }
      & Pick<AchievementWithPromoCodes, 'id' | 'title'>
    ) | (
      { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
      & Pick<AchievementWithUnlimitedNumberOfPrizes, 'id' | 'title'>
    )> }
  ) }
);

export type AchievementByIdQueryVariables = Exact<{
  id: Scalars['AchievementId'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type AchievementByIdQuery = (
  { __typename?: 'Query' }
  & { achievementById?: Maybe<(
    { __typename: 'AchievementWithMaterialPrizes' }
    & Pick<AchievementWithMaterialPrizes, 'totalNumberOfPrizes' | 'numberOfGrantedPrizes'>
    & { benefactors?: Maybe<(
      { __typename?: 'BenefactorAchievementsConnection' }
      & Pick<BenefactorAchievementsConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<Maybe<(
        { __typename?: 'BenefactorAchievementWithMaterialPrize' }
        & Pick<BenefactorAchievementWithMaterialPrize, 'id' | 'receivedAt'>
        & { achievement: (
          { __typename?: 'AchievementWithMaterialPrizes' }
          & AchievementsProps_AchievementWithMaterialPrizes_Fragment
        ) | (
          { __typename?: 'AchievementWithPromoCodes' }
          & AchievementsProps_AchievementWithPromoCodes_Fragment
        ) | (
          { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
          & AchievementsProps_AchievementWithUnlimitedNumberOfPrizes_Fragment
        ), benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
        ) }
      ) | (
        { __typename?: 'BenefactorAchievementWithPromoCode' }
        & Pick<BenefactorAchievementWithPromoCode, 'id' | 'receivedAt'>
        & { achievement: (
          { __typename?: 'AchievementWithMaterialPrizes' }
          & AchievementsProps_AchievementWithMaterialPrizes_Fragment
        ) | (
          { __typename?: 'AchievementWithPromoCodes' }
          & AchievementsProps_AchievementWithPromoCodes_Fragment
        ) | (
          { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
          & AchievementsProps_AchievementWithUnlimitedNumberOfPrizes_Fragment
        ), benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
        ) }
      ) | (
        { __typename?: 'BenefactorAchievementWithVideo' }
        & Pick<BenefactorAchievementWithVideo, 'id' | 'receivedAt'>
        & { achievement: (
          { __typename?: 'AchievementWithMaterialPrizes' }
          & AchievementsProps_AchievementWithMaterialPrizes_Fragment
        ) | (
          { __typename?: 'AchievementWithPromoCodes' }
          & AchievementsProps_AchievementWithPromoCodes_Fragment
        ) | (
          { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
          & AchievementsProps_AchievementWithUnlimitedNumberOfPrizes_Fragment
        ), benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
        ) }
      ) | (
        { __typename?: 'BenefactorAchievementWithoutPrize' }
        & Pick<BenefactorAchievementWithoutPrize, 'id' | 'receivedAt'>
        & { achievement: (
          { __typename?: 'AchievementWithMaterialPrizes' }
          & AchievementsProps_AchievementWithMaterialPrizes_Fragment
        ) | (
          { __typename?: 'AchievementWithPromoCodes' }
          & AchievementsProps_AchievementWithPromoCodes_Fragment
        ) | (
          { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
          & AchievementsProps_AchievementWithUnlimitedNumberOfPrizes_Fragment
        ), benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
        ) }
      )>>> }
    )>, published?: Maybe<(
      { __typename?: 'AuditMarker' }
      & AuditMarkerPropsFragment
    )>, image: (
      { __typename?: 'FileInformation' }
      & PhotoFilePropsFragment
    ), story: (
      { __typename?: 'Story' }
      & Pick<Story, 'id'>
      & { storyBlock: (
        { __typename?: 'StoryBlock' }
        & Pick<StoryBlock, 'id'>
      ) }
    ) }
    & AchievementsProps_AchievementWithMaterialPrizes_Fragment
  ) | (
    { __typename: 'AchievementWithPromoCodes' }
    & Pick<AchievementWithPromoCodes, 'totalNumberOfPrizes' | 'numberOfGrantedPrizes'>
    & { benefactors?: Maybe<(
      { __typename?: 'BenefactorAchievementsConnection' }
      & Pick<BenefactorAchievementsConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<Maybe<(
        { __typename?: 'BenefactorAchievementWithMaterialPrize' }
        & Pick<BenefactorAchievementWithMaterialPrize, 'id' | 'receivedAt'>
        & { achievement: (
          { __typename?: 'AchievementWithMaterialPrizes' }
          & AchievementsProps_AchievementWithMaterialPrizes_Fragment
        ) | (
          { __typename?: 'AchievementWithPromoCodes' }
          & AchievementsProps_AchievementWithPromoCodes_Fragment
        ) | (
          { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
          & AchievementsProps_AchievementWithUnlimitedNumberOfPrizes_Fragment
        ), benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
        ) }
      ) | (
        { __typename?: 'BenefactorAchievementWithPromoCode' }
        & Pick<BenefactorAchievementWithPromoCode, 'id' | 'receivedAt'>
        & { achievement: (
          { __typename?: 'AchievementWithMaterialPrizes' }
          & AchievementsProps_AchievementWithMaterialPrizes_Fragment
        ) | (
          { __typename?: 'AchievementWithPromoCodes' }
          & AchievementsProps_AchievementWithPromoCodes_Fragment
        ) | (
          { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
          & AchievementsProps_AchievementWithUnlimitedNumberOfPrizes_Fragment
        ), benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
        ) }
      ) | (
        { __typename?: 'BenefactorAchievementWithVideo' }
        & Pick<BenefactorAchievementWithVideo, 'id' | 'receivedAt'>
        & { achievement: (
          { __typename?: 'AchievementWithMaterialPrizes' }
          & AchievementsProps_AchievementWithMaterialPrizes_Fragment
        ) | (
          { __typename?: 'AchievementWithPromoCodes' }
          & AchievementsProps_AchievementWithPromoCodes_Fragment
        ) | (
          { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
          & AchievementsProps_AchievementWithUnlimitedNumberOfPrizes_Fragment
        ), benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
        ) }
      ) | (
        { __typename?: 'BenefactorAchievementWithoutPrize' }
        & Pick<BenefactorAchievementWithoutPrize, 'id' | 'receivedAt'>
        & { achievement: (
          { __typename?: 'AchievementWithMaterialPrizes' }
          & AchievementsProps_AchievementWithMaterialPrizes_Fragment
        ) | (
          { __typename?: 'AchievementWithPromoCodes' }
          & AchievementsProps_AchievementWithPromoCodes_Fragment
        ) | (
          { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
          & AchievementsProps_AchievementWithUnlimitedNumberOfPrizes_Fragment
        ), benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
        ) }
      )>>> }
    )>, published?: Maybe<(
      { __typename?: 'AuditMarker' }
      & AuditMarkerPropsFragment
    )>, image: (
      { __typename?: 'FileInformation' }
      & PhotoFilePropsFragment
    ), story: (
      { __typename?: 'Story' }
      & Pick<Story, 'id'>
      & { storyBlock: (
        { __typename?: 'StoryBlock' }
        & Pick<StoryBlock, 'id'>
      ) }
    ) }
    & AchievementsProps_AchievementWithPromoCodes_Fragment
  ) | (
    { __typename: 'AchievementWithUnlimitedNumberOfPrizes' }
    & { benefactors?: Maybe<(
      { __typename?: 'BenefactorAchievementsConnection' }
      & Pick<BenefactorAchievementsConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<Maybe<(
        { __typename?: 'BenefactorAchievementWithMaterialPrize' }
        & Pick<BenefactorAchievementWithMaterialPrize, 'id' | 'receivedAt'>
        & { achievement: (
          { __typename?: 'AchievementWithMaterialPrizes' }
          & AchievementsProps_AchievementWithMaterialPrizes_Fragment
        ) | (
          { __typename?: 'AchievementWithPromoCodes' }
          & AchievementsProps_AchievementWithPromoCodes_Fragment
        ) | (
          { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
          & AchievementsProps_AchievementWithUnlimitedNumberOfPrizes_Fragment
        ), benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
        ) }
      ) | (
        { __typename?: 'BenefactorAchievementWithPromoCode' }
        & Pick<BenefactorAchievementWithPromoCode, 'id' | 'receivedAt'>
        & { achievement: (
          { __typename?: 'AchievementWithMaterialPrizes' }
          & AchievementsProps_AchievementWithMaterialPrizes_Fragment
        ) | (
          { __typename?: 'AchievementWithPromoCodes' }
          & AchievementsProps_AchievementWithPromoCodes_Fragment
        ) | (
          { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
          & AchievementsProps_AchievementWithUnlimitedNumberOfPrizes_Fragment
        ), benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
        ) }
      ) | (
        { __typename?: 'BenefactorAchievementWithVideo' }
        & Pick<BenefactorAchievementWithVideo, 'id' | 'receivedAt'>
        & { achievement: (
          { __typename?: 'AchievementWithMaterialPrizes' }
          & AchievementsProps_AchievementWithMaterialPrizes_Fragment
        ) | (
          { __typename?: 'AchievementWithPromoCodes' }
          & AchievementsProps_AchievementWithPromoCodes_Fragment
        ) | (
          { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
          & AchievementsProps_AchievementWithUnlimitedNumberOfPrizes_Fragment
        ), benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
        ) }
      ) | (
        { __typename?: 'BenefactorAchievementWithoutPrize' }
        & Pick<BenefactorAchievementWithoutPrize, 'id' | 'receivedAt'>
        & { achievement: (
          { __typename?: 'AchievementWithMaterialPrizes' }
          & AchievementsProps_AchievementWithMaterialPrizes_Fragment
        ) | (
          { __typename?: 'AchievementWithPromoCodes' }
          & AchievementsProps_AchievementWithPromoCodes_Fragment
        ) | (
          { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
          & AchievementsProps_AchievementWithUnlimitedNumberOfPrizes_Fragment
        ), benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
        ) }
      )>>> }
    )>, published?: Maybe<(
      { __typename?: 'AuditMarker' }
      & AuditMarkerPropsFragment
    )>, image: (
      { __typename?: 'FileInformation' }
      & PhotoFilePropsFragment
    ), story: (
      { __typename?: 'Story' }
      & Pick<Story, 'id'>
      & { storyBlock: (
        { __typename?: 'StoryBlock' }
        & Pick<StoryBlock, 'id'>
      ) }
    ) }
    & AchievementsProps_AchievementWithUnlimitedNumberOfPrizes_Fragment
  )> }
);

export type DeleteBenefactorStatusMutationVariables = Exact<{
  input: DeleteBenefactorStatusInput;
}>;


export type DeleteBenefactorStatusMutation = (
  { __typename?: 'Mutation' }
  & { deleteBenefactorStatus: (
    { __typename?: 'DeleteBenefactorStatusPayload' }
    & Pick<DeleteBenefactorStatusPayload, 'success'>
  ) }
);

export type UpdateBenefactorStatusMutationVariables = Exact<{
  input: UpdateBenefactorStatusInput;
}>;


export type UpdateBenefactorStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateBenefactorStatus: (
    { __typename?: 'UpdateBenefactorStatusPayload' }
    & { status?: Maybe<(
      { __typename?: 'BenefactorStatus' }
      & Pick<BenefactorStatus, 'id' | 'title'>
      & { created: (
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      ) }
    )> }
  ) }
);

export type BenefactorStatusByIdQueryVariables = Exact<{
  id: Scalars['BenefactorStatusId'];
}>;


export type BenefactorStatusByIdQuery = (
  { __typename?: 'Query' }
  & { benefactorStatusById?: Maybe<(
    { __typename: 'BenefactorStatus' }
    & Pick<BenefactorStatus, 'id' | 'description' | 'title' | 'coins'>
    & { image: (
      { __typename?: 'FileInformation' }
      & PhotoFilePropsFragment
    ), story: (
      { __typename?: 'Story' }
      & Pick<Story, 'id'>
      & { storyBlock: (
        { __typename?: 'StoryBlock' }
        & Pick<StoryBlock, 'id'>
      ) }
    ) }
  )> }
);

export type BenefactorStatusesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type BenefactorStatusesQuery = (
  { __typename?: 'Query' }
  & { benefactorStatuses?: Maybe<(
    { __typename?: 'BenefactorStatusesConnection' }
    & Pick<BenefactorStatusesConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
    ), nodes?: Maybe<Array<(
      { __typename?: 'BenefactorStatus' }
      & BenefactorStatusPropsFragment
    )>> }
  )> }
);

export type AchievementsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  includeUnpublished: Scalars['Boolean'];
  last?: Maybe<Scalars['Int']>;
}>;


export type AchievementsQuery = (
  { __typename?: 'Query' }
  & { achievements?: Maybe<(
    { __typename?: 'AchievementsConnection' }
    & Pick<AchievementsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
    ), nodes?: Maybe<Array<Maybe<(
      { __typename?: 'AchievementWithMaterialPrizes' }
      & AchievementsProps_AchievementWithMaterialPrizes_Fragment
    ) | (
      { __typename?: 'AchievementWithPromoCodes' }
      & AchievementsProps_AchievementWithPromoCodes_Fragment
    ) | (
      { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
      & AchievementsProps_AchievementWithUnlimitedNumberOfPrizes_Fragment
    )>>> }
  )> }
);

type AchievementsProps_AchievementWithMaterialPrizes_Fragment = (
  { __typename: 'AchievementWithMaterialPrizes' }
  & Pick<AchievementWithMaterialPrizes, 'id' | 'title' | 'coins' | 'topPosition' | 'description' | 'isHighlighted'>
  & { topImage?: Maybe<(
    { __typename?: 'FileInformation' }
    & PhotoFilePropsFragment
  )>, created: (
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  ), published?: Maybe<(
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  )>, image: (
    { __typename?: 'FileInformation' }
    & PhotoFilePropsFragment
  ), story: (
    { __typename?: 'Story' }
    & StoryPropsFragment
  ), updated: (
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  ) }
);

type AchievementsProps_AchievementWithPromoCodes_Fragment = (
  { __typename: 'AchievementWithPromoCodes' }
  & Pick<AchievementWithPromoCodes, 'id' | 'title' | 'coins' | 'topPosition' | 'description' | 'isHighlighted'>
  & { topImage?: Maybe<(
    { __typename?: 'FileInformation' }
    & PhotoFilePropsFragment
  )>, created: (
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  ), published?: Maybe<(
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  )>, image: (
    { __typename?: 'FileInformation' }
    & PhotoFilePropsFragment
  ), story: (
    { __typename?: 'Story' }
    & StoryPropsFragment
  ), updated: (
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  ) }
);

type AchievementsProps_AchievementWithUnlimitedNumberOfPrizes_Fragment = (
  { __typename: 'AchievementWithUnlimitedNumberOfPrizes' }
  & Pick<AchievementWithUnlimitedNumberOfPrizes, 'id' | 'title' | 'coins' | 'topPosition' | 'description' | 'isHighlighted'>
  & { topImage?: Maybe<(
    { __typename?: 'FileInformation' }
    & PhotoFilePropsFragment
  )>, created: (
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  ), published?: Maybe<(
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  )>, image: (
    { __typename?: 'FileInformation' }
    & PhotoFilePropsFragment
  ), story: (
    { __typename?: 'Story' }
    & StoryPropsFragment
  ), updated: (
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  ) }
);

export type AchievementsPropsFragment = AchievementsProps_AchievementWithMaterialPrizes_Fragment | AchievementsProps_AchievementWithPromoCodes_Fragment | AchievementsProps_AchievementWithUnlimitedNumberOfPrizes_Fragment;

export type BenefactorStatusPropsFragment = (
  { __typename: 'BenefactorStatus' }
  & Pick<BenefactorStatus, 'id' | 'title' | 'coins' | 'description'>
  & { image: (
    { __typename?: 'FileInformation' }
    & PhotoFilePropsFragment
  ), story: (
    { __typename?: 'Story' }
    & StoryPropsFragment
  ) }
);

export type CreateBenefactorMutationVariables = Exact<{
  input: CreateBenefactorInput;
}>;


export type CreateBenefactorMutation = (
  { __typename?: 'Mutation' }
  & { createBenefactor: (
    { __typename?: 'CreateBenefactorPayload' }
    & { benefactor?: Maybe<(
      { __typename?: 'Benefactor' }
      & BenefactorPropsFragment
    )> }
  ) }
);

export type UpdateBenefactorEmailByAdministratorMutationVariables = Exact<{
  input: UpdateBenefactorEmailByAdministratorInput;
}>;


export type UpdateBenefactorEmailByAdministratorMutation = (
  { __typename?: 'Mutation' }
  & { updateBenefactorEmailByAdministrator: (
    { __typename?: 'UpdateBenefactorEmailByAdministratorPayload' }
    & { benefactor?: Maybe<(
      { __typename?: 'Benefactor' }
      & BenefactorExtendedPropsFragment
    )> }
  ) }
);

export type ChangePhoneNumberByAdministratorMutationVariables = Exact<{
  input: ChangePhoneNumberByAdministratorInput;
}>;


export type ChangePhoneNumberByAdministratorMutation = (
  { __typename?: 'Mutation' }
  & { changePhoneNumberByAdministrator: (
    { __typename?: 'ChangePhoneNumberByAdministratorPayload' }
    & Pick<ChangePhoneNumberByAdministratorPayload, 'success'>
  ) }
);

export type NewOrderInformationQueryVariables = Exact<{
  donationIntents: Array<DonationIntentInput> | DonationIntentInput;
  benefactorId?: Maybe<Scalars['BenefactorId']>;
}>;


export type NewOrderInformationQuery = (
  { __typename?: 'Query' }
  & { newOrderInformation: (
    { __typename?: 'NewOrderInformation' }
    & { availablePaymentMethods: Array<(
      { __typename?: 'CloudPaymentsNewCardPaymentMethod' }
      & Pick<CloudPaymentsNewCardPaymentMethod, 'title'>
    ) | (
      { __typename?: 'CloudPaymentsSavedCardPaymentMethod' }
      & Pick<CloudPaymentsSavedCardPaymentMethod, 'title'>
    ) | (
      { __typename?: 'CloudPaymentsSbpPaymentMethod' }
      & Pick<CloudPaymentsSbpPaymentMethod, 'title'>
    ) | (
      { __typename?: 'VoucherPaymentMethod' }
      & Pick<VoucherPaymentMethod, 'title'>
    )>, commissionPercentageSuggestions: Array<(
      { __typename?: 'CommissionPercentageSuggestion' }
      & Pick<CommissionPercentageSuggestion, 'commissionPercentage' | 'title' | 'description'>
    )> }
  ) }
);

export type OrderStatusQueryVariables = Exact<{
  id: Scalars['OrderId'];
}>;


export type OrderStatusQuery = (
  { __typename?: 'Query' }
  & { orderById?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'status'>
  )> }
);

export type PlaceOrderMutationVariables = Exact<{
  input: PlaceOrderInput;
}>;


export type PlaceOrderMutation = (
  { __typename?: 'Mutation' }
  & { placeOrder: (
    { __typename?: 'PlaceOrderPayload' }
    & { errors?: Maybe<Array<(
      { __typename?: 'OrderPlacementError' }
      & Pick<OrderPlacementError, 'message'>
    )>>, order?: Maybe<(
      { __typename?: 'Order' }
      & Pick<Order, 'id'>
    )> }
  ) }
);

export type CreateBenefactorDepositAccountForAllWardsMutationVariables = Exact<{
  input: CreateBenefactorDepositAccountForAllWardsInput;
}>;


export type CreateBenefactorDepositAccountForAllWardsMutation = (
  { __typename?: 'Mutation' }
  & { createBenefactorDepositAccountForAllWards: (
    { __typename?: 'CreateBenefactorDepositAccountForAllWardsPayload' }
    & { benefactor?: Maybe<(
      { __typename?: 'Benefactor' }
      & BenefactorExtendedPropsFragment
      & BenefactorPaymentInformationFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'DepositAccountAlreadyExistsError' }
      & Pick<DepositAccountAlreadyExistsError, 'message'>
    )>> }
  ) }
);

export type CreateBenefactorDepositAccountForProjectNeedsMutationVariables = Exact<{
  input: CreateBenefactorDepositAccountForProjectNeedsInput;
}>;


export type CreateBenefactorDepositAccountForProjectNeedsMutation = (
  { __typename?: 'Mutation' }
  & { createBenefactorDepositAccountForProjectNeeds: (
    { __typename?: 'CreateBenefactorDepositAccountForProjectNeedsPayload' }
    & { benefactor?: Maybe<(
      { __typename?: 'Benefactor' }
      & BenefactorExtendedPropsFragment
      & BenefactorPaymentInformationFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'DepositAccountAlreadyExistsError' }
      & Pick<DepositAccountAlreadyExistsError, 'message'>
    )>> }
  ) }
);

export type CreateBenefactorDepositAccountForSpecificWardsMutationVariables = Exact<{
  input: CreateBenefactorDepositAccountForSpecificWardsInput;
}>;


export type CreateBenefactorDepositAccountForSpecificWardsMutation = (
  { __typename?: 'Mutation' }
  & { createBenefactorDepositAccountForSpecificWards: (
    { __typename?: 'CreateBenefactorDepositAccountForSpecificWardsPayload' }
    & { benefactor?: Maybe<(
      { __typename?: 'Benefactor' }
      & BenefactorExtendedPropsFragment
      & BenefactorPaymentInformationFragment
    )> }
  ) }
);

export type CreateBenefactorDepositAccountWithoutConditionsMutationVariables = Exact<{
  input: CreateBenefactorDepositAccountWithoutConditionsInput;
}>;


export type CreateBenefactorDepositAccountWithoutConditionsMutation = (
  { __typename?: 'Mutation' }
  & { createBenefactorDepositAccountWithoutConditions: (
    { __typename?: 'CreateBenefactorDepositAccountWithoutConditionsPayload' }
    & { benefactor?: Maybe<(
      { __typename?: 'Benefactor' }
      & BenefactorExtendedPropsFragment
      & BenefactorPaymentInformationFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'DepositAccountAlreadyExistsError' }
      & Pick<DepositAccountAlreadyExistsError, 'message'>
    )>> }
  ) }
);

export type PlaceOrderDepositMutationVariables = Exact<{
  input: PlaceOrderInput;
}>;


export type PlaceOrderDepositMutation = (
  { __typename?: 'Mutation' }
  & { placeOrder: (
    { __typename?: 'PlaceOrderPayload' }
    & { errors?: Maybe<Array<(
      { __typename?: 'OrderPlacementError' }
      & Pick<OrderPlacementError, 'message'>
    )>>, order?: Maybe<(
      { __typename?: 'Order' }
      & OrdersPropsFragment
    )> }
  ) }
);

export type BenefactorByIdQueryVariables = Exact<{
  id: Scalars['BenefactorId'];
  permission: Scalars['Boolean'];
}>;


export type BenefactorByIdQuery = (
  { __typename?: 'Query' }
  & { benefactorById: (
    { __typename?: 'Benefactor' }
    & BenefactorExtendedPropsFragment
  ), paymentInformation: Maybe<(
    { __typename?: 'Benefactor' }
    & BenefactorPaymentInformationFragment
  )> }
);

export type BenefactorByIdWithoutPermissionQueryVariables = Exact<{
  id: Scalars['BenefactorId'];
}>;


export type BenefactorByIdWithoutPermissionQuery = (
  { __typename?: 'Query' }
  & { benefactorById: (
    { __typename?: 'Benefactor' }
    & BenefactorExtendedPropsWithoutPermissionFragment
  ) }
);

export type BenefactorExtendedPropsFragment = (
  { __typename?: 'Benefactor' }
  & Pick<Benefactor, 'id' | 'displayName' | 'phoneNumber' | 'createdAt' | 'email' | 'hideProfile' | 'amountOfDonationsInWardsReserveAccount' | 'coins' | 'citiesOfInterest' | 'wardsCount'>
  & { user?: Maybe<(
    { __typename?: 'BenefactorIdentity' }
    & Pick<BenefactorIdentity, 'disabled'>
  ) | (
    { __typename?: 'FoundationRepresentative' }
    & Pick<FoundationRepresentative, 'disabled'>
  ) | (
    { __typename?: 'Moderator' }
    & Pick<Moderator, 'disabled'>
  ) | (
    { __typename?: 'SystemAdministrator' }
    & Pick<SystemAdministrator, 'disabled'>
  )>, skillsInformation?: Maybe<(
    { __typename?: 'BenefactorSkillsInformation' }
    & Pick<BenefactorSkillsInformation, 'experience' | 'portfolio'>
    & { skills: Array<(
      { __typename?: 'Skill' }
      & Pick<Skill, 'id' | 'title' | 'description' | 'portfolioIsRequired'>
    )> }
  )>, personalData?: Maybe<(
    { __typename?: 'BenefactorPersonalData' }
    & Pick<BenefactorPersonalData, 'birthday' | 'about' | 'vkUsername'>
    & { name: (
      { __typename?: 'Nomen' }
      & Pick<Nomen, 'fullName' | 'firstName' | 'lastName' | 'middleName'>
    ) }
  )>, intentsToRecoverCancelledSubscriptions: Array<(
    { __typename: 'ProjectSubscriptionIntent' }
    & Pick<ProjectSubscriptionIntent, 'amount'>
  ) | { __typename?: 'WardsReserveSubscriptionIntent' } | (
    { __typename: 'WardsSubscriptionIntent' }
    & Pick<WardsSubscriptionIntent, 'commissionPercentage' | 'isCommissionCoveredByBenefactor'>
    & { wardsSubscriptionsAmounts: Array<(
      { __typename?: 'WardSubscriptionAmount' }
      & Pick<WardSubscriptionAmount, 'amount'>
      & { ward: (
        { __typename?: 'Ward' }
        & Pick<Ward, 'id'>
        & { publicInformation: (
          { __typename?: 'WardPublicInformation' }
          & { name: (
            { __typename?: 'Nomen' }
            & Pick<Nomen, 'fullName'>
          ) }
        ) }
      ) }
    )> }
  )>, volunteerInformation?: Maybe<(
    { __typename?: 'BenefactorVolunteerInformation' }
    & Pick<BenefactorVolunteerInformation, 'experience'>
    & { documents: Array<(
      { __typename?: 'Document' }
      & Pick<Document, 'title'>
      & { file: (
        { __typename?: 'FileInformation' }
        & Pick<FileInformation, 'id' | 'url'>
      ) }
    )> }
  )>, orders?: Maybe<(
    { __typename?: 'OrdersConnection' }
    & Pick<OrdersConnection, 'totalCount'>
    & { nodes?: Maybe<Array<(
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'status'>
    )>> }
  )>, cloudPaymentsSavedCards: Array<(
    { __typename?: 'CloudPaymentsSavedCard' }
    & CloudPaymentsSavedCardsPropsFragment
  )>, defaultCloudPaymentsSavedCard?: Maybe<(
    { __typename?: 'CloudPaymentsSavedCard' }
    & CloudPaymentsSavedCardsPropsFragment
  )>, photo?: Maybe<(
    { __typename?: 'FileInformation' }
    & Pick<FileInformation, 'id' | 'url'>
  )> }
);

export type BenefactorExtendedPropsWithoutPermissionFragment = (
  { __typename?: 'Benefactor' }
  & Pick<Benefactor, 'id' | 'displayName' | 'phoneNumber' | 'createdAt' | 'email' | 'hideProfile' | 'amountOfDonationsInWardsReserveAccount' | 'coins' | 'citiesOfInterest' | 'wardsCount'>
  & { user?: Maybe<(
    { __typename?: 'BenefactorIdentity' }
    & Pick<BenefactorIdentity, 'disabled'>
  ) | (
    { __typename?: 'FoundationRepresentative' }
    & Pick<FoundationRepresentative, 'disabled'>
  ) | (
    { __typename?: 'Moderator' }
    & Pick<Moderator, 'disabled'>
  ) | (
    { __typename?: 'SystemAdministrator' }
    & Pick<SystemAdministrator, 'disabled'>
  )>, skillsInformation?: Maybe<(
    { __typename?: 'BenefactorSkillsInformation' }
    & Pick<BenefactorSkillsInformation, 'experience' | 'portfolio'>
    & { skills: Array<(
      { __typename?: 'Skill' }
      & Pick<Skill, 'id' | 'title' | 'description' | 'portfolioIsRequired'>
    )> }
  )>, personalData?: Maybe<(
    { __typename?: 'BenefactorPersonalData' }
    & Pick<BenefactorPersonalData, 'birthday' | 'about' | 'vkUsername'>
    & { name: (
      { __typename?: 'Nomen' }
      & Pick<Nomen, 'fullName' | 'firstName' | 'lastName' | 'middleName'>
    ) }
  )>, intentsToRecoverCancelledSubscriptions: Array<(
    { __typename: 'ProjectSubscriptionIntent' }
    & Pick<ProjectSubscriptionIntent, 'amount'>
  ) | { __typename?: 'WardsReserveSubscriptionIntent' } | (
    { __typename: 'WardsSubscriptionIntent' }
    & Pick<WardsSubscriptionIntent, 'commissionPercentage' | 'isCommissionCoveredByBenefactor'>
    & { wardsSubscriptionsAmounts: Array<(
      { __typename?: 'WardSubscriptionAmount' }
      & Pick<WardSubscriptionAmount, 'amount'>
      & { ward: (
        { __typename?: 'Ward' }
        & Pick<Ward, 'id'>
        & { publicInformation: (
          { __typename?: 'WardPublicInformation' }
          & { name: (
            { __typename?: 'Nomen' }
            & Pick<Nomen, 'fullName'>
          ) }
        ) }
      ) }
    )> }
  )>, volunteerInformation?: Maybe<(
    { __typename?: 'BenefactorVolunteerInformation' }
    & Pick<BenefactorVolunteerInformation, 'experience'>
    & { documents: Array<(
      { __typename?: 'Document' }
      & Pick<Document, 'title'>
      & { file: (
        { __typename?: 'FileInformation' }
        & Pick<FileInformation, 'id' | 'url'>
      ) }
    )> }
  )>, photo?: Maybe<(
    { __typename?: 'FileInformation' }
    & Pick<FileInformation, 'id' | 'url'>
  )> }
);

export type BenefactorPaymentInformationFragment = (
  { __typename?: 'Benefactor' }
  & Pick<Benefactor, 'id' | 'failedPaymentsCount' | 'paymentsAmount'>
  & { depositAccounts: Array<(
    { __typename: 'BenefactorDepositAccountForSpecificWards' }
    & Pick<BenefactorDepositAccountForSpecificWards, 'amount' | 'id' | 'title'>
    & { wards: Array<(
      { __typename?: 'Ward' }
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
      & WardsGridWardFragment
    )> }
  ) | (
    { __typename: 'RegularNeedsAccount' }
    & Pick<RegularNeedsAccount, 'amount' | 'id'>
  ) | (
    { __typename: 'BenefactorDepositAccountForAllWards' }
    & Pick<BenefactorDepositAccountForAllWards, 'amount' | 'id'>
  ) | (
    { __typename: 'BenefactorDepositAccountForProjectNeeds' }
    & Pick<BenefactorDepositAccountForProjectNeeds, 'amount' | 'id'>
  ) | (
    { __typename: 'BenefactorDepositAccountWithoutConditions' }
    & Pick<BenefactorDepositAccountWithoutConditions, 'amount' | 'id'>
  ) | (
    { __typename: 'OneTimeNeedAccount' }
    & Pick<OneTimeNeedAccount, 'amount' | 'id'>
  ) | (
    { __typename: 'ProjectExpenseAccount' }
    & Pick<ProjectExpenseAccount, 'amount' | 'id'>
  ) | (
    { __typename: 'RegularNeedPeriodAccount' }
    & Pick<RegularNeedPeriodAccount, 'amount' | 'id'>
  ) | (
    { __typename: 'SpecialProjectAccount' }
    & Pick<SpecialProjectAccount, 'amount' | 'id'>
  ) | (
    { __typename: 'SystemAccount' }
    & Pick<SystemAccount, 'amount' | 'id'>
  )> }
);

export type BenefactorPaymentInformationByIdQueryVariables = Exact<{
  id: Scalars['BenefactorId'];
}>;


export type BenefactorPaymentInformationByIdQuery = (
  { __typename?: 'Query' }
  & { benefactorById: (
    { __typename?: 'Benefactor' }
    & BenefactorPaymentInformationFragment
  ) }
);

export type BenefactorPermissionsByIdQueryVariables = Exact<{
  id: Scalars['BenefactorId'];
}>;


export type BenefactorPermissionsByIdQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Me' }
    & { permissions: (
      { __typename?: 'Permissions' }
      & { benefactor: (
        { __typename?: 'BenefactorPermissions' }
        & Pick<BenefactorPermissions, 'edit' | 'viewContactInformation' | 'viewPaymentInformation' | 'viewPersonalData' | 'viewVolunteerInformation'>
      ) }
    ) }
  ) }
);

export type CloudPaymentsSavedCardsPropsFragment = (
  { __typename?: 'CloudPaymentsSavedCard' }
  & Pick<CloudPaymentsSavedCard, 'id' | 'maskedCardNumber' | 'failureMessage' | 'failuresCount' | 'cardType' | 'cardExpirationDate'>
);

export type BenefactorsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Array<BenefactorSort> | BenefactorSort>;
  where?: Maybe<BenefactorFilter>;
  last?: Maybe<Scalars['Int']>;
  permission: Scalars['Boolean'];
}>;


export type BenefactorsQuery = (
  { __typename?: 'Query' }
  & { benefactors?: Maybe<(
    { __typename?: 'BenefactorsConnection' }
    & Pick<BenefactorsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
    ), nodes?: Maybe<Array<(
      { __typename?: 'Benefactor' }
      & BenefactorPropsFragment
      & BenefactorPaymentInformationFragment
    )>> }
  )> }
);

export type BenefactorsWithoutPermissionsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Array<BenefactorSort> | BenefactorSort>;
  where?: Maybe<BenefactorFilter>;
  last?: Maybe<Scalars['Int']>;
}>;


export type BenefactorsWithoutPermissionsQuery = (
  { __typename?: 'Query' }
  & { benefactors?: Maybe<(
    { __typename?: 'BenefactorsConnection' }
    & Pick<BenefactorsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
    ), nodes?: Maybe<Array<(
      { __typename?: 'Benefactor' }
      & BenefactorPropsFragment
    )>> }
  )> }
);

export type UpdateBenefactorByAdministratorMutationVariables = Exact<{
  input: UpdateBenefactorByAdministratorInput;
}>;


export type UpdateBenefactorByAdministratorMutation = (
  { __typename?: 'Mutation' }
  & { updateBenefactorByAdministrator: (
    { __typename?: 'UpdateBenefactorByAdministratorPayload' }
    & { benefactor?: Maybe<(
      { __typename?: 'Benefactor' }
      & BenefactorExtendedPropsFragment
    )> }
  ) }
);

export type UpdateBenefactorPersonalDataByAdministratorMutationVariables = Exact<{
  input: UpdateBenefactorPersonalDataByAdministratorInput;
}>;


export type UpdateBenefactorPersonalDataByAdministratorMutation = (
  { __typename?: 'Mutation' }
  & { updateBenefactorPersonalDataByAdministrator: (
    { __typename?: 'UpdateBenefactorPersonalDataByAdministratorPayload' }
    & { benefactor?: Maybe<(
      { __typename?: 'Benefactor' }
      & BenefactorExtendedPropsFragment
    )> }
  ) }
);

export type UpdateBenefactorVolunteerInformationByAdministratorMutationVariables = Exact<{
  input: UpdateBenefactorVolunteerInformationByAdministratorInput;
}>;


export type UpdateBenefactorVolunteerInformationByAdministratorMutation = (
  { __typename?: 'Mutation' }
  & { updateBenefactorVolunteerInformationByAdministrator: (
    { __typename?: 'UpdateBenefactorVolunteerInformationByAdministratorPayload' }
    & { benefactor?: Maybe<(
      { __typename?: 'Benefactor' }
      & BenefactorExtendedPropsFragment
    )> }
  ) }
);

export type UpdateBenefactorSkillsByAdministratorMutationVariables = Exact<{
  input: UpdateBenefactorSkillsInformationByAdministratorInput;
}>;


export type UpdateBenefactorSkillsByAdministratorMutation = (
  { __typename?: 'Mutation' }
  & { updateBenefactorSkillsInformationByAdministrator: (
    { __typename?: 'UpdateBenefactorSkillsInformationByAdministratorPayload' }
    & { benefactor?: Maybe<(
      { __typename?: 'Benefactor' }
      & BenefactorExtendedPropsFragment
    )> }
  ) }
);

export type UpdateBenefactorDepositAccountForSpecificWardsMutationVariables = Exact<{
  input: UpdateBenefactorDepositAccountForSpecificWardsInput;
}>;


export type UpdateBenefactorDepositAccountForSpecificWardsMutation = (
  { __typename?: 'Mutation' }
  & { updateBenefactorDepositAccountForSpecificWards: (
    { __typename?: 'UpdateBenefactorDepositAccountForSpecificWardsPayload' }
    & { benefactorDepositAccountForSpecificWards?: Maybe<(
      { __typename?: 'BenefactorDepositAccountForSpecificWards' }
      & Pick<BenefactorDepositAccountForSpecificWards, 'id' | 'title'>
      & { wards: Array<(
        { __typename?: 'Ward' }
        & WardPropsFragment
      )> }
    )> }
  ) }
);

export type BenefactorAchievementsQueryVariables = Exact<{
  id: Scalars['BenefactorId'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type BenefactorAchievementsQuery = (
  { __typename?: 'Query' }
  & { benefactorById: (
    { __typename?: 'Benefactor' }
    & Pick<Benefactor, 'id'>
    & { achievements?: Maybe<(
      { __typename?: 'BenefactorAchievementsConnection' }
      & Pick<BenefactorAchievementsConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<Maybe<(
        { __typename?: 'BenefactorAchievementWithMaterialPrize' }
        & Pick<BenefactorAchievementWithMaterialPrize, 'receivedAt'>
        & { achievement: (
          { __typename?: 'AchievementWithMaterialPrizes' }
          & AchievementsProps_AchievementWithMaterialPrizes_Fragment
        ) | (
          { __typename?: 'AchievementWithPromoCodes' }
          & AchievementsProps_AchievementWithPromoCodes_Fragment
        ) | (
          { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
          & AchievementsProps_AchievementWithUnlimitedNumberOfPrizes_Fragment
        ) }
      ) | (
        { __typename?: 'BenefactorAchievementWithPromoCode' }
        & Pick<BenefactorAchievementWithPromoCode, 'receivedAt'>
        & { achievement: (
          { __typename?: 'AchievementWithMaterialPrizes' }
          & AchievementsProps_AchievementWithMaterialPrizes_Fragment
        ) | (
          { __typename?: 'AchievementWithPromoCodes' }
          & AchievementsProps_AchievementWithPromoCodes_Fragment
        ) | (
          { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
          & AchievementsProps_AchievementWithUnlimitedNumberOfPrizes_Fragment
        ) }
      ) | (
        { __typename?: 'BenefactorAchievementWithVideo' }
        & Pick<BenefactorAchievementWithVideo, 'receivedAt'>
        & { achievement: (
          { __typename?: 'AchievementWithMaterialPrizes' }
          & AchievementsProps_AchievementWithMaterialPrizes_Fragment
        ) | (
          { __typename?: 'AchievementWithPromoCodes' }
          & AchievementsProps_AchievementWithPromoCodes_Fragment
        ) | (
          { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
          & AchievementsProps_AchievementWithUnlimitedNumberOfPrizes_Fragment
        ) }
      ) | (
        { __typename?: 'BenefactorAchievementWithoutPrize' }
        & Pick<BenefactorAchievementWithoutPrize, 'receivedAt'>
        & { achievement: (
          { __typename?: 'AchievementWithMaterialPrizes' }
          & AchievementsProps_AchievementWithMaterialPrizes_Fragment
        ) | (
          { __typename?: 'AchievementWithPromoCodes' }
          & AchievementsProps_AchievementWithPromoCodes_Fragment
        ) | (
          { __typename?: 'AchievementWithUnlimitedNumberOfPrizes' }
          & AchievementsProps_AchievementWithUnlimitedNumberOfPrizes_Fragment
        ) }
      )>>> }
    )> }
  ) }
);

export type BenefactorApplicationsQueryVariables = Exact<{
  id: Scalars['BenefactorId'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type BenefactorApplicationsQuery = (
  { __typename?: 'Query' }
  & { benefactorById: (
    { __typename?: 'Benefactor' }
    & Pick<Benefactor, 'id'>
    & { foundationEventApplications?: Maybe<(
      { __typename?: 'FoundationEventApplicationsConnection' }
      & Pick<FoundationEventApplicationsConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<(
        { __typename?: 'FoundationEventApplication' }
        & Pick<FoundationEventApplication, 'id'>
        & { event: (
          { __typename?: 'FoundationEvent' }
          & { details: (
            { __typename?: 'FoundationEventDetails' }
            & Pick<FoundationEventDetails, 'title'>
          ), foundation?: Maybe<(
            { __typename?: 'Foundation' }
            & Pick<Foundation, 'name'>
          )> }
        ), created: (
          { __typename?: 'AuditMarker' }
          & Pick<AuditMarker, 'timestamp'>
        ) }
      )>> }
    )> }
  ) }
);

export type BenefactorAttendancesQueryVariables = Exact<{
  id: Scalars['BenefactorId'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type BenefactorAttendancesQuery = (
  { __typename?: 'Query' }
  & { benefactorById: (
    { __typename?: 'Benefactor' }
    & Pick<Benefactor, 'id'>
    & { foundationEventAttendance?: Maybe<(
      { __typename?: 'FoundationEventAttendanceConnection' }
      & Pick<FoundationEventAttendanceConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<(
        { __typename?: 'FoundationEventAttendance' }
        & Pick<FoundationEventAttendance, 'id' | 'foundationRating' | 'foundationComment'>
        & { foundationEvent: (
          { __typename?: 'FoundationEvent' }
          & { details: (
            { __typename?: 'FoundationEventDetails' }
            & Pick<FoundationEventDetails, 'title'>
          ), foundation?: Maybe<(
            { __typename?: 'Foundation' }
            & Pick<Foundation, 'name'>
          )> }
        ) }
      )>> }
    )> }
  ) }
);

export type BenefactorOrdersQueryVariables = Exact<{
  id: Scalars['BenefactorId'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OrderSortInput> | OrderSortInput>;
}>;


export type BenefactorOrdersQuery = (
  { __typename?: 'Query' }
  & { benefactorById: (
    { __typename?: 'Benefactor' }
    & Pick<Benefactor, 'id'>
    & { orders?: Maybe<(
      { __typename?: 'OrdersConnection' }
      & Pick<OrdersConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<(
        { __typename?: 'Order' }
        & OrdersPropsFragment
      )>> }
    )> }
  ) }
);

export type BenefactorPropsFragment = (
  { __typename?: 'Benefactor' }
  & Pick<Benefactor, 'id' | 'displayName' | 'phoneNumber' | 'createdAt' | 'email' | 'hideProfile' | 'failedPaymentsCount' | 'wardsCount'>
  & { photo?: Maybe<(
    { __typename?: 'FileInformation' }
    & Pick<FileInformation, 'id' | 'url'>
  )> }
);

export type BenefactorByIdStatusesQueryVariables = Exact<{
  id: Scalars['BenefactorId'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type BenefactorByIdStatusesQuery = (
  { __typename?: 'Query' }
  & { benefactorById: (
    { __typename?: 'Benefactor' }
    & Pick<Benefactor, 'id'>
    & { statuses?: Maybe<(
      { __typename?: 'BenefactorStatusesConnection' }
      & Pick<BenefactorStatusesConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<(
        { __typename?: 'BenefactorStatus' }
        & BenefactorStatusPropsFragment
      )>> }
    )> }
  ) }
);

export type BenefactorSubscriptionsQueryVariables = Exact<{
  id: Scalars['BenefactorId'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type BenefactorSubscriptionsQuery = (
  { __typename?: 'Query' }
  & { benefactorById: (
    { __typename?: 'Benefactor' }
    & Pick<Benefactor, 'id'>
    & { subscriptions?: Maybe<(
      { __typename?: 'SubscriptionsConnection' }
      & Pick<SubscriptionsConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<Maybe<(
        { __typename?: 'WardDonationSubscription' }
        & DonationSubscriptionProps_WardDonationSubscription_Fragment
      ) | (
        { __typename?: 'ProjectDonationSubscription' }
        & DonationSubscriptionProps_ProjectDonationSubscription_Fragment
      ) | (
        { __typename?: 'WardsReserveDonationSubscription' }
        & DonationSubscriptionProps_WardsReserveDonationSubscription_Fragment
      )>>> }
    )> }
  ) }
);

export type DonationsPaymentQueryVariables = Exact<{
  id: Scalars['OrderId'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type DonationsPaymentQuery = (
  { __typename?: 'Query' }
  & { orderById?: Maybe<(
    { __typename?: 'Order' }
    & { actualAmountSummary?: Maybe<(
      { __typename?: 'OrderAmountSummary' }
      & OrderAmountSummaryPropsFragment
    )>, intentAmountSummary?: Maybe<(
      { __typename?: 'OrderAmountSummary' }
      & OrderAmountSummaryPropsFragment
    )>, donationIntents: Array<{ __typename: 'BenefactorDepositAccountDonationIntent' } | { __typename: 'LegacyOneTimeDonationIntent' } | { __typename: 'LegacySubscriptionChargeIntent' } | { __typename: 'LegacySubscriptionIntent' } | { __typename: 'ProjectDonationIntent' } | { __typename: 'ProjectSubscriptionIntent' } | { __typename: 'SpecialProjectDonationIntent' } | { __typename: 'SubscriptionChargeIntent' } | { __typename: 'WardOneTimeNeedDonationIntent' } | { __typename: 'WardRegularNeedsDonationIntent' } | { __typename: 'WardsReserveDonationIntent' } | { __typename: 'WardsReserveSubscriptionIntent' } | { __typename: 'WardsSubscriptionIntent' }>, donations?: Maybe<(
      { __typename?: 'DonationsConnection' }
      & Pick<DonationsConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Donation' }
        & PaymentWithDonationGridFragment
      )>>> }
    )> }
    & OrdersPropsFragment
  )> }
);

type DonationSubscriptionProps_WardDonationSubscription_Fragment = (
  { __typename?: 'WardDonationSubscription' }
  & Pick<WardDonationSubscription, 'commissionAmount' | 'id' | 'amount' | 'createdAt' | 'cancelledAt'>
  & { ward: (
    { __typename?: 'Ward' }
    & WardPropsFragment
  ) }
);

type DonationSubscriptionProps_ProjectDonationSubscription_Fragment = (
  { __typename?: 'ProjectDonationSubscription' }
  & Pick<ProjectDonationSubscription, 'id' | 'amount' | 'createdAt' | 'cancelledAt'>
);

type DonationSubscriptionProps_WardsReserveDonationSubscription_Fragment = (
  { __typename?: 'WardsReserveDonationSubscription' }
  & Pick<WardsReserveDonationSubscription, 'id' | 'amount' | 'createdAt' | 'cancelledAt'>
);

export type DonationSubscriptionPropsFragment = DonationSubscriptionProps_WardDonationSubscription_Fragment | DonationSubscriptionProps_ProjectDonationSubscription_Fragment | DonationSubscriptionProps_WardsReserveDonationSubscription_Fragment;

export type DonationWardInfoFragment = (
  { __typename?: 'Ward' }
  & Pick<Ward, 'id'>
  & { publicInformation: (
    { __typename?: 'WardPublicInformation' }
    & { name: (
      { __typename?: 'Nomen' }
      & Pick<Nomen, 'fullName'>
    ) }
  ) }
);

export type BankTransferPaymentIntentPropsFragment = (
  { __typename?: 'BankTransferPaymentIntent' }
  & Pick<BankTransferPaymentIntent, 'transferDate' | 'documentNumber' | 'counterpartyName'>
);

export type NewCardPaymentIntentPropsFragment = (
  { __typename?: 'CloudPaymentsNewCardPaymentIntent' }
  & Pick<CloudPaymentsNewCardPaymentIntent, 'setAsDefaultCard' | 'saveCard'>
);

export type SavedCardPaymentIntentPropsFragment = (
  { __typename?: 'CloudPaymentsSavedCardPaymentIntent' }
  & { savedCard?: Maybe<(
    { __typename?: 'CloudPaymentsSavedCard' }
    & Pick<CloudPaymentsSavedCard, 'maskedCardNumber'>
  )> }
);

export type SbpPaymentIntentPropsFragment = (
  { __typename?: 'CloudPaymentsSbpPaymentIntent' }
  & Pick<CloudPaymentsSbpPaymentIntent, 'anyValue'>
);

export type VoucherPaymentIntentPropsFragment = (
  { __typename?: 'VoucherPaymentIntent' }
  & { voucher?: Maybe<(
    { __typename?: 'Voucher' }
    & Pick<Voucher, 'id' | 'title'>
  )> }
);

export type OrderAmountSummaryPropsFragment = (
  { __typename?: 'OrderAmountSummary' }
  & Pick<OrderAmountSummary, 'oneTimeProjectDonation' | 'oneTimeWardDonation' | 'projectSubscription' | 'projectSubscriptionCharge' | 'specialProjectDonation' | 'oneTimeWardsReserveDonation' | 'wardsReserveSubscription' | 'wardsReserveSubscriptionCharge' | 'wardSubscription' | 'wardSubscriptionCharge' | 'commission'>
);

export type OrdersPropsFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'amount' | 'status' | 'failureMessage'>
  & { created: (
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  ), paymentDetails?: Maybe<(
    { __typename?: 'BankTransferPaymentDetails' }
    & PaymentDetailsProps_BankTransferPaymentDetails_Fragment
  ) | (
    { __typename?: 'CloudPaymentsCardFailedPaymentDetails' }
    & PaymentDetailsProps_CloudPaymentsCardFailedPaymentDetails_Fragment
  ) | (
    { __typename?: 'CloudPaymentsCardSuccessfulPaymentDetails' }
    & PaymentDetailsProps_CloudPaymentsCardSuccessfulPaymentDetails_Fragment
  ) | (
    { __typename?: 'CloudPaymentsSbpFailedPaymentDetails' }
    & PaymentDetailsProps_CloudPaymentsSbpFailedPaymentDetails_Fragment
  ) | (
    { __typename?: 'CloudPaymentsSbpSuccessfulPaymentDetails' }
    & PaymentDetailsProps_CloudPaymentsSbpSuccessfulPaymentDetails_Fragment
  ) | (
    { __typename?: 'GenericFailedPaymentDetails' }
    & PaymentDetailsProps_GenericFailedPaymentDetails_Fragment
  ) | (
    { __typename?: 'VoucherFailedPaymentDetails' }
    & PaymentDetailsProps_VoucherFailedPaymentDetails_Fragment
  ) | (
    { __typename?: 'VoucherSuccessfulPaymentDetails' }
    & PaymentDetailsProps_VoucherSuccessfulPaymentDetails_Fragment
  )>, paymentIntent?: Maybe<(
    { __typename?: 'BankTransferPaymentIntent' }
    & PaymentIntentProps_BankTransferPaymentIntent_Fragment
  ) | (
    { __typename?: 'CloudPaymentsNewCardPaymentIntent' }
    & PaymentIntentProps_CloudPaymentsNewCardPaymentIntent_Fragment
  ) | (
    { __typename?: 'CloudPaymentsSavedCardPaymentIntent' }
    & PaymentIntentProps_CloudPaymentsSavedCardPaymentIntent_Fragment
  ) | (
    { __typename?: 'CloudPaymentsSbpPaymentIntent' }
    & PaymentIntentProps_CloudPaymentsSbpPaymentIntent_Fragment
  ) | (
    { __typename?: 'VoucherPaymentIntent' }
    & PaymentIntentProps_VoucherPaymentIntent_Fragment
  )> }
);

type PaymentDetailsProps_BankTransferPaymentDetails_Fragment = (
  { __typename?: 'BankTransferPaymentDetails' }
  & BankTransferPaymentDetailsPropsFragment
);

type PaymentDetailsProps_CloudPaymentsCardFailedPaymentDetails_Fragment = (
  { __typename?: 'CloudPaymentsCardFailedPaymentDetails' }
  & CloudPaymentsCardFailedPaymentDetailsPropsFragment
);

type PaymentDetailsProps_CloudPaymentsCardSuccessfulPaymentDetails_Fragment = (
  { __typename?: 'CloudPaymentsCardSuccessfulPaymentDetails' }
  & CardSuccessfulPaymentDetailsPropsFragment
);

type PaymentDetailsProps_CloudPaymentsSbpFailedPaymentDetails_Fragment = (
  { __typename?: 'CloudPaymentsSbpFailedPaymentDetails' }
  & SbpFailedPaymentDetailsPropsFragment
);

type PaymentDetailsProps_CloudPaymentsSbpSuccessfulPaymentDetails_Fragment = (
  { __typename?: 'CloudPaymentsSbpSuccessfulPaymentDetails' }
  & SbpSuccessfulPaymentDetailsPropsFragment
);

type PaymentDetailsProps_GenericFailedPaymentDetails_Fragment = (
  { __typename?: 'GenericFailedPaymentDetails' }
  & GenericFailedPaymentDetailsPropsFragment
);

type PaymentDetailsProps_VoucherFailedPaymentDetails_Fragment = (
  { __typename?: 'VoucherFailedPaymentDetails' }
  & VoucherFailedPaymentDetailsPropsFragment
);

type PaymentDetailsProps_VoucherSuccessfulPaymentDetails_Fragment = (
  { __typename?: 'VoucherSuccessfulPaymentDetails' }
  & VoucherSuccessfulPaymentDetailsPropsFragment
);

export type PaymentDetailsPropsFragment = PaymentDetailsProps_BankTransferPaymentDetails_Fragment | PaymentDetailsProps_CloudPaymentsCardFailedPaymentDetails_Fragment | PaymentDetailsProps_CloudPaymentsCardSuccessfulPaymentDetails_Fragment | PaymentDetailsProps_CloudPaymentsSbpFailedPaymentDetails_Fragment | PaymentDetailsProps_CloudPaymentsSbpSuccessfulPaymentDetails_Fragment | PaymentDetailsProps_GenericFailedPaymentDetails_Fragment | PaymentDetailsProps_VoucherFailedPaymentDetails_Fragment | PaymentDetailsProps_VoucherSuccessfulPaymentDetails_Fragment;

type PaymentIntentProps_BankTransferPaymentIntent_Fragment = (
  { __typename?: 'BankTransferPaymentIntent' }
  & BankTransferPaymentIntentPropsFragment
);

type PaymentIntentProps_CloudPaymentsNewCardPaymentIntent_Fragment = (
  { __typename?: 'CloudPaymentsNewCardPaymentIntent' }
  & NewCardPaymentIntentPropsFragment
);

type PaymentIntentProps_CloudPaymentsSavedCardPaymentIntent_Fragment = (
  { __typename?: 'CloudPaymentsSavedCardPaymentIntent' }
  & SavedCardPaymentIntentPropsFragment
);

type PaymentIntentProps_CloudPaymentsSbpPaymentIntent_Fragment = (
  { __typename?: 'CloudPaymentsSbpPaymentIntent' }
  & SbpPaymentIntentPropsFragment
);

type PaymentIntentProps_VoucherPaymentIntent_Fragment = (
  { __typename?: 'VoucherPaymentIntent' }
  & VoucherPaymentIntentPropsFragment
);

export type PaymentIntentPropsFragment = PaymentIntentProps_BankTransferPaymentIntent_Fragment | PaymentIntentProps_CloudPaymentsNewCardPaymentIntent_Fragment | PaymentIntentProps_CloudPaymentsSavedCardPaymentIntent_Fragment | PaymentIntentProps_CloudPaymentsSbpPaymentIntent_Fragment | PaymentIntentProps_VoucherPaymentIntent_Fragment;

export type PaymentWithDonationGridFragment = (
  { __typename?: 'Donation' }
  & Pick<Donation, 'id' | 'createdAt' | 'amount'>
  & { account: { __typename: 'BenefactorDepositAccountForSpecificWards' } | (
    { __typename: 'RegularNeedsAccount' }
    & { ward: (
      { __typename?: 'Ward' }
      & Pick<Ward, 'id'>
    ) }
  ) | { __typename: 'BenefactorDepositAccountForAllWards' } | { __typename: 'BenefactorDepositAccountForProjectNeeds' } | { __typename: 'BenefactorDepositAccountWithoutConditions' } | { __typename: 'OneTimeNeedAccount' } | (
    { __typename: 'ProjectExpenseAccount' }
    & Pick<ProjectExpenseAccount, 'amount'>
  ) | (
    { __typename: 'RegularNeedPeriodAccount' }
    & { need: (
      { __typename?: 'OneTimeNeed' }
      & Pick<OneTimeNeed, 'id'>
    ) | (
      { __typename?: 'GrocerySetNeed' }
      & Pick<GrocerySetNeed, 'id'>
    ), regularNeedPeriod: (
      { __typename?: 'RegularNeedPeriod' }
      & Pick<RegularNeedPeriod, 'id' | 'start'>
    ), ward: (
      { __typename?: 'Ward' }
      & Pick<Ward, 'id'>
      & { publicInformation: (
        { __typename?: 'WardPublicInformation' }
        & { name: (
          { __typename?: 'Nomen' }
          & Pick<Nomen, 'fullName'>
        ) }
      ) }
    ) }
  ) | (
    { __typename: 'SpecialProjectAccount' }
    & { specialProject: (
      { __typename?: 'SpecialProject' }
      & Pick<SpecialProject, 'id' | 'title'>
    ) }
  ) | (
    { __typename: 'SystemAccount' }
    & Pick<SystemAccount, 'kind'>
  ) }
);

export type BankTransferPaymentDetailsPropsFragment = (
  { __typename?: 'BankTransferPaymentDetails' }
  & Pick<BankTransferPaymentDetails, 'documentNumber' | 'counterpartyName' | 'purpose' | 'transferDate'>
);

export type CloudPaymentsCardFailedPaymentDetailsPropsFragment = (
  { __typename?: 'CloudPaymentsCardFailedPaymentDetails' }
  & Pick<CloudPaymentsCardFailedPaymentDetails, 'transactionId' | 'cardholderMessage' | 'reasonCode'>
);

export type GenericFailedPaymentDetailsPropsFragment = (
  { __typename?: 'GenericFailedPaymentDetails' }
  & Pick<GenericFailedPaymentDetails, 'failureMessage'>
);

export type SbpFailedPaymentDetailsPropsFragment = (
  { __typename?: 'CloudPaymentsSbpFailedPaymentDetails' }
  & Pick<CloudPaymentsSbpFailedPaymentDetails, 'transactionId' | 'message'>
);

export type SbpSuccessfulPaymentDetailsPropsFragment = (
  { __typename?: 'CloudPaymentsSbpSuccessfulPaymentDetails' }
  & Pick<CloudPaymentsSbpSuccessfulPaymentDetails, 'transactionId'>
);

export type VoucherFailedPaymentDetailsPropsFragment = (
  { __typename?: 'VoucherFailedPaymentDetails' }
  & Pick<VoucherFailedPaymentDetails, 'reason'>
  & { maybeVoucher?: Maybe<(
    { __typename?: 'Voucher' }
    & Pick<Voucher, 'id' | 'title'>
  )> }
);

export type VoucherSuccessfulPaymentDetailsPropsFragment = (
  { __typename?: 'VoucherSuccessfulPaymentDetails' }
  & { voucher: (
    { __typename?: 'Voucher' }
    & Pick<Voucher, 'id' | 'title'>
  ) }
);

export type CardSuccessfulPaymentDetailsPropsFragment = (
  { __typename?: 'CloudPaymentsCardSuccessfulPaymentDetails' }
  & Pick<CloudPaymentsCardSuccessfulPaymentDetails, 'authorizationDate' | 'confirmDate' | 'transactionId' | 'authorizationCode'>
);

export type AddCategoryMutationVariables = Exact<{
  input: AddCategoryInput;
}>;


export type AddCategoryMutation = (
  { __typename?: 'Mutation' }
  & { addCategory: (
    { __typename?: 'AddCategoryPayload' }
    & { category?: Maybe<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name'>
    )> }
  ) }
);

export type DeleteCategoryMutationVariables = Exact<{
  input: DeleteCategoryInput;
}>;


export type DeleteCategoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteCategory: (
    { __typename?: 'DeleteCategoryPayload' }
    & Pick<DeleteCategoryPayload, 'success'>
  ) }
);

export type UpdateCategoryMutationVariables = Exact<{
  input: UpdateCategoryInput;
}>;


export type UpdateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateCategory: (
    { __typename?: 'UpdateCategoryPayload' }
    & { category?: Maybe<(
      { __typename?: 'Category' }
      & Pick<Category, 'id'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'CategoryAlreadyExistsError' }
      & Pick<CategoryAlreadyExistsError, 'message'>
    )>> }
  ) }
);

export type UpdateCategoriesOrderMutationVariables = Exact<{
  input: UpdateCategoriesOrderInput;
}>;


export type UpdateCategoriesOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateCategoriesOrder: (
    { __typename?: 'UpdateCategoriesOrderPayload' }
    & Pick<UpdateCategoriesOrderPayload, 'success'>
  ) }
);

export type CategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesQuery = (
  { __typename?: 'Query' }
  & { categories: Array<(
    { __typename?: 'Category' }
    & CategoryPropsFragment
  )> }
);

export type CategoryByIdQueryVariables = Exact<{
  id: Scalars['CategoryId'];
}>;


export type CategoryByIdQuery = (
  { __typename?: 'Query' }
  & { categoryById?: Maybe<(
    { __typename?: 'Category' }
    & CategoryPropsFragment
  )> }
);

export type CategoryPropsFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'name' | 'order'>
  & { image?: Maybe<(
    { __typename?: 'FileInformation' }
    & PhotoFilePropsFragment
  )> }
);

export type AddCityMutationVariables = Exact<{
  input: AddCityInput;
}>;


export type AddCityMutation = (
  { __typename?: 'Mutation' }
  & { addCity: (
    { __typename?: 'AddCityPayload' }
    & { city?: Maybe<(
      { __typename?: 'City' }
      & CityPropsFragment
    )> }
  ) }
);

export type UpdateCityMutationVariables = Exact<{
  input: UpdateCityInput;
}>;


export type UpdateCityMutation = (
  { __typename?: 'Mutation' }
  & { updateCity: (
    { __typename?: 'UpdateCityPayload' }
    & { city?: Maybe<(
      { __typename?: 'City' }
      & CityPropsFragment
    )>, errors?: Maybe<Array<(
      { __typename: 'CityAlreadyExistsError' }
      & Pick<CityAlreadyExistsError, 'message'>
    )>> }
  ) }
);

export type CitiesQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CitySortInput> | CitySortInput>;
}>;


export type CitiesQuery = (
  { __typename?: 'Query' }
  & { cities?: Maybe<(
    { __typename?: 'CitiesConnection' }
    & Pick<CitiesConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
    ), nodes?: Maybe<Array<(
      { __typename?: 'City' }
      & CityPropsFragment
    )>> }
  )> }
);

export type CityPropsFragment = (
  { __typename?: 'City' }
  & Pick<City, 'id' | 'name' | 'default' | 'order'>
);

export type CityByIdQueryVariables = Exact<{
  id: Scalars['CityId'];
}>;


export type CityByIdQuery = (
  { __typename?: 'Query' }
  & { cityById?: Maybe<(
    { __typename?: 'City' }
    & CityPropsFragment
  )> }
);

export type DeleteCityMutationVariables = Exact<{
  input: DeleteCityInput;
}>;


export type DeleteCityMutation = (
  { __typename?: 'Mutation' }
  & { deleteCity: (
    { __typename?: 'DeleteCityPayload' }
    & Pick<DeleteCityPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename: 'CityIsAssignedError' }
      & Pick<CityIsAssignedError, 'message'>
    )>> }
  ) }
);

export type CreateCompanyReportMutationVariables = Exact<{
  input: CreateLimitedLiabilityCompanyReportInput;
}>;


export type CreateCompanyReportMutation = (
  { __typename?: 'Mutation' }
  & { createLimitedLiabilityCompanyReport: (
    { __typename?: 'CreateLimitedLiabilityCompanyReportPayload' }
    & { report?: Maybe<(
      { __typename?: 'LimitedLiabilityCompanyReport' }
      & Pick<LimitedLiabilityCompanyReport, 'id'>
    )> }
  ) }
);

export type UpdateCompanyBalanceMutationVariables = Exact<{
  input: UpdateLimitedLiabilityCompanyBalanceInput;
}>;


export type UpdateCompanyBalanceMutation = (
  { __typename?: 'Mutation' }
  & { updateLimitedLiabilityCompanyBalance: (
    { __typename?: 'UpdateLimitedLiabilityCompanyBalancePayload' }
    & { company?: Maybe<(
      { __typename?: 'LimitedLiabilityCompany' }
      & Pick<LimitedLiabilityCompany, 'balance'>
    )> }
  ) }
);

export type CompanyReportPropsFragment = (
  { __typename?: 'LimitedLiabilityCompanyReport' }
  & Pick<LimitedLiabilityCompanyReport, 'id' | 'referenceDate' | 'title'>
  & { published?: Maybe<(
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  )>, documents: Array<(
    { __typename?: 'Document' }
    & DocumentFilePropsFragment
  )> }
);

export type LimitedLiabilityCompanyReportQueryVariables = Exact<{
  id: Scalars['LimitedLiabilityCompanyReportId'];
}>;


export type LimitedLiabilityCompanyReportQuery = (
  { __typename?: 'Query' }
  & { limitedLiabilityCompanyReport?: Maybe<(
    { __typename?: 'LimitedLiabilityCompanyReport' }
    & CompanyReportPropsFragment
  )> }
);

export type PublishLimitedLiabilityCompanyReportMutationVariables = Exact<{
  input: PublishLimitedLiabilityCompanyReportInput;
}>;


export type PublishLimitedLiabilityCompanyReportMutation = (
  { __typename?: 'Mutation' }
  & { publishLimitedLiabilityCompanyReport: (
    { __typename?: 'PublishLimitedLiabilityCompanyReportPayload' }
    & { report?: Maybe<(
      { __typename?: 'LimitedLiabilityCompanyReport' }
      & LimitedLiabilityCompanyReportFragment
    )> }
  ) }
);

export type UpdateCompanyReportDocumentsMutationVariables = Exact<{
  input: UpdateLimitedLiabilityCompanyReportDocumentsInput;
}>;


export type UpdateCompanyReportDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { updateLimitedLiabilityCompanyReportDocuments: (
    { __typename?: 'UpdateLimitedLiabilityCompanyReportDocumentsPayload' }
    & { report?: Maybe<(
      { __typename?: 'LimitedLiabilityCompanyReport' }
      & CompanyReportPropsFragment
    )> }
  ) }
);

export type LimitedLiabilityCompanyReportFragment = (
  { __typename?: 'LimitedLiabilityCompanyReport' }
  & Pick<LimitedLiabilityCompanyReport, 'id' | 'title' | 'referenceDate'>
  & { updated: (
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  ), published?: Maybe<(
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  )>, documents: Array<(
    { __typename?: 'Document' }
    & DocumentFilePropsFragment
  )> }
);

export type CompanyReportsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type CompanyReportsQuery = (
  { __typename?: 'Query' }
  & { limitedLiabilityCompany: (
    { __typename?: 'LimitedLiabilityCompany' }
    & Pick<LimitedLiabilityCompany, 'balance'>
    & { reports?: Maybe<(
      { __typename?: 'ReportsConnection' }
      & Pick<ReportsConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<(
        { __typename?: 'LimitedLiabilityCompanyReport' }
        & LimitedLiabilityCompanyReportFragment
      )>> }
    )> }
  ) }
);

export type AddGrocerySetNeedMutationVariables = Exact<{
  input: AddGrocerySetNeedInput;
}>;


export type AddGrocerySetNeedMutation = (
  { __typename?: 'Mutation' }
  & { addGrocerySetNeed: (
    { __typename?: 'AddGrocerySetNeedPayload' }
    & { need?: Maybe<(
      { __typename?: 'OneTimeNeed' }
      & Pick<OneTimeNeed, 'id' | 'requiredAmount'>
    ) | (
      { __typename?: 'GrocerySetNeed' }
      & Pick<GrocerySetNeed, 'id' | 'requiredAmount'>
    )> }
  ) }
);

export type AddOneTimeNeedMutationVariables = Exact<{
  input: AddOneTimeNeedInput;
}>;


export type AddOneTimeNeedMutation = (
  { __typename?: 'Mutation' }
  & { addOneTimeNeed: (
    { __typename?: 'AddOneTimeNeedPayload' }
    & { need?: Maybe<(
      { __typename?: 'OneTimeNeed' }
      & Pick<OneTimeNeed, 'id'>
    ) | (
      { __typename?: 'GrocerySetNeed' }
      & Pick<GrocerySetNeed, 'id'>
    )> }
  ) }
);

export type UpdateOneTimeNeedMutationVariables = Exact<{
  input: UpdateOneTimeNeedInput;
}>;


export type UpdateOneTimeNeedMutation = (
  { __typename?: 'Mutation' }
  & { updateOneTimeNeed: (
    { __typename?: 'UpdateOneTimeNeedPayload' }
    & { need?: Maybe<(
      { __typename?: 'OneTimeNeed' }
      & Pick<OneTimeNeed, 'id'>
    ) | (
      { __typename?: 'GrocerySetNeed' }
      & Pick<GrocerySetNeed, 'id'>
    )> }
  ) }
);

export type AmountsQueryVariables = Exact<{ [key: string]: never; }>;


export type AmountsQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & { mainAccount: (
      { __typename?: 'BenefactorDepositAccountForSpecificWards' }
      & Pick<BenefactorDepositAccountForSpecificWards, 'amount'>
    ) | (
      { __typename?: 'RegularNeedsAccount' }
      & Pick<RegularNeedsAccount, 'amount'>
    ) | (
      { __typename?: 'BenefactorDepositAccountForAllWards' }
      & Pick<BenefactorDepositAccountForAllWards, 'amount'>
    ) | (
      { __typename?: 'BenefactorDepositAccountForProjectNeeds' }
      & Pick<BenefactorDepositAccountForProjectNeeds, 'amount'>
    ) | (
      { __typename?: 'BenefactorDepositAccountWithoutConditions' }
      & Pick<BenefactorDepositAccountWithoutConditions, 'amount'>
    ) | (
      { __typename?: 'OneTimeNeedAccount' }
      & Pick<OneTimeNeedAccount, 'amount'>
    ) | (
      { __typename?: 'ProjectExpenseAccount' }
      & Pick<ProjectExpenseAccount, 'amount'>
    ) | (
      { __typename?: 'RegularNeedPeriodAccount' }
      & Pick<RegularNeedPeriodAccount, 'amount'>
    ) | (
      { __typename?: 'SpecialProjectAccount' }
      & Pick<SpecialProjectAccount, 'amount'>
    ) | (
      { __typename?: 'SystemAccount' }
      & Pick<SystemAccount, 'amount'>
    ), wardsReserveAccount: (
      { __typename?: 'BenefactorDepositAccountForSpecificWards' }
      & Pick<BenefactorDepositAccountForSpecificWards, 'amount'>
    ) | (
      { __typename?: 'RegularNeedsAccount' }
      & Pick<RegularNeedsAccount, 'amount'>
    ) | (
      { __typename?: 'BenefactorDepositAccountForAllWards' }
      & Pick<BenefactorDepositAccountForAllWards, 'amount'>
    ) | (
      { __typename?: 'BenefactorDepositAccountForProjectNeeds' }
      & Pick<BenefactorDepositAccountForProjectNeeds, 'amount'>
    ) | (
      { __typename?: 'BenefactorDepositAccountWithoutConditions' }
      & Pick<BenefactorDepositAccountWithoutConditions, 'amount'>
    ) | (
      { __typename?: 'OneTimeNeedAccount' }
      & Pick<OneTimeNeedAccount, 'amount'>
    ) | (
      { __typename?: 'ProjectExpenseAccount' }
      & Pick<ProjectExpenseAccount, 'amount'>
    ) | (
      { __typename?: 'RegularNeedPeriodAccount' }
      & Pick<RegularNeedPeriodAccount, 'amount'>
    ) | (
      { __typename?: 'SpecialProjectAccount' }
      & Pick<SpecialProjectAccount, 'amount'>
    ) | (
      { __typename?: 'SystemAccount' }
      & Pick<SystemAccount, 'amount'>
    ) }
  ), amounts: (
    { __typename?: 'Amounts' }
    & Pick<Amounts, 'amountOfProjectActiveSubscriptions' | 'amountOfProjectActiveSubscriptionsCreatedBeforeCurrentMonth' | 'amountOfProjectActiveSubscriptionsCreatedInCurrentMonth' | 'amountOfProjectNonSubscriptionDonations' | 'amountOfProjectNonSubscriptionDonationsCollectedInCurrentMonth' | 'amountOfProjectSubscriptionDonationsCollectedInCurrentMonth' | 'amountOfProjectSubscriptionsCancelledInCurrentMonth' | 'amountOfWardsRegularNeedsAccounts' | 'amountOfWardsActiveSubscriptions' | 'amountOfWardsActiveSubscriptionsCreatedBeforeCurrentMonth' | 'amountOfWardsActiveSubscriptionsCreatedInCurrentMonth' | 'amountOfWardsNonSubscriptionDonations' | 'amountOfWardsNonSubscriptionDonationsCollectedInCurrentMonth' | 'amountOfWardsSubscriptionDonationsCollectedInCurrentMonth' | 'amountOfWardsSubscriptionsCancelledInCurrentMonth' | 'amountOfBenefactorDeposits'>
  ) }
);

export type StatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type StatisticsQuery = (
  { __typename?: 'Query' }
  & { statistics: (
    { __typename?: 'Statistics' }
    & { today: (
      { __typename?: 'StatisticsPeriod' }
      & StatisticsPeriodFragmentFragment
    ), yesterday: (
      { __typename?: 'StatisticsPeriod' }
      & StatisticsPeriodFragmentFragment
    ), thisWeek: (
      { __typename?: 'StatisticsPeriod' }
      & StatisticsPeriodFragmentFragment
    ), lastWeek: (
      { __typename?: 'StatisticsPeriod' }
      & StatisticsPeriodFragmentFragment
    ), thisMonth: (
      { __typename?: 'StatisticsPeriod' }
      & StatisticsPeriodFragmentFragment
    ), lastMonth: (
      { __typename?: 'StatisticsPeriod' }
      & StatisticsPeriodFragmentFragment
    ) }
  ) }
);

export type StatisticsPeriodFragmentFragment = (
  { __typename?: 'StatisticsPeriod' }
  & Pick<StatisticsPeriod, 'start' | 'end' | 'transactionsAmount' | 'completedTransactionsCount'>
);

export type ForgotPasswordMutationVariables = Exact<{
  input: ForgotPasswordInput;
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & { forgotPassword: (
    { __typename?: 'ForgotPasswordPayload' }
    & Pick<ForgotPasswordPayload, 'success'>
  ) }
);

export type AddFoundationEventMutationVariables = Exact<{
  input: AddFoundationEventInput;
}>;


export type AddFoundationEventMutation = (
  { __typename?: 'Mutation' }
  & { addFoundationEvent: (
    { __typename?: 'AddFoundationEventPayload' }
    & { foundationEvent?: Maybe<(
      { __typename?: 'FoundationEvent' }
      & FoundationEventPropsFragment
    )> }
  ) }
);

export type UpdateFoundationEventMutationVariables = Exact<{
  input: UpdateFoundationEventInput;
}>;


export type UpdateFoundationEventMutation = (
  { __typename?: 'Mutation' }
  & { updateFoundationEvent: (
    { __typename?: 'UpdateFoundationEventPayload' }
    & { foundationEvent?: Maybe<(
      { __typename?: 'FoundationEvent' }
      & FoundationEventPropsFragment
    )> }
  ) }
);

export type AddFoundationMutationVariables = Exact<{
  input: AddFoundationInput;
}>;


export type AddFoundationMutation = (
  { __typename?: 'Mutation' }
  & { addFoundation: (
    { __typename?: 'AddFoundationPayload' }
    & { foundation?: Maybe<(
      { __typename?: 'Foundation' }
      & FoundationPropsFragment
    )> }
  ) }
);

export type UpdateFoundationMutationVariables = Exact<{
  input: UpdateFoundationInput;
}>;


export type UpdateFoundationMutation = (
  { __typename?: 'Mutation' }
  & { updateFoundation: (
    { __typename?: 'UpdateFoundationPayload' }
    & { foundation?: Maybe<(
      { __typename?: 'Foundation' }
      & FoundationPropsFragment
    )> }
  ) }
);

export type CreateFoundationRepresentativeMutationVariables = Exact<{
  input: CreateFoundationRepresentativeInput;
}>;


export type CreateFoundationRepresentativeMutation = (
  { __typename?: 'Mutation' }
  & { createFoundationRepresentative: (
    { __typename?: 'CreateFoundationRepresentativePayload' }
    & { user?: Maybe<(
      { __typename?: 'BenefactorIdentity' }
      & UserProps_BenefactorIdentity_Fragment
    ) | (
      { __typename?: 'FoundationRepresentative' }
      & UserProps_FoundationRepresentative_Fragment
    ) | (
      { __typename?: 'Moderator' }
      & UserProps_Moderator_Fragment
    ) | (
      { __typename?: 'SystemAdministrator' }
      & UserProps_SystemAdministrator_Fragment
    )>, errors?: Maybe<Array<(
      { __typename: 'EmailAlreadyRegisteredError' }
      & Pick<EmailAlreadyRegisteredError, 'message'>
    ) | (
      { __typename: 'UserCommunicationError' }
      & Pick<UserCommunicationError, 'message'>
    ) | (
      { __typename: 'UserPersistenceError' }
      & Pick<UserPersistenceError, 'message'>
    )>> }
  ) }
);

export type EventDetailsPropsFragment = (
  { __typename?: 'FoundationEventDetails' }
  & Pick<FoundationEventDetails, 'title' | 'start' | 'city' | 'phoneNumber' | 'passportRequired' | 'meetingInstructions' | 'description' | 'agreementUrl'>
  & { requirements: Array<(
    { __typename?: 'FoundationEventParticipationRequirement' }
    & Pick<FoundationEventParticipationRequirement, 'description' | 'quantity' | 'autoSelect'>
    & { skill: (
      { __typename?: 'Skill' }
      & Pick<Skill, 'id' | 'title'>
    ) }
  )>, images: Array<(
    { __typename?: 'FileInformation' }
    & Pick<FileInformation, 'id' | 'url'>
  )>, address: (
    { __typename?: 'Address' }
    & Pick<Address, 'displayText' | 'fullAddress'>
    & { city?: Maybe<(
      { __typename?: 'IdentifiableAddressElement' }
      & Pick<IdentifiableAddressElement, 'valueWithType'>
    )> }
  ), agreementFile?: Maybe<(
    { __typename?: 'FileInformation' }
    & Pick<FileInformation, 'url' | 'id'>
  )> }
);

export type EventPermissionsByIdQueryVariables = Exact<{
  id: Scalars['FoundationEventId'];
}>;


export type EventPermissionsByIdQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Me' }
    & { permissions: (
      { __typename?: 'Permissions' }
      & { foundationEvent: (
        { __typename?: 'FoundationEventPermissions' }
        & Pick<FoundationEventPermissions, 'id' | 'close' | 'edit' | 'takeOwnership' | 'openOrCloseRegistration' | 'rateAttendees' | 'requestApproval' | 'selectApplications' | 'view' | 'viewAttendees' | 'editApproved'>
      ) }
    ) }
  ) }
);

export type FoundationEventByIdQueryVariables = Exact<{
  id: Scalars['FoundationEventId'];
}>;


export type FoundationEventByIdQuery = (
  { __typename?: 'Query' }
  & { foundationEventById?: Maybe<(
    { __typename?: 'FoundationEvent' }
    & FoundationEventPropsFragment
  )> }
);

export type FoundationEventPropsFragment = (
  { __typename?: 'FoundationEvent' }
  & Pick<FoundationEvent, 'id' | 'checkinUrl'>
  & { report?: Maybe<(
    { __typename?: 'FoundationEventReport' }
    & Pick<FoundationEventReport, 'text'>
    & { images: Array<(
      { __typename?: 'Document' }
      & Pick<Document, 'title'>
      & { file: (
        { __typename?: 'FileInformation' }
        & Pick<FileInformation, 'id' | 'url'>
      ) }
    )> }
  )>, approvalRequested?: Maybe<(
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  )>, approved?: Maybe<(
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  )>, cancelled?: Maybe<(
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  )>, closed?: Maybe<(
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  )>, counters: (
    { __typename?: 'FoundationEventApplicantsCounters' }
    & Pick<FoundationEventApplicantsCounters, 'applied' | 'attended' | 'revoked' | 'selected'>
  ), created: (
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  ), owner: (
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  ), details: (
    { __typename?: 'FoundationEventDetails' }
    & EventDetailsPropsFragment
  ), foundation?: Maybe<(
    { __typename?: 'Foundation' }
    & Pick<Foundation, 'id' | 'name'>
  )>, registrationClosed?: Maybe<(
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  )>, updated: (
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  ) }
);

export type UpdateFoundationEventReportMutationVariables = Exact<{
  id: Scalars['FoundationEventId'];
  images: Array<DocumentInput> | DocumentInput;
  text: Scalars['String'];
}>;


export type UpdateFoundationEventReportMutation = (
  { __typename?: 'Mutation' }
  & { updateFoundationEventReport: (
    { __typename?: 'UpdateFoundationEventReportPayload' }
    & { foundationEvent?: Maybe<(
      { __typename?: 'FoundationEvent' }
      & FoundationEventPropsFragment
    )> }
  ) }
);

export type FoundationByIdQueryVariables = Exact<{
  id: Scalars['FoundationId'];
}>;


export type FoundationByIdQuery = (
  { __typename?: 'Query' }
  & { foundationById?: Maybe<(
    { __typename?: 'Foundation' }
    & FoundationPropsFragment
  )> }
);

export type FoundationPermissionsByIdQueryVariables = Exact<{
  id: Scalars['FoundationId'];
}>;


export type FoundationPermissionsByIdQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Me' }
    & { permissions: (
      { __typename?: 'Permissions' }
      & { foundation: (
        { __typename?: 'FoundationPermissions' }
        & Pick<FoundationPermissions, 'id' | 'createEvents' | 'addRepresentative' | 'edit' | 'viewRepresentatives'>
      ) }
    ) }
  ) }
);

export type FoundationsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<FoundationSortInput> | FoundationSortInput>;
  where?: Maybe<FoundationFilterInput>;
}>;


export type FoundationsQuery = (
  { __typename?: 'Query' }
  & { foundations?: Maybe<(
    { __typename: 'FoundationsConnection' }
    & Pick<FoundationsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
    ), nodes?: Maybe<Array<(
      { __typename: 'Foundation' }
      & Pick<Foundation, 'id' | 'name' | 'description'>
      & { created: (
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      ), eventsCounters: (
        { __typename?: 'FoundationEventsCounters' }
        & Pick<FoundationEventsCounters, 'total' | 'active'>
      ) }
    )>> }
  )> }
);

export type FoundationPropsFragment = (
  { __typename?: 'Foundation' }
  & Pick<Foundation, 'id' | 'name' | 'description'>
  & { address: (
    { __typename?: 'Address' }
    & Pick<Address, 'fullAddress' | 'displayText'>
  ), logo?: Maybe<(
    { __typename?: 'FileInformation' }
    & Pick<FileInformation, 'id' | 'url'>
  )>, created: (
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  ), updated: (
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  ), representatives?: Maybe<(
    { __typename?: 'RepresentativesConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'BenefactorIdentity' }
      & Pick<BenefactorIdentity, 'id' | 'displayName' | 'disabled'>
    ) | (
      { __typename?: 'FoundationRepresentative' }
      & Pick<FoundationRepresentative, 'id' | 'displayName' | 'disabled'>
    ) | (
      { __typename?: 'Moderator' }
      & Pick<Moderator, 'id' | 'displayName' | 'disabled'>
    ) | (
      { __typename?: 'SystemAdministrator' }
      & Pick<SystemAdministrator, 'id' | 'displayName' | 'disabled'>
    )>>> }
  )> }
);

export type EventsQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<FoundationEventFilterInput>;
  id: Scalars['FoundationId'];
}>;


export type EventsQuery = (
  { __typename?: 'Query' }
  & { foundationById?: Maybe<(
    { __typename?: 'Foundation' }
    & Pick<Foundation, 'id'>
    & { events?: Maybe<(
      { __typename: 'EventsConnection' }
      & Pick<EventsConnection, 'totalCount'>
      & { pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<(
        { __typename: 'FoundationEvent' }
        & Pick<FoundationEvent, 'id'>
        & { created: (
          { __typename?: 'AuditMarker' }
          & AuditMarkerPropsFragment
        ), approved?: Maybe<(
          { __typename?: 'AuditMarker' }
          & Pick<AuditMarker, 'timestamp'>
          & { user?: Maybe<(
            { __typename?: 'BenefactorIdentity' }
            & UserBaseProps_BenefactorIdentity_Fragment
          ) | (
            { __typename?: 'FoundationRepresentative' }
            & UserBaseProps_FoundationRepresentative_Fragment
          ) | (
            { __typename?: 'Moderator' }
            & UserBaseProps_Moderator_Fragment
          ) | (
            { __typename?: 'SystemAdministrator' }
            & UserBaseProps_SystemAdministrator_Fragment
          )> }
        )>, cancelled?: Maybe<(
          { __typename?: 'AuditMarker' }
          & Pick<AuditMarker, 'timestamp'>
        )>, closed?: Maybe<(
          { __typename?: 'AuditMarker' }
          & Pick<AuditMarker, 'timestamp'>
        )>, details: (
          { __typename?: 'FoundationEventDetails' }
          & Pick<FoundationEventDetails, 'title' | 'city' | 'start'>
          & { address: (
            { __typename?: 'Address' }
            & { city?: Maybe<(
              { __typename?: 'IdentifiableAddressElement' }
              & Pick<IdentifiableAddressElement, 'valueWithType'>
            )>, settlement?: Maybe<(
              { __typename?: 'IdentifiableAddressElement' }
              & Pick<IdentifiableAddressElement, 'valueWithType'>
            )> }
          ) }
        ) }
      )>> }
    )> }
  )> }
);

export type ApproveFoundationEventMutationVariables = Exact<{
  input: ApproveFoundationEventInput;
}>;


export type ApproveFoundationEventMutation = (
  { __typename?: 'Mutation' }
  & { approveFoundationEvent: (
    { __typename?: 'ApproveFoundationEventPayload' }
    & { foundationEvent?: Maybe<(
      { __typename?: 'FoundationEvent' }
      & FoundationEventPropsFragment
    )> }
  ) }
);

export type CancelFoundationEventMutationVariables = Exact<{
  input: CancelFoundationEventInput;
}>;


export type CancelFoundationEventMutation = (
  { __typename?: 'Mutation' }
  & { cancelFoundationEvent: (
    { __typename?: 'CancelFoundationEventPayload' }
    & { foundationEvent?: Maybe<(
      { __typename?: 'FoundationEvent' }
      & FoundationEventPropsFragment
    )> }
  ) }
);

export type CloseFoundationEventMutationVariables = Exact<{
  input: CloseFoundationEventInput;
}>;


export type CloseFoundationEventMutation = (
  { __typename?: 'Mutation' }
  & { closeFoundationEvent: (
    { __typename?: 'CloseFoundationEventPayload' }
    & { foundationEvent?: Maybe<(
      { __typename?: 'FoundationEvent' }
      & FoundationEventPropsFragment
    )>, errors?: Maybe<Array<(
      { __typename: 'FoundationEventReportDoesNotExistsError' }
      & Pick<FoundationEventReportDoesNotExistsError, 'message'>
    )>> }
  ) }
);

export type CloseFoundationEventRegistrationMutationVariables = Exact<{
  input: CloseFoundationEventRegistrationInput;
}>;


export type CloseFoundationEventRegistrationMutation = (
  { __typename?: 'Mutation' }
  & { closeFoundationEventRegistration: (
    { __typename?: 'CloseFoundationEventRegistrationPayload' }
    & { foundationEvent?: Maybe<(
      { __typename?: 'FoundationEvent' }
      & FoundationEventPropsFragment
    )> }
  ) }
);

export type OpenFoundationEventRegistrationMutationVariables = Exact<{
  input: OpenFoundationEventRegistrationInput;
}>;


export type OpenFoundationEventRegistrationMutation = (
  { __typename?: 'Mutation' }
  & { openFoundationEventRegistration: (
    { __typename?: 'OpenFoundationEventRegistrationPayload' }
    & { foundationEvent?: Maybe<(
      { __typename?: 'FoundationEvent' }
      & FoundationEventPropsFragment
    )> }
  ) }
);

export type RequestFoundationEventApprovalMutationVariables = Exact<{
  input: RequestFoundationEventApprovalInput;
}>;


export type RequestFoundationEventApprovalMutation = (
  { __typename?: 'Mutation' }
  & { requestFoundationEventApproval: (
    { __typename?: 'RequestFoundationEventApprovalPayload' }
    & { foundationEvent?: Maybe<(
      { __typename?: 'FoundationEvent' }
      & FoundationEventPropsFragment
    )> }
  ) }
);

export type TakeFoundationEventOwnershipMutationVariables = Exact<{
  input: TakeFoundationEventOwnershipInput;
}>;


export type TakeFoundationEventOwnershipMutation = (
  { __typename?: 'Mutation' }
  & { takeFoundationEventOwnership: (
    { __typename?: 'TakeFoundationEventOwnershipPayload' }
    & { foundationEvent?: Maybe<(
      { __typename?: 'FoundationEvent' }
      & FoundationEventPropsFragment
    )> }
  ) }
);

export type ApplicationsQueryVariables = Exact<{
  id: Scalars['FoundationEventId'];
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<FoundationEventApplicationSortInput> | FoundationEventApplicationSortInput>;
  where?: Maybe<FoundationEventApplicationFilterInput>;
}>;


export type ApplicationsQuery = (
  { __typename?: 'Query' }
  & { foundationEventById?: Maybe<(
    { __typename?: 'FoundationEvent' }
    & Pick<FoundationEvent, 'id'>
    & { applications?: Maybe<(
      { __typename?: 'ApplicationsConnection' }
      & Pick<ApplicationsConnection, 'totalCount'>
      & { pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<(
        { __typename?: 'FoundationEventApplication' }
        & Pick<FoundationEventApplication, 'id' | 'benefactorRating' | 'status'>
        & { benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
        ), skill: Array<(
          { __typename?: 'Skill' }
          & Pick<Skill, 'id' | 'title'>
        )>, attendance?: Maybe<(
          { __typename?: 'FoundationEventAttendance' }
          & Pick<FoundationEventAttendance, 'checkedInAt'>
        )>, created: (
          { __typename?: 'AuditMarker' }
          & Pick<AuditMarker, 'timestamp'>
          & { user?: Maybe<(
            { __typename?: 'BenefactorIdentity' }
            & UserBaseProps_BenefactorIdentity_Fragment
          ) | (
            { __typename?: 'FoundationRepresentative' }
            & UserBaseProps_FoundationRepresentative_Fragment
          ) | (
            { __typename?: 'Moderator' }
            & UserBaseProps_Moderator_Fragment
          ) | (
            { __typename?: 'SystemAdministrator' }
            & UserBaseProps_SystemAdministrator_Fragment
          )> }
        ) }
      )>> }
    )> }
  )> }
);

export type ConvertFoundationEventApplicationToAttendanceMutationVariables = Exact<{
  input: ConvertFoundationEventApplicationToAttendanceInput;
}>;


export type ConvertFoundationEventApplicationToAttendanceMutation = (
  { __typename?: 'Mutation' }
  & { convertFoundationEventApplicationToAttendance: (
    { __typename?: 'ConvertFoundationEventApplicationToAttendancePayload' }
    & { attendance?: Maybe<(
      { __typename?: 'FoundationEventAttendance' }
      & Pick<FoundationEventAttendance, 'id'>
    )> }
  ) }
);

export type DeclineFoundationEventApplicationMutationVariables = Exact<{
  input: DeclineFoundationEventApplicationInput;
}>;


export type DeclineFoundationEventApplicationMutation = (
  { __typename?: 'Mutation' }
  & { declineFoundationEventApplication: (
    { __typename?: 'DeclineFoundationEventApplicationPayload' }
    & { application?: Maybe<(
      { __typename?: 'FoundationEventApplication' }
      & Pick<FoundationEventApplication, 'id' | 'benefactorRating' | 'status'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & Pick<Benefactor, 'displayName'>
      ), updated: (
        { __typename?: 'AuditMarker' }
        & Pick<AuditMarker, 'timestamp'>
        & { user?: Maybe<(
          { __typename?: 'BenefactorIdentity' }
          & UserBaseProps_BenefactorIdentity_Fragment
        ) | (
          { __typename?: 'FoundationRepresentative' }
          & UserBaseProps_FoundationRepresentative_Fragment
        ) | (
          { __typename?: 'Moderator' }
          & UserBaseProps_Moderator_Fragment
        ) | (
          { __typename?: 'SystemAdministrator' }
          & UserBaseProps_SystemAdministrator_Fragment
        )> }
      ) }
    )> }
  ) }
);

export type SelectFoundationEventApplicationMutationVariables = Exact<{
  input: SelectFoundationEventApplicationInput;
}>;


export type SelectFoundationEventApplicationMutation = (
  { __typename?: 'Mutation' }
  & { selectFoundationEventApplication: (
    { __typename?: 'SelectFoundationEventApplicationPayload' }
    & { application?: Maybe<(
      { __typename?: 'FoundationEventApplication' }
      & Pick<FoundationEventApplication, 'id' | 'benefactorRating' | 'status'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & Pick<Benefactor, 'displayName'>
      ), updated: (
        { __typename?: 'AuditMarker' }
        & Pick<AuditMarker, 'timestamp'>
        & { user?: Maybe<(
          { __typename?: 'BenefactorIdentity' }
          & UserBaseProps_BenefactorIdentity_Fragment
        ) | (
          { __typename?: 'FoundationRepresentative' }
          & UserBaseProps_FoundationRepresentative_Fragment
        ) | (
          { __typename?: 'Moderator' }
          & UserBaseProps_Moderator_Fragment
        ) | (
          { __typename?: 'SystemAdministrator' }
          & UserBaseProps_SystemAdministrator_Fragment
        )> }
      ) }
    )> }
  ) }
);

export type UpdateFoundationEventAttendanceRatingMutationVariables = Exact<{
  input: UpdateFoundationEventAttendanceRatingInput;
}>;


export type UpdateFoundationEventAttendanceRatingMutation = (
  { __typename?: 'Mutation' }
  & { updateFoundationEventAttendanceRating: (
    { __typename?: 'UpdateFoundationEventAttendanceRatingPayload' }
    & { attendance?: Maybe<(
      { __typename?: 'FoundationEventAttendance' }
      & Pick<FoundationEventAttendance, 'id' | 'checkedInAt' | 'attendeeComment' | 'attendeeRating' | 'foundationComment' | 'foundationRating'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & Pick<Benefactor, 'id' | 'displayName' | 'phoneNumber'>
      ) }
    )> }
  ) }
);

export type AttendanceQueryVariables = Exact<{
  id: Scalars['FoundationEventId'];
  benefactorSearch?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<FoundationEventAttendanceSortInput> | FoundationEventAttendanceSortInput>;
  where?: Maybe<FoundationEventAttendanceFilterInput>;
}>;


export type AttendanceQuery = (
  { __typename?: 'Query' }
  & { foundationEventById?: Maybe<(
    { __typename?: 'FoundationEvent' }
    & Pick<FoundationEvent, 'id'>
    & { attendances?: Maybe<(
      { __typename?: 'AttendancesConnection' }
      & Pick<AttendancesConnection, 'totalCount'>
      & { pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<(
        { __typename?: 'FoundationEventAttendance' }
        & Pick<FoundationEventAttendance, 'id' | 'checkedInAt' | 'attendeeComment' | 'attendeeRating' | 'foundationComment' | 'foundationRating'>
        & { benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName' | 'phoneNumber'>
        ) }
      )>> }
    )> }
  )> }
);

type NeedProps_OneTimeNeed_Fragment = (
  { __typename: 'OneTimeNeed' }
  & Pick<OneTimeNeed, 'id' | 'requiredAmount'>
  & OneTimeNeedPropsFragment
);

type NeedProps_GrocerySetNeed_Fragment = (
  { __typename: 'GrocerySetNeed' }
  & Pick<GrocerySetNeed, 'id' | 'requiredAmount'>
  & { periods?: Maybe<(
    { __typename?: 'RegularNeedPeriodsConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'RegularNeedPeriod' }
      & RegularNeedPeriodPropsFragment
    )>>> }
  )>, archived?: Maybe<(
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  )> }
);

export type NeedPropsFragment = NeedProps_OneTimeNeed_Fragment | NeedProps_GrocerySetNeed_Fragment;

export type OneTimeNeedPropsFragment = (
  { __typename?: 'OneTimeNeed' }
  & Pick<OneTimeNeed, 'id' | 'title' | 'targetDate' | 'requiredAmount' | 'icon' | 'description'>
  & { account: (
    { __typename: 'BenefactorDepositAccountForSpecificWards' }
    & Pick<BenefactorDepositAccountForSpecificWards, 'id' | 'amount' | 'closedAt'>
    & { donations?: Maybe<(
      { __typename?: 'DonationsConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Donation' }
        & PaymentWithDonationGridFragment
      )>>> }
    )> }
  ) | (
    { __typename: 'RegularNeedsAccount' }
    & Pick<RegularNeedsAccount, 'id' | 'amount' | 'closedAt'>
    & { donations?: Maybe<(
      { __typename?: 'DonationsConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Donation' }
        & PaymentWithDonationGridFragment
      )>>> }
    )> }
  ) | (
    { __typename: 'BenefactorDepositAccountForAllWards' }
    & Pick<BenefactorDepositAccountForAllWards, 'id' | 'amount' | 'closedAt'>
    & { donations?: Maybe<(
      { __typename?: 'DonationsConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Donation' }
        & PaymentWithDonationGridFragment
      )>>> }
    )> }
  ) | (
    { __typename: 'BenefactorDepositAccountForProjectNeeds' }
    & Pick<BenefactorDepositAccountForProjectNeeds, 'id' | 'amount' | 'closedAt'>
    & { donations?: Maybe<(
      { __typename?: 'DonationsConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Donation' }
        & PaymentWithDonationGridFragment
      )>>> }
    )> }
  ) | (
    { __typename: 'BenefactorDepositAccountWithoutConditions' }
    & Pick<BenefactorDepositAccountWithoutConditions, 'id' | 'amount' | 'closedAt'>
    & { donations?: Maybe<(
      { __typename?: 'DonationsConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Donation' }
        & PaymentWithDonationGridFragment
      )>>> }
    )> }
  ) | (
    { __typename: 'OneTimeNeedAccount' }
    & Pick<OneTimeNeedAccount, 'id' | 'amount' | 'closedAt'>
    & { donations?: Maybe<(
      { __typename?: 'DonationsConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Donation' }
        & PaymentWithDonationGridFragment
      )>>> }
    )> }
  ) | (
    { __typename: 'ProjectExpenseAccount' }
    & Pick<ProjectExpenseAccount, 'id' | 'amount' | 'closedAt'>
    & { donations?: Maybe<(
      { __typename?: 'DonationsConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Donation' }
        & PaymentWithDonationGridFragment
      )>>> }
    )> }
  ) | (
    { __typename: 'RegularNeedPeriodAccount' }
    & Pick<RegularNeedPeriodAccount, 'id' | 'amount' | 'closedAt'>
    & { donations?: Maybe<(
      { __typename?: 'DonationsConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Donation' }
        & PaymentWithDonationGridFragment
      )>>> }
    )> }
  ) | (
    { __typename: 'SpecialProjectAccount' }
    & Pick<SpecialProjectAccount, 'id' | 'amount' | 'closedAt'>
    & { donations?: Maybe<(
      { __typename?: 'DonationsConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Donation' }
        & PaymentWithDonationGridFragment
      )>>> }
    )> }
  ) | (
    { __typename: 'SystemAccount' }
    & Pick<SystemAccount, 'id' | 'amount' | 'closedAt'>
    & { donations?: Maybe<(
      { __typename?: 'DonationsConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Donation' }
        & PaymentWithDonationGridFragment
      )>>> }
    )> }
  ), documents: Array<(
    { __typename?: 'Document' }
    & DocumentFilePropsFragment
  )>, reportSent?: Maybe<(
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  )>, archived?: Maybe<(
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  )> }
);

export type RegularNeedPropsFragment = (
  { __typename?: 'GrocerySetNeed' }
  & Pick<GrocerySetNeed, 'id'>
  & { archived?: Maybe<(
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  )>, periods?: Maybe<(
    { __typename?: 'RegularNeedPeriodsConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'RegularNeedPeriod' }
      & RegularNeedPeriodPropsFragment
    )>>> }
  )> }
);

export type RegularNeedPeriodPropsFragment = (
  { __typename?: 'RegularNeedPeriod' }
  & Pick<RegularNeedPeriod, 'id' | 'reportSentAt' | 'createdAt' | 'start' | 'end'>
  & { account?: Maybe<(
    { __typename?: 'BenefactorDepositAccountForSpecificWards' }
    & Account_BenefactorDepositAccountForSpecificWards_Fragment
  ) | (
    { __typename?: 'RegularNeedsAccount' }
    & Account_RegularNeedsAccount_Fragment
  ) | (
    { __typename?: 'BenefactorDepositAccountForAllWards' }
    & Account_BenefactorDepositAccountForAllWards_Fragment
  ) | (
    { __typename?: 'BenefactorDepositAccountForProjectNeeds' }
    & Account_BenefactorDepositAccountForProjectNeeds_Fragment
  ) | (
    { __typename?: 'BenefactorDepositAccountWithoutConditions' }
    & Account_BenefactorDepositAccountWithoutConditions_Fragment
  ) | (
    { __typename?: 'OneTimeNeedAccount' }
    & Account_OneTimeNeedAccount_Fragment
  ) | (
    { __typename?: 'ProjectExpenseAccount' }
    & Account_ProjectExpenseAccount_Fragment
  ) | (
    { __typename?: 'RegularNeedPeriodAccount' }
    & Account_RegularNeedPeriodAccount_Fragment
  ) | (
    { __typename?: 'SpecialProjectAccount' }
    & Account_SpecialProjectAccount_Fragment
  ) | (
    { __typename?: 'SystemAccount' }
    & Account_SystemAccount_Fragment
  )>, documents: Array<(
    { __typename?: 'Document' }
    & DocumentFilePropsFragment
  )>, need: (
    { __typename?: 'OneTimeNeed' }
    & Pick<OneTimeNeed, 'requiredAmount'>
    & { archived?: Maybe<(
      { __typename?: 'AuditMarker' }
      & AuditMarkerPropsFragment
    )> }
  ) | (
    { __typename?: 'GrocerySetNeed' }
    & Pick<GrocerySetNeed, 'requiredAmount'>
    & { archived?: Maybe<(
      { __typename?: 'AuditMarker' }
      & AuditMarkerPropsFragment
    )> }
  ) }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Me' }
    & { user: (
      { __typename: 'BenefactorIdentity' }
      & Pick<BenefactorIdentity, 'id' | 'displayName'>
    ) | (
      { __typename: 'FoundationRepresentative' }
      & Pick<FoundationRepresentative, 'id' | 'displayName'>
      & { foundation: (
        { __typename?: 'Foundation' }
        & Pick<Foundation, 'id'>
      ) }
    ) | (
      { __typename: 'Moderator' }
      & Pick<Moderator, 'id' | 'displayName'>
    ) | (
      { __typename: 'SystemAdministrator' }
      & Pick<SystemAdministrator, 'id' | 'displayName'>
    ), permissions: (
      { __typename?: 'Permissions' }
      & PermissionsPropsFragment
    ) }
  ) }
);

export type PermissionsPropsFragment = (
  { __typename?: 'Permissions' }
  & { accounts: (
    { __typename?: 'AccountsPermissions' }
    & Pick<AccountsPermissions, 'changeStates' | 'manageDocuments' | 'transferFunds' | 'manageDepositAccounts' | 'viewDepositAccounts'>
  ), benefactors: (
    { __typename?: 'BenefactorsPermissions' }
    & Pick<BenefactorsPermissions, 'create' | 'list' | 'manage'>
  ), broadcastNotifications: (
    { __typename?: 'BroadcastNotificationsPermissions' }
    & Pick<BroadcastNotificationsPermissions, 'create' | 'view'>
  ), clientVersions: (
    { __typename?: 'ClientVersionsPermissions' }
    & Pick<ClientVersionsPermissions, 'manage'>
  ), foundations: (
    { __typename?: 'FoundationsPermissions' }
    & Pick<FoundationsPermissions, 'create' | 'edit' | 'approveEvents'>
  ), limitedLiabilityCompany: (
    { __typename?: 'LimitedLiabilityCompanyPermissions' }
    & Pick<LimitedLiabilityCompanyPermissions, 'manage'>
  ), project: (
    { __typename?: 'ProjectPermissions' }
    & Pick<ProjectPermissions, 'manageExpenses'>
  ), users: (
    { __typename?: 'UsersPermissions' }
    & Pick<UsersPermissions, 'changePassword' | 'changeEmailAndPhoneNumber' | 'createAndEditFoundationRepresentative' | 'createAndEditModerator' | 'createAndEditSystemAdministrator' | 'delete' | 'enableOrDisable' | 'list' | 'view'>
  ), wards: (
    { __typename?: 'WardsPermissions' }
    & Pick<WardsPermissions, 'create' | 'edit' | 'manageNeeds' | 'viewPrivateInformation'>
  ), catalogs: (
    { __typename?: 'CatalogsPermissions' }
    & Pick<CatalogsPermissions, 'manageCities'>
  ), stories: (
    { __typename?: 'StoriesPermissions' }
    & Pick<StoriesPermissions, 'view' | 'manage'>
  ), achievements: (
    { __typename?: 'AchievementsPermissions' }
    & Pick<AchievementsPermissions, 'view' | 'manage' | 'assignCoins'>
  ), specialProjects: (
    { __typename?: 'SpecialProjectsPermissions' }
    & Pick<SpecialProjectsPermissions, 'manage'>
  ), categories: (
    { __typename?: 'CategoriesPermissions' }
    & Pick<CategoriesPermissions, 'manage'>
  ) }
);

export type ChangeUserPasswordMutationVariables = Exact<{
  input: ChangeUserPasswordInput;
}>;


export type ChangeUserPasswordMutation = (
  { __typename?: 'Mutation' }
  & { changeUserPassword: (
    { __typename?: 'ChangeUserPasswordPayload' }
    & Pick<ChangeUserPasswordPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename: 'ForbiddenForUserError' }
      & Pick<ForbiddenForUserError, 'message'>
    ) | (
      { __typename: 'UserPersistenceError' }
      & Pick<UserPersistenceError, 'message'>
    )>> }
  ) }
);

export type CreateModeratorMutationVariables = Exact<{
  input: CreateModeratorInput;
}>;


export type CreateModeratorMutation = (
  { __typename?: 'Mutation' }
  & { createModerator: (
    { __typename?: 'CreateModeratorPayload' }
    & { user?: Maybe<(
      { __typename?: 'BenefactorIdentity' }
      & UserProps_BenefactorIdentity_Fragment
    ) | (
      { __typename?: 'FoundationRepresentative' }
      & UserProps_FoundationRepresentative_Fragment
    ) | (
      { __typename?: 'Moderator' }
      & UserProps_Moderator_Fragment
    ) | (
      { __typename?: 'SystemAdministrator' }
      & UserProps_SystemAdministrator_Fragment
    )>, errors?: Maybe<Array<(
      { __typename: 'EmailAlreadyRegisteredError' }
      & Pick<EmailAlreadyRegisteredError, 'message'>
    ) | (
      { __typename: 'UserCommunicationError' }
      & Pick<UserCommunicationError, 'message'>
    ) | (
      { __typename: 'UserPersistenceError' }
      & Pick<UserPersistenceError, 'message'>
    )>> }
  ) }
);

export type CreateSystemAdministratorMutationVariables = Exact<{
  input: CreateSystemAdministratorInput;
}>;


export type CreateSystemAdministratorMutation = (
  { __typename?: 'Mutation' }
  & { createSystemAdministrator: (
    { __typename?: 'CreateSystemAdministratorPayload' }
    & { user?: Maybe<(
      { __typename?: 'BenefactorIdentity' }
      & UserProps_BenefactorIdentity_Fragment
    ) | (
      { __typename?: 'FoundationRepresentative' }
      & UserProps_FoundationRepresentative_Fragment
    ) | (
      { __typename?: 'Moderator' }
      & UserProps_Moderator_Fragment
    ) | (
      { __typename?: 'SystemAdministrator' }
      & UserProps_SystemAdministrator_Fragment
    )>, errors?: Maybe<Array<(
      { __typename: 'EmailAlreadyRegisteredError' }
      & Pick<EmailAlreadyRegisteredError, 'message'>
    ) | (
      { __typename: 'UserCommunicationError' }
      & Pick<UserCommunicationError, 'message'>
    ) | (
      { __typename: 'UserPersistenceError' }
      & Pick<UserPersistenceError, 'message'>
    )>> }
  ) }
);

export type UpdateFoundationRepresentativeMutationVariables = Exact<{
  input: UpdateFoundationRepresentativeInput;
}>;


export type UpdateFoundationRepresentativeMutation = (
  { __typename?: 'Mutation' }
  & { updateFoundationRepresentative: (
    { __typename?: 'UpdateFoundationRepresentativePayload' }
    & { user?: Maybe<(
      { __typename?: 'BenefactorIdentity' }
      & UserProps_BenefactorIdentity_Fragment
    ) | (
      { __typename?: 'FoundationRepresentative' }
      & UserProps_FoundationRepresentative_Fragment
    ) | (
      { __typename?: 'Moderator' }
      & UserProps_Moderator_Fragment
    ) | (
      { __typename?: 'SystemAdministrator' }
      & UserProps_SystemAdministrator_Fragment
    )>, errors?: Maybe<Array<(
      { __typename: 'UserPersistenceError' }
      & Pick<UserPersistenceError, 'message'>
    )>> }
  ) }
);

export type UpdateModeratorMutationVariables = Exact<{
  input: UpdateModeratorInput;
}>;


export type UpdateModeratorMutation = (
  { __typename?: 'Mutation' }
  & { updateModerator: (
    { __typename?: 'UpdateModeratorPayload' }
    & { user?: Maybe<(
      { __typename?: 'BenefactorIdentity' }
      & UserProps_BenefactorIdentity_Fragment
    ) | (
      { __typename?: 'FoundationRepresentative' }
      & UserProps_FoundationRepresentative_Fragment
    ) | (
      { __typename?: 'Moderator' }
      & UserProps_Moderator_Fragment
    ) | (
      { __typename?: 'SystemAdministrator' }
      & UserProps_SystemAdministrator_Fragment
    )>, errors?: Maybe<Array<(
      { __typename: 'UserPersistenceError' }
      & Pick<UserPersistenceError, 'message'>
    )>> }
  ) }
);

export type UpdateSystemAdministratorMutationVariables = Exact<{
  input: UpdateSystemAdministratorInput;
}>;


export type UpdateSystemAdministratorMutation = (
  { __typename?: 'Mutation' }
  & { updateSystemAdministrator: (
    { __typename?: 'UpdateSystemAdministratorPayload' }
    & { user?: Maybe<(
      { __typename?: 'BenefactorIdentity' }
      & UserProps_BenefactorIdentity_Fragment
    ) | (
      { __typename?: 'FoundationRepresentative' }
      & UserProps_FoundationRepresentative_Fragment
    ) | (
      { __typename?: 'Moderator' }
      & UserProps_Moderator_Fragment
    ) | (
      { __typename?: 'SystemAdministrator' }
      & UserProps_SystemAdministrator_Fragment
    )>, errors?: Maybe<Array<(
      { __typename: 'UserPersistenceError' }
      & Pick<UserPersistenceError, 'message'>
    )>> }
  ) }
);

export type AdministratorsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Array<AdministratorsSortInput> | AdministratorsSortInput>;
  filter: AdministratorsFilterInput;
  last?: Maybe<Scalars['Int']>;
}>;


export type AdministratorsQuery = (
  { __typename?: 'Query' }
  & { administrators?: Maybe<(
    { __typename?: 'AdministratorsConnection' }
    & Pick<AdministratorsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
    ), nodes?: Maybe<Array<Maybe<(
      { __typename?: 'BenefactorIdentity' }
      & Pick<BenefactorIdentity, 'id' | 'disabled' | 'displayName' | 'email' | 'emailConfirmed' | 'phoneNumber' | 'createdOn'>
    ) | (
      { __typename?: 'FoundationRepresentative' }
      & Pick<FoundationRepresentative, 'id' | 'disabled' | 'displayName' | 'email' | 'emailConfirmed' | 'phoneNumber' | 'createdOn'>
      & { foundation: (
        { __typename?: 'Foundation' }
        & Pick<Foundation, 'name'>
      ) }
    ) | (
      { __typename?: 'Moderator' }
      & Pick<Moderator, 'id' | 'disabled' | 'displayName' | 'email' | 'emailConfirmed' | 'phoneNumber' | 'createdOn'>
    ) | (
      { __typename?: 'SystemAdministrator' }
      & Pick<SystemAdministrator, 'id' | 'disabled' | 'displayName' | 'email' | 'emailConfirmed' | 'phoneNumber' | 'createdOn'>
    )>>> }
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  input: DeleteUserInput;
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser: (
    { __typename?: 'DeleteUserPayload' }
    & Pick<DeleteUserPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename: 'ForbiddenForUserError' }
      & Pick<ForbiddenForUserError, 'message'>
    ) | (
      { __typename: 'UserCanNotBeDeletedError' }
      & Pick<UserCanNotBeDeletedError, 'message'>
    ) | (
      { __typename: 'UserPersistenceError' }
      & Pick<UserPersistenceError, 'message'>
    )>> }
  ) }
);

export type EnableOrDisableUserMutationVariables = Exact<{
  input: EnableOrDisableUserInput;
}>;


export type EnableOrDisableUserMutation = (
  { __typename?: 'Mutation' }
  & { enableOrDisableUser: (
    { __typename?: 'EnableOrDisableUserPayload' }
    & { user?: Maybe<(
      { __typename?: 'BenefactorIdentity' }
      & UserBaseProps_BenefactorIdentity_Fragment
    ) | (
      { __typename?: 'FoundationRepresentative' }
      & UserBaseProps_FoundationRepresentative_Fragment
    ) | (
      { __typename?: 'Moderator' }
      & UserBaseProps_Moderator_Fragment
    ) | (
      { __typename?: 'SystemAdministrator' }
      & UserBaseProps_SystemAdministrator_Fragment
    )>, errors?: Maybe<Array<(
      { __typename: 'ForbiddenForUserError' }
      & Pick<ForbiddenForUserError, 'message'>
    ) | (
      { __typename: 'UserPersistenceError' }
      & Pick<UserPersistenceError, 'message'>
    )>> }
  ) }
);

export type UserByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserByIdQuery = (
  { __typename?: 'Query' }
  & { userById?: Maybe<(
    { __typename?: 'BenefactorIdentity' }
    & UserProps_BenefactorIdentity_Fragment
  ) | (
    { __typename?: 'FoundationRepresentative' }
    & UserProps_FoundationRepresentative_Fragment
  ) | (
    { __typename?: 'Moderator' }
    & UserProps_Moderator_Fragment
  ) | (
    { __typename?: 'SystemAdministrator' }
    & UserProps_SystemAdministrator_Fragment
  )> }
);

export type AddBroadcastNotificationMutationVariables = Exact<{
  input: AddBroadcastNotificationInput;
}>;


export type AddBroadcastNotificationMutation = (
  { __typename?: 'Mutation' }
  & { addBroadcastNotification: (
    { __typename?: 'AddBroadcastNotificationPayload' }
    & { broadcastNotification?: Maybe<(
      { __typename?: 'BroadcastNotification' }
      & BroadcastNotificationPropsFragment
    )> }
  ) }
);

export type BroadcastNotificationPropsFragment = (
  { __typename?: 'BroadcastNotification' }
  & Pick<BroadcastNotification, 'id' | 'buttonText' | 'buttonUrl' | 'populatedNotificationsCount' | 'recipients' | 'status' | 'text' | 'title'>
  & { created: (
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  ), fileWithListOfRecipients?: Maybe<(
    { __typename?: 'FileInformation' }
    & Pick<FileInformation, 'id' | 'url'>
  )> }
);

export type BroadcastNotificationsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type BroadcastNotificationsQuery = (
  { __typename?: 'Query' }
  & { broadcastNotifications?: Maybe<(
    { __typename?: 'BroadcastNotificationsConnection' }
    & Pick<BroadcastNotificationsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
    ), nodes?: Maybe<Array<(
      { __typename?: 'BroadcastNotification' }
      & BroadcastNotificationPropsFragment
    )>> }
  )> }
);

export type UpdateProjectExpenseDocumentsMutationVariables = Exact<{
  input: UpdateProjectExpenseDocumentsInput;
}>;


export type UpdateProjectExpenseDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectExpenseDocuments: (
    { __typename?: 'UpdateProjectExpenseDocumentsPayload' }
    & { projectExpense?: Maybe<(
      { __typename?: 'ProjectExpense' }
      & { documents: Array<(
        { __typename?: 'Document' }
        & DocumentFilePropsFragment
      )> }
    )> }
  ) }
);

export type AddProjectExpenseMutationVariables = Exact<{
  input: AddProjectExpenseInput;
}>;


export type AddProjectExpenseMutation = (
  { __typename?: 'Mutation' }
  & { addProjectExpense: (
    { __typename?: 'AddProjectExpensePayload' }
    & { projectExpense?: Maybe<(
      { __typename?: 'ProjectExpense' }
      & Pick<ProjectExpense, 'id' | 'title'>
    )> }
  ) }
);

export type UpdateProjectRequirementsMutationVariables = Exact<{
  input: UpdateProjectRequirementsInput;
}>;


export type UpdateProjectRequirementsMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectRequirements: (
    { __typename?: 'UpdateProjectRequirementsPayload' }
    & Pick<UpdateProjectRequirementsPayload, 'success'>
  ) }
);

export type ProjectExpensesQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type ProjectExpensesQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & { expenses?: Maybe<(
      { __typename?: 'ProjectExpensesConnection' }
      & Pick<ProjectExpensesConnection, 'totalCount'>
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
      ), nodes?: Maybe<Array<(
        { __typename?: 'ProjectExpense' }
        & ProjectExpensePropsFragment
      )>> }
    )> }
  ) }
);

export type ProjectExpensePropsFragment = (
  { __typename?: 'ProjectExpense' }
  & Pick<ProjectExpense, 'id' | 'requiredAmount' | 'title' | 'reportSentAt' | 'referenceDate'>
  & { documents: Array<(
    { __typename?: 'Document' }
    & DocumentFilePropsFragment
  )>, account: (
    { __typename?: 'BenefactorDepositAccountForSpecificWards' }
    & Account_BenefactorDepositAccountForSpecificWards_Fragment
  ) | (
    { __typename?: 'RegularNeedsAccount' }
    & Account_RegularNeedsAccount_Fragment
  ) | (
    { __typename?: 'BenefactorDepositAccountForAllWards' }
    & Account_BenefactorDepositAccountForAllWards_Fragment
  ) | (
    { __typename?: 'BenefactorDepositAccountForProjectNeeds' }
    & Account_BenefactorDepositAccountForProjectNeeds_Fragment
  ) | (
    { __typename?: 'BenefactorDepositAccountWithoutConditions' }
    & Account_BenefactorDepositAccountWithoutConditions_Fragment
  ) | (
    { __typename?: 'OneTimeNeedAccount' }
    & Account_OneTimeNeedAccount_Fragment
  ) | (
    { __typename?: 'ProjectExpenseAccount' }
    & Account_ProjectExpenseAccount_Fragment
  ) | (
    { __typename?: 'RegularNeedPeriodAccount' }
    & Account_RegularNeedPeriodAccount_Fragment
  ) | (
    { __typename?: 'SpecialProjectAccount' }
    & Account_SpecialProjectAccount_Fragment
  ) | (
    { __typename?: 'SystemAccount' }
    & Account_SystemAccount_Fragment
  ) }
);

export type ProjectQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & { requirements: (
      { __typename?: 'ProjectRequirements' }
      & Pick<ProjectRequirements, 'remainingDuration' | 'estimatedMonthlyExpensesAmount'>
    ), mainAccount: (
      { __typename?: 'BenefactorDepositAccountForSpecificWards' }
      & Pick<BenefactorDepositAccountForSpecificWards, 'id' | 'amount'>
    ) | (
      { __typename?: 'RegularNeedsAccount' }
      & Pick<RegularNeedsAccount, 'id' | 'amount'>
    ) | (
      { __typename?: 'BenefactorDepositAccountForAllWards' }
      & Pick<BenefactorDepositAccountForAllWards, 'id' | 'amount'>
    ) | (
      { __typename?: 'BenefactorDepositAccountForProjectNeeds' }
      & Pick<BenefactorDepositAccountForProjectNeeds, 'id' | 'amount'>
    ) | (
      { __typename?: 'BenefactorDepositAccountWithoutConditions' }
      & Pick<BenefactorDepositAccountWithoutConditions, 'id' | 'amount'>
    ) | (
      { __typename?: 'OneTimeNeedAccount' }
      & Pick<OneTimeNeedAccount, 'id' | 'amount'>
    ) | (
      { __typename?: 'ProjectExpenseAccount' }
      & Pick<ProjectExpenseAccount, 'id' | 'amount'>
    ) | (
      { __typename?: 'RegularNeedPeriodAccount' }
      & Pick<RegularNeedPeriodAccount, 'id' | 'amount'>
    ) | (
      { __typename?: 'SpecialProjectAccount' }
      & Pick<SpecialProjectAccount, 'id' | 'amount'>
    ) | (
      { __typename?: 'SystemAccount' }
      & Pick<SystemAccount, 'id' | 'amount'>
    ), wardsReserveAccount: (
      { __typename?: 'BenefactorDepositAccountForSpecificWards' }
      & Pick<BenefactorDepositAccountForSpecificWards, 'id' | 'amount'>
    ) | (
      { __typename?: 'RegularNeedsAccount' }
      & Pick<RegularNeedsAccount, 'id' | 'amount'>
    ) | (
      { __typename?: 'BenefactorDepositAccountForAllWards' }
      & Pick<BenefactorDepositAccountForAllWards, 'id' | 'amount'>
    ) | (
      { __typename?: 'BenefactorDepositAccountForProjectNeeds' }
      & Pick<BenefactorDepositAccountForProjectNeeds, 'id' | 'amount'>
    ) | (
      { __typename?: 'BenefactorDepositAccountWithoutConditions' }
      & Pick<BenefactorDepositAccountWithoutConditions, 'id' | 'amount'>
    ) | (
      { __typename?: 'OneTimeNeedAccount' }
      & Pick<OneTimeNeedAccount, 'id' | 'amount'>
    ) | (
      { __typename?: 'ProjectExpenseAccount' }
      & Pick<ProjectExpenseAccount, 'id' | 'amount'>
    ) | (
      { __typename?: 'RegularNeedPeriodAccount' }
      & Pick<RegularNeedPeriodAccount, 'id' | 'amount'>
    ) | (
      { __typename?: 'SpecialProjectAccount' }
      & Pick<SpecialProjectAccount, 'id' | 'amount'>
    ) | (
      { __typename?: 'SystemAccount' }
      & Pick<SystemAccount, 'id' | 'amount'>
    ) }
  ), amounts: (
    { __typename?: 'Amounts' }
    & Pick<Amounts, 'amountOfBenefactorDeposits'>
  ) }
);

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword: (
    { __typename?: 'ResetPasswordPayload' }
    & Pick<ResetPasswordPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename: 'UserPersistenceError' }
      & Pick<UserPersistenceError, 'message'>
    )>> }
  ) }
);

export type CreateSpecialProjectMutationVariables = Exact<{
  input: CreateSpecialProjectInput;
}>;


export type CreateSpecialProjectMutation = (
  { __typename?: 'Mutation' }
  & { createSpecialProject: (
    { __typename?: 'CreateSpecialProjectPayload' }
    & { specialProject?: Maybe<(
      { __typename?: 'SpecialProject' }
      & Pick<SpecialProject, 'id' | 'title' | 'description' | 'start' | 'end' | 'campaign'>
      & { images: Array<(
        { __typename?: 'FileInformation' }
        & PhotoFilePropsFragment
      )> }
    )> }
  ) }
);

export type UpdateSpecialProjectMutationVariables = Exact<{
  input: UpdateSpecialProjectInput;
}>;


export type UpdateSpecialProjectMutation = (
  { __typename?: 'Mutation' }
  & { updateSpecialProject: (
    { __typename?: 'UpdateSpecialProjectPayload' }
    & { errors?: Maybe<Array<{ __typename?: 'CategoryNotFoundError' } | { __typename?: 'CityNotFoundError' } | (
      { __typename?: 'RequiredAmountIsLessThanCollectedAmountError' }
      & Pick<RequiredAmountIsLessThanCollectedAmountError, 'message'>
    )>>, specialProject?: Maybe<(
      { __typename?: 'SpecialProject' }
      & Pick<SpecialProject, 'id' | 'title' | 'description' | 'start' | 'end' | 'campaign'>
      & { images: Array<(
        { __typename?: 'FileInformation' }
        & PhotoFilePropsFragment
      )> }
    )> }
  ) }
);

export type UpdateSpecialProjectsVisibilityOrderMutationVariables = Exact<{
  input: UpdateSpecialProjectsVisibilityOrderInput;
}>;


export type UpdateSpecialProjectsVisibilityOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateSpecialProjectsVisibilityOrder: (
    { __typename?: 'UpdateSpecialProjectsVisibilityOrderPayload' }
    & Pick<UpdateSpecialProjectsVisibilityOrderPayload, 'success'>
  ) }
);

export type SpecialProjectByIdQueryVariables = Exact<{
  id: Scalars['SpecialProjectId'];
}>;


export type SpecialProjectByIdQuery = (
  { __typename?: 'Query' }
  & { specialProjectById?: Maybe<(
    { __typename?: 'SpecialProject' }
    & SpecialProjectsPropsFragment
  )> }
);

export type CancelSpecialProjectMutationVariables = Exact<{
  input: CancelSpecialProjectInput;
}>;


export type CancelSpecialProjectMutation = (
  { __typename?: 'Mutation' }
  & { cancelSpecialProject: (
    { __typename?: 'CancelSpecialProjectPayload' }
    & { specialProject?: Maybe<(
      { __typename?: 'SpecialProject' }
      & Pick<SpecialProject, 'id'>
      & { cancelled?: Maybe<(
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      )> }
    )> }
  ) }
);

export type CloseSpecialProjectMutationVariables = Exact<{
  input: CloseSpecialProjectInput;
}>;


export type CloseSpecialProjectMutation = (
  { __typename?: 'Mutation' }
  & { closeSpecialProject: (
    { __typename?: 'CloseSpecialProjectPayload' }
    & { specialProject?: Maybe<(
      { __typename?: 'SpecialProject' }
      & Pick<SpecialProject, 'id'>
      & { closed?: Maybe<(
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      )> }
    )> }
  ) }
);

export type PublishSpecialProjectMutationVariables = Exact<{
  input: PublishSpecialProjectInput;
}>;


export type PublishSpecialProjectMutation = (
  { __typename?: 'Mutation' }
  & { publishSpecialProject: (
    { __typename?: 'PublishSpecialProjectPayload' }
    & { specialProject?: Maybe<(
      { __typename?: 'SpecialProject' }
      & Pick<SpecialProject, 'id'>
      & { published?: Maybe<(
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      )> }
    )> }
  ) }
);

export type SendReportSpecialProjectReportMutationVariables = Exact<{
  input: SendSpecialProjectReportInput;
}>;


export type SendReportSpecialProjectReportMutation = (
  { __typename?: 'Mutation' }
  & { sendSpecialProjectReport: (
    { __typename?: 'SendSpecialProjectReportPayload' }
    & { specialProject?: Maybe<(
      { __typename?: 'SpecialProject' }
      & Pick<SpecialProject, 'id'>
      & { reportSent?: Maybe<(
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      )> }
    )> }
  ) }
);

export type UnpublishSpecialProjectMutationVariables = Exact<{
  input: UnpublishSpecialProjectInput;
}>;


export type UnpublishSpecialProjectMutation = (
  { __typename?: 'Mutation' }
  & { unpublishSpecialProject: (
    { __typename?: 'UnpublishSpecialProjectPayload' }
    & { specialProject?: Maybe<(
      { __typename?: 'SpecialProject' }
      & Pick<SpecialProject, 'id'>
      & { published?: Maybe<(
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      )> }
    )> }
  ) }
);

export type UpdateSpecialProjectDocumentsMutationVariables = Exact<{
  input: UpdateSpecialProjectDocumentsInput;
}>;


export type UpdateSpecialProjectDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { updateSpecialProjectDocuments: (
    { __typename?: 'UpdateSpecialProjectDocumentsPayload' }
    & { specialProject?: Maybe<(
      { __typename?: 'SpecialProject' }
      & Pick<SpecialProject, 'id'>
      & { documents: Array<(
        { __typename?: 'Document' }
        & DocumentFilePropsFragment
      )> }
    )> }
  ) }
);

export type SpecialProjectsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  includeClosedOrCancelled: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
}>;


export type SpecialProjectsQuery = (
  { __typename?: 'Query' }
  & { specialProjects?: Maybe<(
    { __typename?: 'SpecialProjectsConnection' }
    & Pick<SpecialProjectsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
    ), nodes?: Maybe<Array<(
      { __typename?: 'SpecialProject' }
      & SpecialProjectsPropsFragment
    )>> }
  )> }
);

export type SpecialProjectsPropsFragment = (
  { __typename?: 'SpecialProject' }
  & Pick<SpecialProject, 'id' | 'city' | 'title' | 'start' | 'end' | 'order' | 'description' | 'campaign'>
  & { logo?: Maybe<(
    { __typename?: 'FileInformation' }
    & PhotoFilePropsFragment
  )>, categories: Array<(
    { __typename?: 'Category' }
    & CategoryPropsFragment
  )>, feedImage: (
    { __typename?: 'FileInformation' }
    & PhotoFilePropsFragment
  ), images: Array<(
    { __typename?: 'FileInformation' }
    & PhotoFilePropsFragment
  )>, address?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'fullAddress' | 'displayText'>
  )>, account: (
    { __typename?: 'BenefactorDepositAccountForSpecificWards' }
    & Pick<BenefactorDepositAccountForSpecificWards, 'id' | 'amount'>
  ) | (
    { __typename?: 'RegularNeedsAccount' }
    & Pick<RegularNeedsAccount, 'id' | 'amount'>
  ) | (
    { __typename?: 'BenefactorDepositAccountForAllWards' }
    & Pick<BenefactorDepositAccountForAllWards, 'id' | 'amount'>
  ) | (
    { __typename?: 'BenefactorDepositAccountForProjectNeeds' }
    & Pick<BenefactorDepositAccountForProjectNeeds, 'id' | 'amount'>
  ) | (
    { __typename?: 'BenefactorDepositAccountWithoutConditions' }
    & Pick<BenefactorDepositAccountWithoutConditions, 'id' | 'amount'>
  ) | (
    { __typename?: 'OneTimeNeedAccount' }
    & Pick<OneTimeNeedAccount, 'id' | 'amount'>
  ) | (
    { __typename?: 'ProjectExpenseAccount' }
    & Pick<ProjectExpenseAccount, 'id' | 'amount'>
  ) | (
    { __typename?: 'RegularNeedPeriodAccount' }
    & Pick<RegularNeedPeriodAccount, 'id' | 'amount'>
  ) | (
    { __typename?: 'SpecialProjectAccount' }
    & Pick<SpecialProjectAccount, 'requiredAmount' | 'id' | 'amount'>
  ) | (
    { __typename?: 'SystemAccount' }
    & Pick<SystemAccount, 'id' | 'amount'>
  ), created: (
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  ), cancelled?: Maybe<(
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  )>, closed?: Maybe<(
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  )>, published?: Maybe<(
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  )>, reportSent?: Maybe<(
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  )>, documents: Array<(
    { __typename?: 'Document' }
    & DocumentFilePropsFragment
  )> }
);

export type AddStoryBlockMutationVariables = Exact<{
  input: AddStoryBlockInput;
}>;


export type AddStoryBlockMutation = (
  { __typename?: 'Mutation' }
  & { addStoryBlock: (
    { __typename?: 'AddStoryBlockPayload' }
    & { storyBlock?: Maybe<(
      { __typename?: 'StoryBlock' }
      & Pick<StoryBlock, 'title' | 'label'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'StoryBlockWithSpecifiedLabelAlreadyExistsError' }
      & Pick<StoryBlockWithSpecifiedLabelAlreadyExistsError, 'message'>
    )>> }
  ) }
);

export type UpdateStoryBlockMutationVariables = Exact<{
  input: UpdateStoryBlockInput;
}>;


export type UpdateStoryBlockMutation = (
  { __typename?: 'Mutation' }
  & { updateStoryBlock: (
    { __typename?: 'UpdateStoryBlockPayload' }
    & { storyBlock?: Maybe<(
      { __typename?: 'StoryBlock' }
      & BlockStoriesPropsFragment
    )> }
  ) }
);

export type AddStoryMutationVariables = Exact<{
  input: AddStoryInput;
}>;


export type AddStoryMutation = (
  { __typename?: 'Mutation' }
  & { addStory: (
    { __typename?: 'AddStoryPayload' }
    & { story?: Maybe<(
      { __typename?: 'Story' }
      & Pick<Story, 'id' | 'title' | 'order'>
    )> }
  ) }
);

export type DeleteStoryMutationVariables = Exact<{
  input: DeleteStoryInput;
}>;


export type DeleteStoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteStory: (
    { __typename?: 'DeleteStoryPayload' }
    & Pick<DeleteStoryPayload, 'success'>
  ) }
);

export type ChangeStoriesOrderMutationVariables = Exact<{
  input: ChangeStoriesOrderInput;
}>;


export type ChangeStoriesOrderMutation = (
  { __typename?: 'Mutation' }
  & { changeStoriesOrder: (
    { __typename?: 'ChangeStoriesOrderPayload' }
    & Pick<ChangeStoriesOrderPayload, 'success'>
  ) }
);

export type PublishStoryMutationVariables = Exact<{
  input: PublishStoryInput;
}>;


export type PublishStoryMutation = (
  { __typename?: 'Mutation' }
  & { publishStory: (
    { __typename?: 'PublishStoryPayload' }
    & { story?: Maybe<(
      { __typename?: 'Story' }
      & StoryPropsFragment
    )>, errors?: Maybe<Array<(
      { __typename?: 'StoryWithoutScreensCanNotBePublishedError' }
      & Pick<StoryWithoutScreensCanNotBePublishedError, 'message'>
    )>> }
  ) }
);

export type StoryByIdQueryVariables = Exact<{
  id: Scalars['StoryId'];
}>;


export type StoryByIdQuery = (
  { __typename?: 'Query' }
  & { storyById?: Maybe<(
    { __typename?: 'Story' }
    & StoryPropsFragment
  )> }
);

export type StoryPropsFragment = (
  { __typename?: 'Story' }
  & Pick<Story, 'id' | 'title' | 'order'>
  & { image?: Maybe<(
    { __typename?: 'FileInformation' }
    & PhotoFilePropsFragment
  )>, screens: Array<(
    { __typename?: 'StoryScreen' }
    & ScreenStoryPropsFragment
  )>, published?: Maybe<(
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  )>, updated: (
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  ), created: (
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  ) }
);

export type UnpublishStoryMutationVariables = Exact<{
  input: UnpublishStoryInput;
}>;


export type UnpublishStoryMutation = (
  { __typename?: 'Mutation' }
  & { unpublishStory: (
    { __typename?: 'UnpublishStoryPayload' }
    & { story?: Maybe<(
      { __typename?: 'Story' }
      & StoryPropsFragment
    )> }
  ) }
);

export type UpdateStoryMutationVariables = Exact<{
  input: UpdateStoryInput;
}>;


export type UpdateStoryMutation = (
  { __typename?: 'Mutation' }
  & { updateStory: (
    { __typename?: 'UpdateStoryPayload' }
    & { story?: Maybe<(
      { __typename?: 'Story' }
      & Pick<Story, 'id' | 'order'>
      & { screens: Array<(
        { __typename?: 'StoryScreen' }
        & ScreenStoryPropsFragment
      )> }
    )> }
  ) }
);

export type UpdateStoryScreensMutationVariables = Exact<{
  input: UpdateStoryScreensInput;
}>;


export type UpdateStoryScreensMutation = (
  { __typename?: 'Mutation' }
  & { updateStoryScreens: (
    { __typename?: 'UpdateStoryScreensPayload' }
    & { story?: Maybe<(
      { __typename?: 'Story' }
      & Pick<Story, 'id'>
    )> }
  ) }
);

export type BlockStoriesPropsFragment = (
  { __typename?: 'StoryBlock' }
  & Pick<StoryBlock, 'id' | 'title' | 'label'>
  & { stories?: Maybe<(
    { __typename?: 'StoriesConnection' }
    & { nodes?: Maybe<Array<(
      { __typename?: 'Story' }
      & Pick<Story, 'id' | 'title' | 'order'>
      & { created: (
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      ), published?: Maybe<(
        { __typename?: 'AuditMarker' }
        & AuditMarkerPropsFragment
      )>, screens: Array<(
        { __typename?: 'StoryScreen' }
        & ScreenStoryPropsFragment
      )> }
    )>> }
  )> }
);

export type ScreenStoryPropsFragment = (
  { __typename?: 'StoryScreen' }
  & Pick<StoryScreen, 'title' | 'buttonTitle' | 'buttonUrl' | 'text' | 'theme'>
  & { image?: Maybe<(
    { __typename?: 'FileInformation' }
    & Pick<FileInformation, 'id' | 'url'>
  )> }
);

export type StoryBlockByIdQueryVariables = Exact<{
  id: Scalars['StoryBlockId'];
}>;


export type StoryBlockByIdQuery = (
  { __typename?: 'Query' }
  & { storyBlockById?: Maybe<(
    { __typename?: 'StoryBlock' }
    & BlockStoriesPropsFragment
  )> }
);

export type StoryBlockQueryVariables = Exact<{ [key: string]: never; }>;


export type StoryBlockQuery = (
  { __typename?: 'Query' }
  & { storyBlocks: Array<(
    { __typename?: 'StoryBlock' }
    & BlockStoriesPropsFragment
  )> }
);

export type AddClientVersionMutationVariables = Exact<{
  input: AddClientVersionInput;
}>;


export type AddClientVersionMutation = (
  { __typename?: 'Mutation' }
  & { addClientVersion: (
    { __typename?: 'AddClientVersionPayload' }
    & { version?: Maybe<(
      { __typename?: 'ClientVersion' }
      & VersionPropsFragment
    )> }
  ) }
);

export type ClientVersionsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type ClientVersionsQuery = (
  { __typename?: 'Query' }
  & { clientVersions?: Maybe<(
    { __typename?: 'ClientVersionsConnection' }
    & Pick<ClientVersionsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
    ), nodes?: Maybe<Array<(
      { __typename?: 'ClientVersion' }
      & VersionPropsFragment
    )>> }
  )> }
);

export type VersionPropsFragment = (
  { __typename?: 'ClientVersion' }
  & Pick<ClientVersion, 'id' | 'client' | 'major' | 'mandatory' | 'minor' | 'patch'>
);

export type AddWardMutationVariables = Exact<{
  input: AddWardInput;
}>;


export type AddWardMutation = (
  { __typename?: 'Mutation' }
  & { addWard: (
    { __typename?: 'AddWardPayload' }
    & { ward?: Maybe<(
      { __typename?: 'Ward' }
      & Pick<Ward, 'id'>
    )> }
  ) }
);

export type AddWardsToTheBenefactorDepositAccountForSpecificWardsMutationVariables = Exact<{
  input: AddWardsToTheBenefactorDepositAccountForSpecificWardsInput;
}>;


export type AddWardsToTheBenefactorDepositAccountForSpecificWardsMutation = (
  { __typename?: 'Mutation' }
  & { addWardsToTheBenefactorDepositAccountForSpecificWards: (
    { __typename?: 'AddWardsToTheBenefactorDepositAccountForSpecificWardsPayload' }
    & { benefactorDepositAccountForSpecificWards?: Maybe<(
      { __typename?: 'BenefactorDepositAccountForSpecificWards' }
      & Pick<BenefactorDepositAccountForSpecificWards, 'title'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & Pick<Benefactor, 'id' | 'displayName'>
      ) }
    )> }
  ) }
);

export type BenefactorsWithDepositAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type BenefactorsWithDepositAccountsQuery = (
  { __typename?: 'Query' }
  & { benefactorsWithDepositAccounts?: Maybe<(
    { __typename?: 'BenefactorsWithDepositAccountsConnection' }
    & { nodes?: Maybe<Array<(
      { __typename?: 'BenefactorAndDepositAccountsAmount' }
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & Pick<Benefactor, 'displayName'>
        & { depositAccounts: Array<(
          { __typename: 'BenefactorDepositAccountForSpecificWards' }
          & Pick<BenefactorDepositAccountForSpecificWards, 'title' | 'id'>
        ) | (
          { __typename: 'RegularNeedsAccount' }
          & Pick<RegularNeedsAccount, 'id'>
        ) | (
          { __typename: 'BenefactorDepositAccountForAllWards' }
          & Pick<BenefactorDepositAccountForAllWards, 'id'>
        ) | (
          { __typename: 'BenefactorDepositAccountForProjectNeeds' }
          & Pick<BenefactorDepositAccountForProjectNeeds, 'id'>
        ) | (
          { __typename: 'BenefactorDepositAccountWithoutConditions' }
          & Pick<BenefactorDepositAccountWithoutConditions, 'id'>
        ) | (
          { __typename: 'OneTimeNeedAccount' }
          & Pick<OneTimeNeedAccount, 'id'>
        ) | (
          { __typename: 'ProjectExpenseAccount' }
          & Pick<ProjectExpenseAccount, 'id'>
        ) | (
          { __typename: 'RegularNeedPeriodAccount' }
          & Pick<RegularNeedPeriodAccount, 'id'>
        ) | (
          { __typename: 'SpecialProjectAccount' }
          & Pick<SpecialProjectAccount, 'id'>
        ) | (
          { __typename: 'SystemAccount' }
          & Pick<SystemAccount, 'id'>
        )> }
      ) }
    )>> }
  )> }
);

export type RemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutationVariables = Exact<{
  input: RemoveWardFromTheBenefactorDepositAccountForSpecificWardsInput;
}>;


export type RemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutation = (
  { __typename?: 'Mutation' }
  & { removeWardFromTheBenefactorDepositAccountForSpecificWards: (
    { __typename?: 'RemoveWardFromTheBenefactorDepositAccountForSpecificWardsPayload' }
    & { benefactorDepositAccountForSpecificWards?: Maybe<(
      { __typename?: 'BenefactorDepositAccountForSpecificWards' }
      & Pick<BenefactorDepositAccountForSpecificWards, 'title'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & Pick<Benefactor, 'id' | 'displayName'>
      ) }
    )> }
  ) }
);

export type UpdateWardMutationVariables = Exact<{
  input: UpdateWardInput;
}>;


export type UpdateWardMutation = (
  { __typename?: 'Mutation' }
  & { updateWard: (
    { __typename?: 'UpdateWardPayload' }
    & { ward?: Maybe<(
      { __typename?: 'Ward' }
      & WardPropsFragment
    )> }
  ) }
);

export type PublishWardMutationVariables = Exact<{
  input: PublishWardInput;
}>;


export type PublishWardMutation = (
  { __typename?: 'Mutation' }
  & { publishWard: (
    { __typename?: 'PublishWardPayload' }
    & { ward?: Maybe<(
      { __typename?: 'Ward' }
      & WardPropsFragment
    )> }
  ) }
);

export type UnpublishWardMutationVariables = Exact<{
  input: UnpublishWardInput;
}>;


export type UnpublishWardMutation = (
  { __typename?: 'Mutation' }
  & { unpublishWard: (
    { __typename?: 'UnpublishWardPayload' }
    & { ward?: Maybe<(
      { __typename?: 'Ward' }
      & WardPropsFragment
    )> }
  ) }
);

export type WardPropsFragment = (
  { __typename?: 'Ward' }
  & Pick<Ward, 'id' | 'published' | 'immediateHelpRequired' | 'subscriptionTransferEnabled'>
  & { created: (
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  ), updated: (
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  ), deactivationInformation?: Maybe<(
    { __typename?: 'WardDeactivationInformation' }
    & Pick<WardDeactivationInformation, 'date' | 'reason'>
  )>, countsAndAmounts: (
    { __typename?: 'WardCountsAndAmounts' }
    & Pick<WardCountsAndAmounts, 'walletSubscriptionDonationsAmount' | 'walletNonSubscriptionDonationsAmount' | 'subscriptionsDeficit' | 'activeSubscriptionsAmount' | 'activeRegularNeedsRequiredAmount' | 'totalSubscriptionsAmount' | 'maximumAllowedRegularNeedsAmount'>
  ), regularNeeds: (
    { __typename?: 'WardRegularNeeds' }
    & Pick<WardRegularNeeds, 'existingSubscriptionsAmount' | 'newSubscriptionsAmount' | 'oneTimeDonationsAmount'>
  ), publicInformation: (
    { __typename?: 'WardPublicInformation' }
    & Pick<WardPublicInformation, 'dateOfBirth' | 'dateOfDeath' | 'gender' | 'city' | 'publicLatitude' | 'publicLongitude' | 'story'>
    & { name: (
      { __typename?: 'Nomen' }
      & Pick<Nomen, 'firstName' | 'lastName' | 'middleName' | 'displayName' | 'fullName'>
    ), categories: Array<(
      { __typename?: 'Category' }
      & CategoryPropsFragment
    )>, photo: (
      { __typename?: 'FileInformation' }
      & Pick<FileInformation, 'id' | 'url'>
      & { originalUrl: FileInformation['url'] }
    ) }
  ), privateInformation?: Maybe<(
    { __typename?: 'WardPrivateInformation' }
    & Pick<WardPrivateInformation, 'comments' | 'disabilityGroup' | 'maritalStatus' | 'profession' | 'rank' | 'phoneNumber'>
    & { address: (
      { __typename?: 'Address' }
      & Pick<Address, 'displayText'>
    ), documents: Array<(
      { __typename?: 'Document' }
      & DocumentFilePropsFragment
    )>, passport: (
      { __typename?: 'Passport' }
      & Pick<Passport, 'dateOfIssue' | 'issuer' | 'issuerCode' | 'number' | 'placeOfBirth' | 'series'>
    ) }
  )> }
);

export type WardByIdQueryVariables = Exact<{
  id: Scalars['WardId'];
  permission: Scalars['Boolean'];
}>;


export type WardByIdQuery = (
  { __typename?: 'Query' }
  & { wardById?: Maybe<(
    { __typename?: 'Ward' }
    & { needs: Array<(
      { __typename?: 'OneTimeNeed' }
      & NeedProps_OneTimeNeed_Fragment
    ) | (
      { __typename?: 'GrocerySetNeed' }
      & NeedProps_GrocerySetNeed_Fragment
    )>, benefactorDepositAccounts?: Maybe<Array<(
      { __typename?: 'BenefactorDepositAccountForSpecificWards' }
      & Pick<BenefactorDepositAccountForSpecificWards, 'id' | 'title'>
      & { benefactor: (
        { __typename?: 'Benefactor' }
        & Pick<Benefactor, 'id' | 'displayName'>
      ) }
    )>> }
    & WardPropsFragment
  )> }
);

export type DeactivateWardMutationVariables = Exact<{
  input: DeactivateWardInput;
}>;


export type DeactivateWardMutation = (
  { __typename?: 'Mutation' }
  & { deactivateWard: (
    { __typename?: 'DeactivateWardPayload' }
    & { ward?: Maybe<(
      { __typename?: 'Ward' }
      & { benefactorDepositAccounts: Array<(
        { __typename?: 'BenefactorDepositAccountForSpecificWards' }
        & { benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
        ) }
      )> }
      & WardPropsFragment
    )> }
  ) }
);

export type UpdateWardDocumentsMutationVariables = Exact<{
  input: UpdateWardDocumentsInput;
}>;


export type UpdateWardDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { updateWardDocuments: (
    { __typename?: 'UpdateWardDocumentsPayload' }
    & { ward?: Maybe<(
      { __typename?: 'Ward' }
      & WardPropsFragment
    )> }
  ) }
);

export type WardNeedAccountsByIdsQueryVariables = Exact<{
  needId: Scalars['NeedId'];
}>;


export type WardNeedAccountsByIdsQuery = (
  { __typename?: 'Query' }
  & { needById?: Maybe<(
    { __typename?: 'OneTimeNeed' }
    & Pick<OneTimeNeed, 'id' | 'requiredAmount'>
  ) | (
    { __typename?: 'GrocerySetNeed' }
    & Pick<GrocerySetNeed, 'id' | 'requiredAmount'>
  )> }
);

export type UpdateOneTimeNeedDocumentsMutationVariables = Exact<{
  input: UpdateOneTimeNeedDocumentsInput;
}>;


export type UpdateOneTimeNeedDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { updateOneTimeNeedDocuments: (
    { __typename?: 'UpdateOneTimeNeedDocumentsPayload' }
    & { oneTimeNeed?: Maybe<(
      { __typename?: 'OneTimeNeed' }
      & { documents: Array<(
        { __typename?: 'Document' }
        & DocumentFilePropsFragment
      )> }
    )> }
  ) }
);

export type RegularNeedPeriodByIdQueryVariables = Exact<{
  id: Scalars['RegularNeedPeriodId'];
}>;


export type RegularNeedPeriodByIdQuery = (
  { __typename?: 'Query' }
  & { regularNeedPeriodById?: Maybe<(
    { __typename?: 'RegularNeedPeriod' }
    & RegularNeedPeriodPropsFragment
  )> }
);

export type UpdateRegularNeedPeriodDocumentsMutationVariables = Exact<{
  input: UpdateRegularNeedPeriodDocumentsInput;
}>;


export type UpdateRegularNeedPeriodDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { updateRegularNeedPeriodDocuments: (
    { __typename?: 'UpdateRegularNeedPeriodDocumentsPayload' }
    & { regularNeedPeriod?: Maybe<(
      { __typename?: 'RegularNeedPeriod' }
      & Pick<RegularNeedPeriod, 'id'>
      & { documents: Array<(
        { __typename?: 'Document' }
        & DocumentFilePropsFragment
      )> }
    )> }
  ) }
);

export type RegularNeedPeriodsQueryVariables = Exact<{
  id: Scalars['WardId'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type RegularNeedPeriodsQuery = (
  { __typename?: 'Query' }
  & { wardById?: Maybe<(
    { __typename?: 'Ward' }
    & { needs: Array<(
      { __typename?: 'OneTimeNeed' }
      & Pick<OneTimeNeed, 'id'>
    ) | (
      { __typename?: 'GrocerySetNeed' }
      & Pick<GrocerySetNeed, 'id'>
      & { periods?: Maybe<(
        { __typename?: 'RegularNeedPeriodsConnection' }
        & Pick<RegularNeedPeriodsConnection, 'totalCount'>
        & { pageInfo: (
          { __typename?: 'PageInfo' }
          & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
        ), nodes?: Maybe<Array<Maybe<(
          { __typename?: 'RegularNeedPeriod' }
          & RegularNeedPeriodPropsFragment
        )>>> }
      )> }
    )> }
  )> }
);

export type ArchiveNeedMutationVariables = Exact<{
  input: ArchiveNeedInput;
}>;


export type ArchiveNeedMutation = (
  { __typename?: 'Mutation' }
  & { archiveNeed: (
    { __typename?: 'ArchiveNeedPayload' }
    & { need?: Maybe<(
      { __typename?: 'OneTimeNeed' }
      & Pick<OneTimeNeed, 'id'>
    ) | (
      { __typename?: 'GrocerySetNeed' }
      & Pick<GrocerySetNeed, 'id'>
    )> }
  ) }
);

export type RestInPeaceMutationVariables = Exact<{
  input: RestInPeaceInput;
}>;


export type RestInPeaceMutation = (
  { __typename?: 'Mutation' }
  & { restInPeace: (
    { __typename?: 'RestInPeacePayload' }
    & { ward?: Maybe<(
      { __typename?: 'Ward' }
      & { benefactorDepositAccounts: Array<(
        { __typename?: 'BenefactorDepositAccountForSpecificWards' }
        & { benefactor: (
          { __typename?: 'Benefactor' }
          & Pick<Benefactor, 'id' | 'displayName'>
        ) }
      )> }
      & WardPropsFragment
    )> }
  ) }
);

export type WardsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Array<WardSortInput> | WardSortInput>;
  where?: Maybe<WardFilterInput>;
  last?: Maybe<Scalars['Int']>;
}>;


export type WardsQuery = (
  { __typename?: 'Query' }
  & { wards?: Maybe<(
    { __typename?: 'WardsConnection' }
    & Pick<WardsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>
    ), nodes?: Maybe<Array<(
      { __typename?: 'Ward' }
      & WardsGridWardFragment
    )>> }
  )> }
);

export type WardsGridWardFragment = (
  { __typename?: 'Ward' }
  & Pick<Ward, 'id' | 'immediateHelpRequired'>
  & { created: (
    { __typename?: 'AuditMarker' }
    & AuditMarkerPropsFragment
  ), deactivationInformation?: Maybe<(
    { __typename?: 'WardDeactivationInformation' }
    & Pick<WardDeactivationInformation, 'date' | 'reason'>
  )>, countsAndAmounts: (
    { __typename?: 'WardCountsAndAmounts' }
    & Pick<WardCountsAndAmounts, 'totalOneTimeNeedsCount' | 'totalRegularNeedsCount' | 'walletNonSubscriptionDonationsAmount' | 'subscriptionsDeficit' | 'activeSubscriptionsAmount' | 'activeRegularNeedsRequiredAmount' | 'totalSubscriptionsAmount'>
  ), needs: Array<(
    { __typename?: 'OneTimeNeed' }
    & Pick<OneTimeNeed, 'title' | 'id'>
    & { archived?: Maybe<(
      { __typename?: 'AuditMarker' }
      & AuditMarkerPropsFragment
    )> }
  ) | (
    { __typename?: 'GrocerySetNeed' }
    & Pick<GrocerySetNeed, 'id'>
    & { archived?: Maybe<(
      { __typename?: 'AuditMarker' }
      & AuditMarkerPropsFragment
    )> }
  )>, regularNeeds: (
    { __typename?: 'WardRegularNeeds' }
    & Pick<WardRegularNeeds, 'oneTimeDonationsAmount'>
  ), publicInformation: (
    { __typename?: 'WardPublicInformation' }
    & Pick<WardPublicInformation, 'city' | 'dateOfDeath'>
    & { name: (
      { __typename?: 'Nomen' }
      & Pick<Nomen, 'displayName' | 'fullName'>
    ), photo: (
      { __typename?: 'FileInformation' }
      & { avatar: FileInformation['url'] }
      & PhotoFilePropsFragment
    ) }
  ) }
);

export type MarkWardAsNotRequiringImmediateHelpMutationVariables = Exact<{
  input: MarkWardAsNotRequiringImmediateHelpInput;
}>;


export type MarkWardAsNotRequiringImmediateHelpMutation = (
  { __typename?: 'Mutation' }
  & { markWardAsNotRequiringImmediateHelp: (
    { __typename?: 'MarkWardAsNotRequiringImmediateHelpPayload' }
    & { ward?: Maybe<(
      { __typename?: 'Ward' }
      & Pick<Ward, 'id' | 'immediateHelpRequired'>
    )> }
  ) }
);

export type MarkWardAsRequiringImmediateHelpMutationVariables = Exact<{
  input: MarkWardAsRequiringImmediateHelpInput;
}>;


export type MarkWardAsRequiringImmediateHelpMutation = (
  { __typename?: 'Mutation' }
  & { markWardAsRequiringImmediateHelp: (
    { __typename?: 'MarkWardAsRequiringImmediateHelpPayload' }
    & { ward?: Maybe<(
      { __typename?: 'Ward' }
      & Pick<Ward, 'id' | 'immediateHelpRequired'>
    )> }
  ) }
);

export type DisableSubscriptionTransferToWardMutationVariables = Exact<{
  input: DisableSubscriptionTransferToWardInput;
}>;


export type DisableSubscriptionTransferToWardMutation = (
  { __typename?: 'Mutation' }
  & { disableSubscriptionTransferToWard: (
    { __typename?: 'DisableSubscriptionTransferToWardPayload' }
    & { ward?: Maybe<(
      { __typename?: 'Ward' }
      & Pick<Ward, 'id' | 'subscriptionTransferEnabled'>
    )> }
  ) }
);

export type EnableSubscriptionTransferToWardMutationVariables = Exact<{
  input: EnableSubscriptionTransferToWardInput;
}>;


export type EnableSubscriptionTransferToWardMutation = (
  { __typename?: 'Mutation' }
  & { enableSubscriptionTransferToWard: (
    { __typename?: 'EnableSubscriptionTransferToWardPayload' }
    & { ward?: Maybe<(
      { __typename?: 'Ward' }
      & Pick<Ward, 'id' | 'subscriptionTransferEnabled'>
    )> }
  ) }
);

export type AuditMarkerPropsFragment = (
  { __typename?: 'AuditMarker' }
  & Pick<AuditMarker, 'timestamp'>
  & { user?: Maybe<(
    { __typename?: 'BenefactorIdentity' }
    & Pick<BenefactorIdentity, 'id' | 'disabled' | 'displayName'>
  ) | (
    { __typename?: 'FoundationRepresentative' }
    & Pick<FoundationRepresentative, 'id' | 'disabled' | 'displayName'>
  ) | (
    { __typename?: 'Moderator' }
    & Pick<Moderator, 'id' | 'disabled' | 'displayName'>
  ) | (
    { __typename?: 'SystemAdministrator' }
    & Pick<SystemAdministrator, 'id' | 'disabled' | 'displayName'>
  )> }
);

export type DocumentFilePropsFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'title'>
  & { file: (
    { __typename?: 'FileInformation' }
    & Pick<FileInformation, 'id' | 'url'>
  ) }
);

export type PhotoFilePropsFragment = (
  { __typename?: 'FileInformation' }
  & Pick<FileInformation, 'id' | 'url'>
);

type UserBaseProps_BenefactorIdentity_Fragment = (
  { __typename?: 'BenefactorIdentity' }
  & Pick<BenefactorIdentity, 'id' | 'disabled'>
);

type UserBaseProps_FoundationRepresentative_Fragment = (
  { __typename?: 'FoundationRepresentative' }
  & Pick<FoundationRepresentative, 'id' | 'disabled'>
);

type UserBaseProps_Moderator_Fragment = (
  { __typename?: 'Moderator' }
  & Pick<Moderator, 'id' | 'disabled'>
);

type UserBaseProps_SystemAdministrator_Fragment = (
  { __typename?: 'SystemAdministrator' }
  & Pick<SystemAdministrator, 'id' | 'disabled'>
);

export type UserBasePropsFragment = UserBaseProps_BenefactorIdentity_Fragment | UserBaseProps_FoundationRepresentative_Fragment | UserBaseProps_Moderator_Fragment | UserBaseProps_SystemAdministrator_Fragment;

type UserProps_BenefactorIdentity_Fragment = (
  { __typename?: 'BenefactorIdentity' }
  & Pick<BenefactorIdentity, 'createdOn' | 'displayName' | 'email' | 'phoneNumber'>
  & UserBaseProps_BenefactorIdentity_Fragment
);

type UserProps_FoundationRepresentative_Fragment = (
  { __typename?: 'FoundationRepresentative' }
  & Pick<FoundationRepresentative, 'createdOn' | 'displayName' | 'email' | 'phoneNumber'>
  & UserBaseProps_FoundationRepresentative_Fragment
);

type UserProps_Moderator_Fragment = (
  { __typename?: 'Moderator' }
  & Pick<Moderator, 'createdOn' | 'displayName' | 'email' | 'phoneNumber'>
  & UserBaseProps_Moderator_Fragment
);

type UserProps_SystemAdministrator_Fragment = (
  { __typename?: 'SystemAdministrator' }
  & Pick<SystemAdministrator, 'createdOn' | 'displayName' | 'email' | 'phoneNumber'>
  & UserBaseProps_SystemAdministrator_Fragment
);

export type UserPropsFragment = UserProps_BenefactorIdentity_Fragment | UserProps_FoundationRepresentative_Fragment | UserProps_Moderator_Fragment | UserProps_SystemAdministrator_Fragment;

export const PhotoFilePropsFragmentDoc = gql`
    fragment PhotoFileProps on FileInformation {
  id
  url(asAttachment: false)
}
    `;
export const AuditMarkerPropsFragmentDoc = gql`
    fragment AuditMarkerProps on AuditMarker {
  timestamp
  user {
    id
    disabled
    displayName
  }
}
    `;
export const ScreenStoryPropsFragmentDoc = gql`
    fragment screenStoryProps on StoryScreen {
  title
  buttonTitle
  buttonUrl
  text
  theme
  image {
    id
    url(asAttachment: false)
  }
}
    `;
export const StoryPropsFragmentDoc = gql`
    fragment StoryProps on Story {
  id
  title
  order
  image {
    ...PhotoFileProps
  }
  screens {
    ...screenStoryProps
  }
  published {
    ...AuditMarkerProps
  }
  updated {
    ...AuditMarkerProps
  }
  created {
    ...AuditMarkerProps
  }
}
    ${PhotoFilePropsFragmentDoc}
${ScreenStoryPropsFragmentDoc}
${AuditMarkerPropsFragmentDoc}`;
export const AchievementsPropsFragmentDoc = gql`
    fragment AchievementsProps on Achievement {
  id
  title
  coins
  topPosition
  description
  isHighlighted
  topImage {
    ...PhotoFileProps
  }
  __typename
  created {
    ...AuditMarkerProps
  }
  published {
    ...AuditMarkerProps
  }
  image {
    ...PhotoFileProps
  }
  story {
    ...StoryProps
  }
  updated {
    ...AuditMarkerProps
  }
}
    ${PhotoFilePropsFragmentDoc}
${AuditMarkerPropsFragmentDoc}
${StoryPropsFragmentDoc}`;
export const BenefactorStatusPropsFragmentDoc = gql`
    fragment BenefactorStatusProps on BenefactorStatus {
  id
  title
  coins
  description
  image {
    ...PhotoFileProps
  }
  story {
    ...StoryProps
  }
  __typename
}
    ${PhotoFilePropsFragmentDoc}
${StoryPropsFragmentDoc}`;
export const CloudPaymentsSavedCardsPropsFragmentDoc = gql`
    fragment cloudPaymentsSavedCardsProps on CloudPaymentsSavedCard {
  id
  maskedCardNumber
  failureMessage
  failuresCount
  cardType
  cardExpirationDate
}
    `;
export const BenefactorExtendedPropsFragmentDoc = gql`
    fragment BenefactorExtendedProps on Benefactor {
  id
  displayName
  phoneNumber
  createdAt
  email
  hideProfile
  user {
    disabled
  }
  skillsInformation {
    skills {
      id
      title
      description
      portfolioIsRequired
    }
    experience
    portfolio
  }
  personalData {
    name {
      fullName
      firstName
      lastName
      middleName
    }
    birthday
    about
    vkUsername
  }
  intentsToRecoverCancelledSubscriptions {
    ... on ProjectSubscriptionIntent {
      amount
      __typename
    }
    ... on WardsSubscriptionIntent {
      __typename
      commissionPercentage
      isCommissionCoveredByBenefactor
      wardsSubscriptionsAmounts {
        amount
        ward {
          publicInformation {
            name {
              fullName
            }
          }
          id
        }
      }
    }
  }
  amountOfDonationsInWardsReserveAccount
  volunteerInformation {
    documents {
      file {
        id
        url
      }
      title
    }
    experience
  }
  orders {
    totalCount
    nodes {
      id
      status
    }
  }
  cloudPaymentsSavedCards {
    ...cloudPaymentsSavedCardsProps
  }
  defaultCloudPaymentsSavedCard {
    ...cloudPaymentsSavedCardsProps
  }
  coins
  citiesOfInterest
  wardsCount
  photo {
    id
    url
  }
}
    ${CloudPaymentsSavedCardsPropsFragmentDoc}`;
export const BenefactorExtendedPropsWithoutPermissionFragmentDoc = gql`
    fragment BenefactorExtendedPropsWithoutPermission on Benefactor {
  id
  displayName
  phoneNumber
  createdAt
  email
  hideProfile
  user {
    disabled
  }
  skillsInformation {
    skills {
      id
      title
      description
      portfolioIsRequired
    }
    experience
    portfolio
  }
  personalData {
    name {
      fullName
      firstName
      lastName
      middleName
    }
    birthday
    about
    vkUsername
  }
  intentsToRecoverCancelledSubscriptions {
    ... on ProjectSubscriptionIntent {
      amount
      __typename
    }
    ... on WardsSubscriptionIntent {
      __typename
      commissionPercentage
      isCommissionCoveredByBenefactor
      wardsSubscriptionsAmounts {
        amount
        ward {
          publicInformation {
            name {
              fullName
            }
          }
          id
        }
      }
    }
  }
  amountOfDonationsInWardsReserveAccount
  volunteerInformation {
    documents {
      file {
        id
        url
      }
      title
    }
    experience
  }
  coins
  citiesOfInterest
  wardsCount
  photo {
    id
    url
  }
}
    `;
export const WardsGridWardFragmentDoc = gql`
    fragment WardsGridWard on Ward {
  id
  created {
    ...AuditMarkerProps
  }
  deactivationInformation {
    date
    reason
  }
  immediateHelpRequired
  countsAndAmounts {
    totalOneTimeNeedsCount
    totalRegularNeedsCount
    walletNonSubscriptionDonationsAmount
    subscriptionsDeficit
    activeSubscriptionsAmount
    activeRegularNeedsRequiredAmount
    totalSubscriptionsAmount
  }
  needs {
    id
    archived {
      ...AuditMarkerProps
    }
    ... on OneTimeNeed {
      title
    }
  }
  countsAndAmounts {
    walletNonSubscriptionDonationsAmount
    subscriptionsDeficit
    activeSubscriptionsAmount
    activeRegularNeedsRequiredAmount
    totalSubscriptionsAmount
  }
  regularNeeds {
    oneTimeDonationsAmount
  }
  publicInformation {
    name {
      displayName
      fullName
    }
    city
    photo {
      ...PhotoFileProps
      avatar: url(asAttachment: false, variant: "medium")
    }
    dateOfDeath
  }
}
    ${AuditMarkerPropsFragmentDoc}
${PhotoFilePropsFragmentDoc}`;
export const BenefactorPaymentInformationFragmentDoc = gql`
    fragment BenefactorPaymentInformation on Benefactor {
  id
  failedPaymentsCount
  paymentsAmount
  depositAccounts {
    __typename
    ... on Account {
      amount
      id
      ... on BenefactorDepositAccountForSpecificWards {
        title
        wards {
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
          ...WardsGridWard
        }
      }
    }
  }
}
    ${WardsGridWardFragmentDoc}`;
export const BenefactorPropsFragmentDoc = gql`
    fragment BenefactorProps on Benefactor {
  id
  displayName
  phoneNumber
  createdAt
  email
  hideProfile
  failedPaymentsCount
  wardsCount
  photo {
    id
    url(asAttachment: false, variant: "medium")
  }
}
    `;
export const CategoryPropsFragmentDoc = gql`
    fragment CategoryProps on Category {
  id
  name
  image {
    ...PhotoFileProps
  }
  order
}
    ${PhotoFilePropsFragmentDoc}`;
export const DocumentFilePropsFragmentDoc = gql`
    fragment DocumentFileProps on Document {
  title
  file {
    id
    url(asAttachment: true)
  }
}
    `;
export const WardPropsFragmentDoc = gql`
    fragment WardProps on Ward {
  id
  published
  immediateHelpRequired
  created {
    ...AuditMarkerProps
  }
  updated {
    ...AuditMarkerProps
  }
  deactivationInformation {
    date
    reason
  }
  subscriptionTransferEnabled
  countsAndAmounts {
    walletSubscriptionDonationsAmount
    walletNonSubscriptionDonationsAmount
    subscriptionsDeficit
    activeSubscriptionsAmount
    activeRegularNeedsRequiredAmount
    totalSubscriptionsAmount
    maximumAllowedRegularNeedsAmount
  }
  regularNeeds {
    existingSubscriptionsAmount
    newSubscriptionsAmount
    oneTimeDonationsAmount
  }
  publicInformation {
    dateOfBirth
    dateOfDeath
    gender
    city
    name {
      firstName
      lastName
      middleName
      displayName
      fullName
    }
    categories {
      ...CategoryProps
    }
    photo {
      id
      url(variant: "medium")
      originalUrl: url
    }
    publicLatitude
    publicLongitude
    story
  }
  privateInformation {
    address {
      displayText
    }
    comments
    disabilityGroup
    documents {
      ...DocumentFileProps
    }
    maritalStatus
    passport {
      dateOfIssue
      issuer
      issuerCode
      number
      placeOfBirth
      series
    }
    profession
    rank
    phoneNumber
  }
}
    ${AuditMarkerPropsFragmentDoc}
${CategoryPropsFragmentDoc}
${DocumentFilePropsFragmentDoc}`;
export const DonationSubscriptionPropsFragmentDoc = gql`
    fragment DonationSubscriptionProps on DonationSubscription {
  id
  amount
  createdAt
  cancelledAt
  ... on WardDonationSubscription {
    commissionAmount
    ward {
      ...WardProps
    }
  }
}
    ${WardPropsFragmentDoc}`;
export const DonationWardInfoFragmentDoc = gql`
    fragment DonationWardInfo on Ward {
  id
  publicInformation {
    name {
      fullName
    }
  }
}
    `;
export const OrderAmountSummaryPropsFragmentDoc = gql`
    fragment OrderAmountSummaryProps on OrderAmountSummary {
  oneTimeProjectDonation
  oneTimeWardDonation
  projectSubscription
  projectSubscriptionCharge
  specialProjectDonation
  oneTimeWardsReserveDonation
  wardsReserveSubscription
  wardsReserveSubscriptionCharge
  wardSubscription
  wardSubscriptionCharge
  commission
}
    `;
export const BankTransferPaymentDetailsPropsFragmentDoc = gql`
    fragment BankTransferPaymentDetailsProps on BankTransferPaymentDetails {
  documentNumber
  counterpartyName
  purpose
  transferDate
}
    `;
export const CloudPaymentsCardFailedPaymentDetailsPropsFragmentDoc = gql`
    fragment CloudPaymentsCardFailedPaymentDetailsProps on CloudPaymentsCardFailedPaymentDetails {
  transactionId
  cardholderMessage
  reasonCode
}
    `;
export const CardSuccessfulPaymentDetailsPropsFragmentDoc = gql`
    fragment CardSuccessfulPaymentDetailsProps on CloudPaymentsCardSuccessfulPaymentDetails {
  authorizationDate
  confirmDate
  transactionId
  authorizationCode
}
    `;
export const GenericFailedPaymentDetailsPropsFragmentDoc = gql`
    fragment GenericFailedPaymentDetailsProps on GenericFailedPaymentDetails {
  failureMessage
}
    `;
export const SbpSuccessfulPaymentDetailsPropsFragmentDoc = gql`
    fragment SbpSuccessfulPaymentDetailsProps on CloudPaymentsSbpSuccessfulPaymentDetails {
  transactionId
}
    `;
export const SbpFailedPaymentDetailsPropsFragmentDoc = gql`
    fragment SbpFailedPaymentDetailsProps on CloudPaymentsSbpFailedPaymentDetails {
  transactionId
  message
}
    `;
export const VoucherSuccessfulPaymentDetailsPropsFragmentDoc = gql`
    fragment VoucherSuccessfulPaymentDetailsProps on VoucherSuccessfulPaymentDetails {
  voucher {
    id
    title
  }
}
    `;
export const VoucherFailedPaymentDetailsPropsFragmentDoc = gql`
    fragment VoucherFailedPaymentDetailsProps on VoucherFailedPaymentDetails {
  reason
  maybeVoucher: voucher {
    id
    title
  }
}
    `;
export const PaymentDetailsPropsFragmentDoc = gql`
    fragment PaymentDetailsProps on PaymentDetails {
  ...BankTransferPaymentDetailsProps
  ...CloudPaymentsCardFailedPaymentDetailsProps
  ...CardSuccessfulPaymentDetailsProps
  ...GenericFailedPaymentDetailsProps
  ...SbpSuccessfulPaymentDetailsProps
  ...SbpFailedPaymentDetailsProps
  ...VoucherSuccessfulPaymentDetailsProps
  ...VoucherFailedPaymentDetailsProps
}
    ${BankTransferPaymentDetailsPropsFragmentDoc}
${CloudPaymentsCardFailedPaymentDetailsPropsFragmentDoc}
${CardSuccessfulPaymentDetailsPropsFragmentDoc}
${GenericFailedPaymentDetailsPropsFragmentDoc}
${SbpSuccessfulPaymentDetailsPropsFragmentDoc}
${SbpFailedPaymentDetailsPropsFragmentDoc}
${VoucherSuccessfulPaymentDetailsPropsFragmentDoc}
${VoucherFailedPaymentDetailsPropsFragmentDoc}`;
export const BankTransferPaymentIntentPropsFragmentDoc = gql`
    fragment BankTransferPaymentIntentProps on BankTransferPaymentIntent {
  transferDate
  documentNumber
  counterpartyName
}
    `;
export const NewCardPaymentIntentPropsFragmentDoc = gql`
    fragment NewCardPaymentIntentProps on CloudPaymentsNewCardPaymentIntent {
  setAsDefaultCard
  saveCard
}
    `;
export const SavedCardPaymentIntentPropsFragmentDoc = gql`
    fragment SavedCardPaymentIntentProps on CloudPaymentsSavedCardPaymentIntent {
  savedCard {
    maskedCardNumber
  }
}
    `;
export const SbpPaymentIntentPropsFragmentDoc = gql`
    fragment SbpPaymentIntentProps on CloudPaymentsSbpPaymentIntent {
  anyValue
}
    `;
export const VoucherPaymentIntentPropsFragmentDoc = gql`
    fragment VoucherPaymentIntentProps on VoucherPaymentIntent {
  voucher {
    id
    title
  }
}
    `;
export const PaymentIntentPropsFragmentDoc = gql`
    fragment PaymentIntentProps on PaymentIntent {
  ...BankTransferPaymentIntentProps
  ...NewCardPaymentIntentProps
  ...SavedCardPaymentIntentProps
  ...SbpPaymentIntentProps
  ...VoucherPaymentIntentProps
}
    ${BankTransferPaymentIntentPropsFragmentDoc}
${NewCardPaymentIntentPropsFragmentDoc}
${SavedCardPaymentIntentPropsFragmentDoc}
${SbpPaymentIntentPropsFragmentDoc}
${VoucherPaymentIntentPropsFragmentDoc}`;
export const OrdersPropsFragmentDoc = gql`
    fragment OrdersProps on Order {
  id
  amount
  status
  failureMessage
  created {
    ...AuditMarkerProps
  }
  paymentDetails {
    ...PaymentDetailsProps
  }
  paymentIntent {
    ...PaymentIntentProps
  }
}
    ${AuditMarkerPropsFragmentDoc}
${PaymentDetailsPropsFragmentDoc}
${PaymentIntentPropsFragmentDoc}`;
export const CityPropsFragmentDoc = gql`
    fragment CityProps on City {
  id
  name
  default
  order
}
    `;
export const CompanyReportPropsFragmentDoc = gql`
    fragment CompanyReportProps on LimitedLiabilityCompanyReport {
  id
  referenceDate
  title
  published {
    ...AuditMarkerProps
  }
  documents {
    ...DocumentFileProps
  }
}
    ${AuditMarkerPropsFragmentDoc}
${DocumentFilePropsFragmentDoc}`;
export const LimitedLiabilityCompanyReportFragmentDoc = gql`
    fragment LimitedLiabilityCompanyReport on LimitedLiabilityCompanyReport {
  id
  title
  updated {
    ...AuditMarkerProps
  }
  published {
    ...AuditMarkerProps
  }
  referenceDate
  documents {
    ...DocumentFileProps
  }
}
    ${AuditMarkerPropsFragmentDoc}
${DocumentFilePropsFragmentDoc}`;
export const StatisticsPeriodFragmentFragmentDoc = gql`
    fragment StatisticsPeriodFragment on StatisticsPeriod {
  start
  end
  transactionsAmount
  completedTransactionsCount
}
    `;
export const EventDetailsPropsFragmentDoc = gql`
    fragment EventDetailsProps on FoundationEventDetails {
  title
  start
  city
  requirements {
    description
    quantity
    autoSelect
    skill {
      id
      title
    }
  }
  phoneNumber
  passportRequired
  meetingInstructions
  images {
    id
    url(asAttachment: true)
  }
  description
  address {
    displayText
    fullAddress
    city {
      valueWithType
    }
  }
  agreementUrl
  agreementFile {
    url(asAttachment: true)
    id
  }
}
    `;
export const FoundationEventPropsFragmentDoc = gql`
    fragment FoundationEventProps on FoundationEvent {
  id
  checkinUrl
  report {
    text
    images {
      title
      file {
        id
        url
      }
    }
  }
  approvalRequested {
    ...AuditMarkerProps
  }
  approved {
    ...AuditMarkerProps
  }
  cancelled {
    ...AuditMarkerProps
  }
  closed {
    ...AuditMarkerProps
  }
  counters {
    applied
    attended
    revoked
    selected
  }
  created {
    ...AuditMarkerProps
  }
  owner {
    ...AuditMarkerProps
  }
  details {
    ...EventDetailsProps
  }
  foundation {
    id
    name
  }
  registrationClosed {
    ...AuditMarkerProps
  }
  updated {
    ...AuditMarkerProps
  }
}
    ${AuditMarkerPropsFragmentDoc}
${EventDetailsPropsFragmentDoc}`;
export const FoundationPropsFragmentDoc = gql`
    fragment FoundationProps on Foundation {
  id
  name
  address {
    fullAddress
    displayText
  }
  logo {
    id
    url
  }
  description
  created {
    ...AuditMarkerProps
  }
  updated {
    ...AuditMarkerProps
  }
  representatives {
    nodes {
      id
      displayName
      disabled
    }
  }
}
    ${AuditMarkerPropsFragmentDoc}`;
export const PaymentWithDonationGridFragmentDoc = gql`
    fragment PaymentWithDonationGrid on Donation {
  id
  createdAt
  amount
  account {
    __typename
    ... on SystemAccount {
      kind
    }
    ... on RegularNeedPeriodAccount {
      need {
        id
      }
      regularNeedPeriod {
        id
        start
      }
      ward {
        id
        publicInformation {
          name {
            fullName
          }
        }
      }
    }
    ... on RegularNeedsAccount {
      ward {
        id
      }
    }
    ... on ProjectExpenseAccount {
      amount
    }
    ... on SpecialProjectAccount {
      specialProject {
        id
        title
      }
    }
  }
}
    `;
export const OneTimeNeedPropsFragmentDoc = gql`
    fragment OneTimeNeedProps on OneTimeNeed {
  id
  title
  targetDate
  requiredAmount
  icon
  account {
    id
    amount
    __typename
    donations {
      nodes {
        ...PaymentWithDonationGrid
      }
    }
    closedAt
  }
  documents {
    ...DocumentFileProps
  }
  reportSent {
    ...AuditMarkerProps
  }
  archived {
    ...AuditMarkerProps
  }
  description
}
    ${PaymentWithDonationGridFragmentDoc}
${DocumentFilePropsFragmentDoc}
${AuditMarkerPropsFragmentDoc}`;
export const AccountWardInformationFragmentDoc = gql`
    fragment AccountWardInformation on Ward {
  id
  publicInformation {
    name {
      fullName
    }
  }
}
    `;
export const SpecialProjectAccountPropsFragmentDoc = gql`
    fragment SpecialProjectAccountProps on SpecialProjectAccount {
  id
  amount
  requiredAmount
  specialProject {
    account {
      amount
    }
    id
    title
    start
    end
  }
  transferSources {
    id
    amount
    updatedAt
  }
}
    `;
export const AccountBenefactorInformationFragmentDoc = gql`
    fragment AccountBenefactorInformation on Benefactor {
  id
  displayName
}
    `;
export const AccountFragmentDoc = gql`
    fragment Account on Account {
  id
  updatedAt
  amount
  closedAt
  ... on RegularNeedsAccount {
    __typename
    amount
    ward {
      ...AccountWardInformation
    }
  }
  ... on RegularNeedPeriodAccount {
    transferSources {
      id
    }
  }
  ... on SpecialProjectAccount {
    ...SpecialProjectAccountProps
  }
  ... on SystemAccount {
    transferSources {
      id
    }
    updatedAt
    kind
  }
  ... on ProjectExpenseAccount {
    id
    projectExpense {
      id
    }
    amount
    updatedAt
  }
  ... on BenefactorAccount {
    benefactor {
      ...AccountBenefactorInformation
    }
  }
  ... on BenefactorDepositAccountForSpecificWards {
    title
  }
}
    ${AccountWardInformationFragmentDoc}
${SpecialProjectAccountPropsFragmentDoc}
${AccountBenefactorInformationFragmentDoc}`;
export const RegularNeedPeriodPropsFragmentDoc = gql`
    fragment RegularNeedPeriodProps on RegularNeedPeriod {
  id
  reportSentAt
  createdAt
  account {
    ...Account
  }
  documents {
    ...DocumentFileProps
  }
  start
  end
  need {
    requiredAmount
    archived {
      ...AuditMarkerProps
    }
  }
}
    ${AccountFragmentDoc}
${DocumentFilePropsFragmentDoc}
${AuditMarkerPropsFragmentDoc}`;
export const NeedPropsFragmentDoc = gql`
    fragment NeedProps on Need {
  __typename
  id
  requiredAmount
  ...OneTimeNeedProps
  ... on GrocerySetNeed {
    id
    periods(first: 50) {
      nodes {
        ...RegularNeedPeriodProps
      }
    }
    archived {
      ...AuditMarkerProps
    }
  }
}
    ${OneTimeNeedPropsFragmentDoc}
${RegularNeedPeriodPropsFragmentDoc}
${AuditMarkerPropsFragmentDoc}`;
export const RegularNeedPropsFragmentDoc = gql`
    fragment RegularNeedProps on RegularNeed {
  id
  archived {
    ...AuditMarkerProps
  }
  periods {
    nodes {
      ...RegularNeedPeriodProps
    }
  }
}
    ${AuditMarkerPropsFragmentDoc}
${RegularNeedPeriodPropsFragmentDoc}`;
export const PermissionsPropsFragmentDoc = gql`
    fragment PermissionsProps on Permissions {
  accounts {
    changeStates
    manageDocuments
    transferFunds
    manageDepositAccounts
    viewDepositAccounts
  }
  benefactors {
    create
    list
    manage
  }
  broadcastNotifications {
    create
    view
  }
  clientVersions {
    manage
  }
  foundations {
    create
    edit
    approveEvents
  }
  limitedLiabilityCompany {
    manage
  }
  project {
    manageExpenses
  }
  users {
    changePassword
    changeEmailAndPhoneNumber
    createAndEditFoundationRepresentative
    createAndEditModerator
    createAndEditSystemAdministrator
    delete
    enableOrDisable
    list
    view
  }
  wards {
    create
    edit
    manageNeeds
    viewPrivateInformation
  }
  catalogs {
    manageCities
  }
  stories {
    view
    manage
  }
  achievements {
    view
    manage
    assignCoins
  }
  specialProjects {
    manage
  }
  categories {
    manage
  }
}
    `;
export const BroadcastNotificationPropsFragmentDoc = gql`
    fragment BroadcastNotificationProps on BroadcastNotification {
  id
  created {
    ...AuditMarkerProps
  }
  buttonText
  buttonUrl
  fileWithListOfRecipients {
    id
    url
  }
  populatedNotificationsCount
  recipients
  status
  text
  title
}
    ${AuditMarkerPropsFragmentDoc}`;
export const ProjectExpensePropsFragmentDoc = gql`
    fragment ProjectExpenseProps on ProjectExpense {
  id
  requiredAmount
  title
  documents {
    ...DocumentFileProps
  }
  reportSentAt
  referenceDate
  account {
    ...Account
  }
}
    ${DocumentFilePropsFragmentDoc}
${AccountFragmentDoc}`;
export const SpecialProjectsPropsFragmentDoc = gql`
    fragment SpecialProjectsProps on SpecialProject {
  id
  city
  title
  start
  end
  order
  description
  campaign
  logo {
    ...PhotoFileProps
  }
  categories {
    ...CategoryProps
  }
  feedImage {
    ...PhotoFileProps
  }
  images {
    ...PhotoFileProps
  }
  address {
    fullAddress
    displayText
  }
  account {
    ... on SpecialProjectAccount {
      requiredAmount
    }
    id
    amount
  }
  created {
    ...AuditMarkerProps
  }
  cancelled {
    ...AuditMarkerProps
  }
  closed {
    ...AuditMarkerProps
  }
  published {
    ...AuditMarkerProps
  }
  reportSent {
    ...AuditMarkerProps
  }
  documents {
    ...DocumentFileProps
  }
}
    ${PhotoFilePropsFragmentDoc}
${CategoryPropsFragmentDoc}
${AuditMarkerPropsFragmentDoc}
${DocumentFilePropsFragmentDoc}`;
export const BlockStoriesPropsFragmentDoc = gql`
    fragment BlockStoriesProps on StoryBlock {
  id
  title
  label
  stories(includeNotPublished: true) {
    nodes {
      created {
        ...AuditMarkerProps
      }
      id
      title
      order
      published {
        ...AuditMarkerProps
      }
      screens {
        ...screenStoryProps
      }
    }
  }
}
    ${AuditMarkerPropsFragmentDoc}
${ScreenStoryPropsFragmentDoc}`;
export const VersionPropsFragmentDoc = gql`
    fragment VersionProps on ClientVersion {
  id
  client
  major
  mandatory
  minor
  patch
}
    `;
export const UserBasePropsFragmentDoc = gql`
    fragment UserBaseProps on User {
  id
  disabled
}
    `;
export const UserPropsFragmentDoc = gql`
    fragment UserProps on User {
  ...UserBaseProps
  createdOn
  displayName
  email
  phoneNumber
}
    ${UserBasePropsFragmentDoc}`;
export const AddressSuggestionsDocument = gql`
    query addressSuggestions($mode: AddressSuggestionMode!, $count: Int = 10, $query: String!) {
  addressSuggestions(count: $count, query: $query, mode: $mode) {
    displayText
    fullAddress
    hasCoordinates
    hasCity
    hasHouse
    hasRegion
  }
}
    `;

/**
 * __useAddressSuggestionsQuery__
 *
 * To run a query within a React component, call `useAddressSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressSuggestionsQuery({
 *   variables: {
 *      mode: // value for 'mode'
 *      count: // value for 'count'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAddressSuggestionsQuery(baseOptions: Apollo.QueryHookOptions<AddressSuggestionsQuery, AddressSuggestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddressSuggestionsQuery, AddressSuggestionsQueryVariables>(AddressSuggestionsDocument, options);
      }
export function useAddressSuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddressSuggestionsQuery, AddressSuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddressSuggestionsQuery, AddressSuggestionsQueryVariables>(AddressSuggestionsDocument, options);
        }
export type AddressSuggestionsQueryHookResult = ReturnType<typeof useAddressSuggestionsQuery>;
export type AddressSuggestionsLazyQueryHookResult = ReturnType<typeof useAddressSuggestionsLazyQuery>;
export type AddressSuggestionsQueryResult = Apollo.QueryResult<AddressSuggestionsQuery, AddressSuggestionsQueryVariables>;
export const SkillCategoriesDocument = gql`
    query skillCategories {
  skillCategories {
    id
    title
    skills {
      id
      title
      description
      portfolioIsRequired
    }
  }
}
    `;

/**
 * __useSkillCategoriesQuery__
 *
 * To run a query within a React component, call `useSkillCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSkillCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<SkillCategoriesQuery, SkillCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SkillCategoriesQuery, SkillCategoriesQueryVariables>(SkillCategoriesDocument, options);
      }
export function useSkillCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SkillCategoriesQuery, SkillCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SkillCategoriesQuery, SkillCategoriesQueryVariables>(SkillCategoriesDocument, options);
        }
export type SkillCategoriesQueryHookResult = ReturnType<typeof useSkillCategoriesQuery>;
export type SkillCategoriesLazyQueryHookResult = ReturnType<typeof useSkillCategoriesLazyQuery>;
export type SkillCategoriesQueryResult = Apollo.QueryResult<SkillCategoriesQuery, SkillCategoriesQueryVariables>;
export const PrepareFilesUploadDocument = gql`
    mutation PrepareFilesUpload($input: PrepareFilesUploadInput!) {
  prepareFilesUpload(input: $input) {
    files {
      fileName
      id
      uploadUrl
      url
    }
  }
}
    `;
export type PrepareFilesUploadMutationFn = Apollo.MutationFunction<PrepareFilesUploadMutation, PrepareFilesUploadMutationVariables>;

/**
 * __usePrepareFilesUploadMutation__
 *
 * To run a mutation, you first call `usePrepareFilesUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrepareFilesUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prepareFilesUploadMutation, { data, loading, error }] = usePrepareFilesUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrepareFilesUploadMutation(baseOptions?: Apollo.MutationHookOptions<PrepareFilesUploadMutation, PrepareFilesUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrepareFilesUploadMutation, PrepareFilesUploadMutationVariables>(PrepareFilesUploadDocument, options);
      }
export type PrepareFilesUploadMutationHookResult = ReturnType<typeof usePrepareFilesUploadMutation>;
export type PrepareFilesUploadMutationResult = Apollo.MutationResult<PrepareFilesUploadMutation>;
export type PrepareFilesUploadMutationOptions = Apollo.BaseMutationOptions<PrepareFilesUploadMutation, PrepareFilesUploadMutationVariables>;
export const SendOneTimeNeedReportDocument = gql`
    mutation sendOneTimeNeedReport($input: SendOneTimeNeedReportInput!) {
  sendOneTimeNeedReport(input: $input) {
    success
  }
}
    `;
export type SendOneTimeNeedReportMutationFn = Apollo.MutationFunction<SendOneTimeNeedReportMutation, SendOneTimeNeedReportMutationVariables>;

/**
 * __useSendOneTimeNeedReportMutation__
 *
 * To run a mutation, you first call `useSendOneTimeNeedReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendOneTimeNeedReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendOneTimeNeedReportMutation, { data, loading, error }] = useSendOneTimeNeedReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendOneTimeNeedReportMutation(baseOptions?: Apollo.MutationHookOptions<SendOneTimeNeedReportMutation, SendOneTimeNeedReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendOneTimeNeedReportMutation, SendOneTimeNeedReportMutationVariables>(SendOneTimeNeedReportDocument, options);
      }
export type SendOneTimeNeedReportMutationHookResult = ReturnType<typeof useSendOneTimeNeedReportMutation>;
export type SendOneTimeNeedReportMutationResult = Apollo.MutationResult<SendOneTimeNeedReportMutation>;
export type SendOneTimeNeedReportMutationOptions = Apollo.BaseMutationOptions<SendOneTimeNeedReportMutation, SendOneTimeNeedReportMutationVariables>;
export const SendProjectExpenseReportDocument = gql`
    mutation sendProjectExpenseReport($input: SendProjectExpenseReportInput!) {
  sendProjectExpenseReport(input: $input) {
    success
  }
}
    `;
export type SendProjectExpenseReportMutationFn = Apollo.MutationFunction<SendProjectExpenseReportMutation, SendProjectExpenseReportMutationVariables>;

/**
 * __useSendProjectExpenseReportMutation__
 *
 * To run a mutation, you first call `useSendProjectExpenseReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendProjectExpenseReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendProjectExpenseReportMutation, { data, loading, error }] = useSendProjectExpenseReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendProjectExpenseReportMutation(baseOptions?: Apollo.MutationHookOptions<SendProjectExpenseReportMutation, SendProjectExpenseReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendProjectExpenseReportMutation, SendProjectExpenseReportMutationVariables>(SendProjectExpenseReportDocument, options);
      }
export type SendProjectExpenseReportMutationHookResult = ReturnType<typeof useSendProjectExpenseReportMutation>;
export type SendProjectExpenseReportMutationResult = Apollo.MutationResult<SendProjectExpenseReportMutation>;
export type SendProjectExpenseReportMutationOptions = Apollo.BaseMutationOptions<SendProjectExpenseReportMutation, SendProjectExpenseReportMutationVariables>;
export const SendRegularNeedPeriodReportDocument = gql`
    mutation sendRegularNeedPeriodReport($input: SendRegularNeedPeriodReportInput!) {
  sendRegularNeedPeriodReport(input: $input) {
    success
  }
}
    `;
export type SendRegularNeedPeriodReportMutationFn = Apollo.MutationFunction<SendRegularNeedPeriodReportMutation, SendRegularNeedPeriodReportMutationVariables>;

/**
 * __useSendRegularNeedPeriodReportMutation__
 *
 * To run a mutation, you first call `useSendRegularNeedPeriodReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRegularNeedPeriodReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRegularNeedPeriodReportMutation, { data, loading, error }] = useSendRegularNeedPeriodReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendRegularNeedPeriodReportMutation(baseOptions?: Apollo.MutationHookOptions<SendRegularNeedPeriodReportMutation, SendRegularNeedPeriodReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendRegularNeedPeriodReportMutation, SendRegularNeedPeriodReportMutationVariables>(SendRegularNeedPeriodReportDocument, options);
      }
export type SendRegularNeedPeriodReportMutationHookResult = ReturnType<typeof useSendRegularNeedPeriodReportMutation>;
export type SendRegularNeedPeriodReportMutationResult = Apollo.MutationResult<SendRegularNeedPeriodReportMutation>;
export type SendRegularNeedPeriodReportMutationOptions = Apollo.BaseMutationOptions<SendRegularNeedPeriodReportMutation, SendRegularNeedPeriodReportMutationVariables>;
export const TransferFundsToAccountDocument = gql`
    mutation transferFundsToAccount($input: TransferFundsToAccountInput!) {
  transferFundsToAccount(input: $input) {
    result {
      sourceAccount {
        id
      }
      targetAccount {
        id
      }
    }
    errors {
      ... on Error {
        message
      }
    }
  }
}
    `;
export type TransferFundsToAccountMutationFn = Apollo.MutationFunction<TransferFundsToAccountMutation, TransferFundsToAccountMutationVariables>;

/**
 * __useTransferFundsToAccountMutation__
 *
 * To run a mutation, you first call `useTransferFundsToAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferFundsToAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferFundsToAccountMutation, { data, loading, error }] = useTransferFundsToAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransferFundsToAccountMutation(baseOptions?: Apollo.MutationHookOptions<TransferFundsToAccountMutation, TransferFundsToAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransferFundsToAccountMutation, TransferFundsToAccountMutationVariables>(TransferFundsToAccountDocument, options);
      }
export type TransferFundsToAccountMutationHookResult = ReturnType<typeof useTransferFundsToAccountMutation>;
export type TransferFundsToAccountMutationResult = Apollo.MutationResult<TransferFundsToAccountMutation>;
export type TransferFundsToAccountMutationOptions = Apollo.BaseMutationOptions<TransferFundsToAccountMutation, TransferFundsToAccountMutationVariables>;
export const AccountDocument = gql`
    query account($id: AccountId!) {
  account(id: $id) {
    ...Account
    transferSources {
      id
      amount
      updatedAt
      ... on SystemAccount {
        __typename
        kind
      }
      ... on BenefactorAccount {
        __typename
        benefactor {
          ...AccountBenefactorInformation
        }
      }
    }
  }
}
    ${AccountFragmentDoc}
${AccountBenefactorInformationFragmentDoc}`;

/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountQuery(baseOptions: Apollo.QueryHookOptions<AccountQuery, AccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
      }
export function useAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountQuery, AccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
        }
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export type AccountQueryResult = Apollo.QueryResult<AccountQuery, AccountQueryVariables>;
export const AccountDonationsDocument = gql`
    query accountDonations($id: AccountId!, $after: String, $before: String, $first: Int, $last: Int) {
  account(id: $id) {
    donations(after: $after, before: $before, first: $first, last: $last) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      totalCount
      nodes {
        id
        amount
        benefactor {
          id
          displayName
          photo {
            url(asAttachment: false, variant: "medium")
          }
        }
        createdAt
      }
    }
  }
}
    `;

/**
 * __useAccountDonationsQuery__
 *
 * To run a query within a React component, call `useAccountDonationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountDonationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDonationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useAccountDonationsQuery(baseOptions: Apollo.QueryHookOptions<AccountDonationsQuery, AccountDonationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountDonationsQuery, AccountDonationsQueryVariables>(AccountDonationsDocument, options);
      }
export function useAccountDonationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountDonationsQuery, AccountDonationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountDonationsQuery, AccountDonationsQueryVariables>(AccountDonationsDocument, options);
        }
export type AccountDonationsQueryHookResult = ReturnType<typeof useAccountDonationsQuery>;
export type AccountDonationsLazyQueryHookResult = ReturnType<typeof useAccountDonationsLazyQuery>;
export type AccountDonationsQueryResult = Apollo.QueryResult<AccountDonationsQuery, AccountDonationsQueryVariables>;
export const AddAchievementWithMaterialPrizesDocument = gql`
    mutation addAchievementWithMaterialPrizes($input: AddAchievementWithMaterialPrizesInput!) {
  addAchievementWithMaterialPrizes(input: $input) {
    achievement {
      id
      title
      created {
        ...AuditMarkerProps
      }
    }
  }
}
    ${AuditMarkerPropsFragmentDoc}`;
export type AddAchievementWithMaterialPrizesMutationFn = Apollo.MutationFunction<AddAchievementWithMaterialPrizesMutation, AddAchievementWithMaterialPrizesMutationVariables>;

/**
 * __useAddAchievementWithMaterialPrizesMutation__
 *
 * To run a mutation, you first call `useAddAchievementWithMaterialPrizesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAchievementWithMaterialPrizesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAchievementWithMaterialPrizesMutation, { data, loading, error }] = useAddAchievementWithMaterialPrizesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAchievementWithMaterialPrizesMutation(baseOptions?: Apollo.MutationHookOptions<AddAchievementWithMaterialPrizesMutation, AddAchievementWithMaterialPrizesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAchievementWithMaterialPrizesMutation, AddAchievementWithMaterialPrizesMutationVariables>(AddAchievementWithMaterialPrizesDocument, options);
      }
export type AddAchievementWithMaterialPrizesMutationHookResult = ReturnType<typeof useAddAchievementWithMaterialPrizesMutation>;
export type AddAchievementWithMaterialPrizesMutationResult = Apollo.MutationResult<AddAchievementWithMaterialPrizesMutation>;
export type AddAchievementWithMaterialPrizesMutationOptions = Apollo.BaseMutationOptions<AddAchievementWithMaterialPrizesMutation, AddAchievementWithMaterialPrizesMutationVariables>;
export const AddAchievementWithPromoCodesDocument = gql`
    mutation addAchievementWithPromoCodes($input: AddAchievementWithPromoCodesInput!) {
  addAchievementWithPromoCodes(input: $input) {
    achievement {
      id
      title
      created {
        ...AuditMarkerProps
      }
    }
  }
}
    ${AuditMarkerPropsFragmentDoc}`;
export type AddAchievementWithPromoCodesMutationFn = Apollo.MutationFunction<AddAchievementWithPromoCodesMutation, AddAchievementWithPromoCodesMutationVariables>;

/**
 * __useAddAchievementWithPromoCodesMutation__
 *
 * To run a mutation, you first call `useAddAchievementWithPromoCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAchievementWithPromoCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAchievementWithPromoCodesMutation, { data, loading, error }] = useAddAchievementWithPromoCodesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAchievementWithPromoCodesMutation(baseOptions?: Apollo.MutationHookOptions<AddAchievementWithPromoCodesMutation, AddAchievementWithPromoCodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAchievementWithPromoCodesMutation, AddAchievementWithPromoCodesMutationVariables>(AddAchievementWithPromoCodesDocument, options);
      }
export type AddAchievementWithPromoCodesMutationHookResult = ReturnType<typeof useAddAchievementWithPromoCodesMutation>;
export type AddAchievementWithPromoCodesMutationResult = Apollo.MutationResult<AddAchievementWithPromoCodesMutation>;
export type AddAchievementWithPromoCodesMutationOptions = Apollo.BaseMutationOptions<AddAchievementWithPromoCodesMutation, AddAchievementWithPromoCodesMutationVariables>;
export const AddAchievementWithUnlimitedNumberOfPrizesDocument = gql`
    mutation addAchievementWithUnlimitedNumberOfPrizes($input: AddAchievementWithUnlimitedNumberOfPrizesInput!) {
  addAchievementWithUnlimitedNumberOfPrizes(input: $input) {
    achievement {
      id
      title
      created {
        ...AuditMarkerProps
      }
    }
  }
}
    ${AuditMarkerPropsFragmentDoc}`;
export type AddAchievementWithUnlimitedNumberOfPrizesMutationFn = Apollo.MutationFunction<AddAchievementWithUnlimitedNumberOfPrizesMutation, AddAchievementWithUnlimitedNumberOfPrizesMutationVariables>;

/**
 * __useAddAchievementWithUnlimitedNumberOfPrizesMutation__
 *
 * To run a mutation, you first call `useAddAchievementWithUnlimitedNumberOfPrizesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAchievementWithUnlimitedNumberOfPrizesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAchievementWithUnlimitedNumberOfPrizesMutation, { data, loading, error }] = useAddAchievementWithUnlimitedNumberOfPrizesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAchievementWithUnlimitedNumberOfPrizesMutation(baseOptions?: Apollo.MutationHookOptions<AddAchievementWithUnlimitedNumberOfPrizesMutation, AddAchievementWithUnlimitedNumberOfPrizesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAchievementWithUnlimitedNumberOfPrizesMutation, AddAchievementWithUnlimitedNumberOfPrizesMutationVariables>(AddAchievementWithUnlimitedNumberOfPrizesDocument, options);
      }
export type AddAchievementWithUnlimitedNumberOfPrizesMutationHookResult = ReturnType<typeof useAddAchievementWithUnlimitedNumberOfPrizesMutation>;
export type AddAchievementWithUnlimitedNumberOfPrizesMutationResult = Apollo.MutationResult<AddAchievementWithUnlimitedNumberOfPrizesMutation>;
export type AddAchievementWithUnlimitedNumberOfPrizesMutationOptions = Apollo.BaseMutationOptions<AddAchievementWithUnlimitedNumberOfPrizesMutation, AddAchievementWithUnlimitedNumberOfPrizesMutationVariables>;
export const AddBenefactorStatusDocument = gql`
    mutation addBenefactorStatus($input: AddBenefactorStatusInput!) {
  addBenefactorStatus(input: $input) {
    status {
      id
      title
    }
  }
}
    `;
export type AddBenefactorStatusMutationFn = Apollo.MutationFunction<AddBenefactorStatusMutation, AddBenefactorStatusMutationVariables>;

/**
 * __useAddBenefactorStatusMutation__
 *
 * To run a mutation, you first call `useAddBenefactorStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBenefactorStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBenefactorStatusMutation, { data, loading, error }] = useAddBenefactorStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBenefactorStatusMutation(baseOptions?: Apollo.MutationHookOptions<AddBenefactorStatusMutation, AddBenefactorStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBenefactorStatusMutation, AddBenefactorStatusMutationVariables>(AddBenefactorStatusDocument, options);
      }
export type AddBenefactorStatusMutationHookResult = ReturnType<typeof useAddBenefactorStatusMutation>;
export type AddBenefactorStatusMutationResult = Apollo.MutationResult<AddBenefactorStatusMutation>;
export type AddBenefactorStatusMutationOptions = Apollo.BaseMutationOptions<AddBenefactorStatusMutation, AddBenefactorStatusMutationVariables>;
export const UploadPromoCodesDocument = gql`
    mutation uploadPromoCodes($input: UploadPromoCodesInput!) {
  uploadPromoCodes(input: $input) {
    achievement {
      id
    }
  }
}
    `;
export type UploadPromoCodesMutationFn = Apollo.MutationFunction<UploadPromoCodesMutation, UploadPromoCodesMutationVariables>;

/**
 * __useUploadPromoCodesMutation__
 *
 * To run a mutation, you first call `useUploadPromoCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPromoCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPromoCodesMutation, { data, loading, error }] = useUploadPromoCodesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadPromoCodesMutation(baseOptions?: Apollo.MutationHookOptions<UploadPromoCodesMutation, UploadPromoCodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadPromoCodesMutation, UploadPromoCodesMutationVariables>(UploadPromoCodesDocument, options);
      }
export type UploadPromoCodesMutationHookResult = ReturnType<typeof useUploadPromoCodesMutation>;
export type UploadPromoCodesMutationResult = Apollo.MutationResult<UploadPromoCodesMutation>;
export type UploadPromoCodesMutationOptions = Apollo.BaseMutationOptions<UploadPromoCodesMutation, UploadPromoCodesMutationVariables>;
export const DeleteAchievementDocument = gql`
    mutation deleteAchievement($input: DeleteAchievementInput!) {
  deleteAchievement(input: $input) {
    success
  }
}
    `;
export type DeleteAchievementMutationFn = Apollo.MutationFunction<DeleteAchievementMutation, DeleteAchievementMutationVariables>;

/**
 * __useDeleteAchievementMutation__
 *
 * To run a mutation, you first call `useDeleteAchievementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAchievementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAchievementMutation, { data, loading, error }] = useDeleteAchievementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAchievementMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAchievementMutation, DeleteAchievementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAchievementMutation, DeleteAchievementMutationVariables>(DeleteAchievementDocument, options);
      }
export type DeleteAchievementMutationHookResult = ReturnType<typeof useDeleteAchievementMutation>;
export type DeleteAchievementMutationResult = Apollo.MutationResult<DeleteAchievementMutation>;
export type DeleteAchievementMutationOptions = Apollo.BaseMutationOptions<DeleteAchievementMutation, DeleteAchievementMutationVariables>;
export const UpdateAchievementWithMaterialPrizesDocument = gql`
    mutation updateAchievementWithMaterialPrizes($input: UpdateAchievementWithMaterialPrizesInput!) {
  updateAchievementWithMaterialPrizes(input: $input) {
    achievement {
      id
      title
      created {
        ...AuditMarkerProps
      }
    }
  }
}
    ${AuditMarkerPropsFragmentDoc}`;
export type UpdateAchievementWithMaterialPrizesMutationFn = Apollo.MutationFunction<UpdateAchievementWithMaterialPrizesMutation, UpdateAchievementWithMaterialPrizesMutationVariables>;

/**
 * __useUpdateAchievementWithMaterialPrizesMutation__
 *
 * To run a mutation, you first call `useUpdateAchievementWithMaterialPrizesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAchievementWithMaterialPrizesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAchievementWithMaterialPrizesMutation, { data, loading, error }] = useUpdateAchievementWithMaterialPrizesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAchievementWithMaterialPrizesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAchievementWithMaterialPrizesMutation, UpdateAchievementWithMaterialPrizesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAchievementWithMaterialPrizesMutation, UpdateAchievementWithMaterialPrizesMutationVariables>(UpdateAchievementWithMaterialPrizesDocument, options);
      }
export type UpdateAchievementWithMaterialPrizesMutationHookResult = ReturnType<typeof useUpdateAchievementWithMaterialPrizesMutation>;
export type UpdateAchievementWithMaterialPrizesMutationResult = Apollo.MutationResult<UpdateAchievementWithMaterialPrizesMutation>;
export type UpdateAchievementWithMaterialPrizesMutationOptions = Apollo.BaseMutationOptions<UpdateAchievementWithMaterialPrizesMutation, UpdateAchievementWithMaterialPrizesMutationVariables>;
export const UpdateAchievementWithPromoCodesDocument = gql`
    mutation updateAchievementWithPromoCodes($input: UpdateAchievementWithPromoCodesInput!) {
  updateAchievementWithPromoCodes(input: $input) {
    achievement {
      id
      title
      created {
        ...AuditMarkerProps
      }
    }
  }
}
    ${AuditMarkerPropsFragmentDoc}`;
export type UpdateAchievementWithPromoCodesMutationFn = Apollo.MutationFunction<UpdateAchievementWithPromoCodesMutation, UpdateAchievementWithPromoCodesMutationVariables>;

/**
 * __useUpdateAchievementWithPromoCodesMutation__
 *
 * To run a mutation, you first call `useUpdateAchievementWithPromoCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAchievementWithPromoCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAchievementWithPromoCodesMutation, { data, loading, error }] = useUpdateAchievementWithPromoCodesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAchievementWithPromoCodesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAchievementWithPromoCodesMutation, UpdateAchievementWithPromoCodesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAchievementWithPromoCodesMutation, UpdateAchievementWithPromoCodesMutationVariables>(UpdateAchievementWithPromoCodesDocument, options);
      }
export type UpdateAchievementWithPromoCodesMutationHookResult = ReturnType<typeof useUpdateAchievementWithPromoCodesMutation>;
export type UpdateAchievementWithPromoCodesMutationResult = Apollo.MutationResult<UpdateAchievementWithPromoCodesMutation>;
export type UpdateAchievementWithPromoCodesMutationOptions = Apollo.BaseMutationOptions<UpdateAchievementWithPromoCodesMutation, UpdateAchievementWithPromoCodesMutationVariables>;
export const UpdateAchievementWithUnlimitedNumberOfPrizesDocument = gql`
    mutation updateAchievementWithUnlimitedNumberOfPrizes($input: UpdateAchievementWithUnlimitedNumberOfPrizesInput!) {
  updateAchievementWithUnlimitedNumberOfPrizes(input: $input) {
    achievement {
      id
      title
      created {
        ...AuditMarkerProps
      }
    }
  }
}
    ${AuditMarkerPropsFragmentDoc}`;
export type UpdateAchievementWithUnlimitedNumberOfPrizesMutationFn = Apollo.MutationFunction<UpdateAchievementWithUnlimitedNumberOfPrizesMutation, UpdateAchievementWithUnlimitedNumberOfPrizesMutationVariables>;

/**
 * __useUpdateAchievementWithUnlimitedNumberOfPrizesMutation__
 *
 * To run a mutation, you first call `useUpdateAchievementWithUnlimitedNumberOfPrizesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAchievementWithUnlimitedNumberOfPrizesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAchievementWithUnlimitedNumberOfPrizesMutation, { data, loading, error }] = useUpdateAchievementWithUnlimitedNumberOfPrizesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAchievementWithUnlimitedNumberOfPrizesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAchievementWithUnlimitedNumberOfPrizesMutation, UpdateAchievementWithUnlimitedNumberOfPrizesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAchievementWithUnlimitedNumberOfPrizesMutation, UpdateAchievementWithUnlimitedNumberOfPrizesMutationVariables>(UpdateAchievementWithUnlimitedNumberOfPrizesDocument, options);
      }
export type UpdateAchievementWithUnlimitedNumberOfPrizesMutationHookResult = ReturnType<typeof useUpdateAchievementWithUnlimitedNumberOfPrizesMutation>;
export type UpdateAchievementWithUnlimitedNumberOfPrizesMutationResult = Apollo.MutationResult<UpdateAchievementWithUnlimitedNumberOfPrizesMutation>;
export type UpdateAchievementWithUnlimitedNumberOfPrizesMutationOptions = Apollo.BaseMutationOptions<UpdateAchievementWithUnlimitedNumberOfPrizesMutation, UpdateAchievementWithUnlimitedNumberOfPrizesMutationVariables>;
export const PublishAchievementDocument = gql`
    mutation publishAchievement($input: PublishAchievementInput!) {
  publishAchievement(input: $input) {
    achievement {
      id
      title
    }
  }
}
    `;
export type PublishAchievementMutationFn = Apollo.MutationFunction<PublishAchievementMutation, PublishAchievementMutationVariables>;

/**
 * __usePublishAchievementMutation__
 *
 * To run a mutation, you first call `usePublishAchievementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishAchievementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishAchievementMutation, { data, loading, error }] = usePublishAchievementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishAchievementMutation(baseOptions?: Apollo.MutationHookOptions<PublishAchievementMutation, PublishAchievementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishAchievementMutation, PublishAchievementMutationVariables>(PublishAchievementDocument, options);
      }
export type PublishAchievementMutationHookResult = ReturnType<typeof usePublishAchievementMutation>;
export type PublishAchievementMutationResult = Apollo.MutationResult<PublishAchievementMutation>;
export type PublishAchievementMutationOptions = Apollo.BaseMutationOptions<PublishAchievementMutation, PublishAchievementMutationVariables>;
export const UnPublishAchievementDocument = gql`
    mutation unPublishAchievement($input: UnpublishAchievementInput!) {
  unpublishAchievement(input: $input) {
    achievement {
      id
      title
    }
  }
}
    `;
export type UnPublishAchievementMutationFn = Apollo.MutationFunction<UnPublishAchievementMutation, UnPublishAchievementMutationVariables>;

/**
 * __useUnPublishAchievementMutation__
 *
 * To run a mutation, you first call `useUnPublishAchievementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnPublishAchievementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unPublishAchievementMutation, { data, loading, error }] = useUnPublishAchievementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnPublishAchievementMutation(baseOptions?: Apollo.MutationHookOptions<UnPublishAchievementMutation, UnPublishAchievementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnPublishAchievementMutation, UnPublishAchievementMutationVariables>(UnPublishAchievementDocument, options);
      }
export type UnPublishAchievementMutationHookResult = ReturnType<typeof useUnPublishAchievementMutation>;
export type UnPublishAchievementMutationResult = Apollo.MutationResult<UnPublishAchievementMutation>;
export type UnPublishAchievementMutationOptions = Apollo.BaseMutationOptions<UnPublishAchievementMutation, UnPublishAchievementMutationVariables>;
export const AchievementByIdDocument = gql`
    query achievementById($id: AchievementId!, $after: String, $before: String, $first: Int, $last: Int) {
  achievementById(id: $id) {
    ...AchievementsProps
    benefactors(first: $first, after: $after, before: $before, last: $last) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      totalCount
      nodes {
        id
        achievement {
          ...AchievementsProps
        }
        receivedAt
        benefactor {
          id
          displayName
        }
      }
    }
    published {
      ...AuditMarkerProps
    }
    image {
      ...PhotoFileProps
    }
    story {
      id
      storyBlock {
        id
      }
    }
    ... on AchievementWithLimitedNumberOfPrizes {
      totalNumberOfPrizes
      numberOfGrantedPrizes
    }
    __typename
  }
}
    ${AchievementsPropsFragmentDoc}
${AuditMarkerPropsFragmentDoc}
${PhotoFilePropsFragmentDoc}`;

/**
 * __useAchievementByIdQuery__
 *
 * To run a query within a React component, call `useAchievementByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAchievementByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAchievementByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useAchievementByIdQuery(baseOptions: Apollo.QueryHookOptions<AchievementByIdQuery, AchievementByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AchievementByIdQuery, AchievementByIdQueryVariables>(AchievementByIdDocument, options);
      }
export function useAchievementByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AchievementByIdQuery, AchievementByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AchievementByIdQuery, AchievementByIdQueryVariables>(AchievementByIdDocument, options);
        }
export type AchievementByIdQueryHookResult = ReturnType<typeof useAchievementByIdQuery>;
export type AchievementByIdLazyQueryHookResult = ReturnType<typeof useAchievementByIdLazyQuery>;
export type AchievementByIdQueryResult = Apollo.QueryResult<AchievementByIdQuery, AchievementByIdQueryVariables>;
export const DeleteBenefactorStatusDocument = gql`
    mutation deleteBenefactorStatus($input: DeleteBenefactorStatusInput!) {
  deleteBenefactorStatus(input: $input) {
    success
  }
}
    `;
export type DeleteBenefactorStatusMutationFn = Apollo.MutationFunction<DeleteBenefactorStatusMutation, DeleteBenefactorStatusMutationVariables>;

/**
 * __useDeleteBenefactorStatusMutation__
 *
 * To run a mutation, you first call `useDeleteBenefactorStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBenefactorStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBenefactorStatusMutation, { data, loading, error }] = useDeleteBenefactorStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBenefactorStatusMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBenefactorStatusMutation, DeleteBenefactorStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBenefactorStatusMutation, DeleteBenefactorStatusMutationVariables>(DeleteBenefactorStatusDocument, options);
      }
export type DeleteBenefactorStatusMutationHookResult = ReturnType<typeof useDeleteBenefactorStatusMutation>;
export type DeleteBenefactorStatusMutationResult = Apollo.MutationResult<DeleteBenefactorStatusMutation>;
export type DeleteBenefactorStatusMutationOptions = Apollo.BaseMutationOptions<DeleteBenefactorStatusMutation, DeleteBenefactorStatusMutationVariables>;
export const UpdateBenefactorStatusDocument = gql`
    mutation updateBenefactorStatus($input: UpdateBenefactorStatusInput!) {
  updateBenefactorStatus(input: $input) {
    status {
      id
      title
      created {
        ...AuditMarkerProps
      }
    }
  }
}
    ${AuditMarkerPropsFragmentDoc}`;
export type UpdateBenefactorStatusMutationFn = Apollo.MutationFunction<UpdateBenefactorStatusMutation, UpdateBenefactorStatusMutationVariables>;

/**
 * __useUpdateBenefactorStatusMutation__
 *
 * To run a mutation, you first call `useUpdateBenefactorStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBenefactorStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBenefactorStatusMutation, { data, loading, error }] = useUpdateBenefactorStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBenefactorStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBenefactorStatusMutation, UpdateBenefactorStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBenefactorStatusMutation, UpdateBenefactorStatusMutationVariables>(UpdateBenefactorStatusDocument, options);
      }
export type UpdateBenefactorStatusMutationHookResult = ReturnType<typeof useUpdateBenefactorStatusMutation>;
export type UpdateBenefactorStatusMutationResult = Apollo.MutationResult<UpdateBenefactorStatusMutation>;
export type UpdateBenefactorStatusMutationOptions = Apollo.BaseMutationOptions<UpdateBenefactorStatusMutation, UpdateBenefactorStatusMutationVariables>;
export const BenefactorStatusByIdDocument = gql`
    query benefactorStatusById($id: BenefactorStatusId!) {
  benefactorStatusById(id: $id) {
    id
    description
    title
    coins
    image {
      ...PhotoFileProps
    }
    story {
      id
      storyBlock {
        id
      }
    }
    __typename
  }
}
    ${PhotoFilePropsFragmentDoc}`;

/**
 * __useBenefactorStatusByIdQuery__
 *
 * To run a query within a React component, call `useBenefactorStatusByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenefactorStatusByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenefactorStatusByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBenefactorStatusByIdQuery(baseOptions: Apollo.QueryHookOptions<BenefactorStatusByIdQuery, BenefactorStatusByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BenefactorStatusByIdQuery, BenefactorStatusByIdQueryVariables>(BenefactorStatusByIdDocument, options);
      }
export function useBenefactorStatusByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BenefactorStatusByIdQuery, BenefactorStatusByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BenefactorStatusByIdQuery, BenefactorStatusByIdQueryVariables>(BenefactorStatusByIdDocument, options);
        }
export type BenefactorStatusByIdQueryHookResult = ReturnType<typeof useBenefactorStatusByIdQuery>;
export type BenefactorStatusByIdLazyQueryHookResult = ReturnType<typeof useBenefactorStatusByIdLazyQuery>;
export type BenefactorStatusByIdQueryResult = Apollo.QueryResult<BenefactorStatusByIdQuery, BenefactorStatusByIdQueryVariables>;
export const BenefactorStatusesDocument = gql`
    query benefactorStatuses($first: Int, $after: String, $before: String, $last: Int) {
  benefactorStatuses(first: $first, after: $after, before: $before, last: $last) {
    pageInfo {
      hasNextPage
      endCursor
      startCursor
      hasPreviousPage
    }
    totalCount
    nodes {
      ...BenefactorStatusProps
    }
  }
}
    ${BenefactorStatusPropsFragmentDoc}`;

/**
 * __useBenefactorStatusesQuery__
 *
 * To run a query within a React component, call `useBenefactorStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenefactorStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenefactorStatusesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useBenefactorStatusesQuery(baseOptions?: Apollo.QueryHookOptions<BenefactorStatusesQuery, BenefactorStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BenefactorStatusesQuery, BenefactorStatusesQueryVariables>(BenefactorStatusesDocument, options);
      }
export function useBenefactorStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BenefactorStatusesQuery, BenefactorStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BenefactorStatusesQuery, BenefactorStatusesQueryVariables>(BenefactorStatusesDocument, options);
        }
export type BenefactorStatusesQueryHookResult = ReturnType<typeof useBenefactorStatusesQuery>;
export type BenefactorStatusesLazyQueryHookResult = ReturnType<typeof useBenefactorStatusesLazyQuery>;
export type BenefactorStatusesQueryResult = Apollo.QueryResult<BenefactorStatusesQuery, BenefactorStatusesQueryVariables>;
export const AchievementsDocument = gql`
    query achievements($first: Int, $after: String, $before: String, $includeUnpublished: Boolean!, $last: Int) {
  achievements(includeUnpublished: $includeUnpublished, first: $first, after: $after, before: $before, last: $last) {
    pageInfo {
      hasNextPage
      endCursor
      startCursor
      hasPreviousPage
    }
    totalCount
    nodes {
      ...AchievementsProps
    }
  }
}
    ${AchievementsPropsFragmentDoc}`;

/**
 * __useAchievementsQuery__
 *
 * To run a query within a React component, call `useAchievementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAchievementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAchievementsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      includeUnpublished: // value for 'includeUnpublished'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useAchievementsQuery(baseOptions: Apollo.QueryHookOptions<AchievementsQuery, AchievementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AchievementsQuery, AchievementsQueryVariables>(AchievementsDocument, options);
      }
export function useAchievementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AchievementsQuery, AchievementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AchievementsQuery, AchievementsQueryVariables>(AchievementsDocument, options);
        }
export type AchievementsQueryHookResult = ReturnType<typeof useAchievementsQuery>;
export type AchievementsLazyQueryHookResult = ReturnType<typeof useAchievementsLazyQuery>;
export type AchievementsQueryResult = Apollo.QueryResult<AchievementsQuery, AchievementsQueryVariables>;
export const CreateBenefactorDocument = gql`
    mutation createBenefactor($input: CreateBenefactorInput!) {
  createBenefactor(input: $input) {
    benefactor {
      ...BenefactorProps
    }
  }
}
    ${BenefactorPropsFragmentDoc}`;
export type CreateBenefactorMutationFn = Apollo.MutationFunction<CreateBenefactorMutation, CreateBenefactorMutationVariables>;

/**
 * __useCreateBenefactorMutation__
 *
 * To run a mutation, you first call `useCreateBenefactorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBenefactorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBenefactorMutation, { data, loading, error }] = useCreateBenefactorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBenefactorMutation(baseOptions?: Apollo.MutationHookOptions<CreateBenefactorMutation, CreateBenefactorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBenefactorMutation, CreateBenefactorMutationVariables>(CreateBenefactorDocument, options);
      }
export type CreateBenefactorMutationHookResult = ReturnType<typeof useCreateBenefactorMutation>;
export type CreateBenefactorMutationResult = Apollo.MutationResult<CreateBenefactorMutation>;
export type CreateBenefactorMutationOptions = Apollo.BaseMutationOptions<CreateBenefactorMutation, CreateBenefactorMutationVariables>;
export const UpdateBenefactorEmailByAdministratorDocument = gql`
    mutation UpdateBenefactorEmailByAdministrator($input: UpdateBenefactorEmailByAdministratorInput!) {
  updateBenefactorEmailByAdministrator(input: $input) {
    benefactor {
      ...BenefactorExtendedProps
    }
  }
}
    ${BenefactorExtendedPropsFragmentDoc}`;
export type UpdateBenefactorEmailByAdministratorMutationFn = Apollo.MutationFunction<UpdateBenefactorEmailByAdministratorMutation, UpdateBenefactorEmailByAdministratorMutationVariables>;

/**
 * __useUpdateBenefactorEmailByAdministratorMutation__
 *
 * To run a mutation, you first call `useUpdateBenefactorEmailByAdministratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBenefactorEmailByAdministratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBenefactorEmailByAdministratorMutation, { data, loading, error }] = useUpdateBenefactorEmailByAdministratorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBenefactorEmailByAdministratorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBenefactorEmailByAdministratorMutation, UpdateBenefactorEmailByAdministratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBenefactorEmailByAdministratorMutation, UpdateBenefactorEmailByAdministratorMutationVariables>(UpdateBenefactorEmailByAdministratorDocument, options);
      }
export type UpdateBenefactorEmailByAdministratorMutationHookResult = ReturnType<typeof useUpdateBenefactorEmailByAdministratorMutation>;
export type UpdateBenefactorEmailByAdministratorMutationResult = Apollo.MutationResult<UpdateBenefactorEmailByAdministratorMutation>;
export type UpdateBenefactorEmailByAdministratorMutationOptions = Apollo.BaseMutationOptions<UpdateBenefactorEmailByAdministratorMutation, UpdateBenefactorEmailByAdministratorMutationVariables>;
export const ChangePhoneNumberByAdministratorDocument = gql`
    mutation changePhoneNumberByAdministrator($input: ChangePhoneNumberByAdministratorInput!) {
  changePhoneNumberByAdministrator(input: $input) {
    success
  }
}
    `;
export type ChangePhoneNumberByAdministratorMutationFn = Apollo.MutationFunction<ChangePhoneNumberByAdministratorMutation, ChangePhoneNumberByAdministratorMutationVariables>;

/**
 * __useChangePhoneNumberByAdministratorMutation__
 *
 * To run a mutation, you first call `useChangePhoneNumberByAdministratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePhoneNumberByAdministratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePhoneNumberByAdministratorMutation, { data, loading, error }] = useChangePhoneNumberByAdministratorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePhoneNumberByAdministratorMutation(baseOptions?: Apollo.MutationHookOptions<ChangePhoneNumberByAdministratorMutation, ChangePhoneNumberByAdministratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePhoneNumberByAdministratorMutation, ChangePhoneNumberByAdministratorMutationVariables>(ChangePhoneNumberByAdministratorDocument, options);
      }
export type ChangePhoneNumberByAdministratorMutationHookResult = ReturnType<typeof useChangePhoneNumberByAdministratorMutation>;
export type ChangePhoneNumberByAdministratorMutationResult = Apollo.MutationResult<ChangePhoneNumberByAdministratorMutation>;
export type ChangePhoneNumberByAdministratorMutationOptions = Apollo.BaseMutationOptions<ChangePhoneNumberByAdministratorMutation, ChangePhoneNumberByAdministratorMutationVariables>;
export const NewOrderInformationDocument = gql`
    query newOrderInformation($donationIntents: [DonationIntentInput!]!, $benefactorId: BenefactorId) {
  newOrderInformation(donationIntents: $donationIntents, benefactorId: $benefactorId) {
    availablePaymentMethods {
      title
    }
    commissionPercentageSuggestions {
      commissionPercentage
      title
      description
    }
  }
}
    `;

/**
 * __useNewOrderInformationQuery__
 *
 * To run a query within a React component, call `useNewOrderInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewOrderInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewOrderInformationQuery({
 *   variables: {
 *      donationIntents: // value for 'donationIntents'
 *      benefactorId: // value for 'benefactorId'
 *   },
 * });
 */
export function useNewOrderInformationQuery(baseOptions: Apollo.QueryHookOptions<NewOrderInformationQuery, NewOrderInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewOrderInformationQuery, NewOrderInformationQueryVariables>(NewOrderInformationDocument, options);
      }
export function useNewOrderInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewOrderInformationQuery, NewOrderInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewOrderInformationQuery, NewOrderInformationQueryVariables>(NewOrderInformationDocument, options);
        }
export type NewOrderInformationQueryHookResult = ReturnType<typeof useNewOrderInformationQuery>;
export type NewOrderInformationLazyQueryHookResult = ReturnType<typeof useNewOrderInformationLazyQuery>;
export type NewOrderInformationQueryResult = Apollo.QueryResult<NewOrderInformationQuery, NewOrderInformationQueryVariables>;
export const OrderStatusDocument = gql`
    query orderStatus($id: OrderId!) {
  orderById(id: $id) {
    status
  }
}
    `;

/**
 * __useOrderStatusQuery__
 *
 * To run a query within a React component, call `useOrderStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderStatusQuery(baseOptions: Apollo.QueryHookOptions<OrderStatusQuery, OrderStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderStatusQuery, OrderStatusQueryVariables>(OrderStatusDocument, options);
      }
export function useOrderStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderStatusQuery, OrderStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderStatusQuery, OrderStatusQueryVariables>(OrderStatusDocument, options);
        }
export type OrderStatusQueryHookResult = ReturnType<typeof useOrderStatusQuery>;
export type OrderStatusLazyQueryHookResult = ReturnType<typeof useOrderStatusLazyQuery>;
export type OrderStatusQueryResult = Apollo.QueryResult<OrderStatusQuery, OrderStatusQueryVariables>;
export const PlaceOrderDocument = gql`
    mutation PlaceOrder($input: PlaceOrderInput!) {
  placeOrder(input: $input) {
    errors {
      ... on OrderPlacementError {
        message
      }
    }
    order {
      id
    }
  }
}
    `;
export type PlaceOrderMutationFn = Apollo.MutationFunction<PlaceOrderMutation, PlaceOrderMutationVariables>;

/**
 * __usePlaceOrderMutation__
 *
 * To run a mutation, you first call `usePlaceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeOrderMutation, { data, loading, error }] = usePlaceOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlaceOrderMutation(baseOptions?: Apollo.MutationHookOptions<PlaceOrderMutation, PlaceOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlaceOrderMutation, PlaceOrderMutationVariables>(PlaceOrderDocument, options);
      }
export type PlaceOrderMutationHookResult = ReturnType<typeof usePlaceOrderMutation>;
export type PlaceOrderMutationResult = Apollo.MutationResult<PlaceOrderMutation>;
export type PlaceOrderMutationOptions = Apollo.BaseMutationOptions<PlaceOrderMutation, PlaceOrderMutationVariables>;
export const CreateBenefactorDepositAccountForAllWardsDocument = gql`
    mutation createBenefactorDepositAccountForAllWards($input: CreateBenefactorDepositAccountForAllWardsInput!) {
  createBenefactorDepositAccountForAllWards(input: $input) {
    benefactor {
      ...BenefactorExtendedProps
      ...BenefactorPaymentInformation
    }
    errors {
      ... on Error {
        message
      }
    }
  }
}
    ${BenefactorExtendedPropsFragmentDoc}
${BenefactorPaymentInformationFragmentDoc}`;
export type CreateBenefactorDepositAccountForAllWardsMutationFn = Apollo.MutationFunction<CreateBenefactorDepositAccountForAllWardsMutation, CreateBenefactorDepositAccountForAllWardsMutationVariables>;

/**
 * __useCreateBenefactorDepositAccountForAllWardsMutation__
 *
 * To run a mutation, you first call `useCreateBenefactorDepositAccountForAllWardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBenefactorDepositAccountForAllWardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBenefactorDepositAccountForAllWardsMutation, { data, loading, error }] = useCreateBenefactorDepositAccountForAllWardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBenefactorDepositAccountForAllWardsMutation(baseOptions?: Apollo.MutationHookOptions<CreateBenefactorDepositAccountForAllWardsMutation, CreateBenefactorDepositAccountForAllWardsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBenefactorDepositAccountForAllWardsMutation, CreateBenefactorDepositAccountForAllWardsMutationVariables>(CreateBenefactorDepositAccountForAllWardsDocument, options);
      }
export type CreateBenefactorDepositAccountForAllWardsMutationHookResult = ReturnType<typeof useCreateBenefactorDepositAccountForAllWardsMutation>;
export type CreateBenefactorDepositAccountForAllWardsMutationResult = Apollo.MutationResult<CreateBenefactorDepositAccountForAllWardsMutation>;
export type CreateBenefactorDepositAccountForAllWardsMutationOptions = Apollo.BaseMutationOptions<CreateBenefactorDepositAccountForAllWardsMutation, CreateBenefactorDepositAccountForAllWardsMutationVariables>;
export const CreateBenefactorDepositAccountForProjectNeedsDocument = gql`
    mutation createBenefactorDepositAccountForProjectNeeds($input: CreateBenefactorDepositAccountForProjectNeedsInput!) {
  createBenefactorDepositAccountForProjectNeeds(input: $input) {
    benefactor {
      ...BenefactorExtendedProps
      ...BenefactorPaymentInformation
    }
    errors {
      ... on Error {
        message
      }
    }
  }
}
    ${BenefactorExtendedPropsFragmentDoc}
${BenefactorPaymentInformationFragmentDoc}`;
export type CreateBenefactorDepositAccountForProjectNeedsMutationFn = Apollo.MutationFunction<CreateBenefactorDepositAccountForProjectNeedsMutation, CreateBenefactorDepositAccountForProjectNeedsMutationVariables>;

/**
 * __useCreateBenefactorDepositAccountForProjectNeedsMutation__
 *
 * To run a mutation, you first call `useCreateBenefactorDepositAccountForProjectNeedsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBenefactorDepositAccountForProjectNeedsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBenefactorDepositAccountForProjectNeedsMutation, { data, loading, error }] = useCreateBenefactorDepositAccountForProjectNeedsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBenefactorDepositAccountForProjectNeedsMutation(baseOptions?: Apollo.MutationHookOptions<CreateBenefactorDepositAccountForProjectNeedsMutation, CreateBenefactorDepositAccountForProjectNeedsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBenefactorDepositAccountForProjectNeedsMutation, CreateBenefactorDepositAccountForProjectNeedsMutationVariables>(CreateBenefactorDepositAccountForProjectNeedsDocument, options);
      }
export type CreateBenefactorDepositAccountForProjectNeedsMutationHookResult = ReturnType<typeof useCreateBenefactorDepositAccountForProjectNeedsMutation>;
export type CreateBenefactorDepositAccountForProjectNeedsMutationResult = Apollo.MutationResult<CreateBenefactorDepositAccountForProjectNeedsMutation>;
export type CreateBenefactorDepositAccountForProjectNeedsMutationOptions = Apollo.BaseMutationOptions<CreateBenefactorDepositAccountForProjectNeedsMutation, CreateBenefactorDepositAccountForProjectNeedsMutationVariables>;
export const CreateBenefactorDepositAccountForSpecificWardsDocument = gql`
    mutation createBenefactorDepositAccountForSpecificWards($input: CreateBenefactorDepositAccountForSpecificWardsInput!) {
  createBenefactorDepositAccountForSpecificWards(input: $input) {
    benefactor {
      ...BenefactorExtendedProps
      ...BenefactorPaymentInformation
    }
  }
}
    ${BenefactorExtendedPropsFragmentDoc}
${BenefactorPaymentInformationFragmentDoc}`;
export type CreateBenefactorDepositAccountForSpecificWardsMutationFn = Apollo.MutationFunction<CreateBenefactorDepositAccountForSpecificWardsMutation, CreateBenefactorDepositAccountForSpecificWardsMutationVariables>;

/**
 * __useCreateBenefactorDepositAccountForSpecificWardsMutation__
 *
 * To run a mutation, you first call `useCreateBenefactorDepositAccountForSpecificWardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBenefactorDepositAccountForSpecificWardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBenefactorDepositAccountForSpecificWardsMutation, { data, loading, error }] = useCreateBenefactorDepositAccountForSpecificWardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBenefactorDepositAccountForSpecificWardsMutation(baseOptions?: Apollo.MutationHookOptions<CreateBenefactorDepositAccountForSpecificWardsMutation, CreateBenefactorDepositAccountForSpecificWardsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBenefactorDepositAccountForSpecificWardsMutation, CreateBenefactorDepositAccountForSpecificWardsMutationVariables>(CreateBenefactorDepositAccountForSpecificWardsDocument, options);
      }
export type CreateBenefactorDepositAccountForSpecificWardsMutationHookResult = ReturnType<typeof useCreateBenefactorDepositAccountForSpecificWardsMutation>;
export type CreateBenefactorDepositAccountForSpecificWardsMutationResult = Apollo.MutationResult<CreateBenefactorDepositAccountForSpecificWardsMutation>;
export type CreateBenefactorDepositAccountForSpecificWardsMutationOptions = Apollo.BaseMutationOptions<CreateBenefactorDepositAccountForSpecificWardsMutation, CreateBenefactorDepositAccountForSpecificWardsMutationVariables>;
export const CreateBenefactorDepositAccountWithoutConditionsDocument = gql`
    mutation createBenefactorDepositAccountWithoutConditions($input: CreateBenefactorDepositAccountWithoutConditionsInput!) {
  createBenefactorDepositAccountWithoutConditions(input: $input) {
    benefactor {
      ...BenefactorExtendedProps
      ...BenefactorPaymentInformation
    }
    errors {
      ... on Error {
        message
      }
    }
  }
}
    ${BenefactorExtendedPropsFragmentDoc}
${BenefactorPaymentInformationFragmentDoc}`;
export type CreateBenefactorDepositAccountWithoutConditionsMutationFn = Apollo.MutationFunction<CreateBenefactorDepositAccountWithoutConditionsMutation, CreateBenefactorDepositAccountWithoutConditionsMutationVariables>;

/**
 * __useCreateBenefactorDepositAccountWithoutConditionsMutation__
 *
 * To run a mutation, you first call `useCreateBenefactorDepositAccountWithoutConditionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBenefactorDepositAccountWithoutConditionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBenefactorDepositAccountWithoutConditionsMutation, { data, loading, error }] = useCreateBenefactorDepositAccountWithoutConditionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBenefactorDepositAccountWithoutConditionsMutation(baseOptions?: Apollo.MutationHookOptions<CreateBenefactorDepositAccountWithoutConditionsMutation, CreateBenefactorDepositAccountWithoutConditionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBenefactorDepositAccountWithoutConditionsMutation, CreateBenefactorDepositAccountWithoutConditionsMutationVariables>(CreateBenefactorDepositAccountWithoutConditionsDocument, options);
      }
export type CreateBenefactorDepositAccountWithoutConditionsMutationHookResult = ReturnType<typeof useCreateBenefactorDepositAccountWithoutConditionsMutation>;
export type CreateBenefactorDepositAccountWithoutConditionsMutationResult = Apollo.MutationResult<CreateBenefactorDepositAccountWithoutConditionsMutation>;
export type CreateBenefactorDepositAccountWithoutConditionsMutationOptions = Apollo.BaseMutationOptions<CreateBenefactorDepositAccountWithoutConditionsMutation, CreateBenefactorDepositAccountWithoutConditionsMutationVariables>;
export const PlaceOrderDepositDocument = gql`
    mutation placeOrderDeposit($input: PlaceOrderInput!) {
  placeOrder(input: $input) {
    errors {
      ... on Error {
        message
      }
    }
    order {
      ...OrdersProps
    }
  }
}
    ${OrdersPropsFragmentDoc}`;
export type PlaceOrderDepositMutationFn = Apollo.MutationFunction<PlaceOrderDepositMutation, PlaceOrderDepositMutationVariables>;

/**
 * __usePlaceOrderDepositMutation__
 *
 * To run a mutation, you first call `usePlaceOrderDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceOrderDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeOrderDepositMutation, { data, loading, error }] = usePlaceOrderDepositMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlaceOrderDepositMutation(baseOptions?: Apollo.MutationHookOptions<PlaceOrderDepositMutation, PlaceOrderDepositMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlaceOrderDepositMutation, PlaceOrderDepositMutationVariables>(PlaceOrderDepositDocument, options);
      }
export type PlaceOrderDepositMutationHookResult = ReturnType<typeof usePlaceOrderDepositMutation>;
export type PlaceOrderDepositMutationResult = Apollo.MutationResult<PlaceOrderDepositMutation>;
export type PlaceOrderDepositMutationOptions = Apollo.BaseMutationOptions<PlaceOrderDepositMutation, PlaceOrderDepositMutationVariables>;
export const BenefactorByIdDocument = gql`
    query benefactorById($id: BenefactorId!, $permission: Boolean!) {
  benefactorById(id: $id) {
    ...BenefactorExtendedProps
  }
  paymentInformation: benefactorById(id: $id) @include(if: $permission) {
    ...BenefactorPaymentInformation
  }
}
    ${BenefactorExtendedPropsFragmentDoc}
${BenefactorPaymentInformationFragmentDoc}`;

/**
 * __useBenefactorByIdQuery__
 *
 * To run a query within a React component, call `useBenefactorByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenefactorByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenefactorByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      permission: // value for 'permission'
 *   },
 * });
 */
export function useBenefactorByIdQuery(baseOptions: Apollo.QueryHookOptions<BenefactorByIdQuery, BenefactorByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BenefactorByIdQuery, BenefactorByIdQueryVariables>(BenefactorByIdDocument, options);
      }
export function useBenefactorByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BenefactorByIdQuery, BenefactorByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BenefactorByIdQuery, BenefactorByIdQueryVariables>(BenefactorByIdDocument, options);
        }
export type BenefactorByIdQueryHookResult = ReturnType<typeof useBenefactorByIdQuery>;
export type BenefactorByIdLazyQueryHookResult = ReturnType<typeof useBenefactorByIdLazyQuery>;
export type BenefactorByIdQueryResult = Apollo.QueryResult<BenefactorByIdQuery, BenefactorByIdQueryVariables>;
export const BenefactorByIdWithoutPermissionDocument = gql`
    query benefactorByIdWithoutPermission($id: BenefactorId!) {
  benefactorById(id: $id) {
    ...BenefactorExtendedPropsWithoutPermission
  }
}
    ${BenefactorExtendedPropsWithoutPermissionFragmentDoc}`;

/**
 * __useBenefactorByIdWithoutPermissionQuery__
 *
 * To run a query within a React component, call `useBenefactorByIdWithoutPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenefactorByIdWithoutPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenefactorByIdWithoutPermissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBenefactorByIdWithoutPermissionQuery(baseOptions: Apollo.QueryHookOptions<BenefactorByIdWithoutPermissionQuery, BenefactorByIdWithoutPermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BenefactorByIdWithoutPermissionQuery, BenefactorByIdWithoutPermissionQueryVariables>(BenefactorByIdWithoutPermissionDocument, options);
      }
export function useBenefactorByIdWithoutPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BenefactorByIdWithoutPermissionQuery, BenefactorByIdWithoutPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BenefactorByIdWithoutPermissionQuery, BenefactorByIdWithoutPermissionQueryVariables>(BenefactorByIdWithoutPermissionDocument, options);
        }
export type BenefactorByIdWithoutPermissionQueryHookResult = ReturnType<typeof useBenefactorByIdWithoutPermissionQuery>;
export type BenefactorByIdWithoutPermissionLazyQueryHookResult = ReturnType<typeof useBenefactorByIdWithoutPermissionLazyQuery>;
export type BenefactorByIdWithoutPermissionQueryResult = Apollo.QueryResult<BenefactorByIdWithoutPermissionQuery, BenefactorByIdWithoutPermissionQueryVariables>;
export const BenefactorPaymentInformationByIdDocument = gql`
    query benefactorPaymentInformationById($id: BenefactorId!) {
  benefactorById(id: $id) {
    ...BenefactorPaymentInformation
  }
}
    ${BenefactorPaymentInformationFragmentDoc}`;

/**
 * __useBenefactorPaymentInformationByIdQuery__
 *
 * To run a query within a React component, call `useBenefactorPaymentInformationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenefactorPaymentInformationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenefactorPaymentInformationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBenefactorPaymentInformationByIdQuery(baseOptions: Apollo.QueryHookOptions<BenefactorPaymentInformationByIdQuery, BenefactorPaymentInformationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BenefactorPaymentInformationByIdQuery, BenefactorPaymentInformationByIdQueryVariables>(BenefactorPaymentInformationByIdDocument, options);
      }
export function useBenefactorPaymentInformationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BenefactorPaymentInformationByIdQuery, BenefactorPaymentInformationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BenefactorPaymentInformationByIdQuery, BenefactorPaymentInformationByIdQueryVariables>(BenefactorPaymentInformationByIdDocument, options);
        }
export type BenefactorPaymentInformationByIdQueryHookResult = ReturnType<typeof useBenefactorPaymentInformationByIdQuery>;
export type BenefactorPaymentInformationByIdLazyQueryHookResult = ReturnType<typeof useBenefactorPaymentInformationByIdLazyQuery>;
export type BenefactorPaymentInformationByIdQueryResult = Apollo.QueryResult<BenefactorPaymentInformationByIdQuery, BenefactorPaymentInformationByIdQueryVariables>;
export const BenefactorPermissionsByIdDocument = gql`
    query BenefactorPermissionsById($id: BenefactorId!) {
  me {
    permissions {
      benefactor(id: $id) {
        edit
        viewContactInformation
        viewPaymentInformation
        viewPersonalData
        viewVolunteerInformation
      }
    }
  }
}
    `;

/**
 * __useBenefactorPermissionsByIdQuery__
 *
 * To run a query within a React component, call `useBenefactorPermissionsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenefactorPermissionsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenefactorPermissionsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBenefactorPermissionsByIdQuery(baseOptions: Apollo.QueryHookOptions<BenefactorPermissionsByIdQuery, BenefactorPermissionsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BenefactorPermissionsByIdQuery, BenefactorPermissionsByIdQueryVariables>(BenefactorPermissionsByIdDocument, options);
      }
export function useBenefactorPermissionsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BenefactorPermissionsByIdQuery, BenefactorPermissionsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BenefactorPermissionsByIdQuery, BenefactorPermissionsByIdQueryVariables>(BenefactorPermissionsByIdDocument, options);
        }
export type BenefactorPermissionsByIdQueryHookResult = ReturnType<typeof useBenefactorPermissionsByIdQuery>;
export type BenefactorPermissionsByIdLazyQueryHookResult = ReturnType<typeof useBenefactorPermissionsByIdLazyQuery>;
export type BenefactorPermissionsByIdQueryResult = Apollo.QueryResult<BenefactorPermissionsByIdQuery, BenefactorPermissionsByIdQueryVariables>;
export const BenefactorsDocument = gql`
    query benefactors($first: Int, $after: String, $before: String, $orderBy: [BenefactorSort!], $where: BenefactorFilter, $last: Int, $permission: Boolean!) {
  benefactors(first: $first, after: $after, before: $before, order: $orderBy, where: $where, last: $last) {
    pageInfo {
      hasNextPage
      endCursor
      startCursor
      hasPreviousPage
    }
    totalCount
    nodes {
      ...BenefactorProps
      ...BenefactorPaymentInformation @include(if: $permission)
    }
  }
}
    ${BenefactorPropsFragmentDoc}
${BenefactorPaymentInformationFragmentDoc}`;

/**
 * __useBenefactorsQuery__
 *
 * To run a query within a React component, call `useBenefactorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenefactorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenefactorsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *      last: // value for 'last'
 *      permission: // value for 'permission'
 *   },
 * });
 */
export function useBenefactorsQuery(baseOptions: Apollo.QueryHookOptions<BenefactorsQuery, BenefactorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BenefactorsQuery, BenefactorsQueryVariables>(BenefactorsDocument, options);
      }
export function useBenefactorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BenefactorsQuery, BenefactorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BenefactorsQuery, BenefactorsQueryVariables>(BenefactorsDocument, options);
        }
export type BenefactorsQueryHookResult = ReturnType<typeof useBenefactorsQuery>;
export type BenefactorsLazyQueryHookResult = ReturnType<typeof useBenefactorsLazyQuery>;
export type BenefactorsQueryResult = Apollo.QueryResult<BenefactorsQuery, BenefactorsQueryVariables>;
export const BenefactorsWithoutPermissionsDocument = gql`
    query benefactorsWithoutPermissions($first: Int, $after: String, $before: String, $orderBy: [BenefactorSort!], $where: BenefactorFilter, $last: Int) {
  benefactors(first: $first, after: $after, before: $before, order: $orderBy, where: $where, last: $last) {
    pageInfo {
      hasNextPage
      endCursor
      startCursor
      hasPreviousPage
    }
    totalCount
    nodes {
      ...BenefactorProps
    }
  }
}
    ${BenefactorPropsFragmentDoc}`;

/**
 * __useBenefactorsWithoutPermissionsQuery__
 *
 * To run a query within a React component, call `useBenefactorsWithoutPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenefactorsWithoutPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenefactorsWithoutPermissionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useBenefactorsWithoutPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<BenefactorsWithoutPermissionsQuery, BenefactorsWithoutPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BenefactorsWithoutPermissionsQuery, BenefactorsWithoutPermissionsQueryVariables>(BenefactorsWithoutPermissionsDocument, options);
      }
export function useBenefactorsWithoutPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BenefactorsWithoutPermissionsQuery, BenefactorsWithoutPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BenefactorsWithoutPermissionsQuery, BenefactorsWithoutPermissionsQueryVariables>(BenefactorsWithoutPermissionsDocument, options);
        }
export type BenefactorsWithoutPermissionsQueryHookResult = ReturnType<typeof useBenefactorsWithoutPermissionsQuery>;
export type BenefactorsWithoutPermissionsLazyQueryHookResult = ReturnType<typeof useBenefactorsWithoutPermissionsLazyQuery>;
export type BenefactorsWithoutPermissionsQueryResult = Apollo.QueryResult<BenefactorsWithoutPermissionsQuery, BenefactorsWithoutPermissionsQueryVariables>;
export const UpdateBenefactorByAdministratorDocument = gql`
    mutation UpdateBenefactorByAdministrator($input: UpdateBenefactorByAdministratorInput!) {
  updateBenefactorByAdministrator(input: $input) {
    benefactor {
      ...BenefactorExtendedProps
    }
  }
}
    ${BenefactorExtendedPropsFragmentDoc}`;
export type UpdateBenefactorByAdministratorMutationFn = Apollo.MutationFunction<UpdateBenefactorByAdministratorMutation, UpdateBenefactorByAdministratorMutationVariables>;

/**
 * __useUpdateBenefactorByAdministratorMutation__
 *
 * To run a mutation, you first call `useUpdateBenefactorByAdministratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBenefactorByAdministratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBenefactorByAdministratorMutation, { data, loading, error }] = useUpdateBenefactorByAdministratorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBenefactorByAdministratorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBenefactorByAdministratorMutation, UpdateBenefactorByAdministratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBenefactorByAdministratorMutation, UpdateBenefactorByAdministratorMutationVariables>(UpdateBenefactorByAdministratorDocument, options);
      }
export type UpdateBenefactorByAdministratorMutationHookResult = ReturnType<typeof useUpdateBenefactorByAdministratorMutation>;
export type UpdateBenefactorByAdministratorMutationResult = Apollo.MutationResult<UpdateBenefactorByAdministratorMutation>;
export type UpdateBenefactorByAdministratorMutationOptions = Apollo.BaseMutationOptions<UpdateBenefactorByAdministratorMutation, UpdateBenefactorByAdministratorMutationVariables>;
export const UpdateBenefactorPersonalDataByAdministratorDocument = gql`
    mutation UpdateBenefactorPersonalDataByAdministrator($input: UpdateBenefactorPersonalDataByAdministratorInput!) {
  updateBenefactorPersonalDataByAdministrator(input: $input) {
    benefactor {
      ...BenefactorExtendedProps
    }
  }
}
    ${BenefactorExtendedPropsFragmentDoc}`;
export type UpdateBenefactorPersonalDataByAdministratorMutationFn = Apollo.MutationFunction<UpdateBenefactorPersonalDataByAdministratorMutation, UpdateBenefactorPersonalDataByAdministratorMutationVariables>;

/**
 * __useUpdateBenefactorPersonalDataByAdministratorMutation__
 *
 * To run a mutation, you first call `useUpdateBenefactorPersonalDataByAdministratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBenefactorPersonalDataByAdministratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBenefactorPersonalDataByAdministratorMutation, { data, loading, error }] = useUpdateBenefactorPersonalDataByAdministratorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBenefactorPersonalDataByAdministratorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBenefactorPersonalDataByAdministratorMutation, UpdateBenefactorPersonalDataByAdministratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBenefactorPersonalDataByAdministratorMutation, UpdateBenefactorPersonalDataByAdministratorMutationVariables>(UpdateBenefactorPersonalDataByAdministratorDocument, options);
      }
export type UpdateBenefactorPersonalDataByAdministratorMutationHookResult = ReturnType<typeof useUpdateBenefactorPersonalDataByAdministratorMutation>;
export type UpdateBenefactorPersonalDataByAdministratorMutationResult = Apollo.MutationResult<UpdateBenefactorPersonalDataByAdministratorMutation>;
export type UpdateBenefactorPersonalDataByAdministratorMutationOptions = Apollo.BaseMutationOptions<UpdateBenefactorPersonalDataByAdministratorMutation, UpdateBenefactorPersonalDataByAdministratorMutationVariables>;
export const UpdateBenefactorVolunteerInformationByAdministratorDocument = gql`
    mutation UpdateBenefactorVolunteerInformationByAdministrator($input: UpdateBenefactorVolunteerInformationByAdministratorInput!) {
  updateBenefactorVolunteerInformationByAdministrator(input: $input) {
    benefactor {
      ...BenefactorExtendedProps
    }
  }
}
    ${BenefactorExtendedPropsFragmentDoc}`;
export type UpdateBenefactorVolunteerInformationByAdministratorMutationFn = Apollo.MutationFunction<UpdateBenefactorVolunteerInformationByAdministratorMutation, UpdateBenefactorVolunteerInformationByAdministratorMutationVariables>;

/**
 * __useUpdateBenefactorVolunteerInformationByAdministratorMutation__
 *
 * To run a mutation, you first call `useUpdateBenefactorVolunteerInformationByAdministratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBenefactorVolunteerInformationByAdministratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBenefactorVolunteerInformationByAdministratorMutation, { data, loading, error }] = useUpdateBenefactorVolunteerInformationByAdministratorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBenefactorVolunteerInformationByAdministratorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBenefactorVolunteerInformationByAdministratorMutation, UpdateBenefactorVolunteerInformationByAdministratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBenefactorVolunteerInformationByAdministratorMutation, UpdateBenefactorVolunteerInformationByAdministratorMutationVariables>(UpdateBenefactorVolunteerInformationByAdministratorDocument, options);
      }
export type UpdateBenefactorVolunteerInformationByAdministratorMutationHookResult = ReturnType<typeof useUpdateBenefactorVolunteerInformationByAdministratorMutation>;
export type UpdateBenefactorVolunteerInformationByAdministratorMutationResult = Apollo.MutationResult<UpdateBenefactorVolunteerInformationByAdministratorMutation>;
export type UpdateBenefactorVolunteerInformationByAdministratorMutationOptions = Apollo.BaseMutationOptions<UpdateBenefactorVolunteerInformationByAdministratorMutation, UpdateBenefactorVolunteerInformationByAdministratorMutationVariables>;
export const UpdateBenefactorSkillsByAdministratorDocument = gql`
    mutation UpdateBenefactorSkillsByAdministrator($input: UpdateBenefactorSkillsInformationByAdministratorInput!) {
  updateBenefactorSkillsInformationByAdministrator(input: $input) {
    benefactor {
      ...BenefactorExtendedProps
    }
  }
}
    ${BenefactorExtendedPropsFragmentDoc}`;
export type UpdateBenefactorSkillsByAdministratorMutationFn = Apollo.MutationFunction<UpdateBenefactorSkillsByAdministratorMutation, UpdateBenefactorSkillsByAdministratorMutationVariables>;

/**
 * __useUpdateBenefactorSkillsByAdministratorMutation__
 *
 * To run a mutation, you first call `useUpdateBenefactorSkillsByAdministratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBenefactorSkillsByAdministratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBenefactorSkillsByAdministratorMutation, { data, loading, error }] = useUpdateBenefactorSkillsByAdministratorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBenefactorSkillsByAdministratorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBenefactorSkillsByAdministratorMutation, UpdateBenefactorSkillsByAdministratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBenefactorSkillsByAdministratorMutation, UpdateBenefactorSkillsByAdministratorMutationVariables>(UpdateBenefactorSkillsByAdministratorDocument, options);
      }
export type UpdateBenefactorSkillsByAdministratorMutationHookResult = ReturnType<typeof useUpdateBenefactorSkillsByAdministratorMutation>;
export type UpdateBenefactorSkillsByAdministratorMutationResult = Apollo.MutationResult<UpdateBenefactorSkillsByAdministratorMutation>;
export type UpdateBenefactorSkillsByAdministratorMutationOptions = Apollo.BaseMutationOptions<UpdateBenefactorSkillsByAdministratorMutation, UpdateBenefactorSkillsByAdministratorMutationVariables>;
export const UpdateBenefactorDepositAccountForSpecificWardsDocument = gql`
    mutation updateBenefactorDepositAccountForSpecificWards($input: UpdateBenefactorDepositAccountForSpecificWardsInput!) {
  updateBenefactorDepositAccountForSpecificWards(input: $input) {
    benefactorDepositAccountForSpecificWards {
      id
      title
      wards {
        ...WardProps
      }
    }
  }
}
    ${WardPropsFragmentDoc}`;
export type UpdateBenefactorDepositAccountForSpecificWardsMutationFn = Apollo.MutationFunction<UpdateBenefactorDepositAccountForSpecificWardsMutation, UpdateBenefactorDepositAccountForSpecificWardsMutationVariables>;

/**
 * __useUpdateBenefactorDepositAccountForSpecificWardsMutation__
 *
 * To run a mutation, you first call `useUpdateBenefactorDepositAccountForSpecificWardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBenefactorDepositAccountForSpecificWardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBenefactorDepositAccountForSpecificWardsMutation, { data, loading, error }] = useUpdateBenefactorDepositAccountForSpecificWardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBenefactorDepositAccountForSpecificWardsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBenefactorDepositAccountForSpecificWardsMutation, UpdateBenefactorDepositAccountForSpecificWardsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBenefactorDepositAccountForSpecificWardsMutation, UpdateBenefactorDepositAccountForSpecificWardsMutationVariables>(UpdateBenefactorDepositAccountForSpecificWardsDocument, options);
      }
export type UpdateBenefactorDepositAccountForSpecificWardsMutationHookResult = ReturnType<typeof useUpdateBenefactorDepositAccountForSpecificWardsMutation>;
export type UpdateBenefactorDepositAccountForSpecificWardsMutationResult = Apollo.MutationResult<UpdateBenefactorDepositAccountForSpecificWardsMutation>;
export type UpdateBenefactorDepositAccountForSpecificWardsMutationOptions = Apollo.BaseMutationOptions<UpdateBenefactorDepositAccountForSpecificWardsMutation, UpdateBenefactorDepositAccountForSpecificWardsMutationVariables>;
export const BenefactorAchievementsDocument = gql`
    query benefactorAchievements($id: BenefactorId!, $after: String, $before: String, $first: Int, $last: Int) {
  benefactorById(id: $id) {
    id
    achievements(after: $after, before: $before, first: $first, last: $last) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      totalCount
      nodes {
        receivedAt
        achievement {
          ...AchievementsProps
        }
      }
    }
  }
}
    ${AchievementsPropsFragmentDoc}`;

/**
 * __useBenefactorAchievementsQuery__
 *
 * To run a query within a React component, call `useBenefactorAchievementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenefactorAchievementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenefactorAchievementsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useBenefactorAchievementsQuery(baseOptions: Apollo.QueryHookOptions<BenefactorAchievementsQuery, BenefactorAchievementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BenefactorAchievementsQuery, BenefactorAchievementsQueryVariables>(BenefactorAchievementsDocument, options);
      }
export function useBenefactorAchievementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BenefactorAchievementsQuery, BenefactorAchievementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BenefactorAchievementsQuery, BenefactorAchievementsQueryVariables>(BenefactorAchievementsDocument, options);
        }
export type BenefactorAchievementsQueryHookResult = ReturnType<typeof useBenefactorAchievementsQuery>;
export type BenefactorAchievementsLazyQueryHookResult = ReturnType<typeof useBenefactorAchievementsLazyQuery>;
export type BenefactorAchievementsQueryResult = Apollo.QueryResult<BenefactorAchievementsQuery, BenefactorAchievementsQueryVariables>;
export const BenefactorApplicationsDocument = gql`
    query BenefactorApplications($id: BenefactorId!, $after: String, $before: String, $first: Int, $last: Int) {
  benefactorById(id: $id) {
    id
    foundationEventApplications(after: $after, before: $before, first: $first, last: $last) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      totalCount
      nodes {
        id
        event {
          details {
            title
          }
          foundation {
            name
          }
        }
        created {
          timestamp
        }
      }
    }
  }
}
    `;

/**
 * __useBenefactorApplicationsQuery__
 *
 * To run a query within a React component, call `useBenefactorApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenefactorApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenefactorApplicationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useBenefactorApplicationsQuery(baseOptions: Apollo.QueryHookOptions<BenefactorApplicationsQuery, BenefactorApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BenefactorApplicationsQuery, BenefactorApplicationsQueryVariables>(BenefactorApplicationsDocument, options);
      }
export function useBenefactorApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BenefactorApplicationsQuery, BenefactorApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BenefactorApplicationsQuery, BenefactorApplicationsQueryVariables>(BenefactorApplicationsDocument, options);
        }
export type BenefactorApplicationsQueryHookResult = ReturnType<typeof useBenefactorApplicationsQuery>;
export type BenefactorApplicationsLazyQueryHookResult = ReturnType<typeof useBenefactorApplicationsLazyQuery>;
export type BenefactorApplicationsQueryResult = Apollo.QueryResult<BenefactorApplicationsQuery, BenefactorApplicationsQueryVariables>;
export const BenefactorAttendancesDocument = gql`
    query BenefactorAttendances($id: BenefactorId!, $after: String, $before: String, $first: Int, $last: Int) {
  benefactorById(id: $id) {
    id
    foundationEventAttendance(after: $after, before: $before, first: $first, last: $last) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      totalCount
      nodes {
        id
        foundationEvent {
          details {
            title
          }
          foundation {
            name
          }
        }
        foundationRating
        foundationComment
      }
    }
  }
}
    `;

/**
 * __useBenefactorAttendancesQuery__
 *
 * To run a query within a React component, call `useBenefactorAttendancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenefactorAttendancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenefactorAttendancesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useBenefactorAttendancesQuery(baseOptions: Apollo.QueryHookOptions<BenefactorAttendancesQuery, BenefactorAttendancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BenefactorAttendancesQuery, BenefactorAttendancesQueryVariables>(BenefactorAttendancesDocument, options);
      }
export function useBenefactorAttendancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BenefactorAttendancesQuery, BenefactorAttendancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BenefactorAttendancesQuery, BenefactorAttendancesQueryVariables>(BenefactorAttendancesDocument, options);
        }
export type BenefactorAttendancesQueryHookResult = ReturnType<typeof useBenefactorAttendancesQuery>;
export type BenefactorAttendancesLazyQueryHookResult = ReturnType<typeof useBenefactorAttendancesLazyQuery>;
export type BenefactorAttendancesQueryResult = Apollo.QueryResult<BenefactorAttendancesQuery, BenefactorAttendancesQueryVariables>;
export const BenefactorOrdersDocument = gql`
    query benefactorOrders($id: BenefactorId!, $after: String, $before: String, $first: Int, $last: Int, $orderBy: [OrderSortInput!]) {
  benefactorById(id: $id) {
    id
    orders(after: $after, before: $before, first: $first, last: $last, order: $orderBy) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      totalCount
      nodes {
        ...OrdersProps
      }
    }
  }
}
    ${OrdersPropsFragmentDoc}`;

/**
 * __useBenefactorOrdersQuery__
 *
 * To run a query within a React component, call `useBenefactorOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenefactorOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenefactorOrdersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useBenefactorOrdersQuery(baseOptions: Apollo.QueryHookOptions<BenefactorOrdersQuery, BenefactorOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BenefactorOrdersQuery, BenefactorOrdersQueryVariables>(BenefactorOrdersDocument, options);
      }
export function useBenefactorOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BenefactorOrdersQuery, BenefactorOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BenefactorOrdersQuery, BenefactorOrdersQueryVariables>(BenefactorOrdersDocument, options);
        }
export type BenefactorOrdersQueryHookResult = ReturnType<typeof useBenefactorOrdersQuery>;
export type BenefactorOrdersLazyQueryHookResult = ReturnType<typeof useBenefactorOrdersLazyQuery>;
export type BenefactorOrdersQueryResult = Apollo.QueryResult<BenefactorOrdersQuery, BenefactorOrdersQueryVariables>;
export const BenefactorByIdStatusesDocument = gql`
    query benefactorByIdStatuses($id: BenefactorId!, $after: String, $before: String, $first: Int, $last: Int) {
  benefactorById(id: $id) {
    id
    statuses(after: $after, before: $before, first: $first, last: $last) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      totalCount
      nodes {
        ...BenefactorStatusProps
      }
    }
  }
}
    ${BenefactorStatusPropsFragmentDoc}`;

/**
 * __useBenefactorByIdStatusesQuery__
 *
 * To run a query within a React component, call `useBenefactorByIdStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenefactorByIdStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenefactorByIdStatusesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useBenefactorByIdStatusesQuery(baseOptions: Apollo.QueryHookOptions<BenefactorByIdStatusesQuery, BenefactorByIdStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BenefactorByIdStatusesQuery, BenefactorByIdStatusesQueryVariables>(BenefactorByIdStatusesDocument, options);
      }
export function useBenefactorByIdStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BenefactorByIdStatusesQuery, BenefactorByIdStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BenefactorByIdStatusesQuery, BenefactorByIdStatusesQueryVariables>(BenefactorByIdStatusesDocument, options);
        }
export type BenefactorByIdStatusesQueryHookResult = ReturnType<typeof useBenefactorByIdStatusesQuery>;
export type BenefactorByIdStatusesLazyQueryHookResult = ReturnType<typeof useBenefactorByIdStatusesLazyQuery>;
export type BenefactorByIdStatusesQueryResult = Apollo.QueryResult<BenefactorByIdStatusesQuery, BenefactorByIdStatusesQueryVariables>;
export const BenefactorSubscriptionsDocument = gql`
    query benefactorSubscriptions($id: BenefactorId!, $after: String, $before: String, $first: Int, $last: Int) {
  benefactorById(id: $id) {
    id
    subscriptions(after: $after, before: $before, first: $first, last: $last) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      totalCount
      nodes {
        ...DonationSubscriptionProps
      }
    }
  }
}
    ${DonationSubscriptionPropsFragmentDoc}`;

/**
 * __useBenefactorSubscriptionsQuery__
 *
 * To run a query within a React component, call `useBenefactorSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenefactorSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenefactorSubscriptionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useBenefactorSubscriptionsQuery(baseOptions: Apollo.QueryHookOptions<BenefactorSubscriptionsQuery, BenefactorSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BenefactorSubscriptionsQuery, BenefactorSubscriptionsQueryVariables>(BenefactorSubscriptionsDocument, options);
      }
export function useBenefactorSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BenefactorSubscriptionsQuery, BenefactorSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BenefactorSubscriptionsQuery, BenefactorSubscriptionsQueryVariables>(BenefactorSubscriptionsDocument, options);
        }
export type BenefactorSubscriptionsQueryHookResult = ReturnType<typeof useBenefactorSubscriptionsQuery>;
export type BenefactorSubscriptionsLazyQueryHookResult = ReturnType<typeof useBenefactorSubscriptionsLazyQuery>;
export type BenefactorSubscriptionsQueryResult = Apollo.QueryResult<BenefactorSubscriptionsQuery, BenefactorSubscriptionsQueryVariables>;
export const DonationsPaymentDocument = gql`
    query DonationsPayment($id: OrderId!, $after: String, $before: String, $first: Int, $last: Int) {
  orderById(id: $id) {
    ...OrdersProps
    actualAmountSummary {
      ...OrderAmountSummaryProps
    }
    intentAmountSummary {
      ...OrderAmountSummaryProps
    }
    donationIntents {
      __typename
    }
    donations(after: $after, before: $before, first: $first, last: $last) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      totalCount
      nodes {
        ...PaymentWithDonationGrid
      }
    }
  }
}
    ${OrdersPropsFragmentDoc}
${OrderAmountSummaryPropsFragmentDoc}
${PaymentWithDonationGridFragmentDoc}`;

/**
 * __useDonationsPaymentQuery__
 *
 * To run a query within a React component, call `useDonationsPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonationsPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonationsPaymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useDonationsPaymentQuery(baseOptions: Apollo.QueryHookOptions<DonationsPaymentQuery, DonationsPaymentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DonationsPaymentQuery, DonationsPaymentQueryVariables>(DonationsPaymentDocument, options);
      }
export function useDonationsPaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonationsPaymentQuery, DonationsPaymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DonationsPaymentQuery, DonationsPaymentQueryVariables>(DonationsPaymentDocument, options);
        }
export type DonationsPaymentQueryHookResult = ReturnType<typeof useDonationsPaymentQuery>;
export type DonationsPaymentLazyQueryHookResult = ReturnType<typeof useDonationsPaymentLazyQuery>;
export type DonationsPaymentQueryResult = Apollo.QueryResult<DonationsPaymentQuery, DonationsPaymentQueryVariables>;
export const AddCategoryDocument = gql`
    mutation addCategory($input: AddCategoryInput!) {
  addCategory(input: $input) {
    category {
      id
      name
    }
  }
}
    `;
export type AddCategoryMutationFn = Apollo.MutationFunction<AddCategoryMutation, AddCategoryMutationVariables>;

/**
 * __useAddCategoryMutation__
 *
 * To run a mutation, you first call `useAddCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCategoryMutation, { data, loading, error }] = useAddCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCategoryMutation(baseOptions?: Apollo.MutationHookOptions<AddCategoryMutation, AddCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCategoryMutation, AddCategoryMutationVariables>(AddCategoryDocument, options);
      }
export type AddCategoryMutationHookResult = ReturnType<typeof useAddCategoryMutation>;
export type AddCategoryMutationResult = Apollo.MutationResult<AddCategoryMutation>;
export type AddCategoryMutationOptions = Apollo.BaseMutationOptions<AddCategoryMutation, AddCategoryMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation deleteCategory($input: DeleteCategoryInput!) {
  deleteCategory(input: $input) {
    success
  }
}
    `;
export type DeleteCategoryMutationFn = Apollo.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, options);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation updateCategory($input: UpdateCategoryInput!) {
  updateCategory(input: $input) {
    category {
      id
    }
    errors {
      ... on Error {
        message
      }
    }
  }
}
    `;
export type UpdateCategoryMutationFn = Apollo.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, options);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = Apollo.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const UpdateCategoriesOrderDocument = gql`
    mutation updateCategoriesOrder($input: UpdateCategoriesOrderInput!) {
  updateCategoriesOrder(input: $input) {
    success
  }
}
    `;
export type UpdateCategoriesOrderMutationFn = Apollo.MutationFunction<UpdateCategoriesOrderMutation, UpdateCategoriesOrderMutationVariables>;

/**
 * __useUpdateCategoriesOrderMutation__
 *
 * To run a mutation, you first call `useUpdateCategoriesOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoriesOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoriesOrderMutation, { data, loading, error }] = useUpdateCategoriesOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCategoriesOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoriesOrderMutation, UpdateCategoriesOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoriesOrderMutation, UpdateCategoriesOrderMutationVariables>(UpdateCategoriesOrderDocument, options);
      }
export type UpdateCategoriesOrderMutationHookResult = ReturnType<typeof useUpdateCategoriesOrderMutation>;
export type UpdateCategoriesOrderMutationResult = Apollo.MutationResult<UpdateCategoriesOrderMutation>;
export type UpdateCategoriesOrderMutationOptions = Apollo.BaseMutationOptions<UpdateCategoriesOrderMutation, UpdateCategoriesOrderMutationVariables>;
export const CategoriesDocument = gql`
    query categories {
  categories {
    ...CategoryProps
  }
}
    ${CategoryPropsFragmentDoc}`;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const CategoryByIdDocument = gql`
    query categoryById($id: CategoryId!) {
  categoryById(id: $id) {
    ...CategoryProps
  }
}
    ${CategoryPropsFragmentDoc}`;

/**
 * __useCategoryByIdQuery__
 *
 * To run a query within a React component, call `useCategoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryByIdQuery(baseOptions: Apollo.QueryHookOptions<CategoryByIdQuery, CategoryByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryByIdQuery, CategoryByIdQueryVariables>(CategoryByIdDocument, options);
      }
export function useCategoryByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryByIdQuery, CategoryByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryByIdQuery, CategoryByIdQueryVariables>(CategoryByIdDocument, options);
        }
export type CategoryByIdQueryHookResult = ReturnType<typeof useCategoryByIdQuery>;
export type CategoryByIdLazyQueryHookResult = ReturnType<typeof useCategoryByIdLazyQuery>;
export type CategoryByIdQueryResult = Apollo.QueryResult<CategoryByIdQuery, CategoryByIdQueryVariables>;
export const AddCityDocument = gql`
    mutation addCity($input: AddCityInput!) {
  addCity(input: $input) {
    city {
      ...CityProps
    }
  }
}
    ${CityPropsFragmentDoc}`;
export type AddCityMutationFn = Apollo.MutationFunction<AddCityMutation, AddCityMutationVariables>;

/**
 * __useAddCityMutation__
 *
 * To run a mutation, you first call `useAddCityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCityMutation, { data, loading, error }] = useAddCityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCityMutation(baseOptions?: Apollo.MutationHookOptions<AddCityMutation, AddCityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCityMutation, AddCityMutationVariables>(AddCityDocument, options);
      }
export type AddCityMutationHookResult = ReturnType<typeof useAddCityMutation>;
export type AddCityMutationResult = Apollo.MutationResult<AddCityMutation>;
export type AddCityMutationOptions = Apollo.BaseMutationOptions<AddCityMutation, AddCityMutationVariables>;
export const UpdateCityDocument = gql`
    mutation UpdateCity($input: UpdateCityInput!) {
  updateCity(input: $input) {
    city {
      ...CityProps
    }
    errors {
      __typename
      ... on Error {
        message
      }
    }
  }
}
    ${CityPropsFragmentDoc}`;
export type UpdateCityMutationFn = Apollo.MutationFunction<UpdateCityMutation, UpdateCityMutationVariables>;

/**
 * __useUpdateCityMutation__
 *
 * To run a mutation, you first call `useUpdateCityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCityMutation, { data, loading, error }] = useUpdateCityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCityMutation, UpdateCityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCityMutation, UpdateCityMutationVariables>(UpdateCityDocument, options);
      }
export type UpdateCityMutationHookResult = ReturnType<typeof useUpdateCityMutation>;
export type UpdateCityMutationResult = Apollo.MutationResult<UpdateCityMutation>;
export type UpdateCityMutationOptions = Apollo.BaseMutationOptions<UpdateCityMutation, UpdateCityMutationVariables>;
export const CitiesDocument = gql`
    query cities($after: String, $before: String, $first: Int, $last: Int, $orderBy: [CitySortInput!]) {
  cities(after: $after, before: $before, first: $first, order: $orderBy, last: $last) {
    pageInfo {
      hasNextPage
      endCursor
      startCursor
      hasPreviousPage
    }
    totalCount
    nodes {
      ...CityProps
    }
  }
}
    ${CityPropsFragmentDoc}`;

/**
 * __useCitiesQuery__
 *
 * To run a query within a React component, call `useCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useCitiesQuery(baseOptions?: Apollo.QueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, options);
      }
export function useCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, options);
        }
export type CitiesQueryHookResult = ReturnType<typeof useCitiesQuery>;
export type CitiesLazyQueryHookResult = ReturnType<typeof useCitiesLazyQuery>;
export type CitiesQueryResult = Apollo.QueryResult<CitiesQuery, CitiesQueryVariables>;
export const CityByIdDocument = gql`
    query CityById($id: CityId!) {
  cityById(id: $id) {
    ...CityProps
  }
}
    ${CityPropsFragmentDoc}`;

/**
 * __useCityByIdQuery__
 *
 * To run a query within a React component, call `useCityByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCityByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCityByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCityByIdQuery(baseOptions: Apollo.QueryHookOptions<CityByIdQuery, CityByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CityByIdQuery, CityByIdQueryVariables>(CityByIdDocument, options);
      }
export function useCityByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CityByIdQuery, CityByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CityByIdQuery, CityByIdQueryVariables>(CityByIdDocument, options);
        }
export type CityByIdQueryHookResult = ReturnType<typeof useCityByIdQuery>;
export type CityByIdLazyQueryHookResult = ReturnType<typeof useCityByIdLazyQuery>;
export type CityByIdQueryResult = Apollo.QueryResult<CityByIdQuery, CityByIdQueryVariables>;
export const DeleteCityDocument = gql`
    mutation DeleteCity($input: DeleteCityInput!) {
  deleteCity(input: $input) {
    success
    errors {
      __typename
      ... on Error {
        message
      }
    }
  }
}
    `;
export type DeleteCityMutationFn = Apollo.MutationFunction<DeleteCityMutation, DeleteCityMutationVariables>;

/**
 * __useDeleteCityMutation__
 *
 * To run a mutation, you first call `useDeleteCityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCityMutation, { data, loading, error }] = useDeleteCityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCityMutation, DeleteCityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCityMutation, DeleteCityMutationVariables>(DeleteCityDocument, options);
      }
export type DeleteCityMutationHookResult = ReturnType<typeof useDeleteCityMutation>;
export type DeleteCityMutationResult = Apollo.MutationResult<DeleteCityMutation>;
export type DeleteCityMutationOptions = Apollo.BaseMutationOptions<DeleteCityMutation, DeleteCityMutationVariables>;
export const CreateCompanyReportDocument = gql`
    mutation createCompanyReport($input: CreateLimitedLiabilityCompanyReportInput!) {
  createLimitedLiabilityCompanyReport(input: $input) {
    report {
      id
    }
  }
}
    `;
export type CreateCompanyReportMutationFn = Apollo.MutationFunction<CreateCompanyReportMutation, CreateCompanyReportMutationVariables>;

/**
 * __useCreateCompanyReportMutation__
 *
 * To run a mutation, you first call `useCreateCompanyReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyReportMutation, { data, loading, error }] = useCreateCompanyReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyReportMutation, CreateCompanyReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyReportMutation, CreateCompanyReportMutationVariables>(CreateCompanyReportDocument, options);
      }
export type CreateCompanyReportMutationHookResult = ReturnType<typeof useCreateCompanyReportMutation>;
export type CreateCompanyReportMutationResult = Apollo.MutationResult<CreateCompanyReportMutation>;
export type CreateCompanyReportMutationOptions = Apollo.BaseMutationOptions<CreateCompanyReportMutation, CreateCompanyReportMutationVariables>;
export const UpdateCompanyBalanceDocument = gql`
    mutation updateCompanyBalance($input: UpdateLimitedLiabilityCompanyBalanceInput!) {
  updateLimitedLiabilityCompanyBalance(input: $input) {
    company {
      balance
    }
  }
}
    `;
export type UpdateCompanyBalanceMutationFn = Apollo.MutationFunction<UpdateCompanyBalanceMutation, UpdateCompanyBalanceMutationVariables>;

/**
 * __useUpdateCompanyBalanceMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyBalanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyBalanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyBalanceMutation, { data, loading, error }] = useUpdateCompanyBalanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyBalanceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyBalanceMutation, UpdateCompanyBalanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyBalanceMutation, UpdateCompanyBalanceMutationVariables>(UpdateCompanyBalanceDocument, options);
      }
export type UpdateCompanyBalanceMutationHookResult = ReturnType<typeof useUpdateCompanyBalanceMutation>;
export type UpdateCompanyBalanceMutationResult = Apollo.MutationResult<UpdateCompanyBalanceMutation>;
export type UpdateCompanyBalanceMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyBalanceMutation, UpdateCompanyBalanceMutationVariables>;
export const LimitedLiabilityCompanyReportDocument = gql`
    query limitedLiabilityCompanyReport($id: LimitedLiabilityCompanyReportId!) {
  limitedLiabilityCompanyReport(id: $id) {
    ...CompanyReportProps
  }
}
    ${CompanyReportPropsFragmentDoc}`;

/**
 * __useLimitedLiabilityCompanyReportQuery__
 *
 * To run a query within a React component, call `useLimitedLiabilityCompanyReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useLimitedLiabilityCompanyReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLimitedLiabilityCompanyReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLimitedLiabilityCompanyReportQuery(baseOptions: Apollo.QueryHookOptions<LimitedLiabilityCompanyReportQuery, LimitedLiabilityCompanyReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LimitedLiabilityCompanyReportQuery, LimitedLiabilityCompanyReportQueryVariables>(LimitedLiabilityCompanyReportDocument, options);
      }
export function useLimitedLiabilityCompanyReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LimitedLiabilityCompanyReportQuery, LimitedLiabilityCompanyReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LimitedLiabilityCompanyReportQuery, LimitedLiabilityCompanyReportQueryVariables>(LimitedLiabilityCompanyReportDocument, options);
        }
export type LimitedLiabilityCompanyReportQueryHookResult = ReturnType<typeof useLimitedLiabilityCompanyReportQuery>;
export type LimitedLiabilityCompanyReportLazyQueryHookResult = ReturnType<typeof useLimitedLiabilityCompanyReportLazyQuery>;
export type LimitedLiabilityCompanyReportQueryResult = Apollo.QueryResult<LimitedLiabilityCompanyReportQuery, LimitedLiabilityCompanyReportQueryVariables>;
export const PublishLimitedLiabilityCompanyReportDocument = gql`
    mutation publishLimitedLiabilityCompanyReport($input: PublishLimitedLiabilityCompanyReportInput!) {
  publishLimitedLiabilityCompanyReport(input: $input) {
    report {
      ...LimitedLiabilityCompanyReport
    }
  }
}
    ${LimitedLiabilityCompanyReportFragmentDoc}`;
export type PublishLimitedLiabilityCompanyReportMutationFn = Apollo.MutationFunction<PublishLimitedLiabilityCompanyReportMutation, PublishLimitedLiabilityCompanyReportMutationVariables>;

/**
 * __usePublishLimitedLiabilityCompanyReportMutation__
 *
 * To run a mutation, you first call `usePublishLimitedLiabilityCompanyReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishLimitedLiabilityCompanyReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishLimitedLiabilityCompanyReportMutation, { data, loading, error }] = usePublishLimitedLiabilityCompanyReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishLimitedLiabilityCompanyReportMutation(baseOptions?: Apollo.MutationHookOptions<PublishLimitedLiabilityCompanyReportMutation, PublishLimitedLiabilityCompanyReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishLimitedLiabilityCompanyReportMutation, PublishLimitedLiabilityCompanyReportMutationVariables>(PublishLimitedLiabilityCompanyReportDocument, options);
      }
export type PublishLimitedLiabilityCompanyReportMutationHookResult = ReturnType<typeof usePublishLimitedLiabilityCompanyReportMutation>;
export type PublishLimitedLiabilityCompanyReportMutationResult = Apollo.MutationResult<PublishLimitedLiabilityCompanyReportMutation>;
export type PublishLimitedLiabilityCompanyReportMutationOptions = Apollo.BaseMutationOptions<PublishLimitedLiabilityCompanyReportMutation, PublishLimitedLiabilityCompanyReportMutationVariables>;
export const UpdateCompanyReportDocumentsDocument = gql`
    mutation updateCompanyReportDocuments($input: UpdateLimitedLiabilityCompanyReportDocumentsInput!) {
  updateLimitedLiabilityCompanyReportDocuments(input: $input) {
    report {
      ...CompanyReportProps
    }
  }
}
    ${CompanyReportPropsFragmentDoc}`;
export type UpdateCompanyReportDocumentsMutationFn = Apollo.MutationFunction<UpdateCompanyReportDocumentsMutation, UpdateCompanyReportDocumentsMutationVariables>;

/**
 * __useUpdateCompanyReportDocumentsMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyReportDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyReportDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyReportDocumentsMutation, { data, loading, error }] = useUpdateCompanyReportDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyReportDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyReportDocumentsMutation, UpdateCompanyReportDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyReportDocumentsMutation, UpdateCompanyReportDocumentsMutationVariables>(UpdateCompanyReportDocumentsDocument, options);
      }
export type UpdateCompanyReportDocumentsMutationHookResult = ReturnType<typeof useUpdateCompanyReportDocumentsMutation>;
export type UpdateCompanyReportDocumentsMutationResult = Apollo.MutationResult<UpdateCompanyReportDocumentsMutation>;
export type UpdateCompanyReportDocumentsMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyReportDocumentsMutation, UpdateCompanyReportDocumentsMutationVariables>;
export const CompanyReportsDocument = gql`
    query companyReports($after: String, $before: String, $first: Int, $last: Int) {
  limitedLiabilityCompany {
    balance
    reports(after: $after, before: $before, first: $first, last: $last, order: {referenceDate: DESC}) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      totalCount
      nodes {
        ...LimitedLiabilityCompanyReport
      }
    }
  }
}
    ${LimitedLiabilityCompanyReportFragmentDoc}`;

/**
 * __useCompanyReportsQuery__
 *
 * To run a query within a React component, call `useCompanyReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyReportsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useCompanyReportsQuery(baseOptions?: Apollo.QueryHookOptions<CompanyReportsQuery, CompanyReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyReportsQuery, CompanyReportsQueryVariables>(CompanyReportsDocument, options);
      }
export function useCompanyReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyReportsQuery, CompanyReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyReportsQuery, CompanyReportsQueryVariables>(CompanyReportsDocument, options);
        }
export type CompanyReportsQueryHookResult = ReturnType<typeof useCompanyReportsQuery>;
export type CompanyReportsLazyQueryHookResult = ReturnType<typeof useCompanyReportsLazyQuery>;
export type CompanyReportsQueryResult = Apollo.QueryResult<CompanyReportsQuery, CompanyReportsQueryVariables>;
export const AddGrocerySetNeedDocument = gql`
    mutation addGrocerySetNeed($input: AddGrocerySetNeedInput!) {
  addGrocerySetNeed(input: $input) {
    need {
      id
      requiredAmount
    }
  }
}
    `;
export type AddGrocerySetNeedMutationFn = Apollo.MutationFunction<AddGrocerySetNeedMutation, AddGrocerySetNeedMutationVariables>;

/**
 * __useAddGrocerySetNeedMutation__
 *
 * To run a mutation, you first call `useAddGrocerySetNeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGrocerySetNeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGrocerySetNeedMutation, { data, loading, error }] = useAddGrocerySetNeedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddGrocerySetNeedMutation(baseOptions?: Apollo.MutationHookOptions<AddGrocerySetNeedMutation, AddGrocerySetNeedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddGrocerySetNeedMutation, AddGrocerySetNeedMutationVariables>(AddGrocerySetNeedDocument, options);
      }
export type AddGrocerySetNeedMutationHookResult = ReturnType<typeof useAddGrocerySetNeedMutation>;
export type AddGrocerySetNeedMutationResult = Apollo.MutationResult<AddGrocerySetNeedMutation>;
export type AddGrocerySetNeedMutationOptions = Apollo.BaseMutationOptions<AddGrocerySetNeedMutation, AddGrocerySetNeedMutationVariables>;
export const AddOneTimeNeedDocument = gql`
    mutation addOneTimeNeed($input: AddOneTimeNeedInput!) {
  addOneTimeNeed(input: $input) {
    need {
      id
    }
  }
}
    `;
export type AddOneTimeNeedMutationFn = Apollo.MutationFunction<AddOneTimeNeedMutation, AddOneTimeNeedMutationVariables>;

/**
 * __useAddOneTimeNeedMutation__
 *
 * To run a mutation, you first call `useAddOneTimeNeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOneTimeNeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOneTimeNeedMutation, { data, loading, error }] = useAddOneTimeNeedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOneTimeNeedMutation(baseOptions?: Apollo.MutationHookOptions<AddOneTimeNeedMutation, AddOneTimeNeedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOneTimeNeedMutation, AddOneTimeNeedMutationVariables>(AddOneTimeNeedDocument, options);
      }
export type AddOneTimeNeedMutationHookResult = ReturnType<typeof useAddOneTimeNeedMutation>;
export type AddOneTimeNeedMutationResult = Apollo.MutationResult<AddOneTimeNeedMutation>;
export type AddOneTimeNeedMutationOptions = Apollo.BaseMutationOptions<AddOneTimeNeedMutation, AddOneTimeNeedMutationVariables>;
export const UpdateOneTimeNeedDocument = gql`
    mutation updateOneTimeNeed($input: UpdateOneTimeNeedInput!) {
  updateOneTimeNeed(input: $input) {
    need {
      id
    }
  }
}
    `;
export type UpdateOneTimeNeedMutationFn = Apollo.MutationFunction<UpdateOneTimeNeedMutation, UpdateOneTimeNeedMutationVariables>;

/**
 * __useUpdateOneTimeNeedMutation__
 *
 * To run a mutation, you first call `useUpdateOneTimeNeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneTimeNeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneTimeNeedMutation, { data, loading, error }] = useUpdateOneTimeNeedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneTimeNeedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneTimeNeedMutation, UpdateOneTimeNeedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneTimeNeedMutation, UpdateOneTimeNeedMutationVariables>(UpdateOneTimeNeedDocument, options);
      }
export type UpdateOneTimeNeedMutationHookResult = ReturnType<typeof useUpdateOneTimeNeedMutation>;
export type UpdateOneTimeNeedMutationResult = Apollo.MutationResult<UpdateOneTimeNeedMutation>;
export type UpdateOneTimeNeedMutationOptions = Apollo.BaseMutationOptions<UpdateOneTimeNeedMutation, UpdateOneTimeNeedMutationVariables>;
export const AmountsDocument = gql`
    query Amounts {
  project {
    mainAccount {
      amount
    }
    wardsReserveAccount {
      amount
    }
  }
  amounts {
    amountOfProjectActiveSubscriptions
    amountOfProjectActiveSubscriptionsCreatedBeforeCurrentMonth
    amountOfProjectActiveSubscriptionsCreatedInCurrentMonth
    amountOfProjectNonSubscriptionDonations
    amountOfProjectNonSubscriptionDonationsCollectedInCurrentMonth
    amountOfProjectSubscriptionDonationsCollectedInCurrentMonth
    amountOfProjectSubscriptionsCancelledInCurrentMonth
    amountOfWardsRegularNeedsAccounts
    amountOfWardsActiveSubscriptions
    amountOfWardsActiveSubscriptionsCreatedBeforeCurrentMonth
    amountOfWardsActiveSubscriptionsCreatedInCurrentMonth
    amountOfWardsNonSubscriptionDonations
    amountOfWardsNonSubscriptionDonationsCollectedInCurrentMonth
    amountOfWardsSubscriptionDonationsCollectedInCurrentMonth
    amountOfWardsSubscriptionsCancelledInCurrentMonth
    amountOfBenefactorDeposits
  }
}
    `;

/**
 * __useAmountsQuery__
 *
 * To run a query within a React component, call `useAmountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAmountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAmountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAmountsQuery(baseOptions?: Apollo.QueryHookOptions<AmountsQuery, AmountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AmountsQuery, AmountsQueryVariables>(AmountsDocument, options);
      }
export function useAmountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AmountsQuery, AmountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AmountsQuery, AmountsQueryVariables>(AmountsDocument, options);
        }
export type AmountsQueryHookResult = ReturnType<typeof useAmountsQuery>;
export type AmountsLazyQueryHookResult = ReturnType<typeof useAmountsLazyQuery>;
export type AmountsQueryResult = Apollo.QueryResult<AmountsQuery, AmountsQueryVariables>;
export const StatisticsDocument = gql`
    query Statistics {
  statistics {
    today {
      ...StatisticsPeriodFragment
    }
    yesterday {
      ...StatisticsPeriodFragment
    }
    thisWeek {
      ...StatisticsPeriodFragment
    }
    lastWeek {
      ...StatisticsPeriodFragment
    }
    thisMonth {
      ...StatisticsPeriodFragment
    }
    lastMonth {
      ...StatisticsPeriodFragment
    }
  }
}
    ${StatisticsPeriodFragmentFragmentDoc}`;

/**
 * __useStatisticsQuery__
 *
 * To run a query within a React component, call `useStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<StatisticsQuery, StatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StatisticsQuery, StatisticsQueryVariables>(StatisticsDocument, options);
      }
export function useStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatisticsQuery, StatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StatisticsQuery, StatisticsQueryVariables>(StatisticsDocument, options);
        }
export type StatisticsQueryHookResult = ReturnType<typeof useStatisticsQuery>;
export type StatisticsLazyQueryHookResult = ReturnType<typeof useStatisticsLazyQuery>;
export type StatisticsQueryResult = Apollo.QueryResult<StatisticsQuery, StatisticsQueryVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($input: ForgotPasswordInput!) {
  forgotPassword(input: $input) {
    success
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const AddFoundationEventDocument = gql`
    mutation AddFoundationEvent($input: AddFoundationEventInput!) {
  addFoundationEvent(input: $input) {
    foundationEvent {
      ...FoundationEventProps
    }
  }
}
    ${FoundationEventPropsFragmentDoc}`;
export type AddFoundationEventMutationFn = Apollo.MutationFunction<AddFoundationEventMutation, AddFoundationEventMutationVariables>;

/**
 * __useAddFoundationEventMutation__
 *
 * To run a mutation, you first call `useAddFoundationEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFoundationEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFoundationEventMutation, { data, loading, error }] = useAddFoundationEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFoundationEventMutation(baseOptions?: Apollo.MutationHookOptions<AddFoundationEventMutation, AddFoundationEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFoundationEventMutation, AddFoundationEventMutationVariables>(AddFoundationEventDocument, options);
      }
export type AddFoundationEventMutationHookResult = ReturnType<typeof useAddFoundationEventMutation>;
export type AddFoundationEventMutationResult = Apollo.MutationResult<AddFoundationEventMutation>;
export type AddFoundationEventMutationOptions = Apollo.BaseMutationOptions<AddFoundationEventMutation, AddFoundationEventMutationVariables>;
export const UpdateFoundationEventDocument = gql`
    mutation UpdateFoundationEvent($input: UpdateFoundationEventInput!) {
  updateFoundationEvent(input: $input) {
    foundationEvent {
      ...FoundationEventProps
    }
  }
}
    ${FoundationEventPropsFragmentDoc}`;
export type UpdateFoundationEventMutationFn = Apollo.MutationFunction<UpdateFoundationEventMutation, UpdateFoundationEventMutationVariables>;

/**
 * __useUpdateFoundationEventMutation__
 *
 * To run a mutation, you first call `useUpdateFoundationEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFoundationEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFoundationEventMutation, { data, loading, error }] = useUpdateFoundationEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFoundationEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFoundationEventMutation, UpdateFoundationEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFoundationEventMutation, UpdateFoundationEventMutationVariables>(UpdateFoundationEventDocument, options);
      }
export type UpdateFoundationEventMutationHookResult = ReturnType<typeof useUpdateFoundationEventMutation>;
export type UpdateFoundationEventMutationResult = Apollo.MutationResult<UpdateFoundationEventMutation>;
export type UpdateFoundationEventMutationOptions = Apollo.BaseMutationOptions<UpdateFoundationEventMutation, UpdateFoundationEventMutationVariables>;
export const AddFoundationDocument = gql`
    mutation AddFoundation($input: AddFoundationInput!) {
  addFoundation(input: $input) {
    foundation {
      ...FoundationProps
    }
  }
}
    ${FoundationPropsFragmentDoc}`;
export type AddFoundationMutationFn = Apollo.MutationFunction<AddFoundationMutation, AddFoundationMutationVariables>;

/**
 * __useAddFoundationMutation__
 *
 * To run a mutation, you first call `useAddFoundationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFoundationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFoundationMutation, { data, loading, error }] = useAddFoundationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFoundationMutation(baseOptions?: Apollo.MutationHookOptions<AddFoundationMutation, AddFoundationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFoundationMutation, AddFoundationMutationVariables>(AddFoundationDocument, options);
      }
export type AddFoundationMutationHookResult = ReturnType<typeof useAddFoundationMutation>;
export type AddFoundationMutationResult = Apollo.MutationResult<AddFoundationMutation>;
export type AddFoundationMutationOptions = Apollo.BaseMutationOptions<AddFoundationMutation, AddFoundationMutationVariables>;
export const UpdateFoundationDocument = gql`
    mutation UpdateFoundation($input: UpdateFoundationInput!) {
  updateFoundation(input: $input) {
    foundation {
      ...FoundationProps
    }
  }
}
    ${FoundationPropsFragmentDoc}`;
export type UpdateFoundationMutationFn = Apollo.MutationFunction<UpdateFoundationMutation, UpdateFoundationMutationVariables>;

/**
 * __useUpdateFoundationMutation__
 *
 * To run a mutation, you first call `useUpdateFoundationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFoundationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFoundationMutation, { data, loading, error }] = useUpdateFoundationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFoundationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFoundationMutation, UpdateFoundationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFoundationMutation, UpdateFoundationMutationVariables>(UpdateFoundationDocument, options);
      }
export type UpdateFoundationMutationHookResult = ReturnType<typeof useUpdateFoundationMutation>;
export type UpdateFoundationMutationResult = Apollo.MutationResult<UpdateFoundationMutation>;
export type UpdateFoundationMutationOptions = Apollo.BaseMutationOptions<UpdateFoundationMutation, UpdateFoundationMutationVariables>;
export const CreateFoundationRepresentativeDocument = gql`
    mutation CreateFoundationRepresentative($input: CreateFoundationRepresentativeInput!) {
  createFoundationRepresentative(input: $input) {
    user {
      ...UserProps
    }
    errors {
      __typename
      ... on Error {
        message
      }
    }
  }
}
    ${UserPropsFragmentDoc}`;
export type CreateFoundationRepresentativeMutationFn = Apollo.MutationFunction<CreateFoundationRepresentativeMutation, CreateFoundationRepresentativeMutationVariables>;

/**
 * __useCreateFoundationRepresentativeMutation__
 *
 * To run a mutation, you first call `useCreateFoundationRepresentativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFoundationRepresentativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFoundationRepresentativeMutation, { data, loading, error }] = useCreateFoundationRepresentativeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFoundationRepresentativeMutation(baseOptions?: Apollo.MutationHookOptions<CreateFoundationRepresentativeMutation, CreateFoundationRepresentativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFoundationRepresentativeMutation, CreateFoundationRepresentativeMutationVariables>(CreateFoundationRepresentativeDocument, options);
      }
export type CreateFoundationRepresentativeMutationHookResult = ReturnType<typeof useCreateFoundationRepresentativeMutation>;
export type CreateFoundationRepresentativeMutationResult = Apollo.MutationResult<CreateFoundationRepresentativeMutation>;
export type CreateFoundationRepresentativeMutationOptions = Apollo.BaseMutationOptions<CreateFoundationRepresentativeMutation, CreateFoundationRepresentativeMutationVariables>;
export const EventPermissionsByIdDocument = gql`
    query EventPermissionsById($id: FoundationEventId!) {
  me {
    permissions {
      foundationEvent(id: $id) {
        id
        close
        edit
        takeOwnership
        openOrCloseRegistration
        rateAttendees
        requestApproval
        selectApplications
        view
        viewAttendees
        editApproved
      }
    }
  }
}
    `;

/**
 * __useEventPermissionsByIdQuery__
 *
 * To run a query within a React component, call `useEventPermissionsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventPermissionsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventPermissionsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventPermissionsByIdQuery(baseOptions: Apollo.QueryHookOptions<EventPermissionsByIdQuery, EventPermissionsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventPermissionsByIdQuery, EventPermissionsByIdQueryVariables>(EventPermissionsByIdDocument, options);
      }
export function useEventPermissionsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventPermissionsByIdQuery, EventPermissionsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventPermissionsByIdQuery, EventPermissionsByIdQueryVariables>(EventPermissionsByIdDocument, options);
        }
export type EventPermissionsByIdQueryHookResult = ReturnType<typeof useEventPermissionsByIdQuery>;
export type EventPermissionsByIdLazyQueryHookResult = ReturnType<typeof useEventPermissionsByIdLazyQuery>;
export type EventPermissionsByIdQueryResult = Apollo.QueryResult<EventPermissionsByIdQuery, EventPermissionsByIdQueryVariables>;
export const FoundationEventByIdDocument = gql`
    query FoundationEventById($id: FoundationEventId!) {
  foundationEventById(id: $id) {
    ...FoundationEventProps
  }
}
    ${FoundationEventPropsFragmentDoc}`;

/**
 * __useFoundationEventByIdQuery__
 *
 * To run a query within a React component, call `useFoundationEventByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoundationEventByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoundationEventByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFoundationEventByIdQuery(baseOptions: Apollo.QueryHookOptions<FoundationEventByIdQuery, FoundationEventByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoundationEventByIdQuery, FoundationEventByIdQueryVariables>(FoundationEventByIdDocument, options);
      }
export function useFoundationEventByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoundationEventByIdQuery, FoundationEventByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoundationEventByIdQuery, FoundationEventByIdQueryVariables>(FoundationEventByIdDocument, options);
        }
export type FoundationEventByIdQueryHookResult = ReturnType<typeof useFoundationEventByIdQuery>;
export type FoundationEventByIdLazyQueryHookResult = ReturnType<typeof useFoundationEventByIdLazyQuery>;
export type FoundationEventByIdQueryResult = Apollo.QueryResult<FoundationEventByIdQuery, FoundationEventByIdQueryVariables>;
export const UpdateFoundationEventReportDocument = gql`
    mutation UpdateFoundationEventReport($id: FoundationEventId!, $images: [DocumentInput!]!, $text: String!) {
  updateFoundationEventReport(input: {id: $id, images: $images, text: $text}) {
    foundationEvent {
      ...FoundationEventProps
    }
  }
}
    ${FoundationEventPropsFragmentDoc}`;
export type UpdateFoundationEventReportMutationFn = Apollo.MutationFunction<UpdateFoundationEventReportMutation, UpdateFoundationEventReportMutationVariables>;

/**
 * __useUpdateFoundationEventReportMutation__
 *
 * To run a mutation, you first call `useUpdateFoundationEventReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFoundationEventReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFoundationEventReportMutation, { data, loading, error }] = useUpdateFoundationEventReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      images: // value for 'images'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useUpdateFoundationEventReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFoundationEventReportMutation, UpdateFoundationEventReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFoundationEventReportMutation, UpdateFoundationEventReportMutationVariables>(UpdateFoundationEventReportDocument, options);
      }
export type UpdateFoundationEventReportMutationHookResult = ReturnType<typeof useUpdateFoundationEventReportMutation>;
export type UpdateFoundationEventReportMutationResult = Apollo.MutationResult<UpdateFoundationEventReportMutation>;
export type UpdateFoundationEventReportMutationOptions = Apollo.BaseMutationOptions<UpdateFoundationEventReportMutation, UpdateFoundationEventReportMutationVariables>;
export const FoundationByIdDocument = gql`
    query FoundationById($id: FoundationId!) {
  foundationById(id: $id) {
    ...FoundationProps
  }
}
    ${FoundationPropsFragmentDoc}`;

/**
 * __useFoundationByIdQuery__
 *
 * To run a query within a React component, call `useFoundationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoundationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoundationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFoundationByIdQuery(baseOptions: Apollo.QueryHookOptions<FoundationByIdQuery, FoundationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoundationByIdQuery, FoundationByIdQueryVariables>(FoundationByIdDocument, options);
      }
export function useFoundationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoundationByIdQuery, FoundationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoundationByIdQuery, FoundationByIdQueryVariables>(FoundationByIdDocument, options);
        }
export type FoundationByIdQueryHookResult = ReturnType<typeof useFoundationByIdQuery>;
export type FoundationByIdLazyQueryHookResult = ReturnType<typeof useFoundationByIdLazyQuery>;
export type FoundationByIdQueryResult = Apollo.QueryResult<FoundationByIdQuery, FoundationByIdQueryVariables>;
export const FoundationPermissionsByIdDocument = gql`
    query FoundationPermissionsById($id: FoundationId!) {
  me {
    permissions {
      foundation(id: $id) {
        id
        createEvents
        addRepresentative
        edit
        viewRepresentatives
      }
    }
  }
}
    `;

/**
 * __useFoundationPermissionsByIdQuery__
 *
 * To run a query within a React component, call `useFoundationPermissionsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoundationPermissionsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoundationPermissionsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFoundationPermissionsByIdQuery(baseOptions: Apollo.QueryHookOptions<FoundationPermissionsByIdQuery, FoundationPermissionsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoundationPermissionsByIdQuery, FoundationPermissionsByIdQueryVariables>(FoundationPermissionsByIdDocument, options);
      }
export function useFoundationPermissionsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoundationPermissionsByIdQuery, FoundationPermissionsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoundationPermissionsByIdQuery, FoundationPermissionsByIdQueryVariables>(FoundationPermissionsByIdDocument, options);
        }
export type FoundationPermissionsByIdQueryHookResult = ReturnType<typeof useFoundationPermissionsByIdQuery>;
export type FoundationPermissionsByIdLazyQueryHookResult = ReturnType<typeof useFoundationPermissionsByIdLazyQuery>;
export type FoundationPermissionsByIdQueryResult = Apollo.QueryResult<FoundationPermissionsByIdQuery, FoundationPermissionsByIdQueryVariables>;
export const FoundationsDocument = gql`
    query Foundations($after: String, $before: String, $first: Int, $last: Int, $orderBy: [FoundationSortInput!], $where: FoundationFilterInput) {
  foundations(after: $after, before: $before, first: $first, order: $orderBy, last: $last, where: $where) {
    pageInfo {
      hasNextPage
      endCursor
      startCursor
      hasPreviousPage
      __typename
    }
    totalCount
    nodes {
      id
      created {
        ...AuditMarkerProps
      }
      name
      description
      eventsCounters {
        total
        active
      }
      __typename
    }
    __typename
  }
}
    ${AuditMarkerPropsFragmentDoc}`;

/**
 * __useFoundationsQuery__
 *
 * To run a query within a React component, call `useFoundationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoundationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoundationsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFoundationsQuery(baseOptions?: Apollo.QueryHookOptions<FoundationsQuery, FoundationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoundationsQuery, FoundationsQueryVariables>(FoundationsDocument, options);
      }
export function useFoundationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoundationsQuery, FoundationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoundationsQuery, FoundationsQueryVariables>(FoundationsDocument, options);
        }
export type FoundationsQueryHookResult = ReturnType<typeof useFoundationsQuery>;
export type FoundationsLazyQueryHookResult = ReturnType<typeof useFoundationsLazyQuery>;
export type FoundationsQueryResult = Apollo.QueryResult<FoundationsQuery, FoundationsQueryVariables>;
export const EventsDocument = gql`
    query Events($after: String, $before: String, $first: Int, $last: Int, $where: FoundationEventFilterInput, $id: FoundationId!) {
  foundationById(id: $id) {
    id
    events(after: $after, before: $before, first: $first, last: $last, order: {approved: {timestamp: DESC}}, where: $where) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
        __typename
      }
      totalCount
      nodes {
        id
        created {
          ...AuditMarkerProps
        }
        approved {
          timestamp
          user {
            ...UserBaseProps
          }
        }
        cancelled {
          timestamp
        }
        closed {
          timestamp
        }
        details {
          title
          city
          address {
            city {
              valueWithType
            }
            settlement {
              valueWithType
            }
          }
          start
        }
        __typename
      }
      __typename
    }
  }
}
    ${AuditMarkerPropsFragmentDoc}
${UserBasePropsFragmentDoc}`;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      where: // value for 'where'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventsQuery(baseOptions: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
      }
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;
export const ApproveFoundationEventDocument = gql`
    mutation ApproveFoundationEvent($input: ApproveFoundationEventInput!) {
  approveFoundationEvent(input: $input) {
    foundationEvent {
      ...FoundationEventProps
    }
  }
}
    ${FoundationEventPropsFragmentDoc}`;
export type ApproveFoundationEventMutationFn = Apollo.MutationFunction<ApproveFoundationEventMutation, ApproveFoundationEventMutationVariables>;

/**
 * __useApproveFoundationEventMutation__
 *
 * To run a mutation, you first call `useApproveFoundationEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveFoundationEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveFoundationEventMutation, { data, loading, error }] = useApproveFoundationEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveFoundationEventMutation(baseOptions?: Apollo.MutationHookOptions<ApproveFoundationEventMutation, ApproveFoundationEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveFoundationEventMutation, ApproveFoundationEventMutationVariables>(ApproveFoundationEventDocument, options);
      }
export type ApproveFoundationEventMutationHookResult = ReturnType<typeof useApproveFoundationEventMutation>;
export type ApproveFoundationEventMutationResult = Apollo.MutationResult<ApproveFoundationEventMutation>;
export type ApproveFoundationEventMutationOptions = Apollo.BaseMutationOptions<ApproveFoundationEventMutation, ApproveFoundationEventMutationVariables>;
export const CancelFoundationEventDocument = gql`
    mutation CancelFoundationEvent($input: CancelFoundationEventInput!) {
  cancelFoundationEvent(input: $input) {
    foundationEvent {
      ...FoundationEventProps
    }
  }
}
    ${FoundationEventPropsFragmentDoc}`;
export type CancelFoundationEventMutationFn = Apollo.MutationFunction<CancelFoundationEventMutation, CancelFoundationEventMutationVariables>;

/**
 * __useCancelFoundationEventMutation__
 *
 * To run a mutation, you first call `useCancelFoundationEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelFoundationEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelFoundationEventMutation, { data, loading, error }] = useCancelFoundationEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelFoundationEventMutation(baseOptions?: Apollo.MutationHookOptions<CancelFoundationEventMutation, CancelFoundationEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelFoundationEventMutation, CancelFoundationEventMutationVariables>(CancelFoundationEventDocument, options);
      }
export type CancelFoundationEventMutationHookResult = ReturnType<typeof useCancelFoundationEventMutation>;
export type CancelFoundationEventMutationResult = Apollo.MutationResult<CancelFoundationEventMutation>;
export type CancelFoundationEventMutationOptions = Apollo.BaseMutationOptions<CancelFoundationEventMutation, CancelFoundationEventMutationVariables>;
export const CloseFoundationEventDocument = gql`
    mutation CloseFoundationEvent($input: CloseFoundationEventInput!) {
  closeFoundationEvent(input: $input) {
    foundationEvent {
      ...FoundationEventProps
    }
    errors {
      __typename
      ... on Error {
        message
      }
    }
  }
}
    ${FoundationEventPropsFragmentDoc}`;
export type CloseFoundationEventMutationFn = Apollo.MutationFunction<CloseFoundationEventMutation, CloseFoundationEventMutationVariables>;

/**
 * __useCloseFoundationEventMutation__
 *
 * To run a mutation, you first call `useCloseFoundationEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseFoundationEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeFoundationEventMutation, { data, loading, error }] = useCloseFoundationEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseFoundationEventMutation(baseOptions?: Apollo.MutationHookOptions<CloseFoundationEventMutation, CloseFoundationEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseFoundationEventMutation, CloseFoundationEventMutationVariables>(CloseFoundationEventDocument, options);
      }
export type CloseFoundationEventMutationHookResult = ReturnType<typeof useCloseFoundationEventMutation>;
export type CloseFoundationEventMutationResult = Apollo.MutationResult<CloseFoundationEventMutation>;
export type CloseFoundationEventMutationOptions = Apollo.BaseMutationOptions<CloseFoundationEventMutation, CloseFoundationEventMutationVariables>;
export const CloseFoundationEventRegistrationDocument = gql`
    mutation CloseFoundationEventRegistration($input: CloseFoundationEventRegistrationInput!) {
  closeFoundationEventRegistration(input: $input) {
    foundationEvent {
      ...FoundationEventProps
    }
  }
}
    ${FoundationEventPropsFragmentDoc}`;
export type CloseFoundationEventRegistrationMutationFn = Apollo.MutationFunction<CloseFoundationEventRegistrationMutation, CloseFoundationEventRegistrationMutationVariables>;

/**
 * __useCloseFoundationEventRegistrationMutation__
 *
 * To run a mutation, you first call `useCloseFoundationEventRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseFoundationEventRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeFoundationEventRegistrationMutation, { data, loading, error }] = useCloseFoundationEventRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseFoundationEventRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<CloseFoundationEventRegistrationMutation, CloseFoundationEventRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseFoundationEventRegistrationMutation, CloseFoundationEventRegistrationMutationVariables>(CloseFoundationEventRegistrationDocument, options);
      }
export type CloseFoundationEventRegistrationMutationHookResult = ReturnType<typeof useCloseFoundationEventRegistrationMutation>;
export type CloseFoundationEventRegistrationMutationResult = Apollo.MutationResult<CloseFoundationEventRegistrationMutation>;
export type CloseFoundationEventRegistrationMutationOptions = Apollo.BaseMutationOptions<CloseFoundationEventRegistrationMutation, CloseFoundationEventRegistrationMutationVariables>;
export const OpenFoundationEventRegistrationDocument = gql`
    mutation OpenFoundationEventRegistration($input: OpenFoundationEventRegistrationInput!) {
  openFoundationEventRegistration(input: $input) {
    foundationEvent {
      ...FoundationEventProps
    }
  }
}
    ${FoundationEventPropsFragmentDoc}`;
export type OpenFoundationEventRegistrationMutationFn = Apollo.MutationFunction<OpenFoundationEventRegistrationMutation, OpenFoundationEventRegistrationMutationVariables>;

/**
 * __useOpenFoundationEventRegistrationMutation__
 *
 * To run a mutation, you first call `useOpenFoundationEventRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenFoundationEventRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openFoundationEventRegistrationMutation, { data, loading, error }] = useOpenFoundationEventRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOpenFoundationEventRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<OpenFoundationEventRegistrationMutation, OpenFoundationEventRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OpenFoundationEventRegistrationMutation, OpenFoundationEventRegistrationMutationVariables>(OpenFoundationEventRegistrationDocument, options);
      }
export type OpenFoundationEventRegistrationMutationHookResult = ReturnType<typeof useOpenFoundationEventRegistrationMutation>;
export type OpenFoundationEventRegistrationMutationResult = Apollo.MutationResult<OpenFoundationEventRegistrationMutation>;
export type OpenFoundationEventRegistrationMutationOptions = Apollo.BaseMutationOptions<OpenFoundationEventRegistrationMutation, OpenFoundationEventRegistrationMutationVariables>;
export const RequestFoundationEventApprovalDocument = gql`
    mutation RequestFoundationEventApproval($input: RequestFoundationEventApprovalInput!) {
  requestFoundationEventApproval(input: $input) {
    foundationEvent {
      ...FoundationEventProps
    }
  }
}
    ${FoundationEventPropsFragmentDoc}`;
export type RequestFoundationEventApprovalMutationFn = Apollo.MutationFunction<RequestFoundationEventApprovalMutation, RequestFoundationEventApprovalMutationVariables>;

/**
 * __useRequestFoundationEventApprovalMutation__
 *
 * To run a mutation, you first call `useRequestFoundationEventApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestFoundationEventApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestFoundationEventApprovalMutation, { data, loading, error }] = useRequestFoundationEventApprovalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestFoundationEventApprovalMutation(baseOptions?: Apollo.MutationHookOptions<RequestFoundationEventApprovalMutation, RequestFoundationEventApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestFoundationEventApprovalMutation, RequestFoundationEventApprovalMutationVariables>(RequestFoundationEventApprovalDocument, options);
      }
export type RequestFoundationEventApprovalMutationHookResult = ReturnType<typeof useRequestFoundationEventApprovalMutation>;
export type RequestFoundationEventApprovalMutationResult = Apollo.MutationResult<RequestFoundationEventApprovalMutation>;
export type RequestFoundationEventApprovalMutationOptions = Apollo.BaseMutationOptions<RequestFoundationEventApprovalMutation, RequestFoundationEventApprovalMutationVariables>;
export const TakeFoundationEventOwnershipDocument = gql`
    mutation TakeFoundationEventOwnership($input: TakeFoundationEventOwnershipInput!) {
  takeFoundationEventOwnership(input: $input) {
    foundationEvent {
      ...FoundationEventProps
    }
  }
}
    ${FoundationEventPropsFragmentDoc}`;
export type TakeFoundationEventOwnershipMutationFn = Apollo.MutationFunction<TakeFoundationEventOwnershipMutation, TakeFoundationEventOwnershipMutationVariables>;

/**
 * __useTakeFoundationEventOwnershipMutation__
 *
 * To run a mutation, you first call `useTakeFoundationEventOwnershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTakeFoundationEventOwnershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [takeFoundationEventOwnershipMutation, { data, loading, error }] = useTakeFoundationEventOwnershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTakeFoundationEventOwnershipMutation(baseOptions?: Apollo.MutationHookOptions<TakeFoundationEventOwnershipMutation, TakeFoundationEventOwnershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TakeFoundationEventOwnershipMutation, TakeFoundationEventOwnershipMutationVariables>(TakeFoundationEventOwnershipDocument, options);
      }
export type TakeFoundationEventOwnershipMutationHookResult = ReturnType<typeof useTakeFoundationEventOwnershipMutation>;
export type TakeFoundationEventOwnershipMutationResult = Apollo.MutationResult<TakeFoundationEventOwnershipMutation>;
export type TakeFoundationEventOwnershipMutationOptions = Apollo.BaseMutationOptions<TakeFoundationEventOwnershipMutation, TakeFoundationEventOwnershipMutationVariables>;
export const ApplicationsDocument = gql`
    query Applications($id: FoundationEventId!, $after: String, $before: String, $first: Int, $last: Int, $orderBy: [FoundationEventApplicationSortInput!], $where: FoundationEventApplicationFilterInput) {
  foundationEventById(id: $id) {
    id
    applications(after: $after, before: $before, first: $first, last: $last, order: $orderBy, where: $where) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
        __typename
      }
      totalCount
      nodes {
        id
        benefactor {
          id
          displayName
        }
        benefactorRating
        skill {
          id
          title
        }
        status
        attendance {
          checkedInAt
        }
        created {
          timestamp
          user {
            ...UserBaseProps
          }
        }
      }
    }
  }
}
    ${UserBasePropsFragmentDoc}`;

/**
 * __useApplicationsQuery__
 *
 * To run a query within a React component, call `useApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useApplicationsQuery(baseOptions: Apollo.QueryHookOptions<ApplicationsQuery, ApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationsQuery, ApplicationsQueryVariables>(ApplicationsDocument, options);
      }
export function useApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationsQuery, ApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationsQuery, ApplicationsQueryVariables>(ApplicationsDocument, options);
        }
export type ApplicationsQueryHookResult = ReturnType<typeof useApplicationsQuery>;
export type ApplicationsLazyQueryHookResult = ReturnType<typeof useApplicationsLazyQuery>;
export type ApplicationsQueryResult = Apollo.QueryResult<ApplicationsQuery, ApplicationsQueryVariables>;
export const ConvertFoundationEventApplicationToAttendanceDocument = gql`
    mutation ConvertFoundationEventApplicationToAttendance($input: ConvertFoundationEventApplicationToAttendanceInput!) {
  convertFoundationEventApplicationToAttendance(input: $input) {
    attendance {
      ... on FoundationEventAttendance {
        id
      }
    }
  }
}
    `;
export type ConvertFoundationEventApplicationToAttendanceMutationFn = Apollo.MutationFunction<ConvertFoundationEventApplicationToAttendanceMutation, ConvertFoundationEventApplicationToAttendanceMutationVariables>;

/**
 * __useConvertFoundationEventApplicationToAttendanceMutation__
 *
 * To run a mutation, you first call `useConvertFoundationEventApplicationToAttendanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertFoundationEventApplicationToAttendanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertFoundationEventApplicationToAttendanceMutation, { data, loading, error }] = useConvertFoundationEventApplicationToAttendanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConvertFoundationEventApplicationToAttendanceMutation(baseOptions?: Apollo.MutationHookOptions<ConvertFoundationEventApplicationToAttendanceMutation, ConvertFoundationEventApplicationToAttendanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConvertFoundationEventApplicationToAttendanceMutation, ConvertFoundationEventApplicationToAttendanceMutationVariables>(ConvertFoundationEventApplicationToAttendanceDocument, options);
      }
export type ConvertFoundationEventApplicationToAttendanceMutationHookResult = ReturnType<typeof useConvertFoundationEventApplicationToAttendanceMutation>;
export type ConvertFoundationEventApplicationToAttendanceMutationResult = Apollo.MutationResult<ConvertFoundationEventApplicationToAttendanceMutation>;
export type ConvertFoundationEventApplicationToAttendanceMutationOptions = Apollo.BaseMutationOptions<ConvertFoundationEventApplicationToAttendanceMutation, ConvertFoundationEventApplicationToAttendanceMutationVariables>;
export const DeclineFoundationEventApplicationDocument = gql`
    mutation DeclineFoundationEventApplication($input: DeclineFoundationEventApplicationInput!) {
  declineFoundationEventApplication(input: $input) {
    application {
      id
      benefactor {
        displayName
      }
      benefactorRating
      status
      updated {
        timestamp
        user {
          ...UserBaseProps
        }
      }
    }
  }
}
    ${UserBasePropsFragmentDoc}`;
export type DeclineFoundationEventApplicationMutationFn = Apollo.MutationFunction<DeclineFoundationEventApplicationMutation, DeclineFoundationEventApplicationMutationVariables>;

/**
 * __useDeclineFoundationEventApplicationMutation__
 *
 * To run a mutation, you first call `useDeclineFoundationEventApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineFoundationEventApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineFoundationEventApplicationMutation, { data, loading, error }] = useDeclineFoundationEventApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineFoundationEventApplicationMutation(baseOptions?: Apollo.MutationHookOptions<DeclineFoundationEventApplicationMutation, DeclineFoundationEventApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeclineFoundationEventApplicationMutation, DeclineFoundationEventApplicationMutationVariables>(DeclineFoundationEventApplicationDocument, options);
      }
export type DeclineFoundationEventApplicationMutationHookResult = ReturnType<typeof useDeclineFoundationEventApplicationMutation>;
export type DeclineFoundationEventApplicationMutationResult = Apollo.MutationResult<DeclineFoundationEventApplicationMutation>;
export type DeclineFoundationEventApplicationMutationOptions = Apollo.BaseMutationOptions<DeclineFoundationEventApplicationMutation, DeclineFoundationEventApplicationMutationVariables>;
export const SelectFoundationEventApplicationDocument = gql`
    mutation SelectFoundationEventApplication($input: SelectFoundationEventApplicationInput!) {
  selectFoundationEventApplication(input: $input) {
    application {
      id
      benefactor {
        displayName
      }
      benefactorRating
      status
      updated {
        timestamp
        user {
          ...UserBaseProps
        }
      }
    }
  }
}
    ${UserBasePropsFragmentDoc}`;
export type SelectFoundationEventApplicationMutationFn = Apollo.MutationFunction<SelectFoundationEventApplicationMutation, SelectFoundationEventApplicationMutationVariables>;

/**
 * __useSelectFoundationEventApplicationMutation__
 *
 * To run a mutation, you first call `useSelectFoundationEventApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectFoundationEventApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectFoundationEventApplicationMutation, { data, loading, error }] = useSelectFoundationEventApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelectFoundationEventApplicationMutation(baseOptions?: Apollo.MutationHookOptions<SelectFoundationEventApplicationMutation, SelectFoundationEventApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SelectFoundationEventApplicationMutation, SelectFoundationEventApplicationMutationVariables>(SelectFoundationEventApplicationDocument, options);
      }
export type SelectFoundationEventApplicationMutationHookResult = ReturnType<typeof useSelectFoundationEventApplicationMutation>;
export type SelectFoundationEventApplicationMutationResult = Apollo.MutationResult<SelectFoundationEventApplicationMutation>;
export type SelectFoundationEventApplicationMutationOptions = Apollo.BaseMutationOptions<SelectFoundationEventApplicationMutation, SelectFoundationEventApplicationMutationVariables>;
export const UpdateFoundationEventAttendanceRatingDocument = gql`
    mutation UpdateFoundationEventAttendanceRating($input: UpdateFoundationEventAttendanceRatingInput!) {
  updateFoundationEventAttendanceRating(input: $input) {
    attendance {
      id
      checkedInAt
      attendeeComment
      attendeeRating
      foundationComment
      foundationRating
      benefactor {
        id
        displayName
        phoneNumber
      }
    }
  }
}
    `;
export type UpdateFoundationEventAttendanceRatingMutationFn = Apollo.MutationFunction<UpdateFoundationEventAttendanceRatingMutation, UpdateFoundationEventAttendanceRatingMutationVariables>;

/**
 * __useUpdateFoundationEventAttendanceRatingMutation__
 *
 * To run a mutation, you first call `useUpdateFoundationEventAttendanceRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFoundationEventAttendanceRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFoundationEventAttendanceRatingMutation, { data, loading, error }] = useUpdateFoundationEventAttendanceRatingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFoundationEventAttendanceRatingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFoundationEventAttendanceRatingMutation, UpdateFoundationEventAttendanceRatingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFoundationEventAttendanceRatingMutation, UpdateFoundationEventAttendanceRatingMutationVariables>(UpdateFoundationEventAttendanceRatingDocument, options);
      }
export type UpdateFoundationEventAttendanceRatingMutationHookResult = ReturnType<typeof useUpdateFoundationEventAttendanceRatingMutation>;
export type UpdateFoundationEventAttendanceRatingMutationResult = Apollo.MutationResult<UpdateFoundationEventAttendanceRatingMutation>;
export type UpdateFoundationEventAttendanceRatingMutationOptions = Apollo.BaseMutationOptions<UpdateFoundationEventAttendanceRatingMutation, UpdateFoundationEventAttendanceRatingMutationVariables>;
export const AttendanceDocument = gql`
    query Attendance($id: FoundationEventId!, $benefactorSearch: String, $after: String, $before: String, $first: Int, $last: Int, $orderBy: [FoundationEventAttendanceSortInput!], $where: FoundationEventAttendanceFilterInput) {
  foundationEventById(id: $id) {
    id
    attendances(benefactorSearch: $benefactorSearch, after: $after, before: $before, first: $first, last: $last, order: $orderBy, where: $where) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
        __typename
      }
      totalCount
      nodes {
        id
        checkedInAt
        attendeeComment
        attendeeRating
        foundationComment
        foundationRating
        benefactor {
          id
          displayName
          phoneNumber
        }
      }
    }
  }
}
    `;

/**
 * __useAttendanceQuery__
 *
 * To run a query within a React component, call `useAttendanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendanceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      benefactorSearch: // value for 'benefactorSearch'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAttendanceQuery(baseOptions: Apollo.QueryHookOptions<AttendanceQuery, AttendanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AttendanceQuery, AttendanceQueryVariables>(AttendanceDocument, options);
      }
export function useAttendanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AttendanceQuery, AttendanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AttendanceQuery, AttendanceQueryVariables>(AttendanceDocument, options);
        }
export type AttendanceQueryHookResult = ReturnType<typeof useAttendanceQuery>;
export type AttendanceLazyQueryHookResult = ReturnType<typeof useAttendanceLazyQuery>;
export type AttendanceQueryResult = Apollo.QueryResult<AttendanceQuery, AttendanceQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    user {
      ... on FoundationRepresentative {
        foundation {
          id
        }
      }
      __typename
      id
      displayName
    }
    permissions {
      ...PermissionsProps
    }
  }
}
    ${PermissionsPropsFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const ChangeUserPasswordDocument = gql`
    mutation ChangeUserPassword($input: ChangeUserPasswordInput!) {
  changeUserPassword(input: $input) {
    success
    errors {
      __typename
      ... on Error {
        message
      }
    }
  }
}
    `;
export type ChangeUserPasswordMutationFn = Apollo.MutationFunction<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>;

/**
 * __useChangeUserPasswordMutation__
 *
 * To run a mutation, you first call `useChangeUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserPasswordMutation, { data, loading, error }] = useChangeUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>(ChangeUserPasswordDocument, options);
      }
export type ChangeUserPasswordMutationHookResult = ReturnType<typeof useChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationResult = Apollo.MutationResult<ChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationOptions = Apollo.BaseMutationOptions<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>;
export const CreateModeratorDocument = gql`
    mutation CreateModerator($input: CreateModeratorInput!) {
  createModerator(input: $input) {
    user {
      ...UserProps
    }
    errors {
      __typename
      ... on Error {
        message
      }
    }
  }
}
    ${UserPropsFragmentDoc}`;
export type CreateModeratorMutationFn = Apollo.MutationFunction<CreateModeratorMutation, CreateModeratorMutationVariables>;

/**
 * __useCreateModeratorMutation__
 *
 * To run a mutation, you first call `useCreateModeratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModeratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModeratorMutation, { data, loading, error }] = useCreateModeratorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateModeratorMutation(baseOptions?: Apollo.MutationHookOptions<CreateModeratorMutation, CreateModeratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateModeratorMutation, CreateModeratorMutationVariables>(CreateModeratorDocument, options);
      }
export type CreateModeratorMutationHookResult = ReturnType<typeof useCreateModeratorMutation>;
export type CreateModeratorMutationResult = Apollo.MutationResult<CreateModeratorMutation>;
export type CreateModeratorMutationOptions = Apollo.BaseMutationOptions<CreateModeratorMutation, CreateModeratorMutationVariables>;
export const CreateSystemAdministratorDocument = gql`
    mutation CreateSystemAdministrator($input: CreateSystemAdministratorInput!) {
  createSystemAdministrator(input: $input) {
    user {
      ...UserProps
    }
    errors {
      __typename
      ... on Error {
        message
      }
    }
  }
}
    ${UserPropsFragmentDoc}`;
export type CreateSystemAdministratorMutationFn = Apollo.MutationFunction<CreateSystemAdministratorMutation, CreateSystemAdministratorMutationVariables>;

/**
 * __useCreateSystemAdministratorMutation__
 *
 * To run a mutation, you first call `useCreateSystemAdministratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSystemAdministratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSystemAdministratorMutation, { data, loading, error }] = useCreateSystemAdministratorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSystemAdministratorMutation(baseOptions?: Apollo.MutationHookOptions<CreateSystemAdministratorMutation, CreateSystemAdministratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSystemAdministratorMutation, CreateSystemAdministratorMutationVariables>(CreateSystemAdministratorDocument, options);
      }
export type CreateSystemAdministratorMutationHookResult = ReturnType<typeof useCreateSystemAdministratorMutation>;
export type CreateSystemAdministratorMutationResult = Apollo.MutationResult<CreateSystemAdministratorMutation>;
export type CreateSystemAdministratorMutationOptions = Apollo.BaseMutationOptions<CreateSystemAdministratorMutation, CreateSystemAdministratorMutationVariables>;
export const UpdateFoundationRepresentativeDocument = gql`
    mutation UpdateFoundationRepresentative($input: UpdateFoundationRepresentativeInput!) {
  updateFoundationRepresentative(input: $input) {
    user {
      ...UserProps
    }
    errors {
      __typename
      ... on Error {
        message
      }
    }
  }
}
    ${UserPropsFragmentDoc}`;
export type UpdateFoundationRepresentativeMutationFn = Apollo.MutationFunction<UpdateFoundationRepresentativeMutation, UpdateFoundationRepresentativeMutationVariables>;

/**
 * __useUpdateFoundationRepresentativeMutation__
 *
 * To run a mutation, you first call `useUpdateFoundationRepresentativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFoundationRepresentativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFoundationRepresentativeMutation, { data, loading, error }] = useUpdateFoundationRepresentativeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFoundationRepresentativeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFoundationRepresentativeMutation, UpdateFoundationRepresentativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFoundationRepresentativeMutation, UpdateFoundationRepresentativeMutationVariables>(UpdateFoundationRepresentativeDocument, options);
      }
export type UpdateFoundationRepresentativeMutationHookResult = ReturnType<typeof useUpdateFoundationRepresentativeMutation>;
export type UpdateFoundationRepresentativeMutationResult = Apollo.MutationResult<UpdateFoundationRepresentativeMutation>;
export type UpdateFoundationRepresentativeMutationOptions = Apollo.BaseMutationOptions<UpdateFoundationRepresentativeMutation, UpdateFoundationRepresentativeMutationVariables>;
export const UpdateModeratorDocument = gql`
    mutation UpdateModerator($input: UpdateModeratorInput!) {
  updateModerator(input: $input) {
    user {
      ...UserProps
    }
    errors {
      __typename
      ... on Error {
        message
      }
    }
  }
}
    ${UserPropsFragmentDoc}`;
export type UpdateModeratorMutationFn = Apollo.MutationFunction<UpdateModeratorMutation, UpdateModeratorMutationVariables>;

/**
 * __useUpdateModeratorMutation__
 *
 * To run a mutation, you first call `useUpdateModeratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModeratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModeratorMutation, { data, loading, error }] = useUpdateModeratorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateModeratorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModeratorMutation, UpdateModeratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModeratorMutation, UpdateModeratorMutationVariables>(UpdateModeratorDocument, options);
      }
export type UpdateModeratorMutationHookResult = ReturnType<typeof useUpdateModeratorMutation>;
export type UpdateModeratorMutationResult = Apollo.MutationResult<UpdateModeratorMutation>;
export type UpdateModeratorMutationOptions = Apollo.BaseMutationOptions<UpdateModeratorMutation, UpdateModeratorMutationVariables>;
export const UpdateSystemAdministratorDocument = gql`
    mutation UpdateSystemAdministrator($input: UpdateSystemAdministratorInput!) {
  updateSystemAdministrator(input: $input) {
    user {
      ...UserProps
    }
    errors {
      __typename
      ... on Error {
        message
      }
    }
  }
}
    ${UserPropsFragmentDoc}`;
export type UpdateSystemAdministratorMutationFn = Apollo.MutationFunction<UpdateSystemAdministratorMutation, UpdateSystemAdministratorMutationVariables>;

/**
 * __useUpdateSystemAdministratorMutation__
 *
 * To run a mutation, you first call `useUpdateSystemAdministratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSystemAdministratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSystemAdministratorMutation, { data, loading, error }] = useUpdateSystemAdministratorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSystemAdministratorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSystemAdministratorMutation, UpdateSystemAdministratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSystemAdministratorMutation, UpdateSystemAdministratorMutationVariables>(UpdateSystemAdministratorDocument, options);
      }
export type UpdateSystemAdministratorMutationHookResult = ReturnType<typeof useUpdateSystemAdministratorMutation>;
export type UpdateSystemAdministratorMutationResult = Apollo.MutationResult<UpdateSystemAdministratorMutation>;
export type UpdateSystemAdministratorMutationOptions = Apollo.BaseMutationOptions<UpdateSystemAdministratorMutation, UpdateSystemAdministratorMutationVariables>;
export const AdministratorsDocument = gql`
    query Administrators($first: Int, $after: String, $before: String, $orderBy: [AdministratorsSortInput!], $filter: AdministratorsFilterInput!, $last: Int) {
  administrators(first: $first, after: $after, before: $before, order: $orderBy, filter: $filter, last: $last) {
    pageInfo {
      hasNextPage
      endCursor
      startCursor
      hasPreviousPage
    }
    totalCount
    nodes {
      ... on FoundationRepresentative {
        foundation {
          name
        }
      }
      id
      disabled
      displayName
      email
      emailConfirmed
      phoneNumber
      createdOn
    }
  }
}
    `;

/**
 * __useAdministratorsQuery__
 *
 * To run a query within a React component, call `useAdministratorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdministratorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdministratorsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useAdministratorsQuery(baseOptions: Apollo.QueryHookOptions<AdministratorsQuery, AdministratorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdministratorsQuery, AdministratorsQueryVariables>(AdministratorsDocument, options);
      }
export function useAdministratorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdministratorsQuery, AdministratorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdministratorsQuery, AdministratorsQueryVariables>(AdministratorsDocument, options);
        }
export type AdministratorsQueryHookResult = ReturnType<typeof useAdministratorsQuery>;
export type AdministratorsLazyQueryHookResult = ReturnType<typeof useAdministratorsLazyQuery>;
export type AdministratorsQueryResult = Apollo.QueryResult<AdministratorsQuery, AdministratorsQueryVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    success
    errors {
      __typename
      ... on Error {
        message
      }
    }
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const EnableOrDisableUserDocument = gql`
    mutation EnableOrDisableUser($input: EnableOrDisableUserInput!) {
  enableOrDisableUser(input: $input) {
    user {
      ...UserBaseProps
    }
    errors {
      __typename
      ... on Error {
        message
      }
    }
  }
}
    ${UserBasePropsFragmentDoc}`;
export type EnableOrDisableUserMutationFn = Apollo.MutationFunction<EnableOrDisableUserMutation, EnableOrDisableUserMutationVariables>;

/**
 * __useEnableOrDisableUserMutation__
 *
 * To run a mutation, you first call `useEnableOrDisableUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableOrDisableUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableOrDisableUserMutation, { data, loading, error }] = useEnableOrDisableUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableOrDisableUserMutation(baseOptions?: Apollo.MutationHookOptions<EnableOrDisableUserMutation, EnableOrDisableUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableOrDisableUserMutation, EnableOrDisableUserMutationVariables>(EnableOrDisableUserDocument, options);
      }
export type EnableOrDisableUserMutationHookResult = ReturnType<typeof useEnableOrDisableUserMutation>;
export type EnableOrDisableUserMutationResult = Apollo.MutationResult<EnableOrDisableUserMutation>;
export type EnableOrDisableUserMutationOptions = Apollo.BaseMutationOptions<EnableOrDisableUserMutation, EnableOrDisableUserMutationVariables>;
export const UserByIdDocument = gql`
    query UserById($id: ID!) {
  userById(id: $id) {
    ...UserProps
  }
}
    ${UserPropsFragmentDoc}`;

/**
 * __useUserByIdQuery__
 *
 * To run a query within a React component, call `useUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserByIdQuery(baseOptions: Apollo.QueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
      }
export function useUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
        }
export type UserByIdQueryHookResult = ReturnType<typeof useUserByIdQuery>;
export type UserByIdLazyQueryHookResult = ReturnType<typeof useUserByIdLazyQuery>;
export type UserByIdQueryResult = Apollo.QueryResult<UserByIdQuery, UserByIdQueryVariables>;
export const AddBroadcastNotificationDocument = gql`
    mutation addBroadcastNotification($input: AddBroadcastNotificationInput!) {
  addBroadcastNotification(input: $input) {
    broadcastNotification {
      ...BroadcastNotificationProps
    }
  }
}
    ${BroadcastNotificationPropsFragmentDoc}`;
export type AddBroadcastNotificationMutationFn = Apollo.MutationFunction<AddBroadcastNotificationMutation, AddBroadcastNotificationMutationVariables>;

/**
 * __useAddBroadcastNotificationMutation__
 *
 * To run a mutation, you first call `useAddBroadcastNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBroadcastNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBroadcastNotificationMutation, { data, loading, error }] = useAddBroadcastNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBroadcastNotificationMutation(baseOptions?: Apollo.MutationHookOptions<AddBroadcastNotificationMutation, AddBroadcastNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBroadcastNotificationMutation, AddBroadcastNotificationMutationVariables>(AddBroadcastNotificationDocument, options);
      }
export type AddBroadcastNotificationMutationHookResult = ReturnType<typeof useAddBroadcastNotificationMutation>;
export type AddBroadcastNotificationMutationResult = Apollo.MutationResult<AddBroadcastNotificationMutation>;
export type AddBroadcastNotificationMutationOptions = Apollo.BaseMutationOptions<AddBroadcastNotificationMutation, AddBroadcastNotificationMutationVariables>;
export const BroadcastNotificationsDocument = gql`
    query broadcastNotifications($first: Int, $after: String, $before: String, $last: Int) {
  broadcastNotifications(first: $first, after: $after, before: $before, last: $last, order: {created: {timestamp: DESC}}) {
    pageInfo {
      hasNextPage
      endCursor
      startCursor
      hasPreviousPage
    }
    totalCount
    nodes {
      ...BroadcastNotificationProps
    }
  }
}
    ${BroadcastNotificationPropsFragmentDoc}`;

/**
 * __useBroadcastNotificationsQuery__
 *
 * To run a query within a React component, call `useBroadcastNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBroadcastNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBroadcastNotificationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useBroadcastNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<BroadcastNotificationsQuery, BroadcastNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BroadcastNotificationsQuery, BroadcastNotificationsQueryVariables>(BroadcastNotificationsDocument, options);
      }
export function useBroadcastNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BroadcastNotificationsQuery, BroadcastNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BroadcastNotificationsQuery, BroadcastNotificationsQueryVariables>(BroadcastNotificationsDocument, options);
        }
export type BroadcastNotificationsQueryHookResult = ReturnType<typeof useBroadcastNotificationsQuery>;
export type BroadcastNotificationsLazyQueryHookResult = ReturnType<typeof useBroadcastNotificationsLazyQuery>;
export type BroadcastNotificationsQueryResult = Apollo.QueryResult<BroadcastNotificationsQuery, BroadcastNotificationsQueryVariables>;
export const UpdateProjectExpenseDocumentsDocument = gql`
    mutation updateProjectExpenseDocuments($input: UpdateProjectExpenseDocumentsInput!) {
  updateProjectExpenseDocuments(input: $input) {
    projectExpense {
      documents {
        ...DocumentFileProps
      }
    }
  }
}
    ${DocumentFilePropsFragmentDoc}`;
export type UpdateProjectExpenseDocumentsMutationFn = Apollo.MutationFunction<UpdateProjectExpenseDocumentsMutation, UpdateProjectExpenseDocumentsMutationVariables>;

/**
 * __useUpdateProjectExpenseDocumentsMutation__
 *
 * To run a mutation, you first call `useUpdateProjectExpenseDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectExpenseDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectExpenseDocumentsMutation, { data, loading, error }] = useUpdateProjectExpenseDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectExpenseDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectExpenseDocumentsMutation, UpdateProjectExpenseDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectExpenseDocumentsMutation, UpdateProjectExpenseDocumentsMutationVariables>(UpdateProjectExpenseDocumentsDocument, options);
      }
export type UpdateProjectExpenseDocumentsMutationHookResult = ReturnType<typeof useUpdateProjectExpenseDocumentsMutation>;
export type UpdateProjectExpenseDocumentsMutationResult = Apollo.MutationResult<UpdateProjectExpenseDocumentsMutation>;
export type UpdateProjectExpenseDocumentsMutationOptions = Apollo.BaseMutationOptions<UpdateProjectExpenseDocumentsMutation, UpdateProjectExpenseDocumentsMutationVariables>;
export const AddProjectExpenseDocument = gql`
    mutation addProjectExpense($input: AddProjectExpenseInput!) {
  addProjectExpense(input: $input) {
    projectExpense {
      id
      title
    }
  }
}
    `;
export type AddProjectExpenseMutationFn = Apollo.MutationFunction<AddProjectExpenseMutation, AddProjectExpenseMutationVariables>;

/**
 * __useAddProjectExpenseMutation__
 *
 * To run a mutation, you first call `useAddProjectExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectExpenseMutation, { data, loading, error }] = useAddProjectExpenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddProjectExpenseMutation(baseOptions?: Apollo.MutationHookOptions<AddProjectExpenseMutation, AddProjectExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddProjectExpenseMutation, AddProjectExpenseMutationVariables>(AddProjectExpenseDocument, options);
      }
export type AddProjectExpenseMutationHookResult = ReturnType<typeof useAddProjectExpenseMutation>;
export type AddProjectExpenseMutationResult = Apollo.MutationResult<AddProjectExpenseMutation>;
export type AddProjectExpenseMutationOptions = Apollo.BaseMutationOptions<AddProjectExpenseMutation, AddProjectExpenseMutationVariables>;
export const UpdateProjectRequirementsDocument = gql`
    mutation updateProjectRequirements($input: UpdateProjectRequirementsInput!) {
  updateProjectRequirements(input: $input) {
    success
  }
}
    `;
export type UpdateProjectRequirementsMutationFn = Apollo.MutationFunction<UpdateProjectRequirementsMutation, UpdateProjectRequirementsMutationVariables>;

/**
 * __useUpdateProjectRequirementsMutation__
 *
 * To run a mutation, you first call `useUpdateProjectRequirementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectRequirementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectRequirementsMutation, { data, loading, error }] = useUpdateProjectRequirementsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectRequirementsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectRequirementsMutation, UpdateProjectRequirementsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectRequirementsMutation, UpdateProjectRequirementsMutationVariables>(UpdateProjectRequirementsDocument, options);
      }
export type UpdateProjectRequirementsMutationHookResult = ReturnType<typeof useUpdateProjectRequirementsMutation>;
export type UpdateProjectRequirementsMutationResult = Apollo.MutationResult<UpdateProjectRequirementsMutation>;
export type UpdateProjectRequirementsMutationOptions = Apollo.BaseMutationOptions<UpdateProjectRequirementsMutation, UpdateProjectRequirementsMutationVariables>;
export const ProjectExpensesDocument = gql`
    query projectExpenses($after: String, $before: String, $first: Int, $last: Int) {
  project {
    expenses(after: $after, before: $before, first: $first, last: $last, order: {referenceDate: DESC}) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      totalCount
      nodes {
        ...ProjectExpenseProps
      }
    }
  }
}
    ${ProjectExpensePropsFragmentDoc}`;

/**
 * __useProjectExpensesQuery__
 *
 * To run a query within a React component, call `useProjectExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectExpensesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useProjectExpensesQuery(baseOptions?: Apollo.QueryHookOptions<ProjectExpensesQuery, ProjectExpensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectExpensesQuery, ProjectExpensesQueryVariables>(ProjectExpensesDocument, options);
      }
export function useProjectExpensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectExpensesQuery, ProjectExpensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectExpensesQuery, ProjectExpensesQueryVariables>(ProjectExpensesDocument, options);
        }
export type ProjectExpensesQueryHookResult = ReturnType<typeof useProjectExpensesQuery>;
export type ProjectExpensesLazyQueryHookResult = ReturnType<typeof useProjectExpensesLazyQuery>;
export type ProjectExpensesQueryResult = Apollo.QueryResult<ProjectExpensesQuery, ProjectExpensesQueryVariables>;
export const ProjectDocument = gql`
    query project {
  project {
    requirements {
      remainingDuration
      estimatedMonthlyExpensesAmount
    }
    mainAccount {
      id
      amount
    }
    wardsReserveAccount {
      id
      amount
    }
  }
  amounts {
    amountOfBenefactorDeposits
  }
}
    `;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectQuery(baseOptions?: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    success
    errors {
      __typename
      ... on Error {
        message
      }
    }
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const CreateSpecialProjectDocument = gql`
    mutation createSpecialProject($input: CreateSpecialProjectInput!) {
  createSpecialProject(input: $input) {
    specialProject {
      id
      title
      description
      start
      end
      campaign
      images {
        ...PhotoFileProps
      }
    }
  }
}
    ${PhotoFilePropsFragmentDoc}`;
export type CreateSpecialProjectMutationFn = Apollo.MutationFunction<CreateSpecialProjectMutation, CreateSpecialProjectMutationVariables>;

/**
 * __useCreateSpecialProjectMutation__
 *
 * To run a mutation, you first call `useCreateSpecialProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpecialProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSpecialProjectMutation, { data, loading, error }] = useCreateSpecialProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSpecialProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateSpecialProjectMutation, CreateSpecialProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSpecialProjectMutation, CreateSpecialProjectMutationVariables>(CreateSpecialProjectDocument, options);
      }
export type CreateSpecialProjectMutationHookResult = ReturnType<typeof useCreateSpecialProjectMutation>;
export type CreateSpecialProjectMutationResult = Apollo.MutationResult<CreateSpecialProjectMutation>;
export type CreateSpecialProjectMutationOptions = Apollo.BaseMutationOptions<CreateSpecialProjectMutation, CreateSpecialProjectMutationVariables>;
export const UpdateSpecialProjectDocument = gql`
    mutation updateSpecialProject($input: UpdateSpecialProjectInput!) {
  updateSpecialProject(input: $input) {
    errors {
      ... on RequiredAmountIsLessThanCollectedAmountError {
        message
      }
    }
    specialProject {
      id
      title
      description
      start
      end
      campaign
      images {
        ...PhotoFileProps
      }
    }
  }
}
    ${PhotoFilePropsFragmentDoc}`;
export type UpdateSpecialProjectMutationFn = Apollo.MutationFunction<UpdateSpecialProjectMutation, UpdateSpecialProjectMutationVariables>;

/**
 * __useUpdateSpecialProjectMutation__
 *
 * To run a mutation, you first call `useUpdateSpecialProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpecialProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpecialProjectMutation, { data, loading, error }] = useUpdateSpecialProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSpecialProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSpecialProjectMutation, UpdateSpecialProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSpecialProjectMutation, UpdateSpecialProjectMutationVariables>(UpdateSpecialProjectDocument, options);
      }
export type UpdateSpecialProjectMutationHookResult = ReturnType<typeof useUpdateSpecialProjectMutation>;
export type UpdateSpecialProjectMutationResult = Apollo.MutationResult<UpdateSpecialProjectMutation>;
export type UpdateSpecialProjectMutationOptions = Apollo.BaseMutationOptions<UpdateSpecialProjectMutation, UpdateSpecialProjectMutationVariables>;
export const UpdateSpecialProjectsVisibilityOrderDocument = gql`
    mutation updateSpecialProjectsVisibilityOrder($input: UpdateSpecialProjectsVisibilityOrderInput!) {
  updateSpecialProjectsVisibilityOrder(input: $input) {
    success
  }
}
    `;
export type UpdateSpecialProjectsVisibilityOrderMutationFn = Apollo.MutationFunction<UpdateSpecialProjectsVisibilityOrderMutation, UpdateSpecialProjectsVisibilityOrderMutationVariables>;

/**
 * __useUpdateSpecialProjectsVisibilityOrderMutation__
 *
 * To run a mutation, you first call `useUpdateSpecialProjectsVisibilityOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpecialProjectsVisibilityOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpecialProjectsVisibilityOrderMutation, { data, loading, error }] = useUpdateSpecialProjectsVisibilityOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSpecialProjectsVisibilityOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSpecialProjectsVisibilityOrderMutation, UpdateSpecialProjectsVisibilityOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSpecialProjectsVisibilityOrderMutation, UpdateSpecialProjectsVisibilityOrderMutationVariables>(UpdateSpecialProjectsVisibilityOrderDocument, options);
      }
export type UpdateSpecialProjectsVisibilityOrderMutationHookResult = ReturnType<typeof useUpdateSpecialProjectsVisibilityOrderMutation>;
export type UpdateSpecialProjectsVisibilityOrderMutationResult = Apollo.MutationResult<UpdateSpecialProjectsVisibilityOrderMutation>;
export type UpdateSpecialProjectsVisibilityOrderMutationOptions = Apollo.BaseMutationOptions<UpdateSpecialProjectsVisibilityOrderMutation, UpdateSpecialProjectsVisibilityOrderMutationVariables>;
export const SpecialProjectByIdDocument = gql`
    query specialProjectById($id: SpecialProjectId!) {
  specialProjectById(id: $id) {
    ...SpecialProjectsProps
  }
}
    ${SpecialProjectsPropsFragmentDoc}`;

/**
 * __useSpecialProjectByIdQuery__
 *
 * To run a query within a React component, call `useSpecialProjectByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecialProjectByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecialProjectByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSpecialProjectByIdQuery(baseOptions: Apollo.QueryHookOptions<SpecialProjectByIdQuery, SpecialProjectByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpecialProjectByIdQuery, SpecialProjectByIdQueryVariables>(SpecialProjectByIdDocument, options);
      }
export function useSpecialProjectByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpecialProjectByIdQuery, SpecialProjectByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpecialProjectByIdQuery, SpecialProjectByIdQueryVariables>(SpecialProjectByIdDocument, options);
        }
export type SpecialProjectByIdQueryHookResult = ReturnType<typeof useSpecialProjectByIdQuery>;
export type SpecialProjectByIdLazyQueryHookResult = ReturnType<typeof useSpecialProjectByIdLazyQuery>;
export type SpecialProjectByIdQueryResult = Apollo.QueryResult<SpecialProjectByIdQuery, SpecialProjectByIdQueryVariables>;
export const CancelSpecialProjectDocument = gql`
    mutation cancelSpecialProject($input: CancelSpecialProjectInput!) {
  cancelSpecialProject(input: $input) {
    specialProject {
      id
      cancelled {
        ...AuditMarkerProps
      }
    }
  }
}
    ${AuditMarkerPropsFragmentDoc}`;
export type CancelSpecialProjectMutationFn = Apollo.MutationFunction<CancelSpecialProjectMutation, CancelSpecialProjectMutationVariables>;

/**
 * __useCancelSpecialProjectMutation__
 *
 * To run a mutation, you first call `useCancelSpecialProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSpecialProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSpecialProjectMutation, { data, loading, error }] = useCancelSpecialProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSpecialProjectMutation(baseOptions?: Apollo.MutationHookOptions<CancelSpecialProjectMutation, CancelSpecialProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelSpecialProjectMutation, CancelSpecialProjectMutationVariables>(CancelSpecialProjectDocument, options);
      }
export type CancelSpecialProjectMutationHookResult = ReturnType<typeof useCancelSpecialProjectMutation>;
export type CancelSpecialProjectMutationResult = Apollo.MutationResult<CancelSpecialProjectMutation>;
export type CancelSpecialProjectMutationOptions = Apollo.BaseMutationOptions<CancelSpecialProjectMutation, CancelSpecialProjectMutationVariables>;
export const CloseSpecialProjectDocument = gql`
    mutation closeSpecialProject($input: CloseSpecialProjectInput!) {
  closeSpecialProject(input: $input) {
    specialProject {
      id
      closed {
        ...AuditMarkerProps
      }
    }
  }
}
    ${AuditMarkerPropsFragmentDoc}`;
export type CloseSpecialProjectMutationFn = Apollo.MutationFunction<CloseSpecialProjectMutation, CloseSpecialProjectMutationVariables>;

/**
 * __useCloseSpecialProjectMutation__
 *
 * To run a mutation, you first call `useCloseSpecialProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseSpecialProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeSpecialProjectMutation, { data, loading, error }] = useCloseSpecialProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseSpecialProjectMutation(baseOptions?: Apollo.MutationHookOptions<CloseSpecialProjectMutation, CloseSpecialProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseSpecialProjectMutation, CloseSpecialProjectMutationVariables>(CloseSpecialProjectDocument, options);
      }
export type CloseSpecialProjectMutationHookResult = ReturnType<typeof useCloseSpecialProjectMutation>;
export type CloseSpecialProjectMutationResult = Apollo.MutationResult<CloseSpecialProjectMutation>;
export type CloseSpecialProjectMutationOptions = Apollo.BaseMutationOptions<CloseSpecialProjectMutation, CloseSpecialProjectMutationVariables>;
export const PublishSpecialProjectDocument = gql`
    mutation publishSpecialProject($input: PublishSpecialProjectInput!) {
  publishSpecialProject(input: $input) {
    specialProject {
      id
      published {
        ...AuditMarkerProps
      }
    }
  }
}
    ${AuditMarkerPropsFragmentDoc}`;
export type PublishSpecialProjectMutationFn = Apollo.MutationFunction<PublishSpecialProjectMutation, PublishSpecialProjectMutationVariables>;

/**
 * __usePublishSpecialProjectMutation__
 *
 * To run a mutation, you first call `usePublishSpecialProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishSpecialProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishSpecialProjectMutation, { data, loading, error }] = usePublishSpecialProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishSpecialProjectMutation(baseOptions?: Apollo.MutationHookOptions<PublishSpecialProjectMutation, PublishSpecialProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishSpecialProjectMutation, PublishSpecialProjectMutationVariables>(PublishSpecialProjectDocument, options);
      }
export type PublishSpecialProjectMutationHookResult = ReturnType<typeof usePublishSpecialProjectMutation>;
export type PublishSpecialProjectMutationResult = Apollo.MutationResult<PublishSpecialProjectMutation>;
export type PublishSpecialProjectMutationOptions = Apollo.BaseMutationOptions<PublishSpecialProjectMutation, PublishSpecialProjectMutationVariables>;
export const SendReportSpecialProjectReportDocument = gql`
    mutation sendReportSpecialProjectReport($input: SendSpecialProjectReportInput!) {
  sendSpecialProjectReport(input: $input) {
    specialProject {
      id
      reportSent {
        ...AuditMarkerProps
      }
    }
  }
}
    ${AuditMarkerPropsFragmentDoc}`;
export type SendReportSpecialProjectReportMutationFn = Apollo.MutationFunction<SendReportSpecialProjectReportMutation, SendReportSpecialProjectReportMutationVariables>;

/**
 * __useSendReportSpecialProjectReportMutation__
 *
 * To run a mutation, you first call `useSendReportSpecialProjectReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendReportSpecialProjectReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendReportSpecialProjectReportMutation, { data, loading, error }] = useSendReportSpecialProjectReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendReportSpecialProjectReportMutation(baseOptions?: Apollo.MutationHookOptions<SendReportSpecialProjectReportMutation, SendReportSpecialProjectReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendReportSpecialProjectReportMutation, SendReportSpecialProjectReportMutationVariables>(SendReportSpecialProjectReportDocument, options);
      }
export type SendReportSpecialProjectReportMutationHookResult = ReturnType<typeof useSendReportSpecialProjectReportMutation>;
export type SendReportSpecialProjectReportMutationResult = Apollo.MutationResult<SendReportSpecialProjectReportMutation>;
export type SendReportSpecialProjectReportMutationOptions = Apollo.BaseMutationOptions<SendReportSpecialProjectReportMutation, SendReportSpecialProjectReportMutationVariables>;
export const UnpublishSpecialProjectDocument = gql`
    mutation unpublishSpecialProject($input: UnpublishSpecialProjectInput!) {
  unpublishSpecialProject(input: $input) {
    specialProject {
      id
      published {
        ...AuditMarkerProps
      }
    }
  }
}
    ${AuditMarkerPropsFragmentDoc}`;
export type UnpublishSpecialProjectMutationFn = Apollo.MutationFunction<UnpublishSpecialProjectMutation, UnpublishSpecialProjectMutationVariables>;

/**
 * __useUnpublishSpecialProjectMutation__
 *
 * To run a mutation, you first call `useUnpublishSpecialProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishSpecialProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishSpecialProjectMutation, { data, loading, error }] = useUnpublishSpecialProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishSpecialProjectMutation(baseOptions?: Apollo.MutationHookOptions<UnpublishSpecialProjectMutation, UnpublishSpecialProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnpublishSpecialProjectMutation, UnpublishSpecialProjectMutationVariables>(UnpublishSpecialProjectDocument, options);
      }
export type UnpublishSpecialProjectMutationHookResult = ReturnType<typeof useUnpublishSpecialProjectMutation>;
export type UnpublishSpecialProjectMutationResult = Apollo.MutationResult<UnpublishSpecialProjectMutation>;
export type UnpublishSpecialProjectMutationOptions = Apollo.BaseMutationOptions<UnpublishSpecialProjectMutation, UnpublishSpecialProjectMutationVariables>;
export const UpdateSpecialProjectDocumentsDocument = gql`
    mutation updateSpecialProjectDocuments($input: UpdateSpecialProjectDocumentsInput!) {
  updateSpecialProjectDocuments(input: $input) {
    specialProject {
      id
      documents {
        ...DocumentFileProps
      }
    }
  }
}
    ${DocumentFilePropsFragmentDoc}`;
export type UpdateSpecialProjectDocumentsMutationFn = Apollo.MutationFunction<UpdateSpecialProjectDocumentsMutation, UpdateSpecialProjectDocumentsMutationVariables>;

/**
 * __useUpdateSpecialProjectDocumentsMutation__
 *
 * To run a mutation, you first call `useUpdateSpecialProjectDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpecialProjectDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpecialProjectDocumentsMutation, { data, loading, error }] = useUpdateSpecialProjectDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSpecialProjectDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSpecialProjectDocumentsMutation, UpdateSpecialProjectDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSpecialProjectDocumentsMutation, UpdateSpecialProjectDocumentsMutationVariables>(UpdateSpecialProjectDocumentsDocument, options);
      }
export type UpdateSpecialProjectDocumentsMutationHookResult = ReturnType<typeof useUpdateSpecialProjectDocumentsMutation>;
export type UpdateSpecialProjectDocumentsMutationResult = Apollo.MutationResult<UpdateSpecialProjectDocumentsMutation>;
export type UpdateSpecialProjectDocumentsMutationOptions = Apollo.BaseMutationOptions<UpdateSpecialProjectDocumentsMutation, UpdateSpecialProjectDocumentsMutationVariables>;
export const SpecialProjectsDocument = gql`
    query specialProjects($first: Int, $after: String, $before: String, $last: Int, $includeClosedOrCancelled: Boolean!, $city: String) {
  specialProjects(includeUnpublished: true, city: $city, includeClosedOrCancelled: $includeClosedOrCancelled, first: $first, after: $after, before: $before, last: $last) {
    pageInfo {
      hasNextPage
      endCursor
      startCursor
      hasPreviousPage
    }
    totalCount
    nodes {
      ...SpecialProjectsProps
    }
  }
}
    ${SpecialProjectsPropsFragmentDoc}`;

/**
 * __useSpecialProjectsQuery__
 *
 * To run a query within a React component, call `useSpecialProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecialProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecialProjectsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      includeClosedOrCancelled: // value for 'includeClosedOrCancelled'
 *      city: // value for 'city'
 *   },
 * });
 */
export function useSpecialProjectsQuery(baseOptions: Apollo.QueryHookOptions<SpecialProjectsQuery, SpecialProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpecialProjectsQuery, SpecialProjectsQueryVariables>(SpecialProjectsDocument, options);
      }
export function useSpecialProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpecialProjectsQuery, SpecialProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpecialProjectsQuery, SpecialProjectsQueryVariables>(SpecialProjectsDocument, options);
        }
export type SpecialProjectsQueryHookResult = ReturnType<typeof useSpecialProjectsQuery>;
export type SpecialProjectsLazyQueryHookResult = ReturnType<typeof useSpecialProjectsLazyQuery>;
export type SpecialProjectsQueryResult = Apollo.QueryResult<SpecialProjectsQuery, SpecialProjectsQueryVariables>;
export const AddStoryBlockDocument = gql`
    mutation addStoryBlock($input: AddStoryBlockInput!) {
  addStoryBlock(input: $input) {
    storyBlock {
      title
      label
    }
    errors {
      ... on Error {
        message
      }
    }
  }
}
    `;
export type AddStoryBlockMutationFn = Apollo.MutationFunction<AddStoryBlockMutation, AddStoryBlockMutationVariables>;

/**
 * __useAddStoryBlockMutation__
 *
 * To run a mutation, you first call `useAddStoryBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStoryBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStoryBlockMutation, { data, loading, error }] = useAddStoryBlockMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStoryBlockMutation(baseOptions?: Apollo.MutationHookOptions<AddStoryBlockMutation, AddStoryBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStoryBlockMutation, AddStoryBlockMutationVariables>(AddStoryBlockDocument, options);
      }
export type AddStoryBlockMutationHookResult = ReturnType<typeof useAddStoryBlockMutation>;
export type AddStoryBlockMutationResult = Apollo.MutationResult<AddStoryBlockMutation>;
export type AddStoryBlockMutationOptions = Apollo.BaseMutationOptions<AddStoryBlockMutation, AddStoryBlockMutationVariables>;
export const UpdateStoryBlockDocument = gql`
    mutation updateStoryBlock($input: UpdateStoryBlockInput!) {
  updateStoryBlock(input: $input) {
    storyBlock {
      ...BlockStoriesProps
    }
  }
}
    ${BlockStoriesPropsFragmentDoc}`;
export type UpdateStoryBlockMutationFn = Apollo.MutationFunction<UpdateStoryBlockMutation, UpdateStoryBlockMutationVariables>;

/**
 * __useUpdateStoryBlockMutation__
 *
 * To run a mutation, you first call `useUpdateStoryBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoryBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoryBlockMutation, { data, loading, error }] = useUpdateStoryBlockMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStoryBlockMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoryBlockMutation, UpdateStoryBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoryBlockMutation, UpdateStoryBlockMutationVariables>(UpdateStoryBlockDocument, options);
      }
export type UpdateStoryBlockMutationHookResult = ReturnType<typeof useUpdateStoryBlockMutation>;
export type UpdateStoryBlockMutationResult = Apollo.MutationResult<UpdateStoryBlockMutation>;
export type UpdateStoryBlockMutationOptions = Apollo.BaseMutationOptions<UpdateStoryBlockMutation, UpdateStoryBlockMutationVariables>;
export const AddStoryDocument = gql`
    mutation addStory($input: AddStoryInput!) {
  addStory(input: $input) {
    story {
      id
      title
      order
    }
  }
}
    `;
export type AddStoryMutationFn = Apollo.MutationFunction<AddStoryMutation, AddStoryMutationVariables>;

/**
 * __useAddStoryMutation__
 *
 * To run a mutation, you first call `useAddStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStoryMutation, { data, loading, error }] = useAddStoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStoryMutation(baseOptions?: Apollo.MutationHookOptions<AddStoryMutation, AddStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStoryMutation, AddStoryMutationVariables>(AddStoryDocument, options);
      }
export type AddStoryMutationHookResult = ReturnType<typeof useAddStoryMutation>;
export type AddStoryMutationResult = Apollo.MutationResult<AddStoryMutation>;
export type AddStoryMutationOptions = Apollo.BaseMutationOptions<AddStoryMutation, AddStoryMutationVariables>;
export const DeleteStoryDocument = gql`
    mutation DeleteStory($input: DeleteStoryInput!) {
  deleteStory(input: $input) {
    success
  }
}
    `;
export type DeleteStoryMutationFn = Apollo.MutationFunction<DeleteStoryMutation, DeleteStoryMutationVariables>;

/**
 * __useDeleteStoryMutation__
 *
 * To run a mutation, you first call `useDeleteStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoryMutation, { data, loading, error }] = useDeleteStoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteStoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStoryMutation, DeleteStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStoryMutation, DeleteStoryMutationVariables>(DeleteStoryDocument, options);
      }
export type DeleteStoryMutationHookResult = ReturnType<typeof useDeleteStoryMutation>;
export type DeleteStoryMutationResult = Apollo.MutationResult<DeleteStoryMutation>;
export type DeleteStoryMutationOptions = Apollo.BaseMutationOptions<DeleteStoryMutation, DeleteStoryMutationVariables>;
export const ChangeStoriesOrderDocument = gql`
    mutation changeStoriesOrder($input: ChangeStoriesOrderInput!) {
  changeStoriesOrder(input: $input) {
    success
  }
}
    `;
export type ChangeStoriesOrderMutationFn = Apollo.MutationFunction<ChangeStoriesOrderMutation, ChangeStoriesOrderMutationVariables>;

/**
 * __useChangeStoriesOrderMutation__
 *
 * To run a mutation, you first call `useChangeStoriesOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeStoriesOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeStoriesOrderMutation, { data, loading, error }] = useChangeStoriesOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeStoriesOrderMutation(baseOptions?: Apollo.MutationHookOptions<ChangeStoriesOrderMutation, ChangeStoriesOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeStoriesOrderMutation, ChangeStoriesOrderMutationVariables>(ChangeStoriesOrderDocument, options);
      }
export type ChangeStoriesOrderMutationHookResult = ReturnType<typeof useChangeStoriesOrderMutation>;
export type ChangeStoriesOrderMutationResult = Apollo.MutationResult<ChangeStoriesOrderMutation>;
export type ChangeStoriesOrderMutationOptions = Apollo.BaseMutationOptions<ChangeStoriesOrderMutation, ChangeStoriesOrderMutationVariables>;
export const PublishStoryDocument = gql`
    mutation publishStory($input: PublishStoryInput!) {
  publishStory(input: $input) {
    story {
      ...StoryProps
    }
    errors {
      ... on Error {
        message
      }
    }
  }
}
    ${StoryPropsFragmentDoc}`;
export type PublishStoryMutationFn = Apollo.MutationFunction<PublishStoryMutation, PublishStoryMutationVariables>;

/**
 * __usePublishStoryMutation__
 *
 * To run a mutation, you first call `usePublishStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishStoryMutation, { data, loading, error }] = usePublishStoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishStoryMutation(baseOptions?: Apollo.MutationHookOptions<PublishStoryMutation, PublishStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishStoryMutation, PublishStoryMutationVariables>(PublishStoryDocument, options);
      }
export type PublishStoryMutationHookResult = ReturnType<typeof usePublishStoryMutation>;
export type PublishStoryMutationResult = Apollo.MutationResult<PublishStoryMutation>;
export type PublishStoryMutationOptions = Apollo.BaseMutationOptions<PublishStoryMutation, PublishStoryMutationVariables>;
export const StoryByIdDocument = gql`
    query storyById($id: StoryId!) {
  storyById(id: $id) {
    ...StoryProps
  }
}
    ${StoryPropsFragmentDoc}`;

/**
 * __useStoryByIdQuery__
 *
 * To run a query within a React component, call `useStoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStoryByIdQuery(baseOptions: Apollo.QueryHookOptions<StoryByIdQuery, StoryByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoryByIdQuery, StoryByIdQueryVariables>(StoryByIdDocument, options);
      }
export function useStoryByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryByIdQuery, StoryByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoryByIdQuery, StoryByIdQueryVariables>(StoryByIdDocument, options);
        }
export type StoryByIdQueryHookResult = ReturnType<typeof useStoryByIdQuery>;
export type StoryByIdLazyQueryHookResult = ReturnType<typeof useStoryByIdLazyQuery>;
export type StoryByIdQueryResult = Apollo.QueryResult<StoryByIdQuery, StoryByIdQueryVariables>;
export const UnpublishStoryDocument = gql`
    mutation unpublishStory($input: UnpublishStoryInput!) {
  unpublishStory(input: $input) {
    story {
      ...StoryProps
    }
  }
}
    ${StoryPropsFragmentDoc}`;
export type UnpublishStoryMutationFn = Apollo.MutationFunction<UnpublishStoryMutation, UnpublishStoryMutationVariables>;

/**
 * __useUnpublishStoryMutation__
 *
 * To run a mutation, you first call `useUnpublishStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishStoryMutation, { data, loading, error }] = useUnpublishStoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishStoryMutation(baseOptions?: Apollo.MutationHookOptions<UnpublishStoryMutation, UnpublishStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnpublishStoryMutation, UnpublishStoryMutationVariables>(UnpublishStoryDocument, options);
      }
export type UnpublishStoryMutationHookResult = ReturnType<typeof useUnpublishStoryMutation>;
export type UnpublishStoryMutationResult = Apollo.MutationResult<UnpublishStoryMutation>;
export type UnpublishStoryMutationOptions = Apollo.BaseMutationOptions<UnpublishStoryMutation, UnpublishStoryMutationVariables>;
export const UpdateStoryDocument = gql`
    mutation UpdateStory($input: UpdateStoryInput!) {
  updateStory(input: $input) {
    story {
      id
      order
      screens {
        ...screenStoryProps
      }
    }
  }
}
    ${ScreenStoryPropsFragmentDoc}`;
export type UpdateStoryMutationFn = Apollo.MutationFunction<UpdateStoryMutation, UpdateStoryMutationVariables>;

/**
 * __useUpdateStoryMutation__
 *
 * To run a mutation, you first call `useUpdateStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoryMutation, { data, loading, error }] = useUpdateStoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoryMutation, UpdateStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoryMutation, UpdateStoryMutationVariables>(UpdateStoryDocument, options);
      }
export type UpdateStoryMutationHookResult = ReturnType<typeof useUpdateStoryMutation>;
export type UpdateStoryMutationResult = Apollo.MutationResult<UpdateStoryMutation>;
export type UpdateStoryMutationOptions = Apollo.BaseMutationOptions<UpdateStoryMutation, UpdateStoryMutationVariables>;
export const UpdateStoryScreensDocument = gql`
    mutation UpdateStoryScreens($input: UpdateStoryScreensInput!) {
  updateStoryScreens(input: $input) {
    story {
      id
    }
  }
}
    `;
export type UpdateStoryScreensMutationFn = Apollo.MutationFunction<UpdateStoryScreensMutation, UpdateStoryScreensMutationVariables>;

/**
 * __useUpdateStoryScreensMutation__
 *
 * To run a mutation, you first call `useUpdateStoryScreensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoryScreensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoryScreensMutation, { data, loading, error }] = useUpdateStoryScreensMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStoryScreensMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoryScreensMutation, UpdateStoryScreensMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoryScreensMutation, UpdateStoryScreensMutationVariables>(UpdateStoryScreensDocument, options);
      }
export type UpdateStoryScreensMutationHookResult = ReturnType<typeof useUpdateStoryScreensMutation>;
export type UpdateStoryScreensMutationResult = Apollo.MutationResult<UpdateStoryScreensMutation>;
export type UpdateStoryScreensMutationOptions = Apollo.BaseMutationOptions<UpdateStoryScreensMutation, UpdateStoryScreensMutationVariables>;
export const StoryBlockByIdDocument = gql`
    query storyBlockById($id: StoryBlockId!) {
  storyBlockById(id: $id) {
    ...BlockStoriesProps
  }
}
    ${BlockStoriesPropsFragmentDoc}`;

/**
 * __useStoryBlockByIdQuery__
 *
 * To run a query within a React component, call `useStoryBlockByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryBlockByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryBlockByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStoryBlockByIdQuery(baseOptions: Apollo.QueryHookOptions<StoryBlockByIdQuery, StoryBlockByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoryBlockByIdQuery, StoryBlockByIdQueryVariables>(StoryBlockByIdDocument, options);
      }
export function useStoryBlockByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryBlockByIdQuery, StoryBlockByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoryBlockByIdQuery, StoryBlockByIdQueryVariables>(StoryBlockByIdDocument, options);
        }
export type StoryBlockByIdQueryHookResult = ReturnType<typeof useStoryBlockByIdQuery>;
export type StoryBlockByIdLazyQueryHookResult = ReturnType<typeof useStoryBlockByIdLazyQuery>;
export type StoryBlockByIdQueryResult = Apollo.QueryResult<StoryBlockByIdQuery, StoryBlockByIdQueryVariables>;
export const StoryBlockDocument = gql`
    query StoryBlock {
  storyBlocks {
    ...BlockStoriesProps
  }
}
    ${BlockStoriesPropsFragmentDoc}`;

/**
 * __useStoryBlockQuery__
 *
 * To run a query within a React component, call `useStoryBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryBlockQuery({
 *   variables: {
 *   },
 * });
 */
export function useStoryBlockQuery(baseOptions?: Apollo.QueryHookOptions<StoryBlockQuery, StoryBlockQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoryBlockQuery, StoryBlockQueryVariables>(StoryBlockDocument, options);
      }
export function useStoryBlockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryBlockQuery, StoryBlockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoryBlockQuery, StoryBlockQueryVariables>(StoryBlockDocument, options);
        }
export type StoryBlockQueryHookResult = ReturnType<typeof useStoryBlockQuery>;
export type StoryBlockLazyQueryHookResult = ReturnType<typeof useStoryBlockLazyQuery>;
export type StoryBlockQueryResult = Apollo.QueryResult<StoryBlockQuery, StoryBlockQueryVariables>;
export const AddClientVersionDocument = gql`
    mutation addClientVersion($input: AddClientVersionInput!) {
  addClientVersion(input: $input) {
    version {
      ...VersionProps
    }
  }
}
    ${VersionPropsFragmentDoc}`;
export type AddClientVersionMutationFn = Apollo.MutationFunction<AddClientVersionMutation, AddClientVersionMutationVariables>;

/**
 * __useAddClientVersionMutation__
 *
 * To run a mutation, you first call `useAddClientVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClientVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClientVersionMutation, { data, loading, error }] = useAddClientVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddClientVersionMutation(baseOptions?: Apollo.MutationHookOptions<AddClientVersionMutation, AddClientVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddClientVersionMutation, AddClientVersionMutationVariables>(AddClientVersionDocument, options);
      }
export type AddClientVersionMutationHookResult = ReturnType<typeof useAddClientVersionMutation>;
export type AddClientVersionMutationResult = Apollo.MutationResult<AddClientVersionMutation>;
export type AddClientVersionMutationOptions = Apollo.BaseMutationOptions<AddClientVersionMutation, AddClientVersionMutationVariables>;
export const ClientVersionsDocument = gql`
    query clientVersions($first: Int, $after: String, $before: String, $last: Int) {
  clientVersions(first: $first, after: $after, before: $before, last: $last) {
    pageInfo {
      hasNextPage
      endCursor
      startCursor
      hasPreviousPage
    }
    totalCount
    nodes {
      ...VersionProps
    }
  }
}
    ${VersionPropsFragmentDoc}`;

/**
 * __useClientVersionsQuery__
 *
 * To run a query within a React component, call `useClientVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientVersionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useClientVersionsQuery(baseOptions?: Apollo.QueryHookOptions<ClientVersionsQuery, ClientVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientVersionsQuery, ClientVersionsQueryVariables>(ClientVersionsDocument, options);
      }
export function useClientVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientVersionsQuery, ClientVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientVersionsQuery, ClientVersionsQueryVariables>(ClientVersionsDocument, options);
        }
export type ClientVersionsQueryHookResult = ReturnType<typeof useClientVersionsQuery>;
export type ClientVersionsLazyQueryHookResult = ReturnType<typeof useClientVersionsLazyQuery>;
export type ClientVersionsQueryResult = Apollo.QueryResult<ClientVersionsQuery, ClientVersionsQueryVariables>;
export const AddWardDocument = gql`
    mutation addWard($input: AddWardInput!) {
  addWard(input: $input) {
    ward {
      id
    }
  }
}
    `;
export type AddWardMutationFn = Apollo.MutationFunction<AddWardMutation, AddWardMutationVariables>;

/**
 * __useAddWardMutation__
 *
 * To run a mutation, you first call `useAddWardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWardMutation, { data, loading, error }] = useAddWardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddWardMutation(baseOptions?: Apollo.MutationHookOptions<AddWardMutation, AddWardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddWardMutation, AddWardMutationVariables>(AddWardDocument, options);
      }
export type AddWardMutationHookResult = ReturnType<typeof useAddWardMutation>;
export type AddWardMutationResult = Apollo.MutationResult<AddWardMutation>;
export type AddWardMutationOptions = Apollo.BaseMutationOptions<AddWardMutation, AddWardMutationVariables>;
export const AddWardsToTheBenefactorDepositAccountForSpecificWardsDocument = gql`
    mutation addWardsToTheBenefactorDepositAccountForSpecificWards($input: AddWardsToTheBenefactorDepositAccountForSpecificWardsInput!) {
  addWardsToTheBenefactorDepositAccountForSpecificWards(input: $input) {
    benefactorDepositAccountForSpecificWards {
      benefactor {
        id
        displayName
      }
      title
    }
  }
}
    `;
export type AddWardsToTheBenefactorDepositAccountForSpecificWardsMutationFn = Apollo.MutationFunction<AddWardsToTheBenefactorDepositAccountForSpecificWardsMutation, AddWardsToTheBenefactorDepositAccountForSpecificWardsMutationVariables>;

/**
 * __useAddWardsToTheBenefactorDepositAccountForSpecificWardsMutation__
 *
 * To run a mutation, you first call `useAddWardsToTheBenefactorDepositAccountForSpecificWardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWardsToTheBenefactorDepositAccountForSpecificWardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWardsToTheBenefactorDepositAccountForSpecificWardsMutation, { data, loading, error }] = useAddWardsToTheBenefactorDepositAccountForSpecificWardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddWardsToTheBenefactorDepositAccountForSpecificWardsMutation(baseOptions?: Apollo.MutationHookOptions<AddWardsToTheBenefactorDepositAccountForSpecificWardsMutation, AddWardsToTheBenefactorDepositAccountForSpecificWardsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddWardsToTheBenefactorDepositAccountForSpecificWardsMutation, AddWardsToTheBenefactorDepositAccountForSpecificWardsMutationVariables>(AddWardsToTheBenefactorDepositAccountForSpecificWardsDocument, options);
      }
export type AddWardsToTheBenefactorDepositAccountForSpecificWardsMutationHookResult = ReturnType<typeof useAddWardsToTheBenefactorDepositAccountForSpecificWardsMutation>;
export type AddWardsToTheBenefactorDepositAccountForSpecificWardsMutationResult = Apollo.MutationResult<AddWardsToTheBenefactorDepositAccountForSpecificWardsMutation>;
export type AddWardsToTheBenefactorDepositAccountForSpecificWardsMutationOptions = Apollo.BaseMutationOptions<AddWardsToTheBenefactorDepositAccountForSpecificWardsMutation, AddWardsToTheBenefactorDepositAccountForSpecificWardsMutationVariables>;
export const BenefactorsWithDepositAccountsDocument = gql`
    query benefactorsWithDepositAccounts {
  benefactorsWithDepositAccounts {
    nodes {
      benefactor {
        displayName
        depositAccounts {
          __typename
          id
          ... on BenefactorDepositAccountForSpecificWards {
            title
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBenefactorsWithDepositAccountsQuery__
 *
 * To run a query within a React component, call `useBenefactorsWithDepositAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenefactorsWithDepositAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenefactorsWithDepositAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBenefactorsWithDepositAccountsQuery(baseOptions?: Apollo.QueryHookOptions<BenefactorsWithDepositAccountsQuery, BenefactorsWithDepositAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BenefactorsWithDepositAccountsQuery, BenefactorsWithDepositAccountsQueryVariables>(BenefactorsWithDepositAccountsDocument, options);
      }
export function useBenefactorsWithDepositAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BenefactorsWithDepositAccountsQuery, BenefactorsWithDepositAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BenefactorsWithDepositAccountsQuery, BenefactorsWithDepositAccountsQueryVariables>(BenefactorsWithDepositAccountsDocument, options);
        }
export type BenefactorsWithDepositAccountsQueryHookResult = ReturnType<typeof useBenefactorsWithDepositAccountsQuery>;
export type BenefactorsWithDepositAccountsLazyQueryHookResult = ReturnType<typeof useBenefactorsWithDepositAccountsLazyQuery>;
export type BenefactorsWithDepositAccountsQueryResult = Apollo.QueryResult<BenefactorsWithDepositAccountsQuery, BenefactorsWithDepositAccountsQueryVariables>;
export const RemoveWardFromTheBenefactorDepositAccountForSpecificWardsDocument = gql`
    mutation removeWardFromTheBenefactorDepositAccountForSpecificWards($input: RemoveWardFromTheBenefactorDepositAccountForSpecificWardsInput!) {
  removeWardFromTheBenefactorDepositAccountForSpecificWards(input: $input) {
    benefactorDepositAccountForSpecificWards {
      benefactor {
        id
        displayName
      }
      title
    }
  }
}
    `;
export type RemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutationFn = Apollo.MutationFunction<RemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutation, RemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutationVariables>;

/**
 * __useRemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutation__
 *
 * To run a mutation, you first call `useRemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWardFromTheBenefactorDepositAccountForSpecificWardsMutation, { data, loading, error }] = useRemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutation, RemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutation, RemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutationVariables>(RemoveWardFromTheBenefactorDepositAccountForSpecificWardsDocument, options);
      }
export type RemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutationHookResult = ReturnType<typeof useRemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutation>;
export type RemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutationResult = Apollo.MutationResult<RemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutation>;
export type RemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutationOptions = Apollo.BaseMutationOptions<RemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutation, RemoveWardFromTheBenefactorDepositAccountForSpecificWardsMutationVariables>;
export const UpdateWardDocument = gql`
    mutation updateWard($input: UpdateWardInput!) {
  updateWard(input: $input) {
    ward {
      ...WardProps
    }
  }
}
    ${WardPropsFragmentDoc}`;
export type UpdateWardMutationFn = Apollo.MutationFunction<UpdateWardMutation, UpdateWardMutationVariables>;

/**
 * __useUpdateWardMutation__
 *
 * To run a mutation, you first call `useUpdateWardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWardMutation, { data, loading, error }] = useUpdateWardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWardMutation, UpdateWardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWardMutation, UpdateWardMutationVariables>(UpdateWardDocument, options);
      }
export type UpdateWardMutationHookResult = ReturnType<typeof useUpdateWardMutation>;
export type UpdateWardMutationResult = Apollo.MutationResult<UpdateWardMutation>;
export type UpdateWardMutationOptions = Apollo.BaseMutationOptions<UpdateWardMutation, UpdateWardMutationVariables>;
export const PublishWardDocument = gql`
    mutation publishWard($input: PublishWardInput!) {
  publishWard(input: $input) {
    ward {
      ...WardProps
    }
  }
}
    ${WardPropsFragmentDoc}`;
export type PublishWardMutationFn = Apollo.MutationFunction<PublishWardMutation, PublishWardMutationVariables>;

/**
 * __usePublishWardMutation__
 *
 * To run a mutation, you first call `usePublishWardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishWardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishWardMutation, { data, loading, error }] = usePublishWardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishWardMutation(baseOptions?: Apollo.MutationHookOptions<PublishWardMutation, PublishWardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishWardMutation, PublishWardMutationVariables>(PublishWardDocument, options);
      }
export type PublishWardMutationHookResult = ReturnType<typeof usePublishWardMutation>;
export type PublishWardMutationResult = Apollo.MutationResult<PublishWardMutation>;
export type PublishWardMutationOptions = Apollo.BaseMutationOptions<PublishWardMutation, PublishWardMutationVariables>;
export const UnpublishWardDocument = gql`
    mutation unpublishWard($input: UnpublishWardInput!) {
  unpublishWard(input: $input) {
    ward {
      ...WardProps
    }
  }
}
    ${WardPropsFragmentDoc}`;
export type UnpublishWardMutationFn = Apollo.MutationFunction<UnpublishWardMutation, UnpublishWardMutationVariables>;

/**
 * __useUnpublishWardMutation__
 *
 * To run a mutation, you first call `useUnpublishWardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishWardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishWardMutation, { data, loading, error }] = useUnpublishWardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnpublishWardMutation(baseOptions?: Apollo.MutationHookOptions<UnpublishWardMutation, UnpublishWardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnpublishWardMutation, UnpublishWardMutationVariables>(UnpublishWardDocument, options);
      }
export type UnpublishWardMutationHookResult = ReturnType<typeof useUnpublishWardMutation>;
export type UnpublishWardMutationResult = Apollo.MutationResult<UnpublishWardMutation>;
export type UnpublishWardMutationOptions = Apollo.BaseMutationOptions<UnpublishWardMutation, UnpublishWardMutationVariables>;
export const WardByIdDocument = gql`
    query wardById($id: WardId!, $permission: Boolean!) {
  wardById(id: $id) {
    ...WardProps
    needs {
      ...NeedProps
    }
    benefactorDepositAccounts @include(if: $permission) {
      id
      benefactor {
        id
        displayName
      }
      title
    }
  }
}
    ${WardPropsFragmentDoc}
${NeedPropsFragmentDoc}`;

/**
 * __useWardByIdQuery__
 *
 * To run a query within a React component, call `useWardByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useWardByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWardByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      permission: // value for 'permission'
 *   },
 * });
 */
export function useWardByIdQuery(baseOptions: Apollo.QueryHookOptions<WardByIdQuery, WardByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WardByIdQuery, WardByIdQueryVariables>(WardByIdDocument, options);
      }
export function useWardByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WardByIdQuery, WardByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WardByIdQuery, WardByIdQueryVariables>(WardByIdDocument, options);
        }
export type WardByIdQueryHookResult = ReturnType<typeof useWardByIdQuery>;
export type WardByIdLazyQueryHookResult = ReturnType<typeof useWardByIdLazyQuery>;
export type WardByIdQueryResult = Apollo.QueryResult<WardByIdQuery, WardByIdQueryVariables>;
export const DeactivateWardDocument = gql`
    mutation DeactivateWard($input: DeactivateWardInput!) {
  deactivateWard(input: $input) {
    ward {
      ...WardProps
      benefactorDepositAccounts {
        benefactor {
          id
          displayName
        }
      }
    }
  }
}
    ${WardPropsFragmentDoc}`;
export type DeactivateWardMutationFn = Apollo.MutationFunction<DeactivateWardMutation, DeactivateWardMutationVariables>;

/**
 * __useDeactivateWardMutation__
 *
 * To run a mutation, you first call `useDeactivateWardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateWardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateWardMutation, { data, loading, error }] = useDeactivateWardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeactivateWardMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateWardMutation, DeactivateWardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateWardMutation, DeactivateWardMutationVariables>(DeactivateWardDocument, options);
      }
export type DeactivateWardMutationHookResult = ReturnType<typeof useDeactivateWardMutation>;
export type DeactivateWardMutationResult = Apollo.MutationResult<DeactivateWardMutation>;
export type DeactivateWardMutationOptions = Apollo.BaseMutationOptions<DeactivateWardMutation, DeactivateWardMutationVariables>;
export const UpdateWardDocumentsDocument = gql`
    mutation updateWardDocuments($input: UpdateWardDocumentsInput!) {
  updateWardDocuments(input: $input) {
    ward {
      ...WardProps
    }
  }
}
    ${WardPropsFragmentDoc}`;
export type UpdateWardDocumentsMutationFn = Apollo.MutationFunction<UpdateWardDocumentsMutation, UpdateWardDocumentsMutationVariables>;

/**
 * __useUpdateWardDocumentsMutation__
 *
 * To run a mutation, you first call `useUpdateWardDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWardDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWardDocumentsMutation, { data, loading, error }] = useUpdateWardDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWardDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWardDocumentsMutation, UpdateWardDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWardDocumentsMutation, UpdateWardDocumentsMutationVariables>(UpdateWardDocumentsDocument, options);
      }
export type UpdateWardDocumentsMutationHookResult = ReturnType<typeof useUpdateWardDocumentsMutation>;
export type UpdateWardDocumentsMutationResult = Apollo.MutationResult<UpdateWardDocumentsMutation>;
export type UpdateWardDocumentsMutationOptions = Apollo.BaseMutationOptions<UpdateWardDocumentsMutation, UpdateWardDocumentsMutationVariables>;
export const WardNeedAccountsByIdsDocument = gql`
    query wardNeedAccountsByIds($needId: NeedId!) {
  needById(needId: $needId) {
    id
    requiredAmount
  }
}
    `;

/**
 * __useWardNeedAccountsByIdsQuery__
 *
 * To run a query within a React component, call `useWardNeedAccountsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWardNeedAccountsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWardNeedAccountsByIdsQuery({
 *   variables: {
 *      needId: // value for 'needId'
 *   },
 * });
 */
export function useWardNeedAccountsByIdsQuery(baseOptions: Apollo.QueryHookOptions<WardNeedAccountsByIdsQuery, WardNeedAccountsByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WardNeedAccountsByIdsQuery, WardNeedAccountsByIdsQueryVariables>(WardNeedAccountsByIdsDocument, options);
      }
export function useWardNeedAccountsByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WardNeedAccountsByIdsQuery, WardNeedAccountsByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WardNeedAccountsByIdsQuery, WardNeedAccountsByIdsQueryVariables>(WardNeedAccountsByIdsDocument, options);
        }
export type WardNeedAccountsByIdsQueryHookResult = ReturnType<typeof useWardNeedAccountsByIdsQuery>;
export type WardNeedAccountsByIdsLazyQueryHookResult = ReturnType<typeof useWardNeedAccountsByIdsLazyQuery>;
export type WardNeedAccountsByIdsQueryResult = Apollo.QueryResult<WardNeedAccountsByIdsQuery, WardNeedAccountsByIdsQueryVariables>;
export const UpdateOneTimeNeedDocumentsDocument = gql`
    mutation updateOneTimeNeedDocuments($input: UpdateOneTimeNeedDocumentsInput!) {
  updateOneTimeNeedDocuments(input: $input) {
    oneTimeNeed {
      documents {
        ...DocumentFileProps
      }
    }
  }
}
    ${DocumentFilePropsFragmentDoc}`;
export type UpdateOneTimeNeedDocumentsMutationFn = Apollo.MutationFunction<UpdateOneTimeNeedDocumentsMutation, UpdateOneTimeNeedDocumentsMutationVariables>;

/**
 * __useUpdateOneTimeNeedDocumentsMutation__
 *
 * To run a mutation, you first call `useUpdateOneTimeNeedDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneTimeNeedDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneTimeNeedDocumentsMutation, { data, loading, error }] = useUpdateOneTimeNeedDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneTimeNeedDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneTimeNeedDocumentsMutation, UpdateOneTimeNeedDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneTimeNeedDocumentsMutation, UpdateOneTimeNeedDocumentsMutationVariables>(UpdateOneTimeNeedDocumentsDocument, options);
      }
export type UpdateOneTimeNeedDocumentsMutationHookResult = ReturnType<typeof useUpdateOneTimeNeedDocumentsMutation>;
export type UpdateOneTimeNeedDocumentsMutationResult = Apollo.MutationResult<UpdateOneTimeNeedDocumentsMutation>;
export type UpdateOneTimeNeedDocumentsMutationOptions = Apollo.BaseMutationOptions<UpdateOneTimeNeedDocumentsMutation, UpdateOneTimeNeedDocumentsMutationVariables>;
export const RegularNeedPeriodByIdDocument = gql`
    query regularNeedPeriodById($id: RegularNeedPeriodId!) {
  regularNeedPeriodById(id: $id) {
    ...RegularNeedPeriodProps
  }
}
    ${RegularNeedPeriodPropsFragmentDoc}`;

/**
 * __useRegularNeedPeriodByIdQuery__
 *
 * To run a query within a React component, call `useRegularNeedPeriodByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegularNeedPeriodByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegularNeedPeriodByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegularNeedPeriodByIdQuery(baseOptions: Apollo.QueryHookOptions<RegularNeedPeriodByIdQuery, RegularNeedPeriodByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegularNeedPeriodByIdQuery, RegularNeedPeriodByIdQueryVariables>(RegularNeedPeriodByIdDocument, options);
      }
export function useRegularNeedPeriodByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegularNeedPeriodByIdQuery, RegularNeedPeriodByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegularNeedPeriodByIdQuery, RegularNeedPeriodByIdQueryVariables>(RegularNeedPeriodByIdDocument, options);
        }
export type RegularNeedPeriodByIdQueryHookResult = ReturnType<typeof useRegularNeedPeriodByIdQuery>;
export type RegularNeedPeriodByIdLazyQueryHookResult = ReturnType<typeof useRegularNeedPeriodByIdLazyQuery>;
export type RegularNeedPeriodByIdQueryResult = Apollo.QueryResult<RegularNeedPeriodByIdQuery, RegularNeedPeriodByIdQueryVariables>;
export const UpdateRegularNeedPeriodDocumentsDocument = gql`
    mutation updateRegularNeedPeriodDocuments($input: UpdateRegularNeedPeriodDocumentsInput!) {
  updateRegularNeedPeriodDocuments(input: $input) {
    regularNeedPeriod {
      id
      documents {
        ...DocumentFileProps
      }
    }
  }
}
    ${DocumentFilePropsFragmentDoc}`;
export type UpdateRegularNeedPeriodDocumentsMutationFn = Apollo.MutationFunction<UpdateRegularNeedPeriodDocumentsMutation, UpdateRegularNeedPeriodDocumentsMutationVariables>;

/**
 * __useUpdateRegularNeedPeriodDocumentsMutation__
 *
 * To run a mutation, you first call `useUpdateRegularNeedPeriodDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegularNeedPeriodDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegularNeedPeriodDocumentsMutation, { data, loading, error }] = useUpdateRegularNeedPeriodDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRegularNeedPeriodDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRegularNeedPeriodDocumentsMutation, UpdateRegularNeedPeriodDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRegularNeedPeriodDocumentsMutation, UpdateRegularNeedPeriodDocumentsMutationVariables>(UpdateRegularNeedPeriodDocumentsDocument, options);
      }
export type UpdateRegularNeedPeriodDocumentsMutationHookResult = ReturnType<typeof useUpdateRegularNeedPeriodDocumentsMutation>;
export type UpdateRegularNeedPeriodDocumentsMutationResult = Apollo.MutationResult<UpdateRegularNeedPeriodDocumentsMutation>;
export type UpdateRegularNeedPeriodDocumentsMutationOptions = Apollo.BaseMutationOptions<UpdateRegularNeedPeriodDocumentsMutation, UpdateRegularNeedPeriodDocumentsMutationVariables>;
export const RegularNeedPeriodsDocument = gql`
    query regularNeedPeriods($id: WardId!, $first: Int, $after: String, $before: String, $last: Int) {
  wardById(id: $id) {
    needs {
      id
      ... on GrocerySetNeed {
        periods(first: $first, after: $after, before: $before, order: {start: DESC}, last: $last) {
          pageInfo {
            hasNextPage
            endCursor
            startCursor
            hasPreviousPage
          }
          totalCount
          nodes {
            ...RegularNeedPeriodProps
          }
        }
      }
    }
  }
}
    ${RegularNeedPeriodPropsFragmentDoc}`;

/**
 * __useRegularNeedPeriodsQuery__
 *
 * To run a query within a React component, call `useRegularNeedPeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegularNeedPeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegularNeedPeriodsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useRegularNeedPeriodsQuery(baseOptions: Apollo.QueryHookOptions<RegularNeedPeriodsQuery, RegularNeedPeriodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegularNeedPeriodsQuery, RegularNeedPeriodsQueryVariables>(RegularNeedPeriodsDocument, options);
      }
export function useRegularNeedPeriodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegularNeedPeriodsQuery, RegularNeedPeriodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegularNeedPeriodsQuery, RegularNeedPeriodsQueryVariables>(RegularNeedPeriodsDocument, options);
        }
export type RegularNeedPeriodsQueryHookResult = ReturnType<typeof useRegularNeedPeriodsQuery>;
export type RegularNeedPeriodsLazyQueryHookResult = ReturnType<typeof useRegularNeedPeriodsLazyQuery>;
export type RegularNeedPeriodsQueryResult = Apollo.QueryResult<RegularNeedPeriodsQuery, RegularNeedPeriodsQueryVariables>;
export const ArchiveNeedDocument = gql`
    mutation archiveNeed($input: ArchiveNeedInput!) {
  archiveNeed(input: $input) {
    need {
      id
    }
  }
}
    `;
export type ArchiveNeedMutationFn = Apollo.MutationFunction<ArchiveNeedMutation, ArchiveNeedMutationVariables>;

/**
 * __useArchiveNeedMutation__
 *
 * To run a mutation, you first call `useArchiveNeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveNeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveNeedMutation, { data, loading, error }] = useArchiveNeedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveNeedMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveNeedMutation, ArchiveNeedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveNeedMutation, ArchiveNeedMutationVariables>(ArchiveNeedDocument, options);
      }
export type ArchiveNeedMutationHookResult = ReturnType<typeof useArchiveNeedMutation>;
export type ArchiveNeedMutationResult = Apollo.MutationResult<ArchiveNeedMutation>;
export type ArchiveNeedMutationOptions = Apollo.BaseMutationOptions<ArchiveNeedMutation, ArchiveNeedMutationVariables>;
export const RestInPeaceDocument = gql`
    mutation RestInPeace($input: RestInPeaceInput!) {
  restInPeace(input: $input) {
    ward {
      ...WardProps
      benefactorDepositAccounts {
        benefactor {
          id
          displayName
        }
      }
    }
  }
}
    ${WardPropsFragmentDoc}`;
export type RestInPeaceMutationFn = Apollo.MutationFunction<RestInPeaceMutation, RestInPeaceMutationVariables>;

/**
 * __useRestInPeaceMutation__
 *
 * To run a mutation, you first call `useRestInPeaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestInPeaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restInPeaceMutation, { data, loading, error }] = useRestInPeaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestInPeaceMutation(baseOptions?: Apollo.MutationHookOptions<RestInPeaceMutation, RestInPeaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestInPeaceMutation, RestInPeaceMutationVariables>(RestInPeaceDocument, options);
      }
export type RestInPeaceMutationHookResult = ReturnType<typeof useRestInPeaceMutation>;
export type RestInPeaceMutationResult = Apollo.MutationResult<RestInPeaceMutation>;
export type RestInPeaceMutationOptions = Apollo.BaseMutationOptions<RestInPeaceMutation, RestInPeaceMutationVariables>;
export const WardsDocument = gql`
    query wards($first: Int, $after: String, $before: String, $orderBy: [WardSortInput!], $where: WardFilterInput, $last: Int) {
  wards(first: $first, after: $after, before: $before, order: $orderBy, where: $where, last: $last) {
    pageInfo {
      hasNextPage
      endCursor
      startCursor
      hasPreviousPage
    }
    totalCount
    nodes {
      ...WardsGridWard
    }
  }
}
    ${WardsGridWardFragmentDoc}`;

/**
 * __useWardsQuery__
 *
 * To run a query within a React component, call `useWardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWardsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useWardsQuery(baseOptions?: Apollo.QueryHookOptions<WardsQuery, WardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WardsQuery, WardsQueryVariables>(WardsDocument, options);
      }
export function useWardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WardsQuery, WardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WardsQuery, WardsQueryVariables>(WardsDocument, options);
        }
export type WardsQueryHookResult = ReturnType<typeof useWardsQuery>;
export type WardsLazyQueryHookResult = ReturnType<typeof useWardsLazyQuery>;
export type WardsQueryResult = Apollo.QueryResult<WardsQuery, WardsQueryVariables>;
export const MarkWardAsNotRequiringImmediateHelpDocument = gql`
    mutation markWardAsNotRequiringImmediateHelp($input: MarkWardAsNotRequiringImmediateHelpInput!) {
  markWardAsNotRequiringImmediateHelp(input: $input) {
    ward {
      id
      immediateHelpRequired
    }
  }
}
    `;
export type MarkWardAsNotRequiringImmediateHelpMutationFn = Apollo.MutationFunction<MarkWardAsNotRequiringImmediateHelpMutation, MarkWardAsNotRequiringImmediateHelpMutationVariables>;

/**
 * __useMarkWardAsNotRequiringImmediateHelpMutation__
 *
 * To run a mutation, you first call `useMarkWardAsNotRequiringImmediateHelpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkWardAsNotRequiringImmediateHelpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markWardAsNotRequiringImmediateHelpMutation, { data, loading, error }] = useMarkWardAsNotRequiringImmediateHelpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkWardAsNotRequiringImmediateHelpMutation(baseOptions?: Apollo.MutationHookOptions<MarkWardAsNotRequiringImmediateHelpMutation, MarkWardAsNotRequiringImmediateHelpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkWardAsNotRequiringImmediateHelpMutation, MarkWardAsNotRequiringImmediateHelpMutationVariables>(MarkWardAsNotRequiringImmediateHelpDocument, options);
      }
export type MarkWardAsNotRequiringImmediateHelpMutationHookResult = ReturnType<typeof useMarkWardAsNotRequiringImmediateHelpMutation>;
export type MarkWardAsNotRequiringImmediateHelpMutationResult = Apollo.MutationResult<MarkWardAsNotRequiringImmediateHelpMutation>;
export type MarkWardAsNotRequiringImmediateHelpMutationOptions = Apollo.BaseMutationOptions<MarkWardAsNotRequiringImmediateHelpMutation, MarkWardAsNotRequiringImmediateHelpMutationVariables>;
export const MarkWardAsRequiringImmediateHelpDocument = gql`
    mutation markWardAsRequiringImmediateHelp($input: MarkWardAsRequiringImmediateHelpInput!) {
  markWardAsRequiringImmediateHelp(input: $input) {
    ward {
      id
      immediateHelpRequired
    }
  }
}
    `;
export type MarkWardAsRequiringImmediateHelpMutationFn = Apollo.MutationFunction<MarkWardAsRequiringImmediateHelpMutation, MarkWardAsRequiringImmediateHelpMutationVariables>;

/**
 * __useMarkWardAsRequiringImmediateHelpMutation__
 *
 * To run a mutation, you first call `useMarkWardAsRequiringImmediateHelpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkWardAsRequiringImmediateHelpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markWardAsRequiringImmediateHelpMutation, { data, loading, error }] = useMarkWardAsRequiringImmediateHelpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkWardAsRequiringImmediateHelpMutation(baseOptions?: Apollo.MutationHookOptions<MarkWardAsRequiringImmediateHelpMutation, MarkWardAsRequiringImmediateHelpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkWardAsRequiringImmediateHelpMutation, MarkWardAsRequiringImmediateHelpMutationVariables>(MarkWardAsRequiringImmediateHelpDocument, options);
      }
export type MarkWardAsRequiringImmediateHelpMutationHookResult = ReturnType<typeof useMarkWardAsRequiringImmediateHelpMutation>;
export type MarkWardAsRequiringImmediateHelpMutationResult = Apollo.MutationResult<MarkWardAsRequiringImmediateHelpMutation>;
export type MarkWardAsRequiringImmediateHelpMutationOptions = Apollo.BaseMutationOptions<MarkWardAsRequiringImmediateHelpMutation, MarkWardAsRequiringImmediateHelpMutationVariables>;
export const DisableSubscriptionTransferToWardDocument = gql`
    mutation disableSubscriptionTransferToWard($input: DisableSubscriptionTransferToWardInput!) {
  disableSubscriptionTransferToWard(input: $input) {
    ward {
      id
      subscriptionTransferEnabled
    }
  }
}
    `;
export type DisableSubscriptionTransferToWardMutationFn = Apollo.MutationFunction<DisableSubscriptionTransferToWardMutation, DisableSubscriptionTransferToWardMutationVariables>;

/**
 * __useDisableSubscriptionTransferToWardMutation__
 *
 * To run a mutation, you first call `useDisableSubscriptionTransferToWardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableSubscriptionTransferToWardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableSubscriptionTransferToWardMutation, { data, loading, error }] = useDisableSubscriptionTransferToWardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisableSubscriptionTransferToWardMutation(baseOptions?: Apollo.MutationHookOptions<DisableSubscriptionTransferToWardMutation, DisableSubscriptionTransferToWardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableSubscriptionTransferToWardMutation, DisableSubscriptionTransferToWardMutationVariables>(DisableSubscriptionTransferToWardDocument, options);
      }
export type DisableSubscriptionTransferToWardMutationHookResult = ReturnType<typeof useDisableSubscriptionTransferToWardMutation>;
export type DisableSubscriptionTransferToWardMutationResult = Apollo.MutationResult<DisableSubscriptionTransferToWardMutation>;
export type DisableSubscriptionTransferToWardMutationOptions = Apollo.BaseMutationOptions<DisableSubscriptionTransferToWardMutation, DisableSubscriptionTransferToWardMutationVariables>;
export const EnableSubscriptionTransferToWardDocument = gql`
    mutation enableSubscriptionTransferToWard($input: EnableSubscriptionTransferToWardInput!) {
  enableSubscriptionTransferToWard(input: $input) {
    ward {
      id
      subscriptionTransferEnabled
    }
  }
}
    `;
export type EnableSubscriptionTransferToWardMutationFn = Apollo.MutationFunction<EnableSubscriptionTransferToWardMutation, EnableSubscriptionTransferToWardMutationVariables>;

/**
 * __useEnableSubscriptionTransferToWardMutation__
 *
 * To run a mutation, you first call `useEnableSubscriptionTransferToWardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableSubscriptionTransferToWardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableSubscriptionTransferToWardMutation, { data, loading, error }] = useEnableSubscriptionTransferToWardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableSubscriptionTransferToWardMutation(baseOptions?: Apollo.MutationHookOptions<EnableSubscriptionTransferToWardMutation, EnableSubscriptionTransferToWardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableSubscriptionTransferToWardMutation, EnableSubscriptionTransferToWardMutationVariables>(EnableSubscriptionTransferToWardDocument, options);
      }
export type EnableSubscriptionTransferToWardMutationHookResult = ReturnType<typeof useEnableSubscriptionTransferToWardMutation>;
export type EnableSubscriptionTransferToWardMutationResult = Apollo.MutationResult<EnableSubscriptionTransferToWardMutation>;
export type EnableSubscriptionTransferToWardMutationOptions = Apollo.BaseMutationOptions<EnableSubscriptionTransferToWardMutation, EnableSubscriptionTransferToWardMutationVariables>;
export const namedOperations = {
  Query: {
    addressSuggestions: 'addressSuggestions',
    skillCategories: 'skillCategories',
    account: 'account',
    accountDonations: 'accountDonations',
    achievementById: 'achievementById',
    benefactorStatusById: 'benefactorStatusById',
    benefactorStatuses: 'benefactorStatuses',
    achievements: 'achievements',
    newOrderInformation: 'newOrderInformation',
    orderStatus: 'orderStatus',
    benefactorById: 'benefactorById',
    benefactorByIdWithoutPermission: 'benefactorByIdWithoutPermission',
    benefactorPaymentInformationById: 'benefactorPaymentInformationById',
    BenefactorPermissionsById: 'BenefactorPermissionsById',
    benefactors: 'benefactors',
    benefactorsWithoutPermissions: 'benefactorsWithoutPermissions',
    benefactorAchievements: 'benefactorAchievements',
    BenefactorApplications: 'BenefactorApplications',
    BenefactorAttendances: 'BenefactorAttendances',
    benefactorOrders: 'benefactorOrders',
    benefactorByIdStatuses: 'benefactorByIdStatuses',
    benefactorSubscriptions: 'benefactorSubscriptions',
    DonationsPayment: 'DonationsPayment',
    categories: 'categories',
    categoryById: 'categoryById',
    cities: 'cities',
    CityById: 'CityById',
    limitedLiabilityCompanyReport: 'limitedLiabilityCompanyReport',
    companyReports: 'companyReports',
    Amounts: 'Amounts',
    Statistics: 'Statistics',
    EventPermissionsById: 'EventPermissionsById',
    FoundationEventById: 'FoundationEventById',
    FoundationById: 'FoundationById',
    FoundationPermissionsById: 'FoundationPermissionsById',
    Foundations: 'Foundations',
    Events: 'Events',
    Applications: 'Applications',
    Attendance: 'Attendance',
    Me: 'Me',
    Administrators: 'Administrators',
    UserById: 'UserById',
    broadcastNotifications: 'broadcastNotifications',
    projectExpenses: 'projectExpenses',
    project: 'project',
    specialProjectById: 'specialProjectById',
    specialProjects: 'specialProjects',
    storyById: 'storyById',
    storyBlockById: 'storyBlockById',
    StoryBlock: 'StoryBlock',
    clientVersions: 'clientVersions',
    benefactorsWithDepositAccounts: 'benefactorsWithDepositAccounts',
    wardById: 'wardById',
    wardNeedAccountsByIds: 'wardNeedAccountsByIds',
    regularNeedPeriodById: 'regularNeedPeriodById',
    regularNeedPeriods: 'regularNeedPeriods',
    wards: 'wards'
  },
  Mutation: {
    PrepareFilesUpload: 'PrepareFilesUpload',
    sendOneTimeNeedReport: 'sendOneTimeNeedReport',
    sendProjectExpenseReport: 'sendProjectExpenseReport',
    sendRegularNeedPeriodReport: 'sendRegularNeedPeriodReport',
    transferFundsToAccount: 'transferFundsToAccount',
    addAchievementWithMaterialPrizes: 'addAchievementWithMaterialPrizes',
    addAchievementWithPromoCodes: 'addAchievementWithPromoCodes',
    addAchievementWithUnlimitedNumberOfPrizes: 'addAchievementWithUnlimitedNumberOfPrizes',
    addBenefactorStatus: 'addBenefactorStatus',
    uploadPromoCodes: 'uploadPromoCodes',
    deleteAchievement: 'deleteAchievement',
    updateAchievementWithMaterialPrizes: 'updateAchievementWithMaterialPrizes',
    updateAchievementWithPromoCodes: 'updateAchievementWithPromoCodes',
    updateAchievementWithUnlimitedNumberOfPrizes: 'updateAchievementWithUnlimitedNumberOfPrizes',
    publishAchievement: 'publishAchievement',
    unPublishAchievement: 'unPublishAchievement',
    deleteBenefactorStatus: 'deleteBenefactorStatus',
    updateBenefactorStatus: 'updateBenefactorStatus',
    createBenefactor: 'createBenefactor',
    UpdateBenefactorEmailByAdministrator: 'UpdateBenefactorEmailByAdministrator',
    changePhoneNumberByAdministrator: 'changePhoneNumberByAdministrator',
    PlaceOrder: 'PlaceOrder',
    createBenefactorDepositAccountForAllWards: 'createBenefactorDepositAccountForAllWards',
    createBenefactorDepositAccountForProjectNeeds: 'createBenefactorDepositAccountForProjectNeeds',
    createBenefactorDepositAccountForSpecificWards: 'createBenefactorDepositAccountForSpecificWards',
    createBenefactorDepositAccountWithoutConditions: 'createBenefactorDepositAccountWithoutConditions',
    placeOrderDeposit: 'placeOrderDeposit',
    UpdateBenefactorByAdministrator: 'UpdateBenefactorByAdministrator',
    UpdateBenefactorPersonalDataByAdministrator: 'UpdateBenefactorPersonalDataByAdministrator',
    UpdateBenefactorVolunteerInformationByAdministrator: 'UpdateBenefactorVolunteerInformationByAdministrator',
    UpdateBenefactorSkillsByAdministrator: 'UpdateBenefactorSkillsByAdministrator',
    updateBenefactorDepositAccountForSpecificWards: 'updateBenefactorDepositAccountForSpecificWards',
    addCategory: 'addCategory',
    deleteCategory: 'deleteCategory',
    updateCategory: 'updateCategory',
    updateCategoriesOrder: 'updateCategoriesOrder',
    addCity: 'addCity',
    UpdateCity: 'UpdateCity',
    DeleteCity: 'DeleteCity',
    createCompanyReport: 'createCompanyReport',
    updateCompanyBalance: 'updateCompanyBalance',
    publishLimitedLiabilityCompanyReport: 'publishLimitedLiabilityCompanyReport',
    updateCompanyReportDocuments: 'updateCompanyReportDocuments',
    addGrocerySetNeed: 'addGrocerySetNeed',
    addOneTimeNeed: 'addOneTimeNeed',
    updateOneTimeNeed: 'updateOneTimeNeed',
    forgotPassword: 'forgotPassword',
    AddFoundationEvent: 'AddFoundationEvent',
    UpdateFoundationEvent: 'UpdateFoundationEvent',
    AddFoundation: 'AddFoundation',
    UpdateFoundation: 'UpdateFoundation',
    CreateFoundationRepresentative: 'CreateFoundationRepresentative',
    UpdateFoundationEventReport: 'UpdateFoundationEventReport',
    ApproveFoundationEvent: 'ApproveFoundationEvent',
    CancelFoundationEvent: 'CancelFoundationEvent',
    CloseFoundationEvent: 'CloseFoundationEvent',
    CloseFoundationEventRegistration: 'CloseFoundationEventRegistration',
    OpenFoundationEventRegistration: 'OpenFoundationEventRegistration',
    RequestFoundationEventApproval: 'RequestFoundationEventApproval',
    TakeFoundationEventOwnership: 'TakeFoundationEventOwnership',
    ConvertFoundationEventApplicationToAttendance: 'ConvertFoundationEventApplicationToAttendance',
    DeclineFoundationEventApplication: 'DeclineFoundationEventApplication',
    SelectFoundationEventApplication: 'SelectFoundationEventApplication',
    UpdateFoundationEventAttendanceRating: 'UpdateFoundationEventAttendanceRating',
    ChangeUserPassword: 'ChangeUserPassword',
    CreateModerator: 'CreateModerator',
    CreateSystemAdministrator: 'CreateSystemAdministrator',
    UpdateFoundationRepresentative: 'UpdateFoundationRepresentative',
    UpdateModerator: 'UpdateModerator',
    UpdateSystemAdministrator: 'UpdateSystemAdministrator',
    DeleteUser: 'DeleteUser',
    EnableOrDisableUser: 'EnableOrDisableUser',
    addBroadcastNotification: 'addBroadcastNotification',
    updateProjectExpenseDocuments: 'updateProjectExpenseDocuments',
    addProjectExpense: 'addProjectExpense',
    updateProjectRequirements: 'updateProjectRequirements',
    resetPassword: 'resetPassword',
    createSpecialProject: 'createSpecialProject',
    updateSpecialProject: 'updateSpecialProject',
    updateSpecialProjectsVisibilityOrder: 'updateSpecialProjectsVisibilityOrder',
    cancelSpecialProject: 'cancelSpecialProject',
    closeSpecialProject: 'closeSpecialProject',
    publishSpecialProject: 'publishSpecialProject',
    sendReportSpecialProjectReport: 'sendReportSpecialProjectReport',
    unpublishSpecialProject: 'unpublishSpecialProject',
    updateSpecialProjectDocuments: 'updateSpecialProjectDocuments',
    addStoryBlock: 'addStoryBlock',
    updateStoryBlock: 'updateStoryBlock',
    addStory: 'addStory',
    DeleteStory: 'DeleteStory',
    changeStoriesOrder: 'changeStoriesOrder',
    publishStory: 'publishStory',
    unpublishStory: 'unpublishStory',
    UpdateStory: 'UpdateStory',
    UpdateStoryScreens: 'UpdateStoryScreens',
    addClientVersion: 'addClientVersion',
    addWard: 'addWard',
    addWardsToTheBenefactorDepositAccountForSpecificWards: 'addWardsToTheBenefactorDepositAccountForSpecificWards',
    removeWardFromTheBenefactorDepositAccountForSpecificWards: 'removeWardFromTheBenefactorDepositAccountForSpecificWards',
    updateWard: 'updateWard',
    publishWard: 'publishWard',
    unpublishWard: 'unpublishWard',
    DeactivateWard: 'DeactivateWard',
    updateWardDocuments: 'updateWardDocuments',
    updateOneTimeNeedDocuments: 'updateOneTimeNeedDocuments',
    updateRegularNeedPeriodDocuments: 'updateRegularNeedPeriodDocuments',
    archiveNeed: 'archiveNeed',
    RestInPeace: 'RestInPeace',
    markWardAsNotRequiringImmediateHelp: 'markWardAsNotRequiringImmediateHelp',
    markWardAsRequiringImmediateHelp: 'markWardAsRequiringImmediateHelp',
    disableSubscriptionTransferToWard: 'disableSubscriptionTransferToWard',
    enableSubscriptionTransferToWard: 'enableSubscriptionTransferToWard'
  },
  Fragment: {
    Account: 'Account',
    AccountBenefactorInformation: 'AccountBenefactorInformation',
    AccountWardInformation: 'AccountWardInformation',
    SpecialProjectAccountProps: 'SpecialProjectAccountProps',
    AchievementsProps: 'AchievementsProps',
    BenefactorStatusProps: 'BenefactorStatusProps',
    BenefactorExtendedProps: 'BenefactorExtendedProps',
    BenefactorExtendedPropsWithoutPermission: 'BenefactorExtendedPropsWithoutPermission',
    BenefactorPaymentInformation: 'BenefactorPaymentInformation',
    cloudPaymentsSavedCardsProps: 'cloudPaymentsSavedCardsProps',
    BenefactorProps: 'BenefactorProps',
    DonationSubscriptionProps: 'DonationSubscriptionProps',
    DonationWardInfo: 'DonationWardInfo',
    BankTransferPaymentIntentProps: 'BankTransferPaymentIntentProps',
    NewCardPaymentIntentProps: 'NewCardPaymentIntentProps',
    SavedCardPaymentIntentProps: 'SavedCardPaymentIntentProps',
    SbpPaymentIntentProps: 'SbpPaymentIntentProps',
    VoucherPaymentIntentProps: 'VoucherPaymentIntentProps',
    OrderAmountSummaryProps: 'OrderAmountSummaryProps',
    OrdersProps: 'OrdersProps',
    PaymentDetailsProps: 'PaymentDetailsProps',
    PaymentIntentProps: 'PaymentIntentProps',
    PaymentWithDonationGrid: 'PaymentWithDonationGrid',
    BankTransferPaymentDetailsProps: 'BankTransferPaymentDetailsProps',
    CloudPaymentsCardFailedPaymentDetailsProps: 'CloudPaymentsCardFailedPaymentDetailsProps',
    GenericFailedPaymentDetailsProps: 'GenericFailedPaymentDetailsProps',
    SbpFailedPaymentDetailsProps: 'SbpFailedPaymentDetailsProps',
    SbpSuccessfulPaymentDetailsProps: 'SbpSuccessfulPaymentDetailsProps',
    VoucherFailedPaymentDetailsProps: 'VoucherFailedPaymentDetailsProps',
    VoucherSuccessfulPaymentDetailsProps: 'VoucherSuccessfulPaymentDetailsProps',
    CardSuccessfulPaymentDetailsProps: 'CardSuccessfulPaymentDetailsProps',
    CategoryProps: 'CategoryProps',
    CityProps: 'CityProps',
    CompanyReportProps: 'CompanyReportProps',
    LimitedLiabilityCompanyReport: 'LimitedLiabilityCompanyReport',
    StatisticsPeriodFragment: 'StatisticsPeriodFragment',
    EventDetailsProps: 'EventDetailsProps',
    FoundationEventProps: 'FoundationEventProps',
    FoundationProps: 'FoundationProps',
    NeedProps: 'NeedProps',
    OneTimeNeedProps: 'OneTimeNeedProps',
    RegularNeedProps: 'RegularNeedProps',
    RegularNeedPeriodProps: 'RegularNeedPeriodProps',
    PermissionsProps: 'PermissionsProps',
    BroadcastNotificationProps: 'BroadcastNotificationProps',
    ProjectExpenseProps: 'ProjectExpenseProps',
    SpecialProjectsProps: 'SpecialProjectsProps',
    StoryProps: 'StoryProps',
    BlockStoriesProps: 'BlockStoriesProps',
    screenStoryProps: 'screenStoryProps',
    VersionProps: 'VersionProps',
    WardProps: 'WardProps',
    WardsGridWard: 'WardsGridWard',
    AuditMarkerProps: 'AuditMarkerProps',
    DocumentFileProps: 'DocumentFileProps',
    PhotoFileProps: 'PhotoFileProps',
    UserBaseProps: 'UserBaseProps',
    UserProps: 'UserProps'
  }
}